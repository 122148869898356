import {
  createStyles,
  Theme,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  CompInputRootCommonStyle,
  CompOptionalInputCommonStyle,
  CompOptionalStyleInputCommonStyle,
} from "./CommonComponent.style";

export const CustomInputStyle = (theme: Theme) =>
  createStyles({
    root: {
      color: "#000",
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: "53px !important",
      fontFamily: "roboto",
      fontSize: 16,
    },
    ...CompOptionalStyleInputCommonStyle,
    ...{ ...CompOptionalInputCommonStyle() },
  });

export const CustomInputOptionalStyle = makeStyles((theme: Theme) => ({
  ...CompInputRootCommonStyle,
  ...CompOptionalStyleInputCommonStyle,
  ...{ ...CompOptionalInputCommonStyle() },
  container: {
    marginTop: 20,
  },
}));

export const TimeInputTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#36dcb6",
      main: "#36dcb6",
      dark: "#36dcb6",
      contrastText: "#fff",
    },
  },
});
export const TimeInputComponentStyles = (theme: Theme) =>
  createStyles({
    root: {
      // color: "#000",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      //width: 512,
      paddingLeft: 0,
      margin: "8px 0px 4px 0px",
      fontFamily: "roboto",
      fontSize: 16,
      height: "20px !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.09,
      color: "rgba(0, 0, 0, 0.87)",
      paddingTop: 0,
    },
    title: {
      marginTop: 21,
      color: "#263238",
    },
    timezoneTitle: {
      marginTop: 21,
      color: "#263238",
      marginRight: 10,
    }
  });

export const TextAreaComponentSTyles = (theme: Theme) =>
  createStyles({
    root: {
      // color: "#000",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      //width: 512,
      paddingLeft: 0,
      margin: "8px 0px 4px 0",
      fontFamily: "roboto",
      fontSize: 16,
      height: "91px !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.09,
      color: "rgba(0, 0, 0, 0.87)",
      paddingTop: 0,
    },
    title: {
      marginTop: 21,
    },
    countText: {
      textAlign: "right",
    },
  });

export const CustomInputComponentStyles = (theme: Theme) =>
  createStyles({
    ...CompInputRootCommonStyle,
    ...CompOptionalStyleInputCommonStyle,
    ...{ ...CompOptionalInputCommonStyle() },
  });

export const CustomInputOptionalComponentStyles = makeStyles((theme) => ({
  ...CompInputRootCommonStyle,
  ...CompOptionalStyleInputCommonStyle,
  ...{ ...CompOptionalInputCommonStyle() },
  container: {
    marginTop: 20,
  },
}));

export const SelectComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      width: 500,
    },
    selectStyle: {
      backgroundColor: "#fff",
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
    optional: {
      textAlign: "right",
    },
  })
);

export const PasswordFieldComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#000",
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: "53px !important",
      margin: "12px 0px 0px 0px",
      fontFamily: "roboto",
      fontSize: 16,
    },
    icon: {
      color: "rgb(28,28,29)",
    },
  })
);

export const MultiSelectComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: 500,
    },
    optional: {
      textAlign: "right",
      marginBottom: -29,
    },
  })
);

export const InputWithCountComponentStyles = (theme: Theme) =>
  createStyles({
    ...CompInputRootCommonStyle,
    ...CompOptionalStyleInputCommonStyle,
    countText: {
      textAlign: "right",
      marginTop: -20,
      marginRight: 14,
    },
    countTextSL: {
      textAlign: "right",
      marginTop: -20,
      marginRight: 12,
    },
    countTextPriceTableId: {
      textAlign: "right",
      marginTop: -28,
      marginRight: 12,
    }
  });

export const InputOptionalWithCountComponentStyles = makeStyles((theme) => ({
  ...CompInputRootCommonStyle,
  ...CompOptionalStyleInputCommonStyle,
  ...{ ...CompOptionalInputCommonStyle() },
  container: {
    marginTop: 20,
  },
}));

export const InputComponentStyles = (theme: Theme) =>
  createStyles({
    root: {
      color: "#000",
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: 56,
      width: 328,
      paddingLeft: 5,
      margin: 8,
      fontFamily: "roboto",
      fontSize: 16,
    },
  });
