import { createStyles, Theme, WithStyles } from "@material-ui/core";

export const ContentStyle = (theme: Theme) =>
  createStyles({
    main: {
      flex: 1,
      // padding: "172px 32px 32px 32px",
      background: "rgb(239,239,244)",
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
    },
  });

export interface ContentProps extends WithStyles<typeof ContentStyle> {}
