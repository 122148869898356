import { EVENT_TAB_VALUES, userRoleEnums } from "constants/enums";
import { selectUser } from "_pages/loginscreen/authSlice";
import {
  eventsData,
  eventStatus,
  setScheduleTabData,
  updateEventStatus,
  getStreamingLinkfromApi,
  streamingLink,
  getStreamingLink,
  calFilteredData,
  applyFilterDataIntoStorage,
  setFilterDataIntoStorage,
  errorInStreamingLink
} from "_pages/scheduleEvents/ScheduleEventSlice";
import {
  getTenantsListfromApi,
  selectTenants,
} from "_pages/tenants/tenantSlice";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { getScheduleTabData } from "../../_pages/scheduleEvents/ScheduleEventSlice";
import { selectTenantAsProviders } from "_pages/provider/providerSlice";
import { getSessionCalFilters } from "utils/utilityFunctions";
import { getmetaDataFromApi } from "_pages/contentTitles/contentTitleSlice";
export const useScheduleContentHook = () => {
  const dispatch = useDispatch();
  const { tenants } = useSelector(selectTenants);
  const user = useSelector(selectUser);
  const [isChooseClass, setChooseClass] = useState(null as any);
  const [data, setData] = useState(null);
  const [selectedEventData, setEventData] = useState(null);
  const { tenantAsProviders } = useSelector(selectTenantAsProviders);

  const scheduleEventsData = useSelector(eventsData);
  const existTabData = useSelector(getScheduleTabData);
  const [showScheduleTable, setScheduleTable] = useState(false);
  const [showScheduleEventCreation, setShowScheduleEventCreation] = useState(
    false
  );
  const [selectedTenantId, setSelectedTenantId] = useState<string | null>("");
  const [selectedTenantName, setSelectedTenantName] = useState("");
  const [showVideoMetaData, setshowVideoMetaData] = useState(false);

  const [isHeaderDisabled, setHeaderDisabaled] = useState(false);

  const [selectedEventId, setId] = useState(null as any);

  const [connect, setConnect] = useState(null as any);
  const [selectedChooseClass, setSelectedChooseClass] = useState(null as any);
  const [fillChooseClass, setFillChooseClass] = useState(null as any);
  const [numSelected, setSelectedNumbers] = useState(0);
  const [payLoadToDelete, setDeletablePayload] = useState([] as any);
  const [payLoadToDeleteTitles, setDeletablePayloadTitles] = useState(
    [] as any
  );
  const eventCreatedStatus = useSelector(eventStatus);
  const [confirmEventTypePopUp, setEventTypePopUp] = useState(false);
  const [eventType, setEventType] = useState(false);
  const filterData = useSelector(calFilteredData);
  const { showCalendar: showCalendarPage } = useSelector(calFilteredData);
  const streamingLinkData = useSelector(streamingLink);
  const streamingLinkError = useSelector(errorInStreamingLink);
  const [fromView, setFromView] = useState("ListView");
  const [creationDate, setCreationDate] = useState(new Date().toISOString());

  const selectedEvent: any = (event) => {
    setEventData(event[0]);
    setId(event[0].event_Id);
    setShowScheduleEventCreation(true);
    setScheduleTable(false);
    setHeaderDisabaled(false);
    setChooseClass(false);
    dispatch(updateEventStatus({ status: event[0] }));
  };
  useEffect(() => {
    const resultParams = getSessionCalFilters(filterData);
    dispatch(applyFilterDataIntoStorage(resultParams));
  }, []);
  useEffect(() => {
    if (tenants) {
      if (user?.role == userRoleEnums.CS_USER) {
        let connect = tenants.filter((obj) => {
          if (obj.tenant) {
            if (obj.tenant.tenantID == localStorage.getItem("csTenantId")) {
              setConnect(obj.tenant.connect);
            }
            return obj.tenant.connect;
          }
        });
      }
    }
  }, [tenants, localStorage.getItem("csTenantId")]);

  useEffect(() => {
    if (tenants) {
      if (user?.role == userRoleEnums.ADMIN_USER) {
        let filter = tenants.filter((obj) => {
          if (obj.tenant) {
            return obj.tenant.providerEmail;
          }
          return false;
        });
        setData(filter);
      } else {
        setData(tenants);
      }
    }
  }, [tenants]);

  const resetChooseClassFilter = () => {
    batch(() => {
      dispatch(updateEventStatus({ status: "" }));
      //dispatch(resetFilterData(null));
    });
  };
  const clearScheduleTabData = () => {
    dispatch(setScheduleTabData({}));
  };
  const updateScheduleTabData = (_: any) => {
    dispatch(setScheduleTabData(_));
  };
  useEffect(() => {
    setId("");
    clearScheduleTabData();
  }, []);

  useEffect(() => {
    if (showScheduleTable === true) {
      setId(null);
      //  setEventData(null)
    }
  }, [showScheduleTable]);

  useEffect(() => {
    // ! due to changes in api
    const eventData =
      scheduleEventsData?.eventsData?.data ?? scheduleEventsData?.eventsData;
    if (selectedEventId && eventData?.length > 0 && existTabData?.tabIndex !== EVENT_TAB_VALUES.VOD) {
      if (selectedEventId !== "") {
        let event = eventData?.filter(
          (events) => events.event_Id === selectedEventId
        );
        if (event?.length > 0) {
          dispatch(updateEventStatus({status: event[0]}));
          setEventData(event[0]);
        }
      }
    }
  }, [selectedEventId, scheduleEventsData]);

  useEffect(() => {
    if (eventCreatedStatus.status) {
      if (eventCreatedStatus?.status?.event_Id != "") {
        setEventData(eventCreatedStatus?.status);
        setId(eventCreatedStatus?.status?.event_Id);
      }
    }
  }, [eventCreatedStatus]);

  useEffect(() => {
    setScheduleTable(true);
    setSelectedChooseClass(null);
    if (user?.role == userRoleEnums.CS_USER) {
      dispatch(getTenantsListfromApi());
    }
  }, []);

  useEffect(() => {
    if (streamingLinkError) {
      setSelectedChooseClass(null);
      setFillChooseClass(null);
    }
  }, [streamingLinkError]);

  useEffect(() => {
    if (user?.role != userRoleEnums.TENANT_AS_PROVIDER_USER)
      dispatch(getTenantsListfromApi());
    else {
      setScheduleTable(true);
      setSelectedTenantName(user?.displayName);
      setSelectedTenantId("");
    }
  }, []);

  useEffect(() => {
    if (user?.role == userRoleEnums.CS_USER) {
      setScheduleTable(true);
      if (localStorage.getItem("csTenantId") !== null) {
        setSelectedTenantId(localStorage.getItem("csTenantId"));
        dispatch(getmetaDataFromApi());
      }
    }
  }, []);

  useEffect(() => {
    if (user?.role == userRoleEnums.CS_USER) {
      setSelectedTenantId(localStorage.getItem("csTenantId"));
    }
  }, [localStorage.getItem("csTenantId")]);

  const setShowCalendarPage = (val) => {
    dispatch(setFilterDataIntoStorage({...filterData, showCalendar: val}));
  }

  const handleCalView = (val) => {
    dispatch(applyFilterDataIntoStorage({...filterData, showCalendar: val}));
  }

  const setStreamingLink = (providerId, tag, tenantId, from: string) => {
    dispatch(getStreamingLinkfromApi(providerId, tag, tenantId, from));
  }

  const resetStreamingLink = () => {
    dispatch(getStreamingLink({streamingLink: ""}))
  }

  return {
    setScheduleTable,
    setShowScheduleEventCreation,
    setEventData,
    setshowVideoMetaData,
    setId,
    clearScheduleTabData,
    setHeaderDisabaled,
    setSelectedTenantId,
    setSelectedTenantName,
    selectedEvent,
    selectedTenantId,
    connect,
    showScheduleTable,
    selectedTenantName,
    user,
    showScheduleEventCreation,
    selectedEventData,
    setShowCalendar: setShowCalendarPage,
    setChooseClass,
    setSelectedChooseClass,
    setFillChooseClass,
    showCalendar: showCalendarPage,
    isChooseClass,
    selectedChooseClass,
    fillChooseClass,
    setSelectedNumbers,
    setDeletablePayload,
    setDeletablePayloadTitles,
    resetChooseClassFilter,
    setEventTypePopUp,
    confirmEventTypePopUp,
    setEventType,
    eventType,
    existTabData,
    updateScheduleTabData,
    tenantAsProviders,
    tenantsList: tenants,
    setStreamingLink,
    streamingLinkData,
    resetStreamingLink,
    fromView,
    setFromView,
    handleCalView,
    creationDate,
    setCreationDate,
    streamingLinkError,
  };
};
