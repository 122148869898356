import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { apiResponseEnums, USER_MESSAGES } from "constants/enums";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage, handleUnauthorized } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";

interface ProviderState {
  providerData: any;
  error: string;
  isProviderCreated: boolean;
  isProviderUpdated: boolean;
  tenantAsProviderData: any;
}

const initialState: ProviderState = {
  providerData: {},
  isProviderCreated: false,
  isProviderUpdated: false,
  error: "",
  tenantAsProviderData: {},
};

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    getProviderList: (state, action: PayloadAction<any>) => {
      let response = action.payload;
      let tenantAsProviders = [] as any;
      //if (user?.role === userRoleEnums.ADMIN_USER) {
      if (response && response.providers && Array.isArray(response.providers)) {
        response.providers = response.providers.filter((obj) => {
          if (obj.isTenantProvider && obj.isTenantProvider == true) {
            tenantAsProviders.push(obj);
            return false;
          }
          return true;
        });
      }
      //}
      state.providerData = response;
      state.tenantAsProviderData = {
        tenantAsProviders: tenantAsProviders,
      };
    },
    providerCreated: (state, action: PayloadAction<any>) => {
      state.isProviderCreated = action.payload;
    },
    providerUpdated: (state, action: PayloadAction<any>) => {
      state.isProviderUpdated = action.payload;
    },
    errorInProviderCreation: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getProviderList,
  providerCreated,
  providerUpdated,
  errorInProviderCreation,
} = providerSlice.actions;

// RR || The function below is called to check user auth status from firebase

export const createProvider = (data: any, history): AppThunk => (dispatch) => {
  let body = {
    displayName: data.name,
    email: data.email,
    password: data.password,
    phoneNumber: data.phone.length > 0 ? data.phone.replace(/\s/g, "") : null,
    contact: data.contact,
    userRole: 1,
    mediaPlatform: data?.mediaPlatform == "0" ? 0 : 1,
    jwPlayerSiteId: data?.mediaPlatform == "1" ? data?.jwPlayerSiteId : null,
    jwPlayerSecret: data?.mediaPlatform == "1" ? data?.jwPlayerSecret : null,
  };

  userApi.post("/api/v1/provider", body).then((res) => {
    if (res.status === apiResponseEnums.CREATED) {
      dispatch(providerCreated(true));
      setTimeout(function() {
        dispatch(providerCreated(false));
      }, 3000);
      history.push('/providers');
      dispatch(getProviderListfromApi());
      dispatch(
        setApiResponse({
          status: res.status as number,
          data: USER_MESSAGES.PUBLISHED,
        })
      );
    } else {
      dispatch(errorInProviderCreation("Please Check You Data"));
      setTimeout(function() {
        dispatch(errorInProviderCreation(""));
      }, 3000);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};
export const updateProvider = (
  data: any,
  existingProviderData: any,
  history,
): AppThunk => (dispatch) => {
  let body = {
    name: data.name,
    email: existingProviderData.email,
    phoneNumber:
      data.phone && data.phone.length > 0
        ? data.phone.replace(/\s/g, "")
        : null,
    contact: data.contact,
    mediaPlatform: data?.mediaPlatform == "0" ? 0 : 1,
    jwPlayerSiteId: data?.mediaPlatform == "1" ? data?.jwPlayerSiteId : null,
    jwPlayerSecret: data?.mediaPlatform == "1" ? data?.jwPlayerSecret : null,
    tenantId: existingProviderData.tenantId,
    isTenantProvider: existingProviderData.isTenantProvider,
    tag: existingProviderData.tag,
    stage1Encode: existingProviderData.stage1Encode,
  };

  userApi
    .put(`${ApiConstants.updateProviderAPI}/${existingProviderData.tag}`, body)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(providerUpdated(true));
        setTimeout(function() {
          dispatch(providerUpdated(false));
        }, 3000);
        history.push('/providers');
        dispatch(getProviderListfromApi());
        dispatch(setApiResponse({ status: res.status, data: USER_MESSAGES.UPDATED }));
      } else {
        dispatch(errorInProviderCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInProviderCreation(""));
        }, 3000);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};
export const getProviderListfromApi = (): AppThunk => (dispatch) => {
  userApi.get(ApiConstants.getProviderListAPI).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getProviderList({ providers: res.data }));
    } else {
      const callback = () => dispatch(regfeshTokenId(getProviderListfromApi));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(getProviderList({ providers: [] }));
    }
  });
};

export const deletProvider = (uids: any): AppThunk => (dispatch) => {
  dispatch(getProviderList({ providers: null }));
  userApi.delete(`/api/v1/providers`, uids).then((res) => {
    dispatch(getProviderListfromApi());
  });
};

export const selectProviders = (state: RootState) =>
  state.provider.providerData;
export const providerCreatedStatus = (state: RootState) =>
  state.provider.isProviderCreated;
export const providerCreatedError = (state: RootState) => state.provider.error;
export const selectTenantAsProviders = (state: RootState) =>
  state.provider.tenantAsProviderData;
export const providerUpdatedStatus = (state: RootState) =>
  state.provider.isProviderUpdated;

export default providerSlice.reducer;
