import { create } from 'apisauce'

const BASE_URL = process.env.REACT_APP_API_URI;

export const userApi = create({
    baseURL: BASE_URL,
    // Accept: 'application/json', 
    headers: {
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-language': 'en-GB'
    },
});
export const tenantHeaderApi = create({
  baseURL: BASE_URL,
  // Accept: 'application/json',
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "no-cache",
    "Accept-language": "en-GB",
  },
});
export const downloadreportApi = create({
    baseURL: BASE_URL,
    // Accept: 'application/json', 
    headers: {
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-language': 'en-GB'
    },
    responseType: 'arraybuffer'
});


export const uploadApi = create({
    baseURL: BASE_URL,
    // Accept: 'application/json',
    headers: {
        'Content-Type': 'multipart/form-data',
        // 'Cache-Control': 'no-cache'
    }
});


export const api = create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        'Accept-language': 'en-GB'
        //'Content-Type': 'multipart/form-data'
    }
});

export const uploadJsonApi = create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Accept-language': 'en-GB'
        // 'Cache-Control': 'no-cache'
    }
});

export const streamingLinkApi = create({
    baseURL: BASE_URL,
    headers: {
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-language': 'en-GB',
    },
});

uploadJsonApi.addRequestTransform(request => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');

})
uploadApi.addRequestTransform(request => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
})

userApi.addRequestTransform(request => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
})

tenantHeaderApi.addRequestTransform((request) => {
  request.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
});
downloadreportApi.addRequestTransform(request => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
})

export const streamingLinkApiHeaderTransform = (tenantId = false) => {
    streamingLinkApi.addRequestTransform(request => {
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        if (request.headers["tenantId"]) delete request.headers["tenantId"];
        if (tenantId) {
            request.headers['tenantId'] = tenantId;}
    });
}

export const tenantHeaderTransform = (tenantId = false) => {
  tenantHeaderApi.addRequestTransform((request) => {
    if (request.headers["TenantID"]) delete request.headers["TenantID"];
    if (tenantId) {
        request.headers["TenantID"] = tenantId;
      }
  });
};
 


// mock.onAny().reply((config) => {
//     const [method, url, ...response]:any = responses.shift();
//     if (config.url === url && config.method.toUpperCase() === method)
//       return response;
//     // Unexpected request, error out
//     return [500, {}];
//   });