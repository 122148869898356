import { titleStatusEnums, userRoleEnums } from "constants/enums";
import { selectTenants } from "_pages/channels/channelSlice";
import { copyURL } from "_pages/collections/CollectionsSlice";
import {
  isMetaDataFormUpdated,
  selectMetaData,
} from "_pages/contentTitles/contentTitleSlice";
import { selectUser } from "_pages/loginscreen/authSlice";
import { selectTenantAsProviders } from "_pages/provider/providerSlice";
import {
  addEvent,
  eventStatus,
  isEventCreated,
  isEventKeyCreated,
  liveEventKey,
  Loading,
  updateEvent,
  updateEventStatus,
  videoUploadProgress,
} from "_pages/scheduleEvents/ScheduleEventSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  contentFormValidation,
  contentFormValidationForNextFourHrs,
  contentFormValidationForPastDateTime,
  ScrollTop,
} from "utils/utilityFunctions";
import { useScheduleContentHook } from "./useScheduleContent.hook";
const _ = require("lodash");
const moment = require("moment-timezone");
const TranslateByLoad = require("assets/translations/en.json");

export const useScheduleFormHook = (props) => {
  const dispatch = useDispatch();
  const isEventCreatedSuccesfully = useSelector(isEventCreated);
  const isEventKeyCreatedSuccesfully = useSelector(isEventKeyCreated);

  const eventCreatedStatus = useSelector(eventStatus);
  const eventKeyGenerated = useSelector(liveEventKey);

  const progress = useSelector(videoUploadProgress);
  const isCheckboxDisabled = useSelector(Loading);

  const { tenants } = useSelector(selectTenants);
  const { tenantAsProviders } = useSelector(selectTenantAsProviders);
  const { metaData } = useSelector(selectMetaData);
  const user = useSelector(selectUser);
  const {
    data,
    tentantId,
    setCreatedEventAsSelected,
    updateEventData: setEventData,
    pageConfig,
  } = props;
  const updateEventData = (newformData: any) => {
    setFormData(newformData);
  };
  const [isEventLocked, setEventLock] = useState(false);

  const [isUploadedEnabled, setUploadEnable] = useState(false);
  const [isShowVideo, setShowVideo] = useState(false);
  const [isEventSwitched, setEventSwitched] = useState(false);
  const [isLiveEventCopyUrl, setLiveEventCopyUrl] = useState(false);
  const [isOldEventType, setOldEventType] = useState(null as any);
  const [confirmChangeEvent, setEventChangePopup] = useState(false);
  const [confirmChangeEventToLive, setEventChangePopupToLive] = useState(false);
  const [formData, setFormData] = useState({
    description: "",
    video: "" as any,
    cover: "",
    creationDate: new Date().toISOString(),
    title: "",
    tag: "",
    event_Id: "",
    tenantId: "",
    dateTime: new Date().toISOString(),
    isCompleted: false,
    active: false, // set true to activate event
    allowAutoPublish: false, //set true to published for autopublish on demand
    type: 0,
    eventKey: "" as any,
    durationSecond: 0 as any,
    category: 2,
  });
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isFormPrefilled, setPreFilledStatus] = useState(false);
  const [isUserNavigatedToVideoMetadataFrom, setNavigationStatus] = useState(
    false
  );
  const [doesUserHasUnSavedChanges, setUnSavedChangesStatus] = useState(false);
  const [isFirstRender, setRenderStatus] = useState(true);
  const [isEventOld, setEventStatus] = useState(false);
  const [titleCount, setTitleCount] = useState(40);
  const [descCount, setDescCount] = useState(500);
  const [uploadingStatus, setUploadingStatus] = useState(null as any);
  const [isAnotherDownloadGoingOn, setAnotherDownloadStatus] = useState(false);
  const [showVideoMetaData, setShowMetaDataForm] = useState(false);
  const [tenantData, setTenantData] = useState(null as any);
  const [disableDates, setDisableDates] = useState([] as any);
  const [showToolTip, setShowTooltip] = useState(false);
  const [toolTipMessage, setTooltipMessage] = useState(
    "Please add metadata to modify this event."
  );
  const [preventPreFilled, setPreventStatus] = useState(false);
  const formUpdateStatus = useSelector(isMetaDataFormUpdated);
  const [isEventKeyCreation, setEventKeyCreation] = useState(false);
  const [isGettingEventKey, setGettingEventKey] = useState(false);
  const [showLiveMetaDataForm, setLiveMetaDataForm] = useState(false);
  const copyUrl = (event) => {
    if (formData.eventKey) {
      navigator.clipboard.writeText(formData.eventKey);
      dispatch(copyURL(true));
    }
  };

  useEffect(() => {
    if (eventCreatedStatus.status) {
      if (eventCreatedStatus.status) {
        setFormData({
          ...formData,
          cover: eventCreatedStatus.status.imageUrl,
          tenantId: eventCreatedStatus.status.tenantId,
          event_Id: eventCreatedStatus.status.event_Id,
          tag: eventCreatedStatus.status.event_Id,
          dateTime: eventCreatedStatus.status?.schedule
            ? eventCreatedStatus.status?.schedule?.next_Occurence_Time !== null
              ? eventCreatedStatus.status?.schedule?.next_Occurence_Time
              : eventCreatedStatus.status?.schedule?.daily_Time
            : eventCreatedStatus.status?.dateTime,
        });
        setCreatedEventAsSelected(eventCreatedStatus.status.event_Id);
        setEventStatus(true);
        updateEventData({
          ...formData,
          cover: eventCreatedStatus.status.imageUrl,
          tenantId: eventCreatedStatus.status.tenantId,
          event_Id: eventCreatedStatus.status.event_Id,
          tag: eventCreatedStatus.status.event_Id,
          dateTime: eventCreatedStatus.status?.schedule
            ? eventCreatedStatus.status?.schedule?.next_Occurence_Time !== null
              ? eventCreatedStatus.status?.schedule?.next_Occurence_Time
              : eventCreatedStatus.status?.schedule?.daily_Time
            : eventCreatedStatus.status?.dateTime,
        });
      }

      return () => {
        setCreatedEventAsSelected("");
        dispatch(updateEventStatus({ status: "" }));
      };
    }
  }, [eventCreatedStatus]);

  useEffect(() => {
    if (formUpdateStatus === true) {
      setLoading(true);
      setButtonStatus(true);
    } else {
      setLoading(false);
      setButtonStatus(true);
    }
  }, [formUpdateStatus]);

  useEffect(() => {
    if (isEventCreatedSuccesfully === true) {
      setLoading(false);
      setUploadEnable(true);
      setButtonStatus(true);
      if (
        formData.type == 2 &&
        formData?.eventKey == "" &&
        formData?.eventKey.length <= 0
      ) {
        setEventKeyCreation(true);
        setFormData({ ...formData, eventKey: "" });
      }
      setFormData({ ...formData });
    }
  }, [isEventCreatedSuccesfully]);

  useEffect(() => {
    if (isEventKeyCreatedSuccesfully === true) {
      if (eventKeyGenerated?.key?.eventKey) {
        setFormData({
          ...formData,
          eventKey: eventKeyGenerated?.key?.eventKey,
        });
        setLoading(false);
        setGettingEventKey(false);
        setEventKeyCreation(false);
        setLiveEventCopyUrl(true);
      }
    }
  }, [isEventKeyCreatedSuccesfully, eventKeyGenerated]);

  useEffect(() => {
    if (progress?.length > 0 && data) {
      let findProgess = progress?.find((item) => item.tag === data.event_Id);

      if (findProgess) {
        if (data?.event_Id === findProgess.tag) {
          setUploadingStatus(findProgess);
          setAnotherDownloadStatus(false);
          if (findProgess.progress === 1) {
            setEventLock(false);
          }
        }
      }
      if (progress === null) {
        //setAnotherDownloadStatus(false);
      }
    } else {
      setUploadingStatus(null);
    }
  }, [progress, data]);

  useEffect(() => {
    if (data && doesUserHasUnSavedChanges === false) {
      let isCompleted = false;
      setEventLock(data.isLocked);
      if (data?.video?.status) {
        let status = data.video.status;
        isCompleted =
          status === titleStatusEnums.Completed
            ? true
            : status === titleStatusEnums.Published;

        if (data.isLocked === false) {
          if (
            status === titleStatusEnums.EncodingQueued ||
            status === titleStatusEnums.NormalisationQueued
          ) {
            // locked because if user make changes in event backend will mark it as completed
            setEventLock(true);
          } else if (status === titleStatusEnums.Incomplete) {
            let checkIfFormFilled = contentFormValidation(
              data.video,
              false,
              data.type
            ); // returns false if form matadata is not completed
            if (data?.type == 1) setEventLock(!checkIfFormFilled);
            setShowTooltip(true);
            if (data?.type == 1) setPreFilledStatus(true);
            // if (data?.type == 2) setPreFilledStatus(true)
          } else {
            setShowTooltip(false);
          }
        }
      }
      if (data.type == 2 && data?.eventKey && data?.eventKey.length > 0) {
        //isCompleted = true;
        //setShowMetaDataForm(true)
        setLiveEventCopyUrl(true);
        setEventKeyCreation(false);
        if (data.allowAutoPublish == true) {
          setLiveMetaDataForm(true);
          //setEventLock(true);
        }
      }
      setFormData({
        description: data.description,
        video: data.video,
        cover: data.cover ? data.cover : data.imageUrl,
        creationDate: "",
        title: data.title,
        tag: data.event_Id,
        event_Id: data.event_Id,
        tenantId: data.tenantId,
        dateTime: data?.schedule
          ? data?.schedule?.next_Occurence_Time !== null
            ? data?.schedule?.next_Occurence_Time
            : data?.schedule?.daily_Time
          : data?.dateTime,
        isCompleted: isCompleted,
        active: data.active,
        allowAutoPublish: data.allowAutoPublish,
        type: data.type,
        eventKey: data.eventKey,
        category: data.category != "" ? data.category : 2,
        durationSecond: data.durationSecond,
      });
      setOldEventType(data.type);
      if (data?.type == 2 && (data?.eventKey == null || data?.eventKey == "")) {
        setEventKeyCreation(true);
      }
      if (data?.type == 1) {
        setShowVideo(true);
        setUploadEnable(true);
        //setShowMetaDataForm(true)
      }
      if (preventPreFilled === false) {
        setPreFilledStatus(true);
      }
      setButtonStatus(true);
      setLoading(false);

      if (data.video === null && data?.type == 1) {
        setUploadEnable(true);
      }
      if (data.video) {
        setEventStatus(true);
      }
      /// Not required
      //fillDisbaleDates(data);
    }
  }, [data]);

  useEffect(() => {
    if (isFirstRender === false) {
      if (
        !showVideoMetaData &&
        formData.title?.length > 0 &&
        formData.description?.length > 0 &&
        formData.cover?.length > 0 &&
        formData.type != 0 &&
        formData.category != 0 &&
        formData.durationSecond != 0
      ) {
        // if((isEventSwitched) || isOldEventType == 1 && formData.type == 1){
        //     setUnSavedChangesStatus(false)
        // } else setUnSavedChangesStatus(true);
        setUnSavedChangesStatus(false);

        if (isFormPrefilled === true) {
          setButtonStatus(true);
          setPreFilledStatus(false);
          setEventStatus(true); // need to remove this duplicate logic //Rajnneesh
          setUnSavedChangesStatus(false);
          // if (formData.video?.status === titleStatusEnums.EncodingQueued) {
          //     setUnSavedChangesStatus(false);
          // }
        } else {
          if (
            formData.video?.status === titleStatusEnums.EncodingQueued ||
            formData.video?.status === titleStatusEnums.NormalisationQueued
          ) {
            setUnSavedChangesStatus(false);
            setButtonStatus(true);
          } else {
            if (isEventCreatedSuccesfully) {
              setButtonStatus(true);
            } else {
              // setButtonStatus(false);

              //allowAutoPublish active
              if (
                _.isEqual(data?.title, formData.title) &&
                _.isEqual(data?.description, formData.description) &&
                _.isEqual(data?.imageUrl, formData.cover) &&
                _.isEqual(data?.type, formData.type) &&
                _.isEqual(data?.category, formData.category) &&
                _.isEqual(data?.allowAutoPublish, formData.allowAutoPublish) &&
                _.isEqual(data?.active, formData.active) &&
                _.isEqual(data?.durationSecond, formData.durationSecond) &&
                _.isEqual(
                  data?.schedule?.next_Occurence_Time,
                  formData.dateTime
                ) === true
              ) {
                setButtonStatus(true);
              } else {
                if (
                  formData.title?.length > 0 &&
                  formData.description?.length > 0 &&
                  formData.cover?.length > 0 &&
                  formData.type != 0 &&
                  formData.category != 0 &&
                  formData.durationSecond != 0
                ) {
                  setButtonStatus(false);
                } else {
                  setButtonStatus(true);
                }
              }
            }
          }
        }
        if (
          formData.video?.status === titleStatusEnums.Incomplete &&
          formData.type == 1
        ) {
          //&& formData.type == 1
          setEventLock(true);
        }
      } else {
        if (
          data &&
          _.isEqual(data?.title, formData.title) &&
          _.isEqual(data?.description, formData.description) &&
          _.isEqual(data?.imageUrl, formData.cover) &&
          _.isEqual(data?.type, formData.type) &&
          _.isEqual(data?.category, formData.category) &&
          _.isEqual(data?.allowAutoPublish, formData.allowAutoPublish) &&
          _.isEqual(data?.active, formData.active) &&
          _.isEqual(data?.durationSecond, formData.durationSecond) &&
          _.isEqual(data?.schedule?.next_Occurence_Time, formData.dateTime) ===
            true
        ) {
          setButtonStatus(true);
        } else {
          if (data == null) setButtonStatus(true);
          else {
            if (
              formData.title?.length > 0 &&
              formData.description?.length > 0 &&
              formData.cover?.length > 0 &&
              formData.type != 0 &&
              formData.category != 0 &&
              formData.durationSecond != 0
            ) {
              setButtonStatus(false);
            } else {
              setButtonStatus(true);
            }
          }
        }
      }
    } else {
      if (!showVideoMetaData) {
        setRenderStatus(false);
      }
    }
  }, [
    formData.title,
    formData.description,
    formData.cover,
    formData.active,
    formData.dateTime,
    formData.type,
    formData.allowAutoPublish,
    formData.category,
    formData.durationSecond,
  ]);

  useEffect(() => {
    if (
      tenants &&
      Array.isArray(tenants) &&
      tenantAsProviders &&
      Array.isArray(tenantAsProviders)
    ) {
      let tenantAsProvider = tenantAsProviders.find(
        (obj) => obj.isTenantProvider == true
      );
      if (tenantAsProvider) {
        let tenant = tenants.find(
          (obj) =>
            obj.id?.toLowerCase() == tenantAsProvider.tenantId.toLowerCase()
        );
        if (tenant) {
          if (
            user?.role == userRoleEnums.CS_USER &&
            localStorage.getItem("csTenantId") != null
          ) {
            localStorage.setItem(
              "tenantId",
              localStorage.getItem("csTenantId")!
            );

            setTenantData(tenant);
          } else {
            localStorage.setItem("tenantId", tenant.id);

            setTenantData(tenant);
          }
        }
      } else {
        if (
          user?.role == userRoleEnums.CS_USER &&
          localStorage.getItem("csTenantId") != null
        ) {
          localStorage.setItem("tenantId", localStorage.getItem("csTenantId")!);
          let tenant = tenants.find(
            (obj) =>
              obj.id?.toLowerCase() ==
              localStorage.getItem("csTenantId")?.toLowerCase()
          );
          setTenantData(tenant);
        }
      }
    }
  }, [tenants, tenantAsProviders]);

  const getTagsData = (video) => {
    let focusAreaTags = [] as any;
    let focusAreas = [] as any;
    if (Array.isArray(video?.focusArea)) {
      video?.focusArea.map((item, index) => {
        let focusArea = {};
        focusArea = metaData?.focusAreas.filter(function(e) {
          return e.name == item;
        });
        if (Array.isArray(focusArea)) {
          focusAreaTags.push(focusArea[0]?.tag);
        }
      });
    } else {
      focusAreas = video?.focusArea ? video?.focusArea.split("|") : [];
      focusAreaTags = video?.focusAreaTags ? video?.focusAreaTags : [];
    }

    let equipmentTypeTags = [] as any;
    let equipments = [] as any;
    if (Array.isArray(video?.equipments)) {
      video?.equipments.map((item, index) => {
        let equipment = {};
        equipment = metaData?.equipments.filter(function(e) {
          return e.name == item;
        });
        if (Array.isArray(equipment)) {
          equipmentTypeTags.push(equipment[0]?.typeTag);
        }
      });
    } else {
      equipments = video?.equipments ? video?.equipments.split("|") : [];
      equipmentTypeTags = video?.equipmentTypeTags
        ? video?.equipmentTypeTags
        : [];
    }

    return video;
  };

  const submitForm = (video: any = null) => {
    let cutoffString = formData.dateTime;
    let dateTimeInUTC = moment.utc(cutoffString);
    setShowTooltip(false);
    if (
      formData?.type !== 0 &&
      contentFormValidationForPastDateTime(formData)
    ) {
      setShowTooltip(true);
      setButtonStatus(true);
      setTooltipMessage(TranslateByLoad?.Schedule?.eventScheduleBeforeDateTime);
      window.scrollTo(20, 0);
      // setTimeout(() => {
      //   setShowTooltip(false);
      // }, 1500);
      return;
    }
    if (formData?.event_Id != "") {
      if (formData?.allowAutoPublish === true) {
        if (
          video == null &&
          (formData?.video?.status === titleStatusEnums.Incomplete ||
            formData.video === null) &&
          formData.active === true
        ) {
          setShowTooltip(true);
          setButtonStatus(false);
          setTooltipMessage(TranslateByLoad?.Schedule?.publishMessage);
          window.scrollTo(20, 0);
          return;
        }
        if (
          formData?.video?.status === titleStatusEnums.Incomplete &&
          !showVideoMetaData &&
          formData?.type == 2
        ) {
          setShowTooltip(true);
          setTooltipMessage(TranslateByLoad?.Schedule?.publishedMessage);
          window.scrollTo(20, 0);
          return;
        }
      }
      if (isOldEventType == 2 && formData.type == 1) {
        if (contentFormValidationForNextFourHrs(formData)) {
          setShowTooltip(true);
          setTooltipMessage(TranslateByLoad?.Schedule?.scheduleTimeMessage);
          window.scrollTo(20, 0);
          return;
        }
      }

      if (video && formData?.type == 1) {
        dispatch(
          updateEvent(
            {
              ...formData,
              dateTimeInUTC: dateTimeInUTC,
              video: video,
            },
            pageConfig,
            false
          )
        );
        if (video) {
          updateEventData({
            ...formData,
            video: {
              ...video,
              className: video.name,
              classDescription: video.description,
              instructor: video.instructorName,
              intensity: video.intensity,
              skill: video.skillLevel,
              classCategory: video.category,
              classLanguage: video.videoLanguage,
              classSubCategory: video.subCategory,
            },
          });
        }
      } else if (
        video &&
        formData?.type == 2 &&
        formData?.eventKey &&
        formData?.eventKey.length > 0
      ) {
        dispatch(
          updateEvent(
            {
              ...formData,
              dateTimeInUTC: dateTimeInUTC,
              video: {
                ...getTagsData(video),
                className: video.name,
                classDescription: video.description,
                instructor: video.instructorName,
                intensity: video.intensity,
                skill: video.skillLevel,
                classCategory: video.category,
                classLanguage: video.videoLanguage,
                classSubCategory: video.subCategory,
              },
            },
            pageConfig,
            false
          )
        );
        if (video) {
          updateEventData({
            ...formData,
            video: {
              ...getTagsData(video),
              className: video.name,
              classDescription: video.description,
              instructor: video.instructorName,
              intensity: video.intensity,
              skill: video.skillLevel,
              classCategory: video.category,
              classLanguage: video.videoLanguage,
              classSubCategory: video.subCategory,
            },
          });
        }
      } else {
        if (formData.video != null) {
          dispatch(
            updateEvent(
              {
                ...formData,
                video: getTagsData(formData.video),
                dateTimeInUTC: dateTimeInUTC,
              },
              pageConfig,
              false
            )
          );
        } else {
          dispatch(
            updateEvent(
              {
                ...formData,
                dateTimeInUTC: dateTimeInUTC,
              },
              pageConfig,
              false
            )
          );
        }
      }
    } else {
      dispatch(
        addEvent(
          {
            ...formData,
            dateTimeInUTC: dateTimeInUTC,
          },
          tentantId,
          pageConfig,
          false
        )
      );
    }
    setLoading(true);
    setButtonStatus(true);
    setPreFilledStatus(false);
    setPreventStatus(true);
    setUnSavedChangesStatus(false);
    setTooltipMessage("");
  };

  useEffect(() => {
    ScrollTop();
  }, []);

  const confirmChangeEventCallback = (e) => {
    let cutoffString = formData.dateTime;
    let dateTimeInUTC = moment.utc(cutoffString);
    if (
      formData?.type !== 0 &&
      contentFormValidationForPastDateTime(formData)
    ) {
      setEventChangePopup(false);
      setShowTooltip(true);
      setButtonStatus(true);
      setTooltipMessage(TranslateByLoad?.Schedule?.eventScheduleBeforeDateTime);
      window.scrollTo(20, 0);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
      return;
    }
    setPreFilledStatus(false);
    setEventChangePopup(false);
    setUnSavedChangesStatus(true);
    setLiveEventCopyUrl(false);
    dispatch(
      updateEvent(
        {
          ...formData,
          dateTimeInUTC: dateTimeInUTC,
          type: 2,
          allowAutoPublish: false,
          cover: formData.cover,
          active: false,
          isCompleted: false,
          eventKey: "",
          video: "",
        },
        pageConfig,
        false
      )
    );
    setFormData({
      ...formData,
      type: 2,
      allowAutoPublish: false,
      cover: formData.cover,
      active: false,
      isCompleted: false,
      video: "",
    });
    setButtonStatus(false);
    setShowVideo(false);
    setEventLock(false);
  };

  const cancelChangeEventCallback = (e) => {
    setEventChangePopup(false);
    setShowVideo(true);
    setFormData({ ...formData, type: 1 });
  };

  const confirmChangeEventCallbackToLive = (e) => {
    let cutoffString = formData.dateTime;
    let dateTimeInUTC = moment.utc(cutoffString);
    if (
      formData?.type !== 0 &&
      contentFormValidationForPastDateTime(formData)
    ) {
      setEventChangePopupToLive(false);
      setShowTooltip(true);
      setButtonStatus(true);
      setTooltipMessage(TranslateByLoad?.Schedule?.eventScheduleBeforeDateTime);
      window.scrollTo(20, 0);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
      return;
    }
    if (isOldEventType == 2 && formData.type == 1) {
      if (contentFormValidationForNextFourHrs(formData)) {
        setShowTooltip(true);
        setTooltipMessage(TranslateByLoad?.Schedule?.scheduleTimeMessage);
        window.scrollTo(20, 0);
        return;
      }
    }
    setEventSwitched(true);
    setUnSavedChangesStatus(true);
    setTooltipMessage("Please add metadata to modify this event.");
    setEventChangePopupToLive(false);
    setLiveEventCopyUrl(false);
    dispatch(
      updateEvent(
        {
          ...formData,
          dateTimeInUTC: dateTimeInUTC,
          type: 1,
          allowAutoPublish: false,
          cover: formData.cover,
          active: false,
          isCompleted: false,
          eventKey: "",
          video: "",
        },
        pageConfig,
        false
      )
    );
    setFormData({
      ...formData,
      type: 1,
      allowAutoPublish: false,
      cover: formData.cover,
      active: false,
      isCompleted: false,
      eventKey: "",
      video: "",
    });
    setUploadEnable(false);
    setButtonStatus(false);
    setShowVideo(false);
    setEventKeyCreation(false);
  };
  return {
    showVideoMetaData,
    formData,
    setFormData,
    submitForm,
    confirmChangeEventToLive,
    confirmChangeEventCallbackToLive,
    confirmChangeEvent,
    confirmChangeEventCallback,
    cancelChangeEventCallback,
    showToolTip,
    toolTipMessage,
    setButtonStatus,
    setLiveMetaDataForm,
    setDescCount,
    setTitleCount,
    isOldEventType,
    setLiveEventCopyUrl,
    setShowTooltip,
    setEventChangePopup,
    setEventChangePopupToLive,
    setEventKeyCreation,
    setPreFilledStatus,
    titleCount,
    isLoading,
    isEventLocked,
    descCount,
    isGettingEventKey,
    isLiveEventCopyUrl,
    copyUrl,
    isUploadedEnabled,
    isAnotherDownloadGoingOn,
    uploadingStatus,
    setRenderStatus,
    setShowMetaDataForm,
    setNavigationStatus,
    setEventLock,
    isCheckboxDisabled,
    showLiveMetaDataForm,
    tenantData,
    isShowVideo,
    isButtonDesabled,
    disableDates,
  };
};
