import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { LinkCommonStyle, SearchAddBarCommonStyle } from "./Common.style";

export const LoginFormStyles = () =>
  createStyles({
    title: {
      paddingTop: 32,
    },
    error: {
      textAlign: "center",
    },
    card: {
      margin: "0 auto",
      background: "#fff",
      zIndex: 100,
      paddingTop: 32,
      paddingLeft: 36,
      paddingRight: 36,
      borderRadius: 8,
      paddingBottom: 0,
      height: 352,
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    },
  });

export const LoginScreenStyles = (theme: Theme) =>
  createStyles({
    ...SearchAddBarCommonStyle(theme),
    ...LinkCommonStyle(theme),
    paper: {
      width: 416,
      background: "transparent",
      margin: "0 auto",
    },
    logoContainer: {
      paddingBottom: 39,
      width: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    logo: {
      margin: "0 auto",
      paddingBottom: 23,
      width: 90,
    },
    logoSubTitle: {
      margin: "0 auto",
      color: "#8e8e93",
    },
    card: {
      margin: "0 auto",
      background: "#fff",
      zIndex: 100,
      paddingTop: 0,
      paddingLeft: 36,
      paddingRight: 36,
      borderRadius: 8,
      paddingBottom: 0,
      height: 352,
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
    },
    app: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    main: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height:'100%',
      flex: 1,
      background: theme.palette.secondary.main,
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginTop: -20,
      zIndex: 2000,
    },
    block: {
      display: "block",
    },
    contentWrapper: {
      marginTop: 20,
    },
    contentWrapperTop: {
      marginBottom: 33,
    },
  });

export interface LoginScreenProps
  extends WithStyles<typeof LoginScreenStyles> {}
