import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_ERRORS_MESSAGES, apiResponseEnums } from "constants/enums";
import { CommonAPIResponse } from "models/common.model";
import {  RootState } from "store/store";

interface LayoutState {
  tabId: any;
  contentChannelValue: any;
  contentTenantValue: any;
  contentSelectedValue: any;
  contentChannelData: any;
  channelSelectedValue: any;
  value: any;
  selectedValue: any;
  selectedChannelValue: any;
  selectedProviderValue: any;
  selectedTenantValue: any;
  showSelector: boolean;
  reviewsChannelValue: any;
  reviewsSelectedValue: any;
  reviewsChannelData: any;
  apiResponse: CommonAPIResponse;
}

const initialState: LayoutState = {
  tabId: 0,
  contentChannelValue: null as any,
  contentTenantValue: null as any,
  contentSelectedValue: null as any,
  contentChannelData: [] as any,
  channelSelectedValue: null as any,
  value: 0,
  selectedValue: null as any,
  selectedChannelValue: null as any,
  selectedTenantValue: null as any,
  showSelector: true,
  selectedProviderValue: null as any,
  reviewsChannelValue: null as any,
  reviewsSelectedValue: null as any,
  reviewsChannelData: [] as any,
  apiResponse: {
    status: 0,
    data: "",
  },
};

export const layoutSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setTabId: (state, action: PayloadAction<any>) => {
      state.tabId = action.payload;
    },
    setContentChannelValue: (state, action: PayloadAction<any>) => {
      state.contentChannelValue = action.payload;
    },
    setContentTenantValue: (state, action: PayloadAction<any>) => {
      state.contentTenantValue = action.payload;
    },
    setContentSelectedValue: (state, action: PayloadAction<any>) => {
      state.contentSelectedValue = action.payload;
    },
    setContentChannelData: (state, action: PayloadAction<any>) => {
      state.contentChannelData = action.payload;
    },
    setChannelSelectedValue: (state, action: PayloadAction<any>) => {
      state.channelSelectedValue = action.payload;
    },
    setValue: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    setSelectedValue: (state, action: PayloadAction<any>) => {
      state.selectedValue = action.payload;
    },
    setSelectedChannelValue: (state, action: PayloadAction<any>) => {
      state.selectedChannelValue = action.payload;
    },
    setSelectedTenantValue: (state, action: PayloadAction<any>) => {
      state.selectedTenantValue = action.payload;
    },
    setShowSelector: (state, action: PayloadAction<any>) => {
      state.showSelector = action.payload;
    },
    setSelectedProviderValue: (state, action: PayloadAction<any>) => {
      state.selectedProviderValue = action.payload;
    },
    setReviewsChannelValue: (state, action: PayloadAction<any>) => {
      state.reviewsChannelValue = action.payload;
    },
    setReviewsSelectedValue: (state, action: PayloadAction<any>) => {
      state.reviewsSelectedValue = action.payload;
    },
    setReviewsChannelData: (state, action: PayloadAction<any>) => {
      state.reviewsChannelData = action.payload;
    },
    setApiResponse: (state, action: PayloadAction<CommonAPIResponse>) => {
      if (!!action.payload) {
        state.apiResponse = {
          status: action.payload.status,
          data: action.payload.data,
          autoHide: action.payload.autoHide,
        }
      } else {
        state.apiResponse = {
          status: apiResponseEnums.TIME_OUT,
          data: API_ERRORS_MESSAGES.OTHER_ERRORS,
        };
      }
    },
    resetApiResponse: (state) => {
      state.apiResponse = { status: 0, data: "", autoHide: true };
    }
  },
});

export const {
  setTabId,
  setContentChannelValue,
  setContentTenantValue,
  setContentSelectedValue,
  setContentChannelData,
  setChannelSelectedValue,
  setValue,
  setSelectedValue,
  setSelectedChannelValue,
  setSelectedTenantValue,
  setShowSelector,
  setSelectedProviderValue,
  setReviewsChannelValue,
  setReviewsSelectedValue,
  setReviewsChannelData,
  setApiResponse,
  resetApiResponse
} = layoutSlice.actions;

export const layoutTabId = (state: RootState) =>
  state.layout.tabId;
export const layoutContentChannelValue = (state: RootState) =>
  state.layout.contentChannelValue;
export const layoutContentTenantValue = (state: RootState) =>
  state.layout.contentTenantValue;
export const layoutContentSelectedValue = (state: RootState) =>
  state.layout.contentSelectedValue;
export const layoutContentChannelData = (state: RootState) =>
  state.layout.contentChannelData;
export const layoutChannelSelectedValue = (state: RootState) =>
  state.layout.channelSelectedValue;
export const layoutValue = (state: RootState) => state.layout.value;
export const layoutSelectedValue = (state: RootState) =>
  state.layout.selectedValue;
export const layoutSelectedChannelValue = (state: RootState) =>
  state.layout.selectedChannelValue;
export const layoutSelectedTenantValue = (state: RootState) =>
  state.layout.selectedTenantValue;
export const layoutShowSelector = (state: RootState) =>
  state.layout.showSelector;
export const layoutSelectedProviderValue = (state: RootState) => state.layout.selectedProviderValue;
export const layoutReviewsChannelValue = (state: RootState) =>
  state.layout.reviewsChannelValue;
export const layoutReviewsSelectedValue = (state: RootState) =>
  state.layout.reviewsSelectedValue;
export const layoutReviewsChannelData = (state: RootState) =>
  state.layout.reviewsChannelData;
export const apiResponse = (state: RootState) => state.layout.apiResponse;
export default layoutSlice.reducer;
