import React from "react";
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import * as RouteContants from "constants/routeConstants";
import WelcomePageShimmer from "components/Loader/WelcomePageShimmer";
import { selectTenants } from "_pages/channels/channelSlice";
import { useSelector } from "react-redux";
import { selectTenantAsProviders } from "_pages/provider/providerSlice";
import { userRoleEnums } from "constants/enums";
import { selectUser } from "_pages/loginscreen/authSlice";
import Translate from "utils/translate";
import ErrorIcon from "@material-ui/icons/Error";
//Lazily Loaded Screens
const WelcomePage = React.lazy(() => import("./_pages/welcomescreen/index"));
const AdminPage = React.lazy(() => import("./_pages/admin/index"));
const ChannelsPage = React.lazy(() => import("./_pages/channels/index"));
const ProviderPage = React.lazy(() => import("./_pages/provider/index"));
const TenantPage = React.lazy(() => import("./_pages/tenants/index"));
const ContentPage = React.lazy(() => import("./_pages/contentTitles/index"));
const SchedulePage = React.lazy(() => import("./_pages/scheduleEvents/index"));
const CollectionsPage = React.lazy(() => import("./_pages/collections/index"));
const ReviewsPage = React.lazy(() => import("./_pages/reviews/index"));
const SubscriptionsPage = React.lazy(() => import("./_pages/subscriptions/index"));
const UserManagement = React.lazy(() => import("./_pages/userManagement/index"));
const UserReport = React.lazy(() => import("./_pages/userReport/index"));
const SocialLinksPage = React.lazy(() => import("./_pages/socialLinks/index"));
const CustomLinksPage = React.lazy(() => import("./_pages/customLinks/index"));
const AppLinksPage = React.lazy(() => import("./_pages/appLinks/index"));
const DocumentsPage = React.lazy(() => import("./_pages/documents/index"));
const CategoryPage = React.lazy(() => import("./_pages/categoryManagement/index"));
const BulkUploadPage = React.lazy(() =>
  import("./_pages/bulkUpload/index")
);

function NoMatch() {
  let location = useLocation();

  return (
    <div className="path-not-found">
      <ErrorIcon className="error-icon-color"/>
      <span className="page-not-found-heading">
        {Translate({id: "userMessages.routeNotMatchMessage"})}
        {/* <code>{location.pathname}</code> */}
      </span>
    </div>
  );
}

function Routes() {
  let providerData = useSelector(selectTenantAsProviders);
  let tenantId = providerData?.tenantAsProviders[0]?.tenantId;
  let tenant = useSelector(selectTenants);
  const user = useSelector(selectUser);
  var tenantChannel = tenant?.tenants?.find((ten) => ten.id == tenantId);

  const CanHasRouteAccess = ({ component: Component, iHasUser, ...rest }) => {

    return iHasUser ? (
      <Route {...rest} render={(props) => <Component {...props} />} />
    ) : (
      <Redirect to="/" />
    );
  };

  const isAccessRoleByRoute = (roles) => {
    if (user?.email !== "" && roles.includes(user?.role)) {
      return true;
    }
    return false;
  }; //
  return (
    <React.Suspense fallback={<WelcomePageShimmer />}>
      <Switch>
        <Route exact path={RouteContants.homePath} component={WelcomePage} />

        <Route path={RouteContants.adminPath} component={AdminPage} />
        <Route path={RouteContants.tenantPath} component={TenantPage} />
        <Route path={RouteContants.channelsPath} component={ChannelsPage} />
        <Route path={RouteContants.providerPath} component={ProviderPage} />

        <Route path={RouteContants.contentTitles} component={ContentPage} />
        <Route path={RouteContants.reviews} component={ReviewsPage} />
        <Route path={RouteContants.categoriesPath} component={CategoryPage} />
        <CanHasRouteAccess
          path={RouteContants.userManagement}
          component={UserManagement}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />
        <CanHasRouteAccess
          path={RouteContants.userReportPath}
          component={UserReport}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />
        <CanHasRouteAccess
          path={RouteContants.socialLinks}
          component={SocialLinksPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />
        <CanHasRouteAccess
          path={RouteContants.customLinks}
          component={CustomLinksPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />
        <CanHasRouteAccess
          path={RouteContants.appLinks}
          component={AppLinksPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />

        <CanHasRouteAccess
          path={RouteContants.documentsPath}
          component={DocumentsPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />

        {tenantChannel?.connect == "both" ||
        tenantChannel?.connect == "live" ||
        tenantChannel?.connect != "none" ? (
          <CanHasRouteAccess
            path={RouteContants.scheduleTitles}
            component={SchedulePage}
            iHasUser={isAccessRoleByRoute([
              userRoleEnums.TENANT_AS_PROVIDER_USER,
              userRoleEnums.CS_USER,
            ])}
          />
        ) : (
          <Redirect to={RouteContants.homePath} />
        )}
        <CanHasRouteAccess
          path={RouteContants.collectionsPath}
          component={CollectionsPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />
        <CanHasRouteAccess
          path={RouteContants.subscriptionsPath}
          component={SubscriptionsPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
          ])}
        />
        <Route path={RouteContants.categoriesPath} component={CategoryPage} />
        <CanHasRouteAccess
          path={RouteContants.bulkUploadPath}
          component={BulkUploadPage}
          iHasUser={isAccessRoleByRoute([
            userRoleEnums.TENANT_AS_PROVIDER_USER,
            userRoleEnums.CS_USER,
            userRoleEnums.PRVIDER_USER,
          ])}
        />

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </React.Suspense>
  );
}

export default Routes;
