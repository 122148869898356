import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ScrollTop } from "utils/utilityFunctions";
import {
  downloadTermsApi,
  getLoadingTenantTermsList,
  getTenantsTermsListfromApi,
  selectTenantsTerms,
} from "_pages/tenants/tenantSlice";

export const useTenantTermsHook = () => {
  const param: any = useParams();
  const [numSelected, setSelectedNumbers] = useState(0);
  const [data, setData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([] as any);
  const [selectedTenant, setSelectedTenant]: any = useState(null);
  const dispatch = useDispatch();
  const { tenantsTerms } = useSelector(selectTenantsTerms);
  const isLoading = useSelector(getLoadingTenantTermsList);
  const [showDownloadForm, setDownloadForm] = useState(false);
  const [selectedVersion, setSelectedVersion]: any = useState();

  useEffect(() => {
    if (tenantsTerms) {
      setData(tenantsTerms);
    }
  }, [tenantsTerms]);

  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    const data = tenantsTerms?.filter((el) => el.tenantId === param.id);
    if (data && data.length > 0) {
      setSelectedTenant(data[0]);
    }
  }, [param?.id, tenantsTerms]);

  useEffect(() => {
    const version = selectedTenant?.versionNumbers.filter(
      (el) => el.versionNumber === +param.versionId
    );
    if (version && version.length > 0) {
      setSelectedVersion(version[0]);
    }
  }, [selectedTenant]);

  return {
    setSelectedNumbers,
    setSelectedTags,
    setSelectedTenant,
    selectedTags,
    showDownloadForm,
    setDownloadForm,
    tenantsTerms,
    setData,
    numSelected,
    selectedTenant,
    data,
    selectedVersion,
    isLoading
  };
};
