import { useEffect, useState } from "react";
import useCheckbox from "_pages/channels/Components/CheckBoxOptions";
import { useChannelsFormHook } from "./useChannelsForm.hooks";

export const useChannelsCheckBoxesHook = (props) => {
  const [availibitydata, setData] = useState(useCheckbox(1));
  const [statusData, setStatus] = useState(useCheckbox(2));
  const { formData, isFormPrefilled } = props;

  useEffect(() => {
    if (isFormPrefilled) {
      setData({
        ...availibitydata,
        selectedStatus: {
          inClub: formData.inClub,
          appAndweb: formData.appAndweb,
          hotel: formData.hotel,
        },
      });
      setStatus({
        ...statusData,
        selectedStatus: {
          status: formData.status,
        },
      });
    }
  }, [isFormPrefilled]);

  return {
    availibitydata,
    setData,
    statusData,
    setStatus,
  };
};
