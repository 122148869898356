import { userApi } from 'api/api';
import ContentTitleResponse from '_pages/contentTitles/Response/contentTitle.json';
import * as ApiConstants from "constants/apiConstants";

import { map, compact, filter } from 'lodash';
import { apiResponseEnums } from 'constants/enums';
const EVERYCHUNK = 4;
const _paramsInfo:any={
    page:1,
    take:100,
    paramsData:{
      dir: "desc",
      sort:"createddate",
      take:9999
    }
};
export const transformKeys = ()=>{
    const newKeys = {isDelete:false,videoStatus:6,trailerStatus:0,chunkNumber:null}
    const {data:Records,...rest} = ContentTitleResponse;
    return {...rest,data:map( Records,res=>({...newKeys,...res}))}
  }
export const transformData = (isTag)=>{
    const newKeys = {isDelete:false,status:8,videoStatus:5,trailerStatus:0,chunkNumber:2,tag:isTag}
    const {data,...rest} = ContentTitleResponse;
    const [Records] = data;
    return {...rest,data:map( [Records],res=>({...res,...newKeys}))}
  }
export const modifyRes = (res:any,isTag:any=false)=>{
    if(res.status === apiResponseEnums.OKAY && res.ok){
      const result = res.data;
      if(result.totalRecords === 0){
        res.data = isTag ? transformData(isTag) : transformKeys();
      }
      return res;
    }
    else return res;
  }
export const getContentList = (_params:any=_paramsInfo)=>{
  return new Promise((resolve, reject) => {
    const {paramsData,...params}=_params;
    let titleTag:any = false;
    titleTag = paramsData?.titleTags && paramsData?.titleTags.length && paramsData?.titleTags[0];
      userApi
        .put(
          `${ApiConstants.getFilteredTitlesAPI}?page=${params.page}&take=${params.take}`,
          { ...paramsData, tenant: params?.paramTenant ?? "" }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      }
    );
}
export const contentTitleListCall = (file)=>{
  const getSuccess = filter(file.chunks,(item)=>{
    try{
      // check whether file uploaded or not and upload status should be success
      return (item.loaded !== 0) ? item.status() === "success" ? true : false : false;
    }
    catch(e){
      return false;
    }
  })
  return getSuccess.length ? getSuccess.length % EVERYCHUNK === 0 : false;
}
export const getContent = (tag:string,tenant:string = "")=>{
      return new Promise((resolve,reject)=>{
        const _params:any={..._paramsInfo,paramsData:{..._paramsInfo.paramsData,titleTags:[tag],tenant}};
        const {paramsData,..._}=_params;
          userApi.put(
            `${ApiConstants.getFilteredTitlesAPI}?page=${_.page}&take=${_.take}`,
            paramsData
            )//.then((res)=>modifyRes(res,tag))
            .then(res=>resolve(res))
            .catch((err)=>reject(err))
          });
    }
export const cancelContentTitle=(_={titleTag:'',isTrailerFile:false})=>{
  return new Promise((resolve,reject)=>{
      userApi
        .put(
          `${ApiConstants.cancelContentTitlesAPI}?isTrailerFile=${_.isTrailerFile}&titleTag=${_.titleTag}`,
          { isTrailerFile: _.isTrailerFile, titleTag: _.titleTag }
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      });
}


