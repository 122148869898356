import React, { useState, useEffect } from 'react';
import Input from 'components/Inputs/Input'
import Button from 'components/Button'
import Typography from '@material-ui/core/Typography';
import Translate from 'utils/translate'
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { asyncActionEnums } from 'constants/enums'
import { emailValidation, passwordValidation } from 'utils/validation'
import {
    auth, selectLoading
} from './authSlice';
import { LoginFormStyles } from 'style';
import { useLoginHook } from '_hooks';

const CustomForm = (props) => {
    const classes:any = LoginFormStyles();
    const hook = useLoginHook();
    return (
        <form noValidate autoComplete="off">
            <div style={{...classes.title}}>
                <Typography variant='h2' color="secondary" align="center">
                    {props.title}
                </Typography>
                <p className="inCorrectEmailPWD">{hook.loadingStatus === asyncActionEnums.ASYNC_ACTION_FAILED && Translate({ id: "loginPage.loginErrors" })}</p>
            </div>
            <div>
                <Input
                    type="email"
                    label={Translate({ id: "loginPage.emailTextFieldLabel" })}
                    callback={hook.fillEmail}
                />
                <br />
                <Input
                    type="password"
                    label={Translate({ id: "loginPage.passwordFieldLabel" })}
                    callback={hook.fillPassword}

                />
            </div>
            <div className="pt16">
                <Button textClassName='button-label' callback={hook.submitForm} disabled={(hook.isEmailValid === false || hook.isPasswordValid === false) ? true : false} loading={hook.isLoading} text={Translate({ id: "loginPage.loginFormTitle" })} />
            </div>

        </form>
    )
}
export default withStyles(LoginFormStyles)(CustomForm);
