import React, { useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Typography } from "@material-ui/core";
import Translate from "utils/translate";
import {
  networkStatus,
  reTriggerEncoding,
} from "_pages/contentTitles/contentTitleSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  NETWORK_STATUS_ENUMS,
  titleStatusEnums,
  userRoleEnums,
  VIDEO_STATUS_ENUM,
} from "constants/enums";
import { selectUser } from "_pages/loginscreen/authSlice";
import { ProgressBarComponentStyles } from "style/components";
import CancelSharp from '@material-ui/icons/CancelSharp';
import { VIDEO_STATUS_FAILED } from '../../constants/enums';
import Translation from "utils/translate";
const CrossIcon = (props:any)=>{
  const handelCancel =(e:any)=>{
    e.preventDefault();
    e.stopPropagation();
    // console.info("HIHIH",props)
    props.callback && props.callback();
  }
  
  return (
    <div onClick={handelCancel}>
      <Typography className="cancelText">
        {Translate({
          id: "userActions.cancelVideoText",
          defaultMessage: "Cancel",
        })}
      </Typography>
    </div>
  );
}
export default function LinearDeterminate(props) {
  const classes = ProgressBarComponentStyles();
  const {
    progress,
    isError,
    callback,
    row,
    isTableOptional,
    from,
    statusCode,
    showCrossBtn,
    isReUpload
  } = props;

  const network = useSelector(networkStatus);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const [isOffline, setOffiline] = useState(false);

  const [showActionableError, setActaionableError] = useState(true);

  const [showProgressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    if (network === NETWORK_STATUS_ENUMS.OFFLINE) {
      setOffiline(true);
    } else if (network === NETWORK_STATUS_ENUMS.ONLINE) {
      setOffiline(false);
    }
  }, [network]);

  useEffect(() => {
    if (row && row.video?.status === titleStatusEnums.Error) {
      setShowProgressBar(false);
    }
  }, [row]);

  useEffect(() => {
    if (isError && isTableOptional) {
      //
      setActaionableError(false);
    }
  }, [isTableOptional]);

  const reCallback = () => {
    setShowProgressBar(true);
    setActaionableError(true);
    if (
      user?.role == userRoleEnums.CS_USER &&
      localStorage.getItem("csTenantId") != null
    ) {
      dispatch(
        reTriggerEncoding(
          row.tag ?? row.video.tag,
          row.tag ? false : true,
          row.tag ? "" : localStorage.getItem("csTenantId")!,
          localStorage.getItem("csTenantId")
        )
      );
    } else {
      dispatch(
        reTriggerEncoding(
          row.tag ?? row.video.tag,
          row.tag ? false : true,
          row.tag ? "" : row.tenantId,
          null
        )
      );
    }
  };
  let progressTxt = { labelText : "userMessages.processingFailed",btnTxt:"userActions.resume" };
  if([VIDEO_STATUS_ENUM.FAILED,VIDEO_STATUS_ENUM.CANCELED].includes(statusCode)){
    progressTxt = statusCode === VIDEO_STATUS_ENUM.FAILED ?
                { labelText : "userMessages.videoStatusFailed",btnTxt:"userActions.reupload" } :
                { labelText : "userMessages.videoStatusCancel",btnTxt:"userActions.reupload" };
  }
  return (
    <div
      className={
        from === "ScheduleEvent" ? "responsiveFormElements" : classes.root
      }
    >
      {!isOffline ? (
        <React.Fragment>
          <div className="progress-bar">
            {progress && progress === 1 && statusCode == 1 ? (
              <React.Fragment>
                <LinearProgress color="primary" />
                <Typography className={classes.text}>
                  {Translate({ id: "actionStatus.Processing" })}
                </Typography>
              </React.Fragment>
            ) : progress === 0 && !isError ? (
              <React.Fragment>
                {/* <LinearProgress
                  className={classes.waitingFiles}
                  variant="buffer"
                  value={0}
                  valueBuffer={1}
                /> */}
                <Typography className={classes.text}>
                  {Translate({ id: "actionStatus.waiting" })}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {showProgressBar ? (
                  <React.Fragment>
                    <LinearProgressContinueProcessing />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {isReUpload === true && (
                      <p className="replace-video">
                        {Translation({
                          id: "userActions.replaceVideo",
                          defaultMessage: "Replacing video…",
                        })}
                      </p>
                    )}
                    <LinearProgress
                      className={classes.bar}
                      style={{ flexBasis: "100%" }}
                      variant="determinate"
                      value={progress ? progress.toFixed(2) * 100 : 0}
                    />
                    {isError ? (
                      // <div className='row-space-between'>
                      //     <Typography className={classes.error}>
                      //         {Translate({ id: 'userMessages.uploadeErrorStatus' })}
                      //     </Typography>
                      //     <Typography className={classes.Actiontext}>
                      //         {Translate({ id: 'userActions.resume' })}
                      //     </Typography>
                      // </div>
                      <React.Fragment>
                        {showActionableError === false ? (
                          <Typography
                            onClick={callback}
                            className={classes.error}
                          >
                            {Translate({
                              id: "userMessages.uploadeErrorStatus",
                            })}
                          </Typography>
                        ) : (
                          <div className="row-space-between">
                            <Typography className={classes.error}>
                              {Translate({
                                id: progressTxt.labelText,
                              })}
                            </Typography>
                            <Typography
                              onClick={reCallback}
                              className={classes.Actiontext}
                            >
                              {Translate({ id: progressTxt.btnTxt })}
                            </Typography>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <Typography className={classes.text}>
                        {/* {Translate({ id: 'actionStatus.Uploading' })} {`${Math.round(progress ? progress.toFixed(2) * 100 : 0)}%`} */}
                        {Translate({ id: "actionStatus.Uploading" })}{" "}
                        {`(${Math.round(progress ? progress * 100 : 0)}%)`}
                      </Typography>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {showCrossBtn && progress !== 0 && (
              <CrossIcon callback={props.videoCancelCallback} />
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <LinearProgress color="primary" />
          <Typography className={classes.text}>
            {Translate({ id: "userMessages.waitingForNetwork" })}
          </Typography>
        </React.Fragment>
      )}
    </div>
  );
}

export function LinearProgressContinue() {
  const classes = ProgressBarComponentStyles();
  const network = useSelector(networkStatus);
  const [isOffline, setOffiline] = useState(false);

  useEffect(() => {
    if (network === NETWORK_STATUS_ENUMS.OFFLINE) {
      setOffiline(true);
    } else if (network === NETWORK_STATUS_ENUMS.ONLINE) {
      setOffiline(false);
    }
  }, [network]);
  return (
    <div className={classes.root}>
      <LinearProgress color="primary" />
      {!isOffline ? (
        <Typography className={classes.text}>
          {Translate({ id: "actionStatus.Processing" })}
        </Typography>
      ) : (
        <Typography className={classes.text}>
          {Translate({ id: "userMessages.waitingForNetwork" })}
        </Typography>
      )}
    </div>
  );
}

export function LinearProgressContinueProcessing(props) {
  const { style, statusCode,showCrossBtn,isReUpload } = props;
  const classes = ProgressBarComponentStyles();

  const network = useSelector(networkStatus);
  const [isOffline, setOffiline] = useState(false);

  useEffect(() => {
    if (network === NETWORK_STATUS_ENUMS.OFFLINE) {
      setOffiline(true);
    } else if (network === NETWORK_STATUS_ENUMS.ONLINE) {
      setOffiline(false);
    }
  }, [network]);
  const statusCodes = [11,VIDEO_STATUS_ENUM.NORMALIZING];
  const showProcessing = VIDEO_STATUS_FAILED.includes(statusCode) === false;
  return (
    <div className={style ?? classes.root}>
      {isReUpload === true && <p className="replace-video">{Translation({ id: "userActions.replaceVideo",defaultMessage:"Replacing video…" })}</p>}
      <LinearProgress color="primary" />
      {!isOffline ? (
        <div style={{display:"flex",justifyContent:"space-between"}}>
        {statusCode && statusCodes.includes(statusCode) ? (
          <Typography className={classes.text}>
            {Translate({ id: "actionStatus.AudioNormalisation" })}
          </Typography>
        ) : showProcessing &&(
          <Typography className={classes.text}>
            {Translate({ id: "actionStatus.Processing" })}
          </Typography>
        )}
        {showCrossBtn && <CrossIcon callback={props.videoCancelCallback} />}
        </div>
      ) : (
        <Typography className={classes.text}>
          {Translate({ id: "userMessages.waitingForNetwork" })}
        </Typography>
      )}
    </div>
  );
}

export function LinearDeterminateWithCallback(props) {
  const classes = ProgressBarComponentStyles();
  const { callback, value } = props;

  return (
    <div className={classes.failedFull}>
      <LinearProgress
        variant="determinate"
        color="primary"
        value={value ? value * 100 : 0}
      />
      <Typography onClick={callback} className={classes.ActionError}>
        {Translate({ id: "userMessages.retryUpload" })}
      </Typography>
    </div>
  );
}

export function LinearDeterminateWithCallbackText(props) {
  const classes = ProgressBarComponentStyles();
  const { callback, value, style } = props;
  return (
    <div className={style ?? classes.root}>
      <LinearProgress color="primary" value={value ? value : 0} />

      <Typography onClick={callback} className={classes.text}>
        {Translate({ id: "actionStatus.Processing" })}
        {`${Math.round(value ? value : 0)}%`}
      </Typography>
    </div>
  );
}
