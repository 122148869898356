import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
// import LoginPage from "./loginscreen";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatus, selectAuth } from "_pages/loginscreen/authSlice";
import HomeShimmer from "components/Loader/HomeScreenShimmer";
import {
  addBrokenTitlesToTable,
} from "_pages/contentTitles/contentTitleSlice";
import { setVideoUploadProgress } from "_pages/scheduleEvents/ScheduleEventSlice";
import {
  authEnums,
  titleStatusEnums,
  userRoleEnums,
  localStorageEventVideos,
} from "constants/enums";
import UploadInProgress from "components/Loader/OpenedInAnotherTab";
import { selectUser } from "_pages/loginscreen/authSlice";
import { selectTenants } from "_pages/channels/channelSlice";
import { removeAlldatafromBrowser } from "utils/uploadUtilFuctions";
import { isEmpty } from "lodash";
import { HomePageProps, HomePageStyles } from "style";
import HomePage from "_pages/HomePage";
import { useHistory } from "react-router-dom";

const _ = require("lodash");

function HomeContainer(props: HomePageProps) {
  const { classes } = props;
  const history = useHistory();
  if (history.location.pathname === '/login') {
    history.push('/');
  }
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    authEnums.AUTH_STATUS_UNKNOWN
  );
  const [isRouteHidden, showRoutes] = React.useState(false);

  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuth);

  const user = useSelector(selectUser);
  const tenantsData = useSelector(selectTenants);
  const userId = localStorage.getItem("uid");
  useEffect(() => {
    if (user?.email !== "" && user?.role === userRoleEnums.PRVIDER_USER) {
      //checking if broken uploads are belonging to correct user
      if (userId !== user.userId) {
        removeAlldatafromBrowser();
        localStorage.setItem("clearLocalStorage", "true");
      } else {
        dispatch(addBrokenTitlesToTable(titleStatusEnums.Error));
      }
    }
    if (
      user?.email !== "" &&
      (user?.role === userRoleEnums.PRVIDER_USER ||
        user?.role === userRoleEnums.TENANT_AS_PROVIDER_USER)
    ) {
      let isLocalStorageCleared = localStorage.getItem("clearLocalStorage");
      if (
        userId === user.userId &&
        isLocalStorageCleared &&
        JSON.parse(isLocalStorageCleared) === true
      ) {
        // checking if broken uploads are belonging to correct user
        dispatch(addBrokenTitlesToTable(titleStatusEnums.Error));
        let checkBrokenEventsVideos = localStorage.getItem(
          localStorageEventVideos
        );
        if (typeof checkBrokenEventsVideos === "string") {
          let eventVideoProgess = JSON.parse(checkBrokenEventsVideos);
          if (eventVideoProgess && eventVideoProgess.length > 0) {
            let setStatusBroken = [] as any;
            for (let i = 0; i < eventVideoProgess.length; i++) {
              setStatusBroken.push({ ...eventVideoProgess[i], isBroken: true });
            }
            dispatch(setVideoUploadProgress(setStatusBroken));
            localStorage.setItem(
              localStorageEventVideos,
              JSON.stringify(setStatusBroken)
            );
          }
        }
      } else {
        removeAlldatafromBrowser();
        localStorage.setItem("clearLocalStorage", "true");
      }
    }
  }, [user]);

  useEffect(() => {
    localStorage.openpages = Date.now();
    var onLocalStorageEvent = function(e) {
      if (e.key === "openpages") {
        localStorage.page_available = Date.now();
      }
      if (e.key === "page_available") {
        if (localStorage.getItem("uploading") === "true") {
          showRoutes(true);
        }
      }
    };
    window.addEventListener("storage", onLocalStorageEvent, false);
  }, []);

  useEffect(() => {
    if (authStatus === authEnums.AUTH_STATUS_LOGGED_IN) {
      setIsLoggedIn(authEnums.AUTH_STATUS_LOGGED_IN);
    } else if (authStatus === authEnums.AUTH_STATUS_LOGGED_OUT) {
      setIsLoggedIn(authEnums.AUTH_STATUS_LOGGED_OUT);
    } else {
      dispatch(checkAuthStatus());
    }
  }, [authStatus, dispatch]);
  const hideShimmer = isLoggedIn === authEnums.AUTH_STATUS_LOGGED_IN && isEmpty(tenantsData) === false
  return (
    <div
      className={hideShimmer ? classes.root : classes.rootWithoutBG}
    >
      {isRouteHidden ? (
        <UploadInProgress />
      ) : (
        <React.Fragment>
          {hideShimmer ? <HomePage /> : <HomeShimmer />}
        </React.Fragment>
      )}
    </div>
  );
}

export default withStyles(HomePageStyles)(HomeContainer);
