import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import {
  FormCommonStyle,
  FormPaddingCommonStyle,
  MainCommonStyle,
} from "./Common.style";

export const DocumentsCreationStyles = makeStyles((theme: Theme) => ({
  label: {
    display: "flex",
    alignSelf: "flexStart",
    width: 56,
    height: 12,
    margin: "34px 432.5px 0px 16px",
    color: "#263238",
    fontSize: 12,
    fontFamily: "Roboto",
    lineHeight: "12px",
    letterSpacing: "0.4px",
  },
  fileLabel: {
    fontSize: 12,
  },
  selectButton: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 12,
  },
  textInputLeft: {
    marginLeft: "16px !important",
  },
  MuiIconButtonLabel: {
    "& span": {
      marginLeft: "6px",
    },
  },
  activeCheckbox: {
    margin: "15px 0px 8px 0px !important",
  },
  rightBtn: {
    textAlign: "right",
  },
  backBtn: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    color: "#36dcb6",
    cursor: "pointer",
    height: "100%",
    alignItems: "center",
    display: "inline-flex"
  },
  ...FormPaddingCommonStyle,
  ...FormCommonStyle(theme),
}));

export const DocumentsScreenStyles = (theme: Theme) =>
  createStyles({
    ...MainCommonStyle(theme),
  });

export interface DocumentsScreenProps
  extends WithStyles<typeof DocumentsScreenStyles> {}
