import React, { useEffect, useState } from "react";
import clsx from "clsx";
//import Divider from '@material-ui/core/Divider';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/HomeRounded";
import Translate from "utils/translate";
import Logo from "assets/images/wexer-white.svg";
import { selectUser } from "_pages/loginscreen/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, useCategories } from "./NavigatorOptions";
import { withRouter } from "react-router-dom";
import { userRoleEnums } from "constants/enums";
import { setShowSelector, setTabId } from "Layout/layoutSlice";

// {TODO: Moving this is messing up the table theme for button text color and radio button color}
// For reference I have created the style in style/component/Navigator.tsx

// export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> { }

function Navigator(props) {
  const { staticContext, ...other } = props;
  const user = useSelector(selectUser);
  const categories = useCategories(user.role);
  const dispatch = useDispatch();
  const [path, setPath] = useState(
    props?.history.location?.pathname.split("/")
  );
  const [parentOpen, setParentOpen] = useState<string | boolean | null>(props?.history.location?.pathname);

  useEffect(() => {
    let p = props.history.location.pathname.split("/");
    setPath(p);
  }, [props.history.location.pathname]);

  const handleMenuItemsClick = (route: string | boolean, isParent: boolean) => {
    const pathName = props.history.location.pathname;
    if (!isParent) {
      if (pathName !== route) {
        dispatch(setShowSelector(true));
        dispatch(setTabId(0));
      }
      props.history.push(route);
    }
    setParentOpen(route);
  };

  const renderChildLink = ({ id, routesTo }) => {
    if ((parentOpen as string).split("/")[1] === routesTo.split("/")[1]) {
      return (
        <ListItem
          onClick={() => handleMenuItemsClick(routesTo, false)}
          key={id}
          button
          className={"item-child"}
        >
          <ListItemText
            className={
              id.toLowerCase() === path[2]?.toLowerCase()
                ? "item-child-active"
                : "item-child-primary"
            }
          >
            {Translate({ id: `sidebar.${id}` })}
          </ListItemText>
        </ListItem>
      );
    }
    return null;
  };

  const renderLink = (item: MenuItem) => {
    const { id, icon, routesTo, children } = item;
    return (
      <React.Fragment key={Math.random()}>
        <ListItem
          onClick={() => handleMenuItemsClick(routesTo, !!children)}
          key={id}
          button
          className={"item-icon"}
        >
          <ListItemIcon
            className={
              id.toLowerCase() === path[1].toLowerCase()
                ? "item-icon-active"
                : "item-icon"
            }
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            className={
              id.toLowerCase() === path[1].toLowerCase()
                ? "item-primary-active"
                : "item-primary"
            }
          >
            {Translate({ id: `sidebar.${id}` })}
          </ListItemText>
        </ListItem>
        {children ? children.map((i: MenuItem) => renderChildLink(i)) : null}
      </React.Fragment>
    );
  };

  return (
    <Drawer variant="permanent" style={{ background: "#19202c" }} {...other}>
      <List disablePadding>
        <ListItem className={"firebase item item-category"}>
          <ListItemIcon
            onClick={() => handleMenuItemsClick("/", false)}
            className={`item-icon ml2`}
          >
            <img src={Logo} alt="logo" />
          </ListItemIcon>
        </ListItem>
        <ListItem
          onClick={() => handleMenuItemsClick("/", false)}
          className={
            "item item-category item-icon-active item-icon primary mb6"
          }
        >
          <ListItemIcon
            className={
              path[1].toLowerCase() !== "" ? "item-icon" : "item-icon-active"
            }
          >
            <HomeIcon />
          </ListItemIcon>

          <ListItemText
            className={
              path[1].toLowerCase() !== "" ? "item" : "item-primary-active"
            }
          >
            {user?.role === userRoleEnums.TENANT_AS_PROVIDER_USER
              ? Translate({ id: "sidebar.home" })
              : Translate({ id: "sidebar.home" })}
          </ListItemText>
        </ListItem>
        <React.Fragment>
          {categories && categories.map((item) =>
            item.id === "siteManagement" ? (
              <ListItemText key={item.id} className="site-management">
                {Translate({ id: `sidebar.${item.id}` })}
              </ListItemText>
            ) : (
              renderLink(item)
            )
          )}
        </React.Fragment>
      </List>
      <div>
        <ListItemText className={"item-primary version-item"}>
          {`Build : ${localStorage.getItem("version")}`}
        </ListItemText>
      </div>
    </Drawer>
  );
}

export default withRouter(Navigator);
