import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { authEnums, asyncActionEnums, userRoleEnums } from "constants/enums";
import * as firebase from "firebase/app";

import { getChannelListfromApi } from "_pages/channels/channelSlice";
import {
  getmetaDataFromApi,
  getLanguageFromAPI,
} from "_pages/contentTitles/contentTitleSlice";

import "firebase/auth";
import { setSelectedTenantValue } from "Layout/layoutSlice";
import { resetSorting } from "components/Sort/Sort.Slice";
import { resetFilterData as resetFilterDataCal } from "_pages/scheduleEvents/ScheduleEventSlice";
import { setResetFilterData } from "_pages/collections/CollectionsSlice";

const moment = require("moment-timezone");

//import api from 'api/api';
// import * as apiConstant from 'constants/apiConstants';
// import axios from 'axios';

interface AuthState {
  isAuthenticated: Number;
  value: number;
  user: any;
  loading: number;
  //error:string
}

const initialState: AuthState = {
  isAuthenticated: authEnums.AUTH_STATUS_UNKNOWN,
  value: 0,
  loading: asyncActionEnums.ASYNC_ACTION_NOT_START,
  user: { displayName: "", email: "", isAdmin: false },
};

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
};
firebase.initializeApp(config);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = authEnums.AUTH_STATUS_LOGGED_IN;
    },
    authLoading: (state) => {
      state.loading = asyncActionEnums.ASYNC_ACTION_STARTED;
    },
    authSuccess: (state) => {
      state.loading = asyncActionEnums.ASYNC_ACTION_SUCCESS;
    },
    authError: (state) => {
      state.loading = asyncActionEnums.ASYNC_ACTION_FAILED;
    },
    logout: (state) => {
      state.isAuthenticated = authEnums.AUTH_STATUS_LOGGED_OUT;
    },

    updateUserDetail: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
});

export const {
  login,
  logout,
  updateUserDetail,
  authError,
  authLoading,
  authSuccess,
} = authSlice.actions;

// RR || The function below is called to check user auth status from firebase

export const regfeshTokenId = (callback): AppThunk => (dispatch) => {
  refreshToken(callback, dispatch);
};

export const regfeshTokenIdWithoutDispatch = (callback): AppThunk => (
  dispatch
) => {
  refreshToken(callback, dispatch, true);
};

export const refreshTokenWithoutCallback = () =>
  firebase.auth().onAuthStateChanged(function(user: any) {
    if (user) {
      user
        .getIdTokenResult(false)
        .then(function(idTokenResult: any) {
          localStorage.setItem("token", idTokenResult.token);
          console.log("Token Updated");
        })
        .catch(function(error) {
          // Handle error
        });
    }
  });

const refreshToken = (callback, dispatch, isCallbackPromise: boolean = false) =>
  firebase.auth().onAuthStateChanged(function(user: any) {
    if (user) {
      dispatch(login());

      let email = user.email;

      let name = user.displayName;
      let uid = user.uid;
      localStorage.setItem("uid", uid);
      user
        .getIdTokenResult(false)
        .then(function(idTokenResult: any) {
          localStorage.setItem("token", idTokenResult.token);
          if (idTokenResult.claims.role === userRoleEnums.ADMIN_USER) {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.ADMIN_USER,
              })
            );
          } else if (
            idTokenResult.claims.role === userRoleEnums.TENANT_AS_PROVIDER_USER
          ) {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.TENANT_AS_PROVIDER_USER,
              })
            );
          } else if (idTokenResult.claims.role === userRoleEnums.CS_USER) {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.CS_USER,
              })
            );
          } else {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.PRVIDER_USER,
              })
            );
          }
          if (isCallbackPromise === false) {
            dispatch(callback(dispatch));
          } else {
            callback();
          }
        })
        .catch(function(error) {
          // Handle error
        });
      // ...
    } else {
      dispatch(logout());
    }
  });

const firebaseAuth = (dispatch) => {
  let unsubscribe: any;
  return (unsubscribe = firebase.auth().onAuthStateChanged(function(user: any) {
    if (user) {
      dispatch(login());
      dispatch(authLoading());
      let email = user.email;
      let name = user.displayName;
      let uid = user.uid;
      localStorage.setItem("uid", uid);
      user
        .getIdTokenResult(false)
        .then(function(idTokenResult: any) {
          localStorage.setItem("token", idTokenResult.token);
          if (idTokenResult.claims.role === userRoleEnums.ADMIN_USER) {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.ADMIN_USER,
              })
            );
          } else if (
            idTokenResult.claims.role === userRoleEnums.TENANT_AS_PROVIDER_USER
          ) {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.TENANT_AS_PROVIDER_USER,
              })
            );
          } else if (idTokenResult.claims.role === userRoleEnums.CS_USER) {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.CS_USER,
              })
            );
          } else {
            dispatch(
              updateUserDetail({
                userId: uid,
                displayName: name,
                email: email,
                role: userRoleEnums.PRVIDER_USER,
              })
            );
          }
          dispatch(getChannelListfromApi());
          if(idTokenResult.claims.role !== userRoleEnums.CS_USER){
              dispatch(getmetaDataFromApi());
          }
          dispatch(getLanguageFromAPI());
          // console.log(idToken);
          // let body = {
          //     "token": idToken
          // }
          //temporary to testing, will be added to custom envs
          // axios.post(`https://ff-int.api.wexer.com${apiConstant.tokenValidationAPI}`, body).then((data) => {
          //     console.log(data);
          // });
        })
        .catch(function(error) {
          // Handle error
        })
        .finally(() => {
          dispatch(authSuccess());
          unsubscribe();
        });

      // ...
    } else {
      dispatch(logout());
    }
  }));
};
export const checkAuthStatus = (): AppThunk => (dispatch) => {
  let isforceFullLogoutDone = localStorage.getItem("isForceFullLogouDone");
  if (isforceFullLogoutDone !== "true") {
    localStorage.setItem("isForceFullLogouDone", "true");
    dispatch(userLogout());
  } else {
    firebaseAuth(dispatch);
  }
};

export const userLogout = (): AppThunk => (dispatch) => {
  // YKS - Commenting below code as it's setting it true on launch
  //localStorage.setItem('userChanged', "true");
  localStorage.removeItem("selectedTenantId");
  localStorage.removeItem("csTenantId");
  localStorage.removeItem("tenantName");
  localStorage.removeItem("tenantId");
  localStorage.removeItem("token");
  sessionStorage.removeItem("filterSessionData");
  sessionStorage.removeItem("filterCalendarData");
  dispatch(resetFilterDataCal());
  dispatch(setResetFilterData(null));
  dispatch(setSelectedTenantValue(null));
  dispatch(authLoading());
  moment.tz.setDefault();
  firebase
    .auth()
    .signOut()
    .then(function() {
      dispatch(resetSorting());
      dispatch(logout());
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const auth = (email: string, password: string): AppThunk => (
  dispatch
) => {
  dispatch(authLoading());
  moment.tz.setDefault();
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      dispatch(authError());
    });

  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

  firebaseAuth(dispatch);
};
export const selectAuth = (state: RootState) => state.auth.isAuthenticated;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;

// firebase.auth().currentUser.getIdToken(/ forceRefresh / true)
// .then(function(idToken) {

// }).catch(function(error) {

// });
