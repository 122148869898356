import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import {
  FormCommonStyle,
  PaperCommonStyle,
  AppCommonStyle,
  SearchBarCommonStyle,
  DisplayCommonStyle,
  AddUserCommonStyle,
  ContentWrapperCommonStyle,
  SecondaryBarCommonStyle,
  LinkCommonStyle,
  InputCommonStyle,
} from "./Common.style";

export const ChannelScreenStyles = (theme: Theme) =>
  createStyles({
    AppCommonStyle,
    ...PaperCommonStyle,
    ...SearchBarCommonStyle(theme),
    ...DisplayCommonStyle,
    ...AddUserCommonStyle(theme),
    ...ContentWrapperCommonStyle,
    ...SecondaryBarCommonStyle,
    ...LinkCommonStyle(theme),
    main: {
      flex: 1,
      // padding: "172px 32px 32px 16px",
      background: "rgb(239,239,244)",
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
    },
  });

export const ChannelCreationFormStyles = makeStyles((theme) => ({
  ...{ ...FormCommonStyle(theme) },
  ...InputCommonStyle,
}));

export const ChannelCheckBoxesStyles = makeStyles((theme) => ({
  formControlSingle: {
    margin: "27px 8px 8px 0px",
    width: "100%",
  },
}));

export interface ChannelScreenProps
  extends WithStyles<typeof ChannelScreenStyles> {}
