import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { apiResponseEnums, SCREEN, USER_MESSAGES } from "constants/enums";
import {
  SubscriptionsModel,
  SubscriptionDeleteRequestPayload,
  SubscriptionListRequestPayload,
  SubscriptionCreateRequestPayload,
  SubscriptionUpdateRequestPayload,
  SubscriptionDetailsRequestPayload,
  SubscriptionsListModel,
  SubscriptionDetailsResponseModel,
  SubscriptionsFormData,
} from "models/subscriptionsModel";
import { ApiResponse } from "apisauce";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";
import { History } from "history";
import { subscriptionsPath } from "constants/routeConstants";

interface SubscriptionsState {
  submitLoading: boolean;
  dataLoading: boolean;
  subscriptionsData: SubscriptionsListModel[];
  subscriptionDetail: SubscriptionDetailsResponseModel | null;
  uploadedImageLink: string | undefined;
  previousStatus: boolean | null;
  formData: SubscriptionsFormData;
  formUpdated: boolean;
}

const initialState: SubscriptionsState = {
  submitLoading: false,
  dataLoading: false,
  subscriptionsData: [],
  subscriptionDetail: null,
  uploadedImageLink: "",
  previousStatus: null,
  formData: {
    title: "",
    description: "",
    collectionTags: [],
    subscriptionType: null,
    pricingTableId: "",
    imageLink: "",
    status: false,
    tenantId: "",
  },
  formUpdated: false,
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.submitLoading = action.payload;
    },
    setDataLoading: (state, action: PayloadAction<boolean>) => {
      state.dataLoading = action.payload;
    },
    setSubscriptionData: (
      state,
      action: PayloadAction<SubscriptionsListModel[]>
    ) => {
      state.subscriptionsData = action.payload;
    },
    setIsFormUpdated: (state, action: PayloadAction<boolean>) => {
      state.formUpdated = action.payload;
    },
    setFormDataRedux: (state, action: PayloadAction<SubscriptionsFormData>) => {
      state.formData = action.payload;
    },
    setSubscriptionDetails: (
      state,
      action: PayloadAction<SubscriptionDetailsResponseModel | null>
    ) => {
      state.subscriptionDetail = action.payload;
    },
  },
});

export const {
  setSubmitLoading,
  setDataLoading,
  setSubscriptionData,
  setSubscriptionDetails,
  setIsFormUpdated,
  setFormDataRedux,
} = subscriptionsSlice.actions;

export const addSubscription = (
  body: SubscriptionCreateRequestPayload,
  history: History
): AppThunk => (dispatch, getState) => {
  dispatch(setSubmitLoading(true));
  userApi
    .post(ApiConstants.addSubscriptionAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(handleApiSuccess(res, USER_MESSAGES.SAVED));
        history.push(`${subscriptionsPath}/edit/${res?.data?.subsPackageId}`);
      } else {
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
      dispatch(setSubmitLoading(false));
    });
};

export const getSubscriptionsListData = (
  body: SubscriptionListRequestPayload
): AppThunk => (dispatch) => {
  dispatch(setDataLoading(true));
  userApi
    .post(ApiConstants.getSubscriptionsListAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setSubscriptionData(res.data as SubscriptionsListModel[]));
      } else {
        dispatch(setSubscriptionData([]));
        handleApiError(res);
      }
      dispatch(setDataLoading(false));
    });
};

export const deleteSubscriptions = (
  body: SubscriptionDeleteRequestPayload,
  callback: () => void
): AppThunk => (dispatch) => {
  userApi
    .delete(ApiConstants.deleteSubscriptionAPI, {}, { data: body })
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        const { failed } = res?.data;
        // dispatch(getSubscriptionsListData({ tenantId }));
        if (failed == null) {
          dispatch(
            setApiResponse({
              status: res.status,
              data: USER_MESSAGES.DELETE,
            })
          );
        }
        if (failed !== null) {
          failed?.map((failedItem) => {
            dispatch(
              setApiResponse({
                status: res.status,
                data: failedItem?.reason ?? USER_MESSAGES.DELETE,
              })
            );
          });
        }
        callback();
      } else {
        handleApiError(res);
      }
    });
};

export const updateSubscription = (
  body: SubscriptionUpdateRequestPayload,
  callback: () => void,
  screen: string,
): AppThunk => (dispatch) => {
  dispatch(setSubmitLoading(true));

  userApi
    .put(ApiConstants.updateSubscriptionAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        if (screen === SCREEN.SUBSCRIPTION_EDIT) {
          dispatch(setIsFormUpdated(false));
        }
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        callback();
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
      dispatch(setSubmitLoading(false));
    });
};

export const getSubscriptionDetails = (
  body: SubscriptionDetailsRequestPayload
): AppThunk => (dispatch, getState) => {
  dispatch(setSubmitLoading(true));

  userApi
    .post(ApiConstants.getSubscriptionDetailsAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        if (res?.data?.data && res?.data?.data.length > 0) {
          const subscriptionDetails = res.data?.data[0];
          dispatch(
            setSubscriptionDetails(
              subscriptionDetails as SubscriptionDetailsResponseModel
            )
          );
        } else {
          handleApiError(res);
        }
      } else {
        handleApiError(res);
      }
      dispatch(setSubmitLoading(false));
    });
};

const handleApiError = (res: ApiResponse<any>): AppThunk => (
  dispatch,
  getState
) => {
  const errorMessage = getCustomAPIErrorMessage({
    status: res.status as number,
    data: res.data as ErrorObjectType,
  });

  dispatch(
    setApiResponse({
      status: res.status ?? apiResponseEnums.BAD_REQUEST,
      data: errorMessage,
    })
  );
};

const handleApiSuccess = (res: ApiResponse<any>, message: string): AppThunk => (
  dispatch
) => {
  dispatch(
    setApiResponse({
      status: res.status,
      data: USER_MESSAGES.SAVED,
    })
  );
};

export const subscriptionsSubmitLoading = (state: RootState) =>
  state.subscriptions.submitLoading;

export const subscriptionsDataLoading = (state: RootState) =>
  state.subscriptions.dataLoading;

export const subscriptionsListData = (state: RootState) =>
  state.subscriptions.subscriptionsData;

export const subscriptionDetailsData = (state: RootState) =>
  state.subscriptions.subscriptionDetail;

export const formDataRedux = (state: RootState) =>
  state.subscriptions.formData;

export const formUpdated = (state: RootState) =>
  state.subscriptions.formUpdated;

export default subscriptionsSlice.reducer;
