import { Theme, makeStyles } from "@material-ui/core/styles";

export const PopOverButtonComponentStyles = makeStyles((theme: Theme) => ({
  AvatarContainer: {
    marginTop: 8,
    marginRight: 8,
    position: "fixed",
    top: 64,
    right: 32,
    borderRadius: 4,
    boxShadow:
      "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)",
    zIndex: 2000,
  },
  button: {
    height: 56,
    width: 165,
    borderRadius: 4,
    backgroundColor: "rgba(0, 0, 0, 0.87)",
    color: "#fff",
    boxShadow:
      "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.87)",
      color: "#fff",
    },
  },
  btnWidth: {
    width: 365,
  },
}));
