import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { downloadreportApi, userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { apiResponseEnums, USER_MESSAGES } from "constants/enums";
import {
  DeleteDocumentsPayload,
  DocumentLibraryData,
  DocumentLibraryModel,
  GetDocumentsMetaDataPayload,
  GetDocumentsPayload,
} from "models/documentsLibrary.model";
import { ApiResponse } from "apisauce";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage, getLanguageFromConfig } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";
import { History } from "history";
import { documentsPath } from "constants/routeConstants";

interface DocumentsState {
  submitLoading: boolean;
  listLoading: boolean;
  documentsList: DocumentLibraryModel | null;
  documentDetailsLoading: boolean;
  documentDetails: DocumentLibraryData | null;
}

const initialState: DocumentsState = {
  submitLoading: false,
  listLoading: true,
  documentsList: null,
  documentDetailsLoading: true,
  documentDetails: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.submitLoading = action.payload;
    },
    setListLoading: (state, action: PayloadAction<boolean>) => {
      state.listLoading = action.payload;
    },
    setDocumentsList: (
      state,
      action: PayloadAction<DocumentLibraryModel | null>
    ) => {
      state.documentsList = action.payload;
    },
    setDocumentDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.documentDetailsLoading = action.payload;
    },
    setDocumentDetails: (
      state,
      action: PayloadAction<DocumentLibraryData | null>
    ) => {
      state.documentDetails = action.payload;
    },
    resetDocumentList: (state) => {
      state.documentsList = null;
      state.listLoading = true;
    }
  },
});

export const {
  setSubmitLoading,
  setListLoading,
  setDocumentsList,
  setDocumentDetailsLoading,
  setDocumentDetails,
  resetDocumentList,
} = documentsSlice.actions;

export const addDocuments = (
  body: FormData,
  history: History,
  setSubtitle: (val: string) => void
): AppThunk => (dispatch) => {
  dispatch(setSubmitLoading(true));
  userApi
    .post(ApiConstants.addDocumentsAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        setSubtitle(res.data.title);
        dispatch(setSubmitLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        history.push(`${documentsPath}/${res.data.documentId}/edit`);
      } else {
        dispatch(setSubmitLoading(false));
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const updateDocuments = (
  body: FormData,
  setSubtitle: (val: string) => void
): AppThunk => (dispatch) => {
  dispatch(setSubmitLoading(true));
  userApi
    .post(ApiConstants.updateDocumentsAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        setSubtitle(res.data.title);
        dispatch(setSubmitLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        dispatch(setDocumentDetails(res.data));
      } else {
        dispatch(setSubmitLoading(false));
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const getDocumentsList = (body: GetDocumentsPayload): AppThunk => (
  dispatch
) => {
  dispatch(setListLoading(true));
  dispatch(setDocumentsList(null));
  userApi
    .post(ApiConstants.getDocumentsListAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setDocumentsList(res.data as DocumentLibraryModel));
        dispatch(setListLoading(false));
      } else {
        dispatch(
          setDocumentsList({
            page: 1,
            totalPages: 1,
            totalRecords: 0,
            data: [],
          } as DocumentLibraryModel)
        );
        dispatch(setListLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const getDocumentDetails = (
  tenantId: string,
  documentId: string
): AppThunk => (dispatch) => {
  dispatch(setDocumentDetailsLoading(true));
  dispatch(setDocumentDetails(null));
  const body: GetDocumentsMetaDataPayload = { tenantId, documentId };
  userApi
    .post(ApiConstants.getDocumentsDetailsAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setDocumentDetails(res.data as DocumentLibraryData));
        dispatch(setDocumentDetailsLoading(false));
      } else {
        dispatch(
          setDocumentsList({
            page: 1,
            totalPages: 1,
            totalRecords: 0,
            data: [],
          } as DocumentLibraryModel)
        );
        dispatch(setDocumentDetailsLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const deleteDocuments = (
  body: DeleteDocumentsPayload,
  callback: () => void
): AppThunk => (dispatch) => {
  userApi
    .delete(ApiConstants.deleteDocumentsAPI, {}, { data: body })
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.DELETE,
          })
        );
        callback();
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const downloadDocumentsFromApi = (data, tenantId): AppThunk => (
  dispatch
) => {
  const body = {
    tenantId,
    documentId: data.documentId,
  };
  const lang = getLanguageFromConfig();
  downloadreportApi
    .post(ApiConstants.downloadDocumentsAPI, body)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        const url = window.URL.createObjectURL(new Blob([res.data] as any));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${data.documentName.split(".")[0]}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(
          setApiResponse({
            status: res.status,
            data: lang.Documents.downloadingFile,
          })
        );
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const submitLoading = (state: RootState) =>
  state.documents.submitLoading;

export const documentsListLoading = (state: RootState) =>
  state.documents.listLoading;

export const documentsList = (state: RootState) =>
  state.documents.documentsList;

export const documentDetailsLoading = (state: RootState) =>
  state.documents.documentDetailsLoading;

export const documentDetails = (state: RootState) =>
  state.documents.documentDetails;

export default documentsSlice.reducer;
