export const primaryColor:string = "#36dcb6";                // Turquoise color, wexer primary color
export const primaryLightColor:string = "#f1f7f9";           // Light Sky color
export const primaryAccentColor:string = "#f5f5f5";          // Ligth grey color

export const secondaryColor:string = "#19202c";              // Secondary Dark color for sidebar 
export const secondaryAccentColor:string = "#19202c";        // Secondary Dark color for sidebar when option is highlighted

export const textColorLight:string = "#fff";              // Secondary Dark color for sidebar 
export const textColorDark:string = "rgba(0, 0, 0, 0.54);";        // Secondary Dark color for sidebar when option is highlighted
export const textColorDarkDesabled:string = "rgba(0, 0, 0, 0.54);";    

export const textColorDarkInfo:string = "#8e8e93";  


    