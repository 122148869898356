import React from "react";
import Providers from "@material-ui/icons/PeopleRounded";
import Key from "@material-ui/icons/VpnKeyRounded";
import Setting from "@material-ui/icons/SettingsRounded";
import Content from "@material-ui/icons/VideoLibraryRounded";
import Channels from "@material-ui/icons/SettingsRemoteRounded";
import ScheduleIcon from "@material-ui/icons/CalendarToday";
import ContentRatingIcon from "@material-ui/icons/Star";
import SettingsIcon from "@material-ui/icons/Settings";
import { userRoleEnums, TENANT_CONNECT_VALUES } from "constants/enums";
import CollectionIcon from "@material-ui/icons/ViewModule";
import PictureAsPdfIcon from "@material-ui/icons//PictureAsPdf";
import * as Routes from "constants/routeConstants";
import { selectTenants } from "_pages/channels/channelSlice";
import { useSelector } from "react-redux";
import { selectTenantAsProviders } from "_pages/provider/providerSlice";
import SubscriptionIcon from "@material-ui/icons/Subscriptions";
import {SupervisedUserCircle} from "@material-ui/icons";
export type MenuItem = {
  id: string;
  icon: JSX.Element | boolean,
  routesTo: string | boolean,
  children?: MenuItem[],
}

let tenantAsProvider: MenuItem[] = [
  {
    id: "schedule",
    icon: <ScheduleIcon />,
    routesTo: Routes.scheduleTitles,
  },
  {
    id: "collections",
    icon: <CollectionIcon />,
    routesTo: Routes.collectionsPath,
  },
  {
    id: "siteManagement",
    icon: false,
    routesTo: false,
  },
  {
    id: "siteSettings",
    icon: <SettingsIcon />,
    routesTo: Routes.siteSettings,
    children: [
      {
        id: "socialLinks",
        icon: false,
        routesTo: Routes.socialLinks,
      },
      {
        id: "customLinks",
        icon: false,
        routesTo: Routes.customLinks,
      },
      {
        id: "appLinks",
        icon: false,
        routesTo: Routes.appLinks,
      },
    ],
  },
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "categories",
        icon: false,
        routesTo: Routes.categoriesPath,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      {
        id: "bulkUpload",
        icon: false,
        routesTo: Routes.bulkUploadPath,
      },
    ],
  },
  {
    id: "documents",
    icon: <PictureAsPdfIcon />,
    routesTo: Routes.documentsPath,
  },
  {
    id: "subscriptions",
    icon: <SubscriptionIcon />,
    routesTo: Routes.subscriptionsPath,
  },
  {
    id: "users",
    icon: <SupervisedUserCircle />,
    routesTo: Routes.user,
    children: [
      {
        id: "userManagement",
        icon: false,
        routesTo: Routes.userManagement,
      },
      {
        id: "userReport",
        icon: false,
        routesTo: Routes.userReportPath,
      },
    ],
  },
];
const adminCategories: MenuItem[] = [
  { id: "admin", icon: <Setting />, routesTo: Routes.adminPath },
  { id: "tenants", icon: <Key />, routesTo: Routes.tenantPath },
  {
    id: "providers",
    icon: <Providers />,
    routesTo: Routes.providerPath,
  },
  {
    id: "channels",
    icon: <Channels />,
    routesTo: Routes.channelsPath,
  },
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      
    ],
  },
];

const providerCategories: MenuItem[] = [
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      {
        id: "bulkUpload",
        icon: false,
        routesTo: Routes.bulkUploadPath,
      },
    ],
  },
];

const csCategories: MenuItem[] = [
  {id: "admin", icon: <Setting />, routesTo: Routes.adminPath},
  {id: "tenants", icon: <Key />, routesTo: Routes.tenantPath},
  {
    id: "providers",
    icon: <Providers />,
    routesTo: Routes.providerPath,
  },
  {
    id: "channels",
    icon: <Channels />,
    routesTo: Routes.channelsPath,
  },
  {
    id: "siteManagement",
    icon: false,
    routesTo: false,
  },
  {
    id: "siteSettings",
    icon: <SettingsIcon />,
    routesTo: Routes.siteSettings,
    children: [
      {
        id: "socialLinks",
        icon: false,
        routesTo: Routes.socialLinks,
      },
      {
        id: "customLinks",
        icon: false,
        routesTo: Routes.customLinks,
      },
      {
        id: "appLinks",
        icon: false,
        routesTo: Routes.appLinks,
      },
    ],
  },
  {
    id: "media",
    icon: <Content />,
    routesTo: Routes.mediaPath,
    children: [
      {
        id: "contentTitles",
        icon: false,
        routesTo: Routes.contentTitles,
      },
      {
        id: "categories",
        icon: false,
        routesTo: Routes.categoriesPath,
      },
      {
        id: "reviews",
        icon: false,
        routesTo: Routes.reviews,
      },
      {
        id: "bulkUpload",
        icon: false,
        routesTo: Routes.bulkUploadPath,
      },
    ],
  },
  {
    id: "schedule",
    icon: <ScheduleIcon />,
    routesTo: Routes.scheduleTitles,
  },
  {
    id: "collections",
    icon: <CollectionIcon />,
    routesTo: Routes.collectionsPath,
  },
  {
    id: "subscriptions",
    icon: <SubscriptionIcon />,
    routesTo: Routes.subscriptionsPath,
  },

  {
    id: "documents",
    icon: <PictureAsPdfIcon />,
    routesTo: Routes.documentsPath,
  },
  {
    id: "users",
    icon: <SupervisedUserCircle />,
    routesTo: Routes.user,
    children: [
      {
        id: "userManagement",
        icon: false,
        routesTo: Routes.userManagement,
      },
      {
        id: "userReport",
        icon: false,
        routesTo: Routes.userReportPath,
      },
    ],
  },
];
export const removeBulkUploadMenu = (isBulkUploadEnabled,menuArray) =>{
  if (isBulkUploadEnabled === false) {
    let mediaIndex = menuArray.findIndex((x) => x.id === "media");
    const modifiedMenu = menuArray.map((obj) => {
      if (obj.id === "media") {
        return {
          ...obj,
          children: menuArray[mediaIndex].children?.slice(0, -1),
        };
      }
      return obj;
    });
    return modifiedMenu;
  }else{
    return menuArray;
  }
}
export const useCategories = (role) => {
  let tenant = useSelector(selectTenants);
  let providerData = useSelector(selectTenantAsProviders);
  let tenantId = providerData?.tenantAsProviders[0]?.tenantId;
  var tenantChannel = tenant?.tenants?.find((tenant) => tenant.id === tenantId);
  var isBulkUploadEnabled = providerData?.tenantAsProviders[0]?.isBulkUploadEnabled;
  if (role === userRoleEnums.ADMIN_USER) {
    return adminCategories;
  } else if (role === userRoleEnums.TENANT_AS_PROVIDER_USER) {

    if (tenantChannel?.connect === null || 
        tenantChannel?.connect === TENANT_CONNECT_VALUES.ONDEMAND ||
        tenantChannel?.connect === TENANT_CONNECT_VALUES.NULL ||
        tenantChannel?.connect === TENANT_CONNECT_VALUES.NONE
    ) {
      return (tenantAsProvider = tenantAsProvider.filter(
        (item) => item.id !== "schedule"
      ));
    }
    return removeBulkUploadMenu(isBulkUploadEnabled, tenantAsProvider);
  } else if (role === userRoleEnums.CS_USER) {

    return csCategories;
  } else {
    return removeBulkUploadMenu(isBulkUploadEnabled, providerCategories);
  }
};
