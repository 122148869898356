import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Translate from "utils/translate";
import { HeaderHooks } from "../Hooks/Header.Hooks";
import LayoutAppBar from "../Components/AppBar.component";
import ToolBar from "../Components/ToolBar.component";
import * as RouteContants from "constants/routeConstants";
import { ChannelsHeader,TenantsHeader,ContentHeader } from ".";
import { DropDownComponent } from "../Components/DropDown.component";
import AdminHeader from "./Admin.Tab";

const CommonHeader = () => {
  const { classes,HeaderTitle,
          showSelector,
        } = HeaderHooks();
  const { pathname } = useLocation();
  const GetTabComp = useMemo(() => {
    switch (pathname) {
      case pathname.includes(RouteContants.adminPath) ? pathname : false:
        return <AdminHeader />
      case pathname.includes(RouteContants.channelsPath) ? pathname : false:
        return <ChannelsHeader />;
      case pathname.includes(RouteContants.tenantPath) ? pathname : false:
        return <TenantsHeader />;
      case RouteContants.contentTitles:
        return <ContentHeader />;
      default:
        return <div></div>;
    }
  }, [pathname]);
  return (
    <React.Fragment>
      <div className="fixedHeader">
        <LayoutAppBar />
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="inherit"
          position="static"
          elevation={0}
        >
          <ToolBar
            HeaderLabel={Translate({
              id: HeaderTitle,
            })}
          />
          <div className="row-container">
            <div className='tab-container'>
              {GetTabComp}
            </div>
           {showSelector &&  <DropDownComponent /> }
          </div>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

CommonHeader.propTypes = {};

export { CommonHeader };
