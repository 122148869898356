import {useEffect} from 'react';
import { paginationValues} from 'constants/enums';
import { useSelector, useDispatch } from 'react-redux';
import { getPaginateInfo,resetPaginate,setPaginate } from './Pagination.Slice';
import { useRouteMatch } from 'react-router-dom';
import { userReportPath } from 'constants/routeConstants';
const usePaginationHook = () => {
    let paginationData = useSelector(getPaginateInfo),dispatch = useDispatch();
    const handelPaginate = (_:any)=> dispatch(setPaginate(_))
    const { path } = useRouteMatch();
    useEffect(() => {
        if((path.includes("/contentTitles")|| path.includes(userReportPath)) && paginationData.DEFAULT_PAGE_SIZE < 1){
            handelPaginate({...paginationData,DEFAULT_PAGE_SIZE:100});
            // dispatch(resetPaginate())
        }
    }, [])
    return {
        handelPaginate,
        paginationValues,
        paginationData
    }
}


export {usePaginationHook}