import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import {
  apiResponseEnums,
  CONFIGURATION_TYPE,
  USER_MESSAGES,
} from "constants/enums";
import {
  CustomLinkModel,
  DeleteApiCustomLinksPayload,
  GetApiCustomLinksPayload,
  TenantConfigLanguageModel,
  UpdateApiCustomLinksPayload,
} from "models/SiteSettings/CustomLinksModel";
import { ApiResponse } from "apisauce";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";
import { History } from "history";
import { customLinks } from "constants/routeConstants";

interface CustomLinksState {
  tenantConfigData: TenantConfigLanguageModel;
  configLoading: boolean;
  submitLoading: boolean;
  dataLoading: boolean;
  customLinkData: CustomLinkModel | null;
}

const initialState: CustomLinksState = {
  tenantConfigData: {
    supportedLanguages: [],
    defaultLanguage: "",
  },
  configLoading: true,
  submitLoading: false,
  dataLoading: true,
  customLinkData: null,
};

export const customLinksSlice = createSlice({
  name: "customLinks",
  initialState,
  reducers: {
    setConfigLoading: (state, action: PayloadAction<boolean>) => {
      state.configLoading = action.payload;
    },
    setTenantConfig: (
      state,
      action: PayloadAction<TenantConfigLanguageModel>
    ) => {
      state.tenantConfigData = action.payload;
    },
    resetTenantConfig: (state) => {
      state.tenantConfigData = {
        supportedLanguages: [],
        defaultLanguage: "",
      };
      state.configLoading = true;
    },
    setSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.submitLoading = action.payload;
    },
    setDataLoading: (state, action: PayloadAction<boolean>) => {
      state.dataLoading = action.payload;
    },
    setCustomLinkData: (
      state,
      action: PayloadAction<CustomLinkModel | null>
    ) => {
      state.customLinkData = action.payload;
    },
  },
});

export const {
  setConfigLoading,
  setTenantConfig,
  resetTenantConfig,
  setSubmitLoading,
  setDataLoading,
  setCustomLinkData,
} = customLinksSlice.actions;

export const getTenantConfigData = (tenantId: string): AppThunk => (
  dispatch
) => {
  dispatch(setConfigLoading(true));
  userApi
    .get(`${ApiConstants.getTenantConfigAPI}/${tenantId}`)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        const {
          languageCodes: supportedLanguages,
          languageTag: defaultLanguage,
        } = res.data[0].tenant;
        dispatch(setTenantConfig({ supportedLanguages, defaultLanguage }));
        dispatch(setConfigLoading(false));
      } else {
        dispatch(setConfigLoading(false));
      }
    });
};

export const addCustomLink = (
  body: UpdateApiCustomLinksPayload,
  history: History
): AppThunk => (dispatch, getState) => {
  dispatch(setSubmitLoading(true));
  const {
    customLinks: { customLinkData },
  } = getState();
  userApi
    .put(ApiConstants.updateSiteConfigAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setSubmitLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        const nextCustomLinkData = { ...customLinkData };
        const nextData = [...nextCustomLinkData.data];
        nextData.push(res.data.data[0]);
        nextCustomLinkData.data = nextData;
        dispatch(setCustomLinkData(nextCustomLinkData as CustomLinkModel));
        history.push(`${customLinks}/${res.data.data[0].tag}/edit`);
      } else {
        dispatch(setSubmitLoading(false));
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const getCustomLinkData = (body: GetApiCustomLinksPayload): AppThunk => (
  dispatch
) => {
  dispatch(setDataLoading(true));
  dispatch(setCustomLinkData(null));
  userApi
    .post(ApiConstants.getSiteConfigAPI, body)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setCustomLinkData(res.data as CustomLinkModel));
        dispatch(setDataLoading(false));
      } else {
        dispatch(
          setCustomLinkData({
            configurationType: CONFIGURATION_TYPE.CUSTOM_LINKS,
            data: [],
          } as CustomLinkModel)
        );
        dispatch(setDataLoading(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const deleteCustomLinks = (
  body: DeleteApiCustomLinksPayload,
  callback: () => void
): AppThunk => (dispatch) => {
  userApi
    .delete(ApiConstants.deleteSiteConfigAPI, {}, { data: body })
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        const { tenantId, configurationType } = body;
        dispatch(getCustomLinkData({ tenantId, configurationType }));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.DELETE,
          })
        );
        callback();
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const tenantConfigLoading = (state: RootState) =>
  state.customLinks.configLoading;

export const languageConfig = (state: RootState) =>
  state.customLinks.tenantConfigData;

export const submitLoading = (state: RootState) =>
  state.customLinks.submitLoading;

export const customLinkDataLoading = (state: RootState) =>
  state.customLinks.dataLoading;

export const customLinkData = (state: RootState) =>
  state.customLinks.customLinkData;

export default customLinksSlice.reducer;
