import { makeStyles } from "@material-ui/core/styles";

export const FileSelectorComponentStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  buttons: {
    backgroundColor: "#ffffff",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: 0.3,
    color: "#36dcb6",
  },
}));
