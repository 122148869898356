import { makeStyles } from "@material-ui/core/styles";
import { CompTitleArrowCommonStyle } from "./CommonComponent.style";

export const TitleComponentStyles = makeStyles((theme) => ({
  ...{ ...CompTitleArrowCommonStyle() },
  subTitle: {
    fontfamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.5,
    letterspacing: 0.09,
    color: "rgba(0, 0, 0, 0.87)",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
}));

export const NavigationTitleComponentStyles = makeStyles((theme) => ({
  ...{ ...CompTitleArrowCommonStyle() },
  subTitle: {
    fontfamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.5,
    letterspacing: 0.09,
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export const TitleStatusComponentStyles = (checked) => {
  if (checked) {
    return { color: "#36dcb6" };
  }
  return {};
};
