import { titleStatusEnums, VIDEO_STATUS_ENUM } from 'constants/enums';
import { CONTENT_STATUS_ENUM } from '../../../constants/enums';

export const createNewTitle = (fileObject: any, isFileBroken: boolean = false, isError:boolean = false) => {

    let k = fileObject.isUploading();
    let progress = fileObject.progress(false);
    if (isFileBroken && localStorage.progress && k) {

        let prevProgress = JSON.parse(localStorage.progress);
        if (prevProgress > progress) {
            progress = prevProgress;
        }
        //  
    }

    let titleDetail = {
        status: isError ? CONTENT_STATUS_ENUM.ERROR : fileObject?.status,
        videoStatus:isError ? VIDEO_STATUS_ENUM.FAILED : fileObject.videoStatus,
        uploadStatus: fileObject.isPaused(),
        uploadingProgress: isError ? 0 : progress,// + preProgress,
        alternateLink: null,
        channelId: fileObject?.channelId ?? null,
        classCategory: null,
        classDescription: null,
        classLanguage: null,
        classLanguageCode: null,
        className: fileObject.fileName ?? null,
        classSubCategory: null,
        creationDate: fileObject?.creationDate ?? null,
        durationSecond: null,
        endDate: null,
        equipments: "None",
        errorType: 3,
        focusArea: null,
        imageLink: null,
        instructor: null,
        intensity: 6,
        isActive: true,
        isEquipment: false,
        jobID: null,
        keywords: null,
        labels: null,
        level: null,
        provider: fileObject?.provider ?? null,
        providerID: fileObject?.providerID ?? null,
        rawVideoFileUrl: null,
        scheduleDate: null,
        skill: 1,
        startDate: null,
        streamingLink: null,
        tag: fileObject?.tag ?? null,
        trailerLinkMobile: null,
        trailerLinkWeb: null,
        trailerName: null
    }
    return titleDetail;
}

export const createNewTitleFromRowFile = (fileObject: any) => {


    let titleDetail = {
        status: fileObject?.status ?? CONTENT_STATUS_ENUM.INCOMPLETE ,
        videoStatus:fileObject.videoStatus ?? VIDEO_STATUS_ENUM.UPLOADING,
        uploadingProgress: 0,
        uploadStatus: false,
        alternateLink: null,
        channelId: fileObject?.channelId ?? null,
        classCategory: null,
        classDescription: null,
        classLanguage: null,
        classLanguageCode: null,
        className: fileObject?.fileName ?? null,
        classSubCategory: null,
        creationDate: fileObject?.creationDate ?? null,
        durationSecond: null,
        endDate: null,
        equipments: "None",
        errorType: 3,
        focusArea: null,
        imageLink: null,
        instructor: null,
        intensity: 6,
        isActive: true,
        isEquipment: false,
        jobID: null,
        keywords: null,
        labels: null,
        level: null,
        provider: fileObject?.provider ?? null,
        providerID: fileObject?.providerID ?? null,
        rawVideoFileUrl: null,
        scheduleDate: null,
        skill: 1,
        startDate: null,
        streamingLink: null,
        tag: fileObject?.tag ?? null,
        trailerLinkMobile: null,
        trailerLinkWeb: null,
        trailerName: null
    }
    return titleDetail;
}


 