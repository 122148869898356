import React from "react";
import Translate from "utils/translate";
import { Tab, Tabs } from "@material-ui/core";
import { a11yProps } from "components/Headers/TabPanel";
import { userRoleEnums } from "constants/enums";
import { HeaderHooks } from "../Hooks/Header.Hooks";
import { useHistory, useLocation } from "react-router";

function AdminHeader() {
  const {
    user,
    admin: { classes, handleChange, tabId },
  } = HeaderHooks();
  const history = useHistory();

  const handleChangeTab = (event) => {
    const routingPath = event.currentTarget.getAttribute("data-route");
    history.push(routingPath);
    handleChange(event, +event.currentTarget.getAttribute("data-tabid"));
  };

  return user.role === userRoleEnums.CS_USER ? (
    <Tabs
      className={`header-tab-wrapper`}
      value={tabId}
      onChange={handleChangeTab}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      <Tab
        className={`${classes.tabBar} header-tab`}
        label={Translate({ id: "Reports.reports" })}
        {...a11yProps(0)}
        data-route="/admin/report"
        data-tabid="0"
      />
    </Tabs>
  ) : (
    <Tabs
      className={`header-tab-wrapper`}
      value={tabId}
      onChange={handleChangeTab}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      <Tab
        className={`${classes.tabBar} header-tab`}
        label={Translate({ id: "Reports.content" })}
        {...a11yProps(0)}
        data-route="/admin"
        data-tabid="0"
      />
      <Tab
        className={`${classes.tabBar} header-tab`}
        label={Translate({ id: "Reports.reports" })}
        {...a11yProps(1)}
        data-route="/admin/report"
        data-tabid="1"
      />
    </Tabs>
  );
}

export default AdminHeader;
