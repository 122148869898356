import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { DisplayCommonStyle, FormCommonStyle, Form2CommonStyle, InputCommonStyle, PaddingCommonStyle } from './Common.style';

export const CollectionScreenStyles = (theme: Theme) =>
  createStyles({
    main: {
      flex: 1,
      // padding: "192px 32px 32px 32px",
      minHeight: "100vh",
      background: "rgb(239,239,244)",
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
    },
  });

export const CollectionFormStyles = makeStyles((theme) => ({
  ...FormCommonStyle(theme) ,
  formControlLable: {
    marginTop: 11,
    marginLeft: 0,
  },
  formControlCopyLable: {
    margin: "0 !important",
    cursor: "copy !important",
  },
  selectSearch: {
    margin: "16px 0px 8px 0px !important",
    width: "100%",
  },
  ...Form2CommonStyle,
  ...InputCommonStyle,
  ...DisplayCommonStyle,
  title: {
    marginTop: 5,
    marginRight: 0,
    color: "#263238",
  },
  select: {
    cursor: "pointer",
    display: "flex",
    alignSelf: "flexStart",
    marginTop: "7px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    textAlign: "right",
    color: "#36dcb6",
  },
  manageClasses: {
    width: "144px",
    height: "24px",
    margin: "27px 0 2px 399px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    textAlign: "right",
    color: "#36dcb6",
  },
  cardContent: {
    padding: 0,
  },
  manageClassHr: {
    marginTop: "5px",
    color: "black",
    height: "1px",
  },
  imageLables: {
    height: 30,
  },
  ...PaddingCommonStyle,
  footerText: {
    paddingLeft: 32,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.58,
    letterSpacing: 0.3,
    color: "rgba(0, 0, 0, 0.54)",
  },
  attachmentLabel: {
    fontSize: 12,
    color: "#263238"
  },
  manageAttachmentButton: {
    fontSize: 12,
    color: "#36dcb6",
    cursor: "pointer"
  },
  attachmentNames: {
    marginBlock: "8px",
    marginRight: "8px",
  }
}));

export interface CollectionScreenProps
  extends WithStyles<typeof CollectionScreenStyles> {}
