import React from "react";
import PropTypes from "prop-types";
import {
  AdminOnlyViewCheck,
  CSOnlyViewCheck,
} from "components/RoleHook/RoleHook";
import SelectTenants from "_pages/tenants/Components/SelectTenants";
import { HeaderHooks } from "../../Hooks/Header.Hooks";
import MultiSelect from "components/Inputs/MultiSelect";
import { filter } from "lodash";
import { handelOptions } from "utils/utilityFunctions";

const SelectUI = (props) => {
  const { options = [] } = props;
  return (
    <React.Fragment>{options && <MultiSelect {...props} />}</React.Fragment>
  );
};
const ChannelDropDown = (props) => {
  const { isDisabled = false, from } = props;
  const {
    content: {
      selectCallback,
      getValue,
      filterParamChannel,
      channelData
    },
    reviews: {
      reviewsSelectCallback,
      reviewsGetValue,
      reviewsFilterParamChannel,
      reviewsChannelData
    }
  } = HeaderHooks();

  return (
    <div className='header-dropdown'>
      <SelectUI
        values={from === "reviews" ? reviewsGetValue(reviewsFilterParamChannel) : getValue(filterParamChannel)}
        style={`header-input-box no-under-line`}
        multiple={false}
        isArray={true}
        disabled={isDisabled}
        selectCallback={from === "reviews" ? reviewsSelectCallback : selectCallback}
        options={from === "reviews" ? handelOptions(true, reviewsChannelData, "name") : handelOptions(true, channelData, "name")}
        lable={"Channel"}
      />
    </div>
  );
};

export { ChannelDropDown };
