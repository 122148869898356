import {
  layoutTabId,
  setTabId,
} from "Layout/layoutSlice";
import { getTenantsTermsListfromApi } from "_pages/tenants/tenantSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import * as RouteContants from "constants/routeConstants";

export const useTenantIndexHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tabId = useSelector(layoutTabId);
  const handleChangeIndex = (index) => {
    dispatch(setTabId(index));
  };
  useEffect(() => {
    dispatch(getTenantsTermsListfromApi());
  }, [dispatch]);
  useEffect(()=>{
    if(tabId === 0){
      history.location.pathname.includes(RouteContants.tenantPublishingPath) && handleChangeIndex(1);
      history.location.pathname.includes(RouteContants.tenantSitetermsPath) && handleChangeIndex(2);
    }
  },[tabId]);
  return {
    tabId,
    handleChangeIndex,
  };
};
