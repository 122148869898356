import {
  addCategory,
  categoryData,
  getCategoryData,
  submitLoading,
  categoryDataLoading,
  updateCategory,
} from "_pages/categoryManagement/CategorySlice";
import {
  tenantConfigLoading,
  getTenantConfigData,
  languageConfig,
  resetTenantConfig,
} from "_pages/customLinks/CustomLinksSlice";

import {categoriesPath} from "constants/routeConstants";
import {
  CategoryData,
  SubCategoryData,
  SubCategoryFormError,
  CategoryModel,
  FormError,
  TenantConfigLanguageModel,
  UpdateApiCategoryPayload,
  SubCategoryItemError,
  PayloadType,
} from "models/Category/CategoryModel";
import {element} from "prop-types";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {allUniqueSupportedLanguagesData, getLanguageFromConfig} from "utils/utilityFunctions";
import {isEqual} from "lodash";
export const useSubCategoryAddHook = ({tenantId}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {id, subCateorgyId} = useParams<{id: string; subCateorgyId: string}>();
  const {supportedLanguages, defaultLanguage} = useAppSelector<
    TenantConfigLanguageModel
  >(languageConfig);
  const lang: any = getLanguageFromConfig();
  const configLoading = useAppSelector<boolean>(tenantConfigLoading);
  const submitLoader = useAppSelector<boolean>(submitLoading);
  const dataLoading = useAppSelector<boolean>(categoryDataLoading);
  const [categoryTitle, setCategoryTitle] = useState<any>(
    lang.buttons.addButton
  );
  const [categorySubTitle, setCategorySubTitle] = useState<any>(
    lang.buttons.addButton
  );
  const [unsavedModal, setUnsavedModal] = useState(false);
  //  const apiData = useAppSelector<SubCategoryData | null>(categoryData);
  const categoryApiData = useAppSelector<CategoryData | null>(categoryData);

  const [formData, setFormData] = useState<SubCategoryData>({
    subCategoryId: "",
    isPromoted: false,
    dynamicData: {},
    name: "",
    isActive: false,
  });
  const [errors, setErrors] = useState<SubCategoryFormError>({
    dynamicErrors: {},
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [noLanguage, setNoLanguage] = useState<boolean>(false);
  const allUniqueLanguagesData = allUniqueSupportedLanguagesData(defaultLanguage,supportedLanguages);
  const handleBack = () => {
    handleBackCategory();
  };
  const handleUnsavedModalOkClick = () => {
    history.push(`${categoriesPath}/${id}/edit`);
  };

  const handleBackCategory = () => {
    if (subCateorgyId) {
      const initiallocalData: any = {isPromoted: false, dynamicData: {}};
     allUniqueLanguagesData?.forEach((lang) => {
        initiallocalData.dynamicData[lang] = {title: ""};
      });
      const rowData = categoryApiData?.subCategories!.find(
        (i) => i.subCategoryId === subCateorgyId
      );
      Object.keys(initiallocalData.dynamicData).forEach((i: string) => {
        const currLinkText = rowData!.name!.localTexts!.find(
          (lT) => (lT.ietfTag)?.toLowerCase() === i?.toLowerCase()
        );
        if (currLinkText) {
          initiallocalData.dynamicData[i].title = currLinkText.text;
          initiallocalData.isPromoted = rowData!.isPromoted;
        }
      });
      if (
        formData.isPromoted == initiallocalData.isPromoted &&
        isEqual(formData.dynamicData, initiallocalData.dynamicData)
      ) {
        history.push(`${categoriesPath}/${id}/edit`);
      } else {
        setUnsavedModal(true);
      }
    } else {
      let dynamicData = {};
     allUniqueLanguagesData?.forEach((lang) => {
        dynamicData[lang] = {title: ""};
      });
      const currData = {
        subCategoryId: formData.subCategoryId,
        isPromoted: formData.isPromoted,
        dynamicData: formData.dynamicData,
        name: formData.name,
        isActive: false,
      };
      const existingData = {
        subCategoryId: "",
        isPromoted: false,
        dynamicData: dynamicData,
        name: "",
        isActive: false,
      };
      if (
        currData.isPromoted == existingData.isPromoted &&
        currData.name == existingData.name &&
        isEqual(currData.dynamicData, existingData.dynamicData)
      ) {
        history.push(`${categoriesPath}/${id}/edit`);
      } else {
        setUnsavedModal(true);
      }
    }
  };

  useEffect(() => {
    return () => {
      setNoLanguage(false);
      setLoading(true);
      dispatch(resetTenantConfig());
    };
  }, [dispatch]);
  const resetErrors = () => {
    const errObj: FormError = {error: false, errorText: ""};
    const initialError: SubCategoryFormError = {
      dynamicErrors: {},
    };
    allUniqueLanguagesData?.forEach((lang) => {
      initialError.dynamicErrors[lang] = {
        title: errObj,
      };
    });
    setErrors(initialError);
  };
  useEffect(() => {
    if (tenantId) {
      dispatch(getTenantConfigData(tenantId));
    }
  }, [tenantId, dispatch]);

  const handleUnsavedModalClose = () => {
    setUnsavedModal(false);
  };

  const constructPayload = (): UpdateApiCategoryPayload => {
    return {
      tenantId,
    };
  };
  const handleSubCateorgyAdd = (values) => {
    const propertyNames = Object.values(values?.dynamicData);
    const valuesname = Object.keys(values?.dynamicData);
    let textarray: {ietfTag: string; text: string}[] = [];
    valuesname.forEach((element2: any, index1) => {
      propertyNames.forEach((element1: any, index2) => {
        if (index1 == index2) {
          textarray.push({ietfTag: element2, text: element1.title});
        }
      });
    });

    let subCatgriesData = [
      {
        subCategoryId: subCateorgyId ? subCateorgyId : "",
        isPromoted: values?.isPromoted,
        name: {
          invariantText: textarray[0]?.text,
          localTexts: textarray,
        },
      },
    ];
    categoryApiData?.subCategories?.forEach((element) => {
      if (!(element.subCategoryId === subCateorgyId)) {
        subCatgriesData.push({
          subCategoryId: element.subCategoryId,
          isPromoted: element?.isPromoted,
          name: element?.name,
        });
      }
    });

    const payload: PayloadType = {
      categoryId: categoryApiData?.categoryId?.toString(),
      tenantId: tenantId,
      isPromoted: categoryApiData?.isPromoted,
      imageLink: categoryApiData?.imageLink,
      name: categoryApiData?.name,
      description: categoryApiData?.description,
      subCategories: subCatgriesData,
    };
    dispatch(updateCategory(payload, history));
  };

  useEffect(() => {
    if (tenantId && id) {
      dispatch(
        getCategoryData({
          tenantId,
          categoryId: id,
        })
      );
    }
  }, [tenantId, id, dispatch]);

  useEffect(() => {
    if (!configLoading && !defaultLanguage) {
      setNoLanguage(true);
      setLoading(false);
    }
    if (!configLoading /*&& !dataLoading*/ && defaultLanguage) {
      const initialData: any = {...formData};
      const errObj: FormError = {error: false, errorText: ""};
      const initialError: SubCategoryFormError = {...errors};
       allUniqueLanguagesData?.forEach((lang) => {
        initialData.dynamicData[lang] = {title: ""};
        initialError.dynamicErrors[lang] = {
          title: errObj,
        };
      });
      setCategoryTitle(categoryApiData?.name?.invariantText);
      if (id && subCateorgyId && !dataLoading) {
        const rowData = categoryApiData?.subCategories!.find(
          (i) => i.subCategoryId === subCateorgyId
        );
        initialData.dynamicData[
          defaultLanguage
        ].title = rowData!.name!.invariantText;
        Object.keys(initialData.dynamicData)
          .forEach((i: string) => {
            const currLinkText = rowData!.name!.localTexts!.find(
              (lT) => (lT.ietfTag)?.toLowerCase() === i?.toLowerCase()
            );
            if (currLinkText) {
              initialData.dynamicData[i].title = currLinkText.text;
              initialData.isPromoted = rowData?.isPromoted;
            }
          });
        setCategorySubTitle(rowData!.name!.invariantText);
      };
      setFormData(initialData);
      setErrors(initialError);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    supportedLanguages,
    defaultLanguage,
    configLoading,
    id,
    dataLoading,
    dispatch,
  ]);
  return {
    tenantId,
    formData,
    errors,
    loading,
    submitLoader,
    noLanguage,
    id,
    handleBack,
    setFormData,
    setErrors,
    resetErrors,
    handleSubCateorgyAdd,
    handleBackCategory,
    categoryTitle,
    categorySubTitle,
    lang,
    unsavedModal,
    handleUnsavedModalOkClick,
    handleUnsavedModalClose,
  };
};
