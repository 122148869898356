import {
    createMuiTheme,
    createStyles,
    WithStyles,
  } from '@material-ui/core/styles';
export let PaperTheme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  PaperTheme = {
    ...PaperTheme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: '#18202c',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: PaperTheme.spacing(1),
        },
        indicator: {
          height: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: '#36dcb6',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [PaperTheme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: PaperTheme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {
          fontWeight: PaperTheme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };

  export const navigatorPanelStye = {
    width: 256,
    display: "flex",
    justifyContent: "space-between",
  };
  
  export const PaperbaseStyle = createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    drawer: {
      [PaperTheme.breakpoints.up('sm')]: {
        width: navigatorPanelStye.width,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
      padding: PaperTheme.spacing(6, 4),
      background: '#eaeff1',
    },
    footer: {
      padding: PaperTheme.spacing(2),
      background: '#eaeff1',
    },
  });
  
  export interface PaperbaseStyleProps extends WithStyles<typeof PaperbaseStyle> {}
  