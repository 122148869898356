import { layoutSelectedTenantValue, setShowSelector } from "Layout/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { userRoleEnums } from "constants/enums";
import { selectUser } from "_pages/loginscreen/authSlice";
import { selectTenants } from "_pages/channels/channelSlice";
import React, { useEffect, useState } from "react";

export const useScheduleIndexHook = () => {
  const dispatch = useDispatch();
  const selectedTenantValue = useSelector(layoutSelectedTenantValue);
  const showSelectorDisable = () => {
    dispatch(setShowSelector(false));
  };
  const showSelectorEnable = () => {
    dispatch(setShowSelector(true));
  };
  const user = useSelector(selectUser);
  const { tenants } = useSelector(selectTenants);
  const [tenantTimeZone, setTenantTimeZone] = useState(null as any);
  const [tenantData, setTenantData] = useState(null as any);
  const [selectedTenantId, setSelectedTenantId] = useState(null as any);
  const [selectedTenantName, setSelectedTenantName] = useState("");
  const [connect, setConnect] = useState(null as any);
  const [pageConfig, setPaginationConfig] = useState(null as any);

  useEffect(() => {
    if (user?.role === userRoleEnums.CS_USER && tenants != "") {
      if (localStorage.getItem("csTenantId") !== null) {
        let filterTenants = tenants.filter(
          (item) => item.id == localStorage.getItem("csTenantId")
        );

        setSelectedTenantId(filterTenants[0]?.id);
        setTenantTimeZone(filterTenants[0]?.timeZoneInfo);
        setSelectedTenantName(filterTenants[0]?.name);
        setTenantData(filterTenants[0]);
        setConnect(filterTenants[0]?.connect);
      }
    } else {
      if (tenants != "") {
        setSelectedTenantId(tenants[0].id);
        setTenantTimeZone(tenants[0].timeZoneInfo);
        setTenantData(tenants[0]);
        setSelectedTenantName(tenants[0].name);
        setConnect(tenants[0].connect);
      }
    }
  }, [tenants, selectedTenantValue]);
  return {
    selectedTenantValue,
    showSelectorDisable,
    showSelectorEnable,
    tenantTimeZone,
    tenantData,
    selectedTenantId,
    selectedTenantName,
    connect,
    pageConfig,
    setPaginationConfig
  };
};
