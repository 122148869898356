import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  RootState } from "store/store";
import { paginationValues } from '../../constants/enums';

interface OPTIONS  {
  lable : string,
  value : number
}
interface PaginateState {
  DEFAULT_PAGE_SIZE: number;
  DAFAULT_PAGE_NUMBER: number;
  PAGE_SIZES_OPTIONS: Array<number>;
  PAGE_SIZES_OPTIONS_WITH_ALL:Array<any>;
}

const initialState: PaginateState = paginationValues;

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPaginate: (state, action: PayloadAction<any>) => ({...state,...action.payload}),
    resetPaginate:(state)=>({...state,...initialState})
  },
});

export const { setPaginate, resetPaginate } = paginationSlice.actions;

export const getPaginateInfo = (state: RootState) => state.pagination;

export default paginationSlice.reducer;
