export const tokenValidationAPI: string = "/api/v1/verify"; // Firebase token verification Endpoint
export const getProviderListAPI: string = "/api/v1/providers"; // To Get the List of Providers
export const getProviderAPI: string = "/api/v1/provider"; // To get one Provider Details
export const updateProviderAPI: string = "/api/v1/provider"; // update provider details

export const getChannelListAPI: string = "/api/v1/channels"; // To Get the List of Channels
export const createChannelAPI: string = "/api/v1/channel"; // To create  Channel Details
export const updateChannelsInBulkAPI: string = "api/v1/channels/bulk"; // To Bulk Update Channel Details || Method PUT
export const publishChannelsAPI: string = "/api/v1/channel/"; // To Bulk Update Channel Details || Method PUT || using - /api/v1/channel/{tag}/publish

export const getTenantsListAPI: string = "api/v1/tenant"; // To Get the List of Tenants
export const getAppKeyAndSecretAPI: string = "api/v1/tenant/appkeysecret" // To get app key and secret for tenant
export const generateAppKeyAndSecretAPI: string = "api/v1/tenant/GenerateKey" // To get app key and secret for tenant
export const getTenantConfigAPI: string = "api/v1/tenant"; // get: api/v1/tenant/{tenantId} To get one Tenant Details

export const getContentTitlesAPI: string = "api/v1/titles"; // To get all the titles from api
export const getFilteredTitlesAPI: string = "api/v1/virtualclass/search"; // To get the titles based on search query from api
export const updateContentTitlesAPI: string = "api/v1/title"; // To update single title at time
export const getMetadataAPI: string = "api/v1/metadata"; // To get updated metadata from api
export const deletContentTitlesAPI: string = "/api/v2/titles"; // To delet single title at time
export const cancelContentTitlesAPI: string = "/api/v1/cancelcontent"; // To delet single title at time
export const publishContentTitles: string = "api/v1/title/publish"; // To Publish a title
export const publishTenantsAPI: string = "api/v1/tenant/publish"; // To publish channels from Tenant screen, it gets called just after bulk update api

export const uploadTitleVideoAPI: string = "/api/v1/videos/"; // api/v1/videos/{skipStage1Encoding},
//skipStage1Encoding is bool flag, it skips Encoding process

export const uploadTrailerVideoAPI: string = "/api/v1/trailer/"; // api/v1/videos/{tag},
//skipStage1Encoding is bool flag, it skips Encoding process

export const reTriggerTitleEncodingAPI: string = "api/v1/encodevideo/"; // post: api/v1/encodevideo/{tag} To re trigger encoding
export const reTriggerTrailerEncodingAPI: string = "api/v1/encodetrailer/"; // post: api/v1/encodetrailer/{tag}  To re trigger encoding
export const replaceTitleVideoAPI: string = "/api/v1/videos/"; // put: api/v1/videos/{titleTag}  to replace Video
export const replaceTrailerVideoAPI: string = "/api/v1/trailer/"; //put: api/v1/trailer/{titleTag} To replace trailer video

//Schedule Events
export const addEventAPI: string = "connect/event"; // To add single event at time
export const updateEventAPI: string = "connect/event";
export const deleteEventAPI: string = "connect/events";
export const getEventsAPI: string = "connect/events/"; // To get all events
export const uploadTitleCover: string = "images/"; //

export const uploadEventTrailerAPI: string = "/api/v1/trailer/event/"; ///api/v1/trailer/event/{eventId}

export const reTriggerEventVideoEncodingAPI: string =
  "/api/v1/event/encodevideo/"; // post: /api/v1/event/encodevideo/{tag} To re trigger encoding
export const generateEventKey: string = "/connect/event/live/key"; // post: /event/live/key

//Admin reports
export const getTenantUploadReport: string =
  "api/v1/tenant/tenantdownloadreport?"; // To add single event at time
export const getOnDemandUploadReport: string =
  "api/v1/ondemand/trackingreport?"; // To add single event at time
//Collections

export const getCollectionListAPI: string = "api/v1/tenants/"; // GET: api/v1/tenants/{tenantid}/collections To Get the List of collections
export const getCollectionAPI: string = "api/v1/tenants/"; // GET: api/v1/tenants/{tenantid}/collections/{collectionid}  T0 Get the specific Collection
export const addCollectionAPI: string = "/api/v1/tenants/"; // post: /api/v1/tenants/{tenantid}/collection To save new collection
export const getAllCollectionClassAPI: string = "/api/v1/tenants/"; //GET: /api/v1/tenants/{tenantid}/titles To get list for Add Manage classes of collections
export const addClassesToCollectionAPI: string = "/api/v1/tenant/"; //POST /api/v1/tenant/{tenantid}/collections/{collectionid}/titles
export const activateCollectionAPI: string = "/api/v1/tenants/"; //PUT api/v1/tenants/{tenantid}/collections/{collectionid} activate/deactivate collections
export const reorderCollectionAPI: string = "/api/v1/tenant/"; // PUT /api/v1/tenants/{tenantid}/collections/{collectionid}/titles  Reorder class for collections
export const deleteManageClassesAPI: string = "/api/v1/tenants/"; //POST /api/v1/tenants/{tenantid}/collections/{collectionid}/title  Delete classes from collections
export const deleteCollectionAPI: string = "/api/v1/tenants/"; //POST /api/v1/tenants/{tenantid}/collections/{collectionid}/title Delete collection from collection list
export const saveReorderedCollectionAPI: string ="v4/portal/collection/reorder"; 
export const setFeaturedCollectionAPI: string = "/api/v1/tenants/"; //PATCH /api/v1/tenants/{tenantid}/collections/{collectionid}
//Admin Content - optus

export const uploadPlaybackAPI: string =
  "api/v1/events/ondemand/playback/upload"; // POST:api/v1/events/ondemand/playback/upload To post json file
export const getUploadedPlaybackAPI: string =
  "api/v1/events/ondemand/playback/upload/"; // GET:api/v1/events/ondemand/playback/upload/{trackingid} get status of uploaded file by tracking id
export const cancelCommitPlaybackAPI: string =
  "api/v1/events/ondemand/playback/upload"; // PUT:api/v1/events/ondemand/playback/upload To process the file for activate(commit) and delete(cancel)

export const getLanguageAPI: string = "api/v1/languages"; // get all the languages

export const getTenantsTermsListAPI: string = "api/consentpolicy/version"; // To Get the List of Tenants
export const uploadTermsAPI: string = "api/consentpolicy/add"; // upload terms
export const downloadTermsAPI: string = "api/v2/consentpolicy/download?"; //api/v2/consentpolicy/download?tenantId=wexertest&languageCode=en-GB&versionnumber=1&consentType=tnc

export const getSchedulechooseClass: string = "v4/portal/titles"; //POST:api/v4/portal/titles

export const getFavouritesClass: string = "v4/titles/favourite"; //GET: v4/titles/favourite/{tenantId} POST: v4/titles/favourite/{tenantId}
export const removeTrailer: string = "api/v1/removetrailer"; //api/v1/removetrailer?titleTag=

// Get Streaming Link common
export const getEventStreamingLinkAPI: string = "connect/portal/event/streaminglink";
export const getTitleStreamingLinkAPI: string = "portal/title/streaminglink";

// Get Reviews
export const getReviewsListAPI: string = "v4/portal/ratingreview";
export const downloadReviewsAPI: string = "v4/portal/ratingreview/report";

//subscriptions

export const getSubscriptionsListAPI: string = "v4/portal/subspkg/list"; // post: /v4/portal/subspkg/list To Get the List of subscriptions
export const addSubscriptionAPI: string = "v4/portal/subspkg/create"; // post: /v4/portal/subspkg/create To save new subscriptions
export const deleteSubscriptionAPI: string = "v4/portal/subspkg/delete"; //delete: v4/portal/subspkg/delete To delete subscriptions
export const updateSubscriptionAPI: string = "v4/portal/subspkg/update"; // put: v4/portal/subspkg/update To update subscriptions
export const uploadCoverImageAPI: string = "v4/portal/media/image"; // post: v4/portal/media/image To upload cover image
export const getSubscriptionDetailsAPI: string = "v4/portal/subspkg/fetch"; // post: v4/portal/subspkg/fetch To Fetch the specific of subscriptions
// UserManagement
export const getUMListAPI: string = "v4/user/fileimport/list";
export const downloadUMTemplateAPI: string = "v4/user/fileimport/downloadtemplate";
export const uploadUMCsvDataAPI: string = "v4/user/fileimport/bulkupload";
export const downloadUMUploadedFileAPI: string = "v4/user/fileimport/downloadfile";

// Get SiteConfig
export const getSiteConfigAPI: string = "v4/tenant/siteconfig/getconfig";
export const updateSiteConfigAPI: string = "v4/tenant/siteconfig/update";
export const deleteSiteConfigAPI: string = "v4/tenant/siteconfig/delete";

// Documents
export const getDocumentsListAPI: string = "v4/portal/document/list";
export const getDocumentsDetailsAPI: string = "v4/portal/document/getdetails";
export const addDocumentsAPI: string = "v4/portal/document/create";
export const updateDocumentsAPI: string = "v4/portal/document/update";
export const deleteDocumentsAPI: string = "v4/portal/document/delete";
export const downloadDocumentsAPI: string = "v4/portal/document/download";
export const addDocumentsToCollectionAPI: string = "v4/portal/document/contentmapping";
export const removeDocsFromCollectionAPI: string = "v4/document/collection/delete";


//Get Category
export const getCategoryAPI: string = "v4/category/list";
export const addCategoryAPI: string = "v4/category/add";
export const updateCategoryAPI:string = "/v4/category/update";
export const reorderCategoryAPI:string ="v4/portal/category/reorder";
// Captions
export const createCaptionsAPI: string = "v4/portal/track/create";
export const deleteCaptionsAPI: string = "v4/portal/track/delete";
export const updateCaptionsAPI: string = "v4/portal/track/update";

//User Report

export const userReportList: string = "/v4/users/list";
export const userMetaData: string = "/v4/users/metadata";
export const userReportExport: string = "v4/users/export";
export const userSubscrptionList:string ="/v4/user/subscriptions/list"


//Titles EXPORT 

export const contentTitlesExport: string = "v4/titles/export"; 

//Bulk Upload
export const bulkUploadListAPI: string = "v4/bulkupload/list";  
export const bulkUploadFetchAPI: string = "v4/bulkupload/fetch";  
export const bulkUploadAddAPI: string = "v4/bulkupload/add"; 
export const bulkUploadStopAPI: string = "v4/bulkupload/stop";  
