import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  FormCommonStyle,
  PaperCommonStyle,
  PickerCommonStyle,
  TextCommonStyle,
  AppCommonStyle,
  SearchBarCommonStyle,
  DisplayCommonStyle,
  AddUserCommonStyle,
  ContentWrapperCommonStyle,
  SecondaryBarCommonStyle,
  LinkCommonStyle,
  InputCommonStyle,
  Form2CommonStyle,
  TitleArrowSubtitleCommonStyle,
} from "./Common.style";

export const ContentTitlesScreenStyles = (theme: Theme) =>
  createStyles({
    ...PaperCommonStyle,
    AppCommonStyle,
    main: {
      height: "100%",
      overflow: "auto",
      flex: 1,
      // padding: "192px 32px 32px 32px",
      background: "rgb(239,239,244)",
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
      maxWidth: 1210,
    },
    mainNew: {
      flex: 1,
      // padding: "172px 16px 32px 16px",
      background: "rgb(239,239,244)",
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
    },
    ...SearchBarCommonStyle(theme),
    ...DisplayCommonStyle,
    ...AddUserCommonStyle(theme),
    ...ContentWrapperCommonStyle,
    ...SecondaryBarCommonStyle,
    ...LinkCommonStyle,
  });

export const TenantContentScreenStyles = makeStyles((theme) => ({
  ...{ ...FormCommonStyle(theme) },
  ...{ ...TitleArrowSubtitleCommonStyle() },
  ...PickerCommonStyle,
  ...TextCommonStyle,
  root: {
    height: 53,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  form: {
    marginTop: "0px",
  },
  selectedTitle: {
    fontfamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.5,
    letterspacing: 0.09,
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "normal",
  },
  arrow: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: 20,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    marginTop: 2,
  },
  subTitle: {
    fontfamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.5,
    letterspacing: 0.09,
    color: "rgba(0, 0, 0, 0.87)",
  },
  uploadHeading: {
    margin: "2px 0 0",
    fontfamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterspacing: "0.1px",
    color: "#36dcb6",
    cursor: "pointer",
  },
  selectFile: {
    margin: "2px 0 0",
    fontfamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterspacing: "0.1px",
    color: "#36dcb6",
    marginTop: "10px",
    cursor: "pointer",
  },
  cancelHeading: {
    margin: "2px 0 0",
    fontfamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.43",
    letterspacing: "0.1px",
    color: "#36dcb6",
    marginLeft: "10px",
    cursor: "pointer",
  },
  input: {
    display: "none",
  },
  errorIcon: {
    color: "#d32f2f",
    marginRight: "5px",
  },
  uploadIcon: {
    color: "#36dcb6",
    marginRight: "5px",
  },
  commitCancel: {
    display: "flex",
    margin: "10px !important",
  },
}));

export const ContentTitlesSearchBoxStyles = makeStyles((theme) => ({
  ...FormCommonStyle(theme),
  formControlWithEquipment: {
    marginTop: "16px 0px 8px 0px !important",
    width: "100%",
  },
  formControlWithoutEquipment: {
    marginTop: "16px 0px 8px 0px !important",
    width: "100%",
  },
  rightBtn: {
    textAlign: "right",
  },
  backBtn: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    color: "#36dcb6",
    cursor: "pointer",
    height: "100%",
    alignItems: "center",
    display: "inline-flex"
  },
  ...Form2CommonStyle,
  ...InputCommonStyle,
  searchImageIcon: {
    marginTop: 8,
    marginLeft: 6,
    marginBottom: 6,
  },
  searchSpan: {
    display: "flex",
    width: 200,
  },
  searchClearIcon: {
    marginTop: "auto",
    marginRight: 8,
    cursor: "pointer",
    marginBottom: "auto",
  },
  fullSpan: {
    display: "flex",
    width: "100%",
  },
  searchBox: {
    backgroundColor: "#efeff4",
    marginRight: 16,
    borderRadius: 4,
  },
  fullWidthsearchBox: {
    backgroundColor: "#efeff4",
    borderRadius: 4,
  },
  captionLabel: {
    fontSize: 12,
    color: "#263238"
  },
  captionHr: {
    marginTop: "5px",
    color: "black",
    height: "1px",
  },
  manageCaptionButton: {
    fontSize: 12,
    color: "#36dcb6",
    cursor: "pointer",
    height: 48,
  },
  disableManageCaptionButton:{
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "none",
    height: 48,
  },
  captionNames: {
    marginBlock: "8px",
    marginRight: "8px",
    backgroundColor: "#e0e0e0"
  },
  trackFileLabel: {
    fontSize: 12,
    color: "#263238",
    marginTop: 16,
  },
  trackFileName: {
    fontSize: 16,
    color: "#263238",
  },
}));

export interface ContentTitleScreenProps
  extends WithStyles<typeof ContentTitlesScreenStyles> {}
