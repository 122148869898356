import { makeStyles } from "@material-ui/core/styles";
import { CompLableCommonStyle } from "./CommonComponent.style";

export const MultiLabelIconComponentStyles = makeStyles(() => ({
  ...{ ...CompLableCommonStyle() },
  videoHelpText: {
    fontSize: "13px",
    lineHeight: "18px",
    letterSpacing: "0px",
    color: "rgba(0, 0, 0)",
  },
  formControlLable: {
    marginTop: 11,
    marginLeft: 0,
  },
}));
