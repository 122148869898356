import React from "react"
import ContentLoader from "react-content-loader"

 

const MyLoader = (props) => (
  <ContentLoader  
    speed={2}
    width="100%"
    height='1900'
    viewBox='0 0 100% 100%'
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* <rect x="48" y="8" rx="3" ry="3" width="88" height="6" /> 
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> 
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />  */}
     <rect x="20%" y="62" rx="3" ry="3" width="20%" height="16" /> 
     <rect x="20%" y="82" rx="3" ry="3" width="15%" height="12" /> 
     <rect x="20%" y="98" rx="3" ry="3" width="10%" height="8" /> 

     <rect x="30%" y="192" rx="3" ry="3" width="50%" height="24" /> 
     <rect x="30%" y="222" rx="3" ry="3" width="40%" height="16" /> 
     <rect x="30%" y="244" rx="3" ry="3" width="30%" height="8" /> 

     <rect x="30%" y="292" rx="3" ry="3" width="50%" height="24" /> 
     <rect x="30%" y="322" rx="3" ry="3" width="40%" height="16" /> 
     <rect x="30%" y="344" rx="3" ry="3" width="30%" height="8" /> 

     <rect x="30%" y="392" rx="3" ry="3" width="50%" height="24" /> 
     <rect x="30%" y="422" rx="3" ry="3" width="40%" height="16" /> 
     <rect x="30%" y="444" rx="3" ry="3" width="30%" height="8" /> 

    <rect x="0" y="0" rx="3" ry="3" width="18%" height="100%" /> 
    <circle cx="96.5%" cy="35" r="20" />
    
  </ContentLoader>
)

export default MyLoader