import React from "react";
import PropTypes from "prop-types";
import SelectTenants from "_pages/tenants/Components/SelectTenants";
import { HeaderHooks } from "../../Hooks/Header.Hooks";
import MultiSelect from "components/Inputs/MultiSelect";
import { filter } from "lodash";
import { handelOptions } from "utils/utilityFunctions";
import * as RouteContants from 'constants/routeConstants';

const SelectUI = (props) => {
  const { options = [] } = props;
  return (
    <React.Fragment>{options && <MultiSelect {...props} />}</React.Fragment>
  );
};
const ProviderDropDown = (props) => {
  const { isDisabled = false, from } = props;
  const {
    providers,
    handleProviderSelect,
    getProviderValues,
    pathname,
    content: {
      actionCallback,
      contentLoading
    },
    reviews: {
      reviewsActionCallback,
    }
  } = HeaderHooks();

  return (
    <div className='header-dropdown'>
      <SelectUI
        values={getProviderValues()}
        style={`header-input-box no-under-line`}
        multiple={false}
        isArray={true}
        disabled={!isDisabled ? contentLoading : isDisabled}
        selectCallback={pathname === RouteContants.contentTitles ? actionCallback : from === "reviews" ? reviewsActionCallback : handleProviderSelect}
        options={handelOptions(true, providers, "name")}
        lable={"Provider"}
      />
    </div>
  );
};

export { ProviderDropDown };
