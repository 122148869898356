import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = React.memo(function Spinner(props: any) {
  return (
    <div
      style={{ textAlign: props.label ? "center" : "left" }}
      className={`classes-spinner ${props.childClassName}`}
    >
      {props.label ? props.labelText : ""}{" "}
      <CircularProgress
        // label="lable"
        size={props.small ? 15 : 70}
        // className={this.props.classes.progress}
        thickness={5}
        color="inherit"
      />
    </div>
  );
});

export default Spinner;
