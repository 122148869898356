import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import * as ApiConstants from "constants/apiConstants";
import { downloadreportApi, userApi, uploadApi, uploadJsonApi } from "api/api";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { apiResponseEnums } from "constants/enums";
import { getFileName, getLanguageFromConfig, handleUnauthorized } from "utils/utilityFunctions";
import { setApiResponse } from "Layout/layoutSlice";

interface AdminState {
  tenantsReportResponse: any;
  uploadedPlaybackData: any;
  commitPlaybackData: any;
  afterUploadedPlaybackData: any;
  fileUploadStatus: any;
  trackingId: any;
  loadingReport: any;
}

const initialState: AdminState = {
  tenantsReportResponse: {},
  uploadedPlaybackData: {},
  commitPlaybackData: {},
  afterUploadedPlaybackData: {},
  fileUploadStatus: {},
  trackingId: {},
  loadingReport: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getTenantsReport: (state, action: PayloadAction<any>) => {
      state.tenantsReportResponse = action.payload;
    },
    uploadPlaybackData: (state, action: PayloadAction<any>) => {
      state.uploadedPlaybackData = action.payload;
    },
    commitPlaybackData: (state, action: PayloadAction<any>) => {
      state.commitPlaybackData = action.payload;
    },
    afterUploadedPlaybackData: (state, action: PayloadAction<any>) => {
      state.afterUploadedPlaybackData = action.payload;
    },
    fileUploadStatus: (state, action: PayloadAction<any>) => {
      state.fileUploadStatus = action.payload;
    },
    setTrackingId: (state, action: PayloadAction<any>) => {
      state.trackingId = action.payload;
    },
    setLoadingReport: (state, action: PayloadAction<any>) => {
      state.loadingReport = action.payload;
    },
  },
});
export const {
  getTenantsReport,
  uploadPlaybackData,
  commitPlaybackData,
  setTrackingId,
  afterUploadedPlaybackData,
  fileUploadStatus,
  setLoadingReport,
} = adminSlice.actions;

export const getUploadedPlaybackData = (trackingId): AppThunk => (dispatch) => {
  dispatch(afterUploadedPlaybackData(null));
  userApi
    .get(`${ApiConstants.getUploadedPlaybackAPI}${trackingId}`)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(afterUploadedPlaybackData(res.data));
      } else {
        const callback = () =>
          dispatch(regfeshTokenId(getUploadedPlaybackData(trackingId)));
        handleUnauthorized(res.status, callback);
        dispatch(afterUploadedPlaybackData(res.status));
        dispatch(setApiResponse({ status: res.status, data: res}));
      }
    });
};

export const uploadPlaybackJSONData = (data): AppThunk => (dispatch) => {
  dispatch(setTrackingId(null));
  dispatch(fileUploadStatus(null));
  dispatch(commitPlaybackData(null));
  uploadApi.post(ApiConstants.uploadPlaybackAPI, data).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setTrackingId(res.data.trackingId));
      dispatch(getUploadedPlaybackData(res?.data?.trackingId)); // call get api after upload with tracking id
      dispatch(fileUploadStatus({ status: res.status, data: res.data }));
    } else {
      const callback = () => dispatch(regfeshTokenId(uploadPlaybackJSONData(data)));
      dispatch(fileUploadStatus({ status: res.status, data: res.data }));
      handleUnauthorized(res.status, callback);
      dispatch(setApiResponse({ status: res.status, data: res }));
    }
  });
};
export const cancelCommitPlaybackAPI = (data): AppThunk => (dispatch) => {
  dispatch(commitPlaybackData(null));
  dispatch(fileUploadStatus(null));
  userApi.put(ApiConstants.cancelCommitPlaybackAPI, data).then((res: any) => {
    dispatch(commitPlaybackData(res.status));
    if (
      res.status === apiResponseEnums.OKAY ||
      res.status === apiResponseEnums.DELETED
    ) {
      dispatch(getUploadedPlaybackData(data?.trackingId)); // call get api after upload with tracking id
    } else {
      const callback = () => dispatch(regfeshTokenId(cancelCommitPlaybackAPI(data)));
      handleUnauthorized(res.status, callback);
      dispatch(commitPlaybackData(res.status));
      dispatch(setApiResponse({ status: res.status, data: res }));
    }
  });
};

export const getTenantsUploadReport = (startDate, endDate, type): AppThunk => (
  dispatch
) => {
  dispatch(getTenantsReport(null));
  dispatch(setLoadingReport(true));
  let parameters;
  let url;
  if (type === "OD") {
    parameters = `startDate=${startDate}&endDate=${endDate}`;
    url = ApiConstants.getOnDemandUploadReport;
  } else {
    parameters = `startDate=${startDate}&endDate=${endDate}&type=${type}`;
    url = ApiConstants.getTenantUploadReport;
  }
  downloadreportApi.get(url + parameters).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", getFileName(type));
      //Append to html page
      document.body.appendChild(link);
      //Force download
      link.click();
      //Clean up and remove the link
      link.remove();
      dispatch(setLoadingReport(false));
    } else {
      dispatch(setLoadingReport(false));
      const lang = getLanguageFromConfig();
      const callback = () =>
        dispatch(
          regfeshTokenId(getTenantsUploadReport(startDate, endDate, type))
        );
      handleUnauthorized(res.status, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data:
            res.status === apiResponseEnums.INTERNAL_ERROR
              ? lang.Reports.reportDownloadError
              : lang.Reports.noReportsFound,
        })
      );
    }
  });
};

export const reportApiResponse = (state: RootState) =>
  state.admin.tenantsReportResponse;

export const uploadJSONDataApiResponse = (state: RootState) =>
  state.admin.uploadedPlaybackData;

export const commitPlaybackDataResponse = (state: RootState) =>
  state.admin.commitPlaybackData;

export const afterUploadedPlaybackDataResponse = (state: RootState) =>
  state.admin.afterUploadedPlaybackData;
export const fileUploadStatusResponse = (state: RootState) =>
  state.admin.fileUploadStatus;
export const trackingIdResponse = (state: RootState) => state.admin.trackingId;
export const loadingReportResponse = (state: RootState) =>
  state.admin.loadingReport;

export default adminSlice.reducer;
