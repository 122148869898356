import {useEffect} from 'react';
import { useTheme } from "@material-ui/core/styles";
import {
  layoutTabId,
  layoutSelectedValue,
  setChannelSelectedValue,
  setTabId,
  setShowSelector,
} from "Layout/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import * as RouteContants from "constants/routeConstants";

export const useChannelsIndexHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tabId = useSelector(layoutTabId);
  const selectedValue = useSelector(layoutSelectedValue);
  const setSelectStatus = (val) => {
    dispatch(setShowSelector(val));
  };
  const handleChangeIndex = (tabId) => {
    dispatch(setChannelSelectedValue(""));
    dispatch(setTabId(tabId));
  };
  useEffect(()=>{
    if(tabId === 0){
      history.location.pathname.includes(RouteContants.channelsPublishPath) && handleChangeIndex(1);
    }
  },[tabId]);
  const theme = useTheme();
  return {
    tabId,
    selectedValue,
    setSelectStatus,
    handleChangeIndex,
    theme,
  };
};
