import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from 'components/Dialog';
import Typography from '@material-ui/core/Typography';
import { withStyles, } from '@material-ui/core/styles';
import { selectUser } from '_pages/loginscreen/authSlice';
import { useSelector } from 'react-redux';
import { userRoleEnums } from 'constants/enums';
import { HeaderStyle,HeaderProps } from 'style';



const HeaderComp = (props: HeaderProps)=>{
  const { classes, onDrawerToggle } = props;
  const user = useSelector(selectUser);

  const getUserName = () => {
    let welcome = "Welcome"
    let userName = "Wexer";
    if (user?.role === userRoleEnums.TENANT_AS_PROVIDER_USER) {
      userName = user?.displayName;
    }

    return `${welcome}, ${userName}`;
  }

  return (
    <React.Fragment>
      <AppBar color="primary" position='static' elevation={0}>
        <Toolbar>
          <Grid container spacing={1} className='pt5' alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Dialog  light={true} user={user} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar style={{ minHeight: 30, height: 32 }}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography className={classes.link} variant="h1" component="h1">
                {getUserName()}
              </Typography>
            </Grid>

          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
const WelcomeHeader = withStyles(HeaderStyle)(HeaderComp);
WelcomeHeader.defaultProps={
    onDrawerToggle: ()=> {throw new Error("Function not implemented.");}
}
WelcomeHeader.prototype={
    onDrawerToggle:PropTypes.func,
}
export { WelcomeHeader };