import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  RootState } from "store/store";
import { ORDER_BY } from '../../constants/enums';

interface SortState {
  pageName: any;
  sortingOptions: any;
  selectedSort: any;
  existedSort:any;
  sort?:any;
  index?:number;
  isDefault?:boolean;
}

const initialState: SortState = {
    pageName: null as any,
    sortingOptions: [],
    selectedSort: null,
    existedSort:{},
    index:1,
    sort:ORDER_BY.DESC,
    isDefault:true
};

export const sortSlice = createSlice({
  name: "tableSorting",
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<any>) => {
      state.pageName = action.payload.pageName;
      state.sortingOptions = action.payload.sortingOptions;
      state.selectedSort = action.payload.selectedSort;
      state.existedSort = action.payload.existedSort;
      state.index =  action.payload.index;
      state.sort =  action.payload.sort;
      state.isDefault = action.payload.isDefault;
    },
    resetSorting:(state)=>{
      return {...state,...initialState}
    }
  },
});

export const {
    setSorting,
    resetSorting
} = sortSlice.actions;

export const getSort = (state: RootState) => state.sortTable;
export const isAsc = (state: RootState) =>{
    const getIndex:any = state.sortTable.index;
    return (getIndex > 0 && getIndex %2 === 0) ? ORDER_BY.DESC : ORDER_BY.ASC;
};

export default sortSlice.reducer;
