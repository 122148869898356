import Dexie from "dexie";

interface Titles {
  id?: number;
  title?: any;
  name?: string;
  file: any;
}
interface Schedules {
  id?: number; // indexed numbers
  event_id?: string; // unique event id
  identifier: string;
  isReplacing?: boolean; // event object, contains content title data
  file: any; // file ref
}

// Rajneesh Raghav, March 24, 2021 || CF-9906
// Creating User Specific Tables,
// As we using SESESSION specific logins,
// One user may logout, can login with other credentials
// Indroducing Columns in existing tables may brreak backward comapalitibity in Content Titles Area
//
// let id = "1";
// let uid = localStorage.getItem('uid')
// if (uid) {
//   id = uid
// }

// const TABLE_NAME = `${id}-titles`;
// const EVENT_TABLE_NAME = `${id}-schedules`;

const TABLE_NAME = "titles";
const EVENT_TABLE_NAME = "schedules";

//Declare Database

class Content extends Dexie {
  public titles: Dexie.Table<Titles, number>; // id is number in this case
  public schedules: Dexie.Table<Schedules, number>; // id is number in this case
  public constructor() {
    super("Content");
    this.version(2).stores({
      titles: "++id,title,name,file",
      schedules: "++id,event_id,identifier,isReplacing,file",
    });
    this.titles = this.table(TABLE_NAME);
    this.schedules = this.table(EVENT_TABLE_NAME);
  }
}

let db = new Content();

export const updateTitlesQueue = (id, title) => {
  // db.table(TABLE_NAME)
  //   .update(1, { title })
  //   .then(() => {
  //
  //   });
};

//   databaseName.tableName.where('primaryKey').equals(primaryKey).delete().then(function(deleteCount) {
//     console.log ("Deleted " + deleteCount + " rows");
//   }).catch(function(error) {
//     console.error ("Error: " + error);
// });

export const deletTitlesQueue = (key: string) => {
  console.log("++++++++++++++++++++++++++++++++++++");
  console.log("file deleted from db");
  console.log("++++++++++++++++++++++++++++++++++++");
  //key = parseInt(key);

  db.table(TABLE_NAME)
    .where("name")
    .equals(key)
    .delete()
    .then((d) => {})
    .catch((err) => {
      console.log(err);
    });
};

export const bulkUpdateTitlesQueue = (id, title) => {
  db.table(TABLE_NAME)
    .update(id, { title: title })
    .then(() => {});
};

export const addTitlesToQueue = (file: any, title: any, name: string) => {
  db.table(TABLE_NAME)
    .toArray()
    .then((uploadingTitles: any) => {
      let ID = 0;
      if (uploadingTitles) {
        ID = (uploadingTitles[uploadingTitles?.length - 1]?.id ?? 0) + 1;
      }
      const titles = {
        id: ID,
        title: title,
        name: name,
        file: file,
      };
      db.table(TABLE_NAME)
        .add(titles, name)
        .then((id) => {
          console.info(id);
        }).catch((err) => {
          console.info(err);
        });;
    })
    .catch((err) => {
      console.info(err);
    });
};
export const addEventsToQueue = (
  file: any,
  tag: string,
  isReplacing: boolean,
  identifier: string
) => {
  db.table(EVENT_TABLE_NAME)
    .toArray()
    .then((uploadingTitles: any) => {
      let ID = 0;
      if (uploadingTitles) {
        ID = (uploadingTitles[uploadingTitles?.length - 1]?.id ?? 0) + 1;
      }
      const titles = {
        id: ID,
        event_id: tag,
        identifier: identifier,
        isReplacing: isReplacing,
        file: file,
      };
      db.table(EVENT_TABLE_NAME)
        .add(titles, tag)
        .then((id) => {
          console.log(id);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getQueuedEvents = () => {
  var promise = new Promise(function(resolve, reject) {
    db.table(EVENT_TABLE_NAME)
      .toArray()
      .then((uploadingTitles: any) => {
        resolve(uploadingTitles);
      });
  });
  return promise;
};

export const getQueuedTitles = () => {
  var promise = new Promise(function(resolve, reject) {
    db.table(TABLE_NAME)
      .toArray()
      .then((uploadingTitles: any) => {
        resolve(uploadingTitles);
      });
  });
  return promise;
};
export const removeEventFromQueue = (key: string) => {
  console.log("++++++++++++++++++++++++++++++++++++");
  console.log("file deleted from db");
  console.log("++++++++++++++++++++++++++++++++++++");
  //key = parseInt(key);

  db.table(EVENT_TABLE_NAME)
    .where("event_id")
    .equals(key)
    .delete()
    .then((d) => {})
    .catch((err) => {
      console.log(err);
    });
};
export const removeMultipleEventFromQueue = (keys: any) => {
  //key = parseInt(key);

  db.table(EVENT_TABLE_NAME)
    .where("event_id")
    .anyOf(...keys)
    .delete()
    .then((d) => {})
    .catch((err) => {
      console.log(err);
    });
};

export const deleteDB = () => {
  //key = parseInt(key);
  db.table(EVENT_TABLE_NAME).clear();
};

// function instantiateDB() {
//   db = new Content()
// }

export default db;
