import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { HeaderHooks } from "../Hooks/Header.Hooks";

const ToolBar = (props) => {
  const {HeaderLabel} = props;
  const {classes} = HeaderHooks();
  return (
    <Toolbar style={{ minHeight: 30, height: 32 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography className={classes.link} variant="h1" component="h1">
            {HeaderLabel}
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

ToolBar.propTypes = {
  HeaderLabel: PropTypes.any
};

export default ToolBar;
