import {
  channelCreatedStatus,
  getChannelListfromApi,
  selectChannels,
  selectTenants,
  updateChannel,
} from "_pages/channels/channelSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import { useChannelsIndexHook } from "./useChannelsIndex.hooks";
import { useHistory } from "react-router";
import { SortHook } from "components/Sort/Sort.Hook";
import { flatten } from "lodash";
import * as RouteContants from "constants/routeConstants";

export const useChannelsPublishingContentHook = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const channelPublishStatus = useSelector(channelCreatedStatus);
  const [numSelected] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState(null as any);
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [tenantData, setTenantData] : any = useState(null);
  const [selectedTenants, setSelectedTenants] = useState([] as any);
  const [preSelectedTenants, setPreSelectedTenants] = useState([] as any);
  const [tenantsToBePublished, setTenantsToBePublished] = useState([] as any);
  const [tenantsToBeUnPublished, setTenantsToBeUnPublished] = useState(
    [] as any
  );
  const [tenantNamesToBeUnPublished, setTenantNamesToBeUnPublished] = useState(
    [] as any
  );
  const [buttonStatus, setButtonStatus] = useState(true);
  const { channels } = useSelector(selectChannels);
  const { tenants } = useSelector(selectTenants);
  const { selectedValue: filterParam, setSelectStatus: callback } = useChannelsIndexHook();
  const [unPublishPopUp, setUnPublishPopUp] = useState(false);

  const dispatch = useDispatch();
  const selectedChannelId = history.location.pathname.split('/')[3];
  const findSelectedChannel = (i: any) => {
    try {
      return i?.tag === selectedChannelId
    } catch (error) {
      return {}
    }
  }

  useEffect(() => {
    if (selectedChannelId) {
      setSelectedChannel(channels?.find(findSelectedChannel));
      setSelectedTenants(channels?.find(findSelectedChannel)?.tenants);
      setPreSelectedTenants(channels?.find(findSelectedChannel)?.tenants);
    };
  }, [history]);

  useEffect(() => {
    if (tenantsToBePublished.length > 0 || tenantsToBeUnPublished.length > 0) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [tenantsToBePublished, tenantsToBeUnPublished]);

  useEffect(() => {
    if (channelPublishStatus) {
      setLoading(false);
      setTenantsToBePublished([]);
      setTenantsToBeUnPublished([]);
      setTenantNamesToBeUnPublished([]);
    }
  }, [channelPublishStatus]);

  useEffect(() => {
    if (data !== null) {
      const ch = channels;
      let newData = [] as any;
      if (filterParam) {
        for (let i = ch.length; i--; ) {
          const { providerId } = ch[i];

          if (providerId === filterParam) {
            newData.unshift(ch[i]);
          }
        }
        setData(newData);
      } else {
        setData(channels);
      }
    }
  }, [filterParam]);

  useEffect(() => {
    if (channels) {
      if (
        filterParam  && !history.location.pathname.includes(RouteContants.channelsPublishPath)
      ) {
        const ch = channels;
        let newData = [] as any;
        for (let i = ch.length; i--; ) {
          const { providerId } = ch[i];

          if (providerId === filterParam) {
            newData.unshift(ch[i]);
          }
        }
        setData(newData);
      } else {
        setData(channels);
      }
    }
  }, [channels]);

  useEffect(() => {
    ScrollTop();
  }, []);

  const reset = () => {
    history.push("/tenants/publishing/");
  };

  const goBackToPublishing = () => {
    history.push("/channels/publishing");
  }

  useEffect(() => {
    if (tenants) {
      setTenantData(tenants);
    }
  }, [tenants]);

  useEffect(() => {
    dispatch(getChannelListfromApi());
  }, [dispatch]);


  const handleSubmit = () => {
    if (tenantNamesToBeUnPublished.length) {
      setUnPublishPopUp(true);
    } else {
      submitCallback();
    }
  }

  const submitCallback = () => {
    setLoading(true);
    setUnPublishPopUp(false);
    dispatch(
      updateChannel(
        { ...selectedChannel, tenants: flatten([...selectedTenants]) },
        true,
        flatten(tenantsToBePublished),
        tenantsToBeUnPublished
      )
    );
    setPreSelectedTenants(selectedTenants);
  };

  const handleCancel = () => {
    setSelectedChannel(channels?.find(findSelectedChannel));
    setSelectedTenants(channels?.find(findSelectedChannel)?.tenants);
    setPreSelectedTenants(channels?.find(findSelectedChannel)?.tenants);
    setTenantsToBePublished([]);
    setTenantsToBeUnPublished([]);
    setTenantNamesToBeUnPublished([]);
    setUnPublishPopUp(false);
  }

  return {
    isLoading,
    setLoading,
    channelPublishStatus,
    numSelected,
    selectedChannel,
    setSelectedChannel,
    data,
    setData,
    pageNumber,
    setPageNumber,
    tenantData,
    setTenantData,
    selectedTenants,
    setSelectedTenants,
    preSelectedTenants,
    setPreSelectedTenants,
    tenantsToBePublished,
    setTenantsToBePublished,
    tenantsToBeUnPublished,
    setTenantsToBeUnPublished,
    tenantNamesToBeUnPublished,
    setTenantNamesToBeUnPublished,
    buttonStatus,
    setButtonStatus,
    channels,
    tenants,
    reset,
    submitCallback,
    goBackToPublishing,
    handleSubmit,
    unPublishPopUp,
    setUnPublishPopUp,
    handleCancel
  };
};
