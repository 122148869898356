import { selectTenants } from "_pages/channels/channelSlice";
import { selectTenantAsProviders } from "_pages/provider/providerSlice";
import {
  deleteEvent,
  eventsData,
  getScheduleEvents,
  isEventDeleted,
  resetEvents,
} from "_pages/scheduleEvents/ScheduleEventSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const moment = require("moment-timezone");

export const useScheduleTableHook = (props) => {
  const dispatch = useDispatch();
  const scheduleEventsData = useSelector(eventsData);
  const eventDeleted = useSelector(isEventDeleted);
  const { tenants } = useSelector(selectTenants);
  const { tenantAsProviders } = useSelector(selectTenantAsProviders);
  const [data, setData] = useState(null as any);
  const [numSelected, setSelectedNumbers] = useState(0);
  const [selectedTags, setSelectedTags] = useState([] as any);
  const [isDeleting, setDeleting] = useState(false);
  const [tenantTimeZone, setTenantTimeZone] = useState(null as any);
  const [eventsToDelet, setEventsToDelet] = useState([]);
  const [confirmDelet, setDeletPopUp] = useState(false);
  const { pageConfig, tentantId, isEventDeleting, isCSUserRole } = props;

  useEffect(() => {
    if (eventDeleted === true) {
      setSelectedNumbers(0);
      setSelectedTags([]);
      setDeleting(false);
    }
  }, [eventDeleted]);

  useEffect(() => {
    if (isEventDeleting === true) {
      setData(null);
    }
  }, [isEventDeleting]);

  useEffect(() => {
    if (tentantId != null) dispatch(getScheduleEvents(tentantId, pageConfig));

    setData(null);
  }, []);

  useEffect(() => {
    if (isCSUserRole === true) {
      if (localStorage.getItem("csTenantId") != null) {
        dispatch(
          getScheduleEvents(localStorage.getItem("csTenantId"), pageConfig)
        );
        setData(null);
      } else {
        dispatch(resetEvents());
      }
    }
  }, [localStorage.getItem("csTenantId")]);

  useEffect(() => {
    // ! due to changes in api
    const eventsData  = scheduleEventsData?.eventsData?.data ?? scheduleEventsData?.eventsData;
    if (eventsData?.length > 0) {
      if (tenantTimeZone) {
        let dataSource = eventsData.map((obj) => {
          let startTime = obj.schedule.next_Occurence_Time;
          try {
            let cutoffString = obj.schedule.next_Occurence_Time; // in utc
            let utcCutoff = moment.utc(cutoffString);
            let clonedUTC = utcCutoff.clone();
            let displayCutoff = clonedUTC.tz(tenantTimeZone.offsetName);
            startTime = displayCutoff.format("YYYY-MM-DD HH:mm:ss");
          } catch (error) {}

          return { ...obj, startTime: startTime };
        });
        setData(dataSource);
      } else {
        if (isCSUserRole == true) {
          let dataSource = eventsData.map((obj) => {
            let startTime = obj.schedule.next_Occurence_Time;
            let tenant = tenants.find(
              (obj) =>
                obj.id?.toLowerCase() ==
                localStorage.getItem("csTenantId")?.toLowerCase()
            );

            try {
              let cutoffString = obj.schedule.next_Occurence_Time; // in utc
              let utcCutoff = moment.utc(cutoffString);
              let clonedUTC = utcCutoff.clone();
              let displayCutoff = clonedUTC.tz(tenant.timeZoneInfo.offsetName);
              startTime = displayCutoff.format("YYYY-MM-DD HH:mm:ss");
            } catch (error) {
            }

            return { ...obj, startTime: startTime };
          });
          setData(dataSource);
        } else {
          setData(
            eventsData.map((obj) => ({
              ...obj,
              startTime: obj.schedule.next_Occurence_Time,
            }))
          );
        }
      }
    } else if (eventsData?.length === 0) {
      //for No resul found message
      setData([]);
    } else {
      //for shimmer
      setData(null);
    }
  }, [scheduleEventsData, tenantTimeZone]);

  useEffect(() => {
    if (
      tenants &&
      Array.isArray(tenants) &&
      tenantAsProviders &&
      Array.isArray(tenantAsProviders)
    ) {
      let tenantAsProvider = tenantAsProviders.find(
        (obj) => obj.isTenantProvider === true
      );
      if (tenantAsProvider) {
        let tenant = tenants.find(
          (obj) =>
            obj.id?.toLowerCase() === tenantAsProvider.tenantId.toLowerCase()
        );
        if (tenant) {
          setTenantTimeZone(tenant.timeZoneInfo);
        }
      }
    } else {
      if (isCSUserRole == true) {
        let tenant = tenants.find(
          (obj) =>
            obj.id?.toLowerCase() ==
            localStorage.getItem("csTenantId")?.toLowerCase()
        );
        if (tenant) {
          setTenantTimeZone(tenant.timeZoneInfo);
        }
      }
    }
  }, [tenants, tenantAsProviders]);

  const confirmDeletCallback = (e) => {
    let event = data?.filter(
      (events) => events.event_Id === selectedTags[0],
      e
    );
    setEventsToDelet(selectedTags);
    dispatch(
      deleteEvent(
        selectedTags,
        event[0].tenantId,
        e,
        pageConfig?.pageNumber,
        pageConfig?.NumberOfRowsSelected
      )
    );

    setData(null);
    setDeleting(true);
    setDeletPopUp(false);
  };

  return {
    setSelectedNumbers,
    setSelectedTags,
    selectedTags,
    confirmDelet,
    confirmDeletCallback,
    isDeleting,
    numSelected,
    data,
    setDeletPopUp,
  };
};
