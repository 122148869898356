import { useEffect, useMemo } from "react";
import {
  AdminHeaderStyles,
  ChannelHeaderStyles,
  ScheduleHeaderStyle,
} from "../../style/Header.style";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectUser } from "../../_pages/loginscreen/authSlice";
import {
  layoutChannelSelectedValue,
  layoutTabId,
  layoutContentChannelValue,
  layoutContentSelectedValue,
  layoutSelectedTenantValue,
  layoutShowSelector,
  setChannelSelectedValue,
  setTabId,
  setContentChannelValue,
  setContentSelectedValue,
  setSelectedTenantValue,
  layoutValue,
  setSelectedValue,
  setContentChannelData,
  layoutContentChannelData,
  setReviewsChannelData,
  setReviewsSelectedValue,
  setReviewsChannelValue,
  layoutReviewsSelectedValue,
  layoutReviewsChannelValue,
  layoutReviewsChannelData,
} from "../layoutSlice";
import { userRoleEnums } from "../../constants/enums";
import { setShowSelector } from "Layout/layoutSlice";
import * as RouteContants from "constants/routeConstants";
import { selectChannels } from "_pages/channels/channelSlice";
import { layoutSelectedValue } from "../layoutSlice";
import {
  getContentLoading,
  getmetaDataFromApi,
} from "_pages/contentTitles/contentTitleSlice";
import { find, filter } from "lodash";
import { selectProviders } from "_pages/provider/providerSlice";
import { ScrollTop } from "utils/utilityFunctions";
import { useHistory } from "react-router";
import { settingsCreatedOrNot } from "_pages/socialLinks/socialLinksSlice";

const HeaderHooks = () => {
  const classes = ScheduleHeaderStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(selectUser);
  const { channels } = useSelector(selectChannels);
  const channelData = useSelector(layoutContentChannelData);
  const reviewsChannelData = useSelector(layoutReviewsChannelData);

  const { pathname } = location;

  const path = pathname.split("/")[1];

  // Layout Slice
  const selectedTenantValue = useSelector(layoutSelectedTenantValue);
  const selectedValue = useSelector(layoutSelectedValue);
  const selectedTabTenantValue = useSelector(layoutValue);
  const showSelector = useSelector(layoutShowSelector);

  // Provider Slice
  const { providers } = useSelector(selectProviders);
  const handleProviderSelect = (option) => {
    const [selectedProvider] = filter(providers, { name: option });
    dispatch(setSelectedValue(selectedProvider?.tag));
  };
  const clearProvider = ()=>{
    selectedValue !== null && dispatch(setSelectedValue(null));
  }
  const getProviderValues = () => {
    const [selected] = filter(providers, { tag: selectedValue });
    return selected?.name ?? "";
  };

  useEffect(() => {
    if (user?.role === userRoleEnums.CS_USER) {
      if (
        localStorage.getItem("csTenantId") !== null &&
        selectedTenantValue === null
      ) {
        const tenantSeleted = {
          id: localStorage.getItem("csTenantId"),
          name: localStorage.getItem("tenantName"),
        };
        dispatch(setSelectedTenantValue(tenantSeleted));
      }
    }
    if (user?.role === userRoleEnums.ADMIN_USER) clearProvider()
  }, []);

  const setTenantValue = (index) => {
    if (index !== null) {
      dispatch(setSelectedTenantValue(index));
      localStorage.setItem("csTenantId", index?.id);
      localStorage.setItem("tenantId", index?.id);
      localStorage.setItem("tenantName", index?.name);
      dispatch(getmetaDataFromApi());
    } else {
      localStorage.removeItem("csTenantId");
      localStorage.removeItem("tenantId");
      localStorage.removeItem("tenantName");
      dispatch(setSelectedTenantValue(null));
    }
    dispatch(setShowSelector(true));
  };
  const handleDrawerToggle = () => {
    //setMobileOpen(!mobileOpen);
  };

  // Header Titles
  const HeaderTitle = useMemo(() => {
    switch (`/${pathname.split("/")[1]}`) {
      case RouteContants.adminPath:
        return "Reports.pageTitle";
      case RouteContants.adminReportPath:
        return "Reports.pageTitle";

      case RouteContants.tenantPath:
        return "Tenants.pageTitle";
      case RouteContants.tenantPublishingPath:
        return "Tenants.pageTitle";
      case RouteContants.tenantSitetermsPath:
        return "Tenants.pageTitle";
      case RouteContants.channelsPath:
        return "Channels.pageTitle";
      case RouteContants.channelsPublishPath:
        return "Channels.pageTitle";
      case RouteContants.collectionsPath:
        return "Collections.pageTitle";
      case RouteContants.scheduleTitles:
        return "Schedule.pageTitle";
      case RouteContants.providerPath:
        return "Providers.pageTitle";

      case RouteContants.subscriptionsPath:
        return "Subscriptions.pageTitle";
      case RouteContants.user:
        let userPath = `${pathname.includes(RouteContants.userReportPath)}`;
        return userPath == "true"
          ? "UserReport.pageTitle"
          : "UserManagement.pageTitle";
      case RouteContants.siteSettings:
        return "SiteSettings.pageTitle";
      case RouteContants.appLinks:
        return "SiteSettings.pageTitle";
      case RouteContants.socialLinks:
        return "SiteSettings.pageTitle";
      case RouteContants.customLinks:
        return "SiteSettings.pageTitle";
      case RouteContants.documentsPath:
        return "Documents.pageTitle";
      case RouteContants.mediaPath:
        if (pathname.split("/")[2].includes("bulkUpload"))
          return "BulkUpload.pageTitle";
        if (pathname.split("/")[2].includes("categories"))
          return "Category.pageTitle";
        if (pathname.split("/")[2].includes("reviews"))
          return "sidebar.reviews";
        if (pathname.split("/")[2].includes("contentTitles"))
          return "ContentTitles.pageTitle";
        break;
      default:
        return "";
    }
  }, [pathname]);

  // Admin Hooks
  const adminClasses = AdminHeaderStyles();
  const adminTabId = useSelector(layoutTabId);
  const adminHandleChange = (event, index) => {
    dispatch(setTabId(index));
  };

  // Channel Hooks
  const channelTabId = useSelector(layoutTabId);
  const channelSelectedValue = useSelector(layoutChannelSelectedValue);
  const channelClasses = ChannelHeaderStyles();
  const channelActionCallback = (value) => {
    if (value !== "") {
      dispatch(setChannelSelectedValue(value));
    } else {
      dispatch(setChannelSelectedValue(null));
    }
    ScrollTop();
  };
  const channelHandleChange = (event, newValue) => {
    dispatch(setChannelSelectedValue(null));
    dispatch(setShowSelector(true));
    dispatch(setTabId(newValue));
    switch (newValue) {
      default:
      case 0:
        history.push(RouteContants.channelsPath);
        break;
      case 1:
        history.push(RouteContants.channelsPublishPath);
        break;
    }
  };
  const channelHandleSelect = (option) => {
    const [selectedProvider] = filter(providers, { name: option });
    channelActionCallback(selectedProvider?.tag);
  };
  const channelGetProviderValues = () => {
    const [selected] = filter(providers, { tag: channelSelectedValue });
    return selected?.name ?? "";
  };

  // Tenant Hooks
  const tenantTabId = useSelector(layoutTabId);
  const tenantHandleChange = (event, newValue) => {
    dispatch(setTabId(newValue));
    switch (newValue) {
      default:
      case 0:
        history.push(RouteContants.tenantPath);
        break;
      case 1:
        history.push(RouteContants.tenantPublishingPath);
        break;
      case 2:
        history.push(RouteContants.tenantSitetermsPath);
        break;
    }
  };

  // Content Hooks
  const contentLoading = useSelector(getContentLoading);
  const contentTabId = useSelector(layoutTabId);
  const contentFilterParamProvider = useSelector(layoutContentSelectedValue);
  const contentFilterParamChannel = useSelector(layoutContentChannelValue);
  // const contentClasses = ContentTitleHeaderStyles();
  useEffect(() => {
    dispatch(setContentChannelData(channels));
  }, [channels]);

  const contentActionCallbackChannels = (val) => {
    dispatch(setContentChannelValue(val));
  };
  const getProviderValue = (value, key = "tag") => {
    try {
      const provider = providers.find(({ name }) => name === value);
      return provider[key];
    } catch (e) {
      return null;
    }
  };
  const contentActionCallback = (value) => {
    if (value !== "") {
      const tag = getProviderValue(value);

      const filteredChannel =
        value === null
          ? channels
          : channels.filter((i) => i?.provider?.tag === tag);

      dispatch(setContentChannelData(filteredChannel));
      dispatch(setContentSelectedValue(tag));
    } else {
      dispatch(setContentChannelData(channels));
      setContentSelectedValue(null);
    }
  };

  const contentSelectCallback = (selectedName) => {
    const selectedData = find(channelData, { name: selectedName });
    contentActionCallbackChannels(selectedData?.tag);
  };

  const contentGetValue = (tag) => {
    const selectedData = find(channelData, { tag });
    return selectedData?.name ?? "";
  };

  const contentHandleChange = (event, newValue) => {
    dispatch(setShowSelector(true));
    dispatch(setTabId(newValue));
  };

  // Reviews Hooks
  const reviewsFilterParamProvider = useSelector(layoutReviewsSelectedValue);
  const reviewsFilterParamChannel = useSelector(layoutReviewsChannelValue);

  useEffect(() => {
    dispatch(setReviewsChannelData(channels));
  }, [channels]);

  const reviewsActionCallbackChannels = (val) => {
    dispatch(setReviewsChannelValue(val));
  };
  const getReviewsProviderValue = (value, key = "tag") => {
    try {
      const provider = providers.find(({ name }) => name === value);
      return provider[key];
    } catch (e) {
      return null;
    }
  };
  const reviewsActionCallback = (value) => {
    if (value !== "") {
      const tag = getReviewsProviderValue(value);
      const filteredChannel =
        value === null
          ? channels
          : channels.filter((i) => i?.provider?.tag === tag);
      dispatch(setReviewsChannelData(filteredChannel));
      dispatch(setReviewsSelectedValue(tag));
    } else {
      dispatch(setReviewsChannelData(channels));
      setReviewsSelectedValue(null);
    }
  };

  const reviewsSelectCallback = (selectedName) => {
    const selectedData = find(reviewsChannelData, { name: selectedName });
    reviewsActionCallbackChannels(selectedData?.tag);
  };

  const reviewsGetValue = (tag) => {
    const selectedData = find(reviewsChannelData, { tag });
    return selectedData?.name ?? "";
  };

  const reviewsHandleChange = (event, newValue) => {
    dispatch(setShowSelector(true));
    dispatch(setTabId(newValue));
  };
 const isSettingsCreated = useSelector(settingsCreatedOrNot);

  return {
    classes,
    showSelector,
    handleProviderSelect,
    getProviderValues,
    handleDrawerToggle,
    selectedTabTenantValue,
    pathname,
    setTenantValue,
    user,
    path,
    selectedTenantValue,
    HeaderTitle,
    channels,
    providers,
    admin: {
      classes: adminClasses,
      handleChange: adminHandleChange,
      tabId: adminTabId,
    },
    channel: {
      tabId: channelTabId,
      classes: channelClasses,
      handleChange: channelHandleChange,
      handleSelect: channelHandleSelect,
      getProviderValues: channelGetProviderValues,
      clearProvider,
    },
    tenant: {
      classes: channelClasses,
      tabId: tenantTabId,
      handleChange: tenantHandleChange,
    },
    content: {
      contentLoading,
      tabId: contentTabId,
      filterParamProvider: contentFilterParamProvider,
      filterParamChannel: contentFilterParamChannel,
      classes: channelClasses,
      channelData,
      clearProvider,
      actionCallback: contentActionCallback,
      selectCallback: contentSelectCallback,
      getValue: contentGetValue,
      handleChange: contentHandleChange,
      actionCallbackChannels: contentActionCallbackChannels,
    },
    reviews: {
      filterParamProvider: reviewsFilterParamProvider,
      reviewsFilterParamChannel,
      reviewsChannelData,
      clearProvider,
      reviewsActionCallback,
      reviewsSelectCallback,
      reviewsGetValue,
      handleChange: reviewsHandleChange,
      actionCallbackChannels: reviewsActionCallbackChannels,
    },
    settings: {
      isSettingsCreated,
    },
  };
};

export { HeaderHooks };
