import React from "react";
import { Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { TitleComponentStyles } from "style/components";

const Title = (props) => {
  const classes = TitleComponentStyles();
  const {
    callback,
    callback2,
    callback3,
    subTitle2,
    subTitle3,
    fillChooseClass,
  } = props;

  return (
    <React.Fragment>
      <div className="row">
        <Typography
          onClick={() => {
            callback(null, null);
          }}
          className={props.subTitle ? classes.title : classes.noSubTitle}
          variant="h5"
        >
          {props.title}
        </Typography>
        {props.subTitle && <NavigateNextIcon className={`${classes.arrow}`} />}
        <Typography
          onClick={() => {
            if (subTitle2) {
              callback2(null, fillChooseClass);
            }
          }}
          className={subTitle2 ? classes.title : classes.subTitle}
          variant="h5"
        >
          {props.subTitle}
        </Typography>
        {subTitle2 && <NavigateNextIcon className={`${classes.arrow}`} />}
        {subTitle2 && (
          <Typography
            onClick={() => {
              if (subTitle3) {
                callback3();
              }
            }}
            className={subTitle3? classes.title : classes.subTitle}
            variant="h5"
          >
            {subTitle2}
          </Typography>
        )}
        {subTitle3 && <NavigateNextIcon className={`${classes.arrow}`} />}
        {subTitle3 && (
          <Typography className={classes.subTitle} variant="h5">
            {subTitle3}
          </Typography>
        )}
      </div>
    </React.Fragment>
  );
};

export default Title;
