import { useSelector } from 'react-redux';
import { selectUser } from '../_pages/loginscreen/authSlice';
import { WELCOME_PAGE_HEADINGS, userRoleEnums } from '../constants/enums';
export const useWelcomeHook = ()=>{
    const user = useSelector(selectUser);
    const { CONTENT_TITLES, CHANNELS, HELP_CENTRE } = WELCOME_PAGE_HEADINGS;
    const getWelcomePageHeadings = () => {
      if (user?.role === userRoleEnums.TENANT_AS_PROVIDER_USER) {
        return [CONTENT_TITLES, HELP_CENTRE];
      }
      return [CONTENT_TITLES, CHANNELS, HELP_CENTRE];
    }
    const getUserName = () => {
      if (user?.role === userRoleEnums.TENANT_AS_PROVIDER_USER) {
        return user?.name;
      }
      return "Wexer";
    }

    return {
        user,
        getWelcomePageHeadings,
        getUserName
    }
}