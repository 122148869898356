import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {filter, isEmpty, map, orderBy} from "lodash";
import Select from "components/Inputs/Select";
import {selectMetaData} from "../contentTitleSlice";
import MultiSelect from "components/Inputs/MultiSelect";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  skillLevelConstants,
  intensityConstants,
  MEDIA_TYPE_FILTER_OPTIONS,
  MEDIA_TYPE_FILTER_VALUES,
} from "constants/enums";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { selectChannels } from "_pages/channels/channelSlice";
import { Skeleton } from "@material-ui/lab";
import { selectProviders } from "_pages/provider/providerSlice";
import { layoutContentChannelData } from "Layout/layoutSlice";
import { scheduleTitles } from "constants/routeConstants";
import {
  handelOptions
} from "utils/utilityFunctions";

import {selectUserReportMetaData} from "_pages/userReport/UserReport.slice";
const Translate = require("assets/translations/en.json");
export const SelectEventDuration = (props) => {
  let ed = [] as any;
  for (let l = 0; l <= 120; l += 5) {
    let eed = 0 as any;
    if (l === 0) eed = 1;
    else eed = l;
    ed.push(eed);
  }
  const options = [...ed];
  return (
    <React.Fragment>
      {
        <Select
          isArray={true}
          itemLable="category"
          lable="Event Duration"
          {...props}
          options={options}
          isDesabled={props.isDisabled}
        />
      }
    </React.Fragment>
  );
};
export const SelectCategories = (props) => {
  const {categoryCallback} = props;
  const {metaData} = useSelector(selectMetaData);

  const callback = (e) => {
    const selectedCatedgory = e.target.value;

    const data = metaData?.categories;

    for (let i = data.length; i--; ) {
      if (data[i].category === selectedCatedgory) {
        if (data[i].subCategories.length > 0) {
          categoryCallback(selectedCatedgory, true, data[i].categoryId);
        } else {
          categoryCallback(selectedCatedgory, false, data[i].categoryId);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {metaData?.categories && (
        <Select
          itemLable="category"
          lable="Category"
          callback={callback}
          {...props}
          options={metaData?.categories}
        />
      )}
    </React.Fragment>
  );
};

export const SelectSubCategories = (props) => {
  const {category} = props;
  const [options, setOptions] = useState([]);
  const {metaData} = useSelector(selectMetaData);
  useEffect(() => {
    if (category && metaData) {
      for (let i = metaData?.categories?.length; i--; ) {
        if (metaData?.categories[i].category === category) {
          let subCategoreis: any = metaData?.categories[i].subCategories;
          let subCategoreisOptions: any =
             subCategoreis && subCategoreis.length > 0
               ? subCategoreis.map((subCat: any) => {
                   let listSubcategory: any = {
                     name: subCat?.name,
                     id: subCat?.id,
                   };
                   return listSubcategory;
                 })
               : [];
          setOptions(subCategoreisOptions);
        }
      }
    }
  }, [category, metaData]);

  return (
    <React.Fragment>
      {
        <Select
          isArray={true}
          isDesabled={options?.length === 0 ? true : false}
          itemLable="category"
          lable="Video Language"
          callback={props.selectCallback}
          {...props}
          options={options}
        />
      }
    </React.Fragment>
  );
};

export const SelectSkillLevel = (props) => {
  const {filterKeys, value} = props;
  return (
    <React.Fragment>
      {
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          itemLable={filterKeys ? undefined : "category"}
          lable="Skill Level"
          {...props}
          options={skillLevelConstants}
        />
      }
    </React.Fragment>
  );
};

export const SelectEquipment = (props) => {
  const {metaData} = useSelector(selectMetaData);
  const [equipments, setEquipments] = useState([]);
  const id = "typeTag";
  useEffect(() => {
    if (metaData) {
      let eq = [] as any;
      for (let l = metaData.equipments.length; l--; ) {
        eq.push(metaData?.equipments[l].name);
      }
      setEquipments(eq);
    }
  }, [metaData]);
  return (
    <React.Fragment>
      {equipments.length > 0 && (
        <MultiSelect
          lable="Equipment"
          itemLable="name"
          isArray={true}
          id={id}
          optional={true}
          {...props}
          options={equipments}
        />
      )}
    </React.Fragment>
  );
};

export const SelectSigleEquipment = (props) => {
  const {metaData} = useSelector(selectMetaData);

  const Optionlable = "name";
  const id = "typeTag";
  const [equipmentsData, setSingleEquipments] = useState([]);

  useEffect(() => {
    if (metaData) {
      let eq = [] as any;
      for (let l = metaData.equipments.length; l--; ) {
        eq.push(metaData?.equipments[l].name);
      }
      setSingleEquipments(eq);
    }
  }, [metaData]);
  return (
    <React.Fragment>
      {metaData?.equipments && (
        <MultiSelect
          multiple={false}
          values={props.value}
          isArray={true}
          disabled={false}
          lable={props.lable ?? Optionlable}
          id={id}
          {...props}
          options={equipmentsData}
        />
      )}
    </React.Fragment>
  );
};
export const SelectUI = (props) => {
  const {options = []} = props;
  return (
    <React.Fragment>{options && <MultiSelect {...props} />}</React.Fragment>
  );
};
export const SelectVideoLanguages = (props) => {
  const {metaData} = useSelector(selectMetaData);

  return (
    <React.Fragment>
      {metaData?.languages && (
        <Select
          itemLable="displayName"
          lable="Video Language"
          {...props}
          options={metaData?.languages}
          callback={(e) => {
            let languageCode = getLanguageCode(metaData, e.target.value);
            props.callback(e, languageCode);
          }}
        />
      )}
    </React.Fragment>
  );
};
export const SelectSingleFocusArea = (props) => {
  const {metaData} = useSelector(selectMetaData);

  const Optionlable = "Focus Area";
  const id = "tag";

  const [focusAreas, setFocusAreas] = useState([]);

  useEffect(() => {
    if (metaData) {
      let fA = [] as any;
      for (let l = metaData.focusAreas.length; l--; ) {
        fA.push(metaData?.focusAreas[l].name);
      }
      setFocusAreas(fA);
    }
  }, [metaData]);
  return (
    <React.Fragment>
      {metaData?.focusAreas && (
        <MultiSelect
          multiple={false}
          values={props.value}
          isArray={true}
          disabled={false}
          lable={props.lable ?? Optionlable}
          id={id}
          {...props}
          options={focusAreas}
        />
      )}
    </React.Fragment>
  );
};
export const SelectFocusArea = (props) => {
  const {metaData} = useSelector(selectMetaData);

  const [focusAreas, setFocusAreas] = useState([]);
  const id = "tag";
  useEffect(() => {
    if (metaData) {
      let fA = [] as any;
      for (let l = metaData.focusAreas.length; l--; ) {
        fA.push(metaData?.focusAreas[l].name);
      }
      setFocusAreas(fA);
    }
  }, [metaData]);
  return (
    <React.Fragment>
      {metaData?.focusAreas && (
        <MultiSelect
          id={id}
          isArray={true}
          lable="Focus Areas"
          optional={true}
          {...props}
          options={focusAreas}
        />
      )}
    </React.Fragment>
  );
};

export const SelectLables = (props) => {
  const {metaData} = useSelector(selectMetaData);
  const id = "tag";
  return (
    <React.Fragment>
      {metaData?.labels && (
        <MultiSelect
          id={id}
          isArray={true}
          lable="Labels"
          optional={true}
          {...props}
          options={metaData?.labels}
        />
      )}
    </React.Fragment>
  );
};

export const SelectIntensity = (props) => {
  const {value} = props;

  return (
    <React.Fragment>
      {
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          lable="Intensity (RPE)"
          {...props}
          options={intensityConstants}
        />
      }
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: "100%",
      color: "#000",
    },
  })
);

export function DatePickers(props) {
  const {callback, value, isDesabled, loading} = props;
  const classes = useStyles();

  const getDate = (date) => {
    if (date) {
      let array = date.split("T");
      if (array && array.length > 1) {
        return array[0];
      }
    }
  };

  if (loading) {
    return (
      <div className={`${classes.container} ${props.style}`}>
        <Skeleton variant="rect" animation="pulse" width="100%" height={53} />
      </div>
    );
  }

  return (
    <form className={`${classes.container} ${props.style}`} noValidate>
      <TextField
        id="date"
        label={props.label ? props.label : "Release Date"}
        type="date"
        // value={new Date().toLocaleDateString('en-GB', {
        //     day: 'numeric',
        //     month: 'short',
        //     year: 'numeric'
        // }).split(' ').join(' ')}
        value={getDate(value)}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={callback}
        disabled={isDesabled}
      />
    </form>
  );
}

export function DatePickerNew(props) {
  const {callback, value, callbackBlur, callbackFocus} = props;
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: "#36dcb6",
        main: "#36dcb6",
        dark: "#36dcb6",
        contrastText: "#fff",
      },
    },
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    let convertedDate = moment(date).toDate();
    callback(convertedDate);
  };

  const shouldDisableDate = (date) => {
    if (props.shouldDisableDate) {
      let convertedDate = moment(date).toDate();
      return props.shouldDisableDate(convertedDate);
    }
    return true;
  };
  const handleOnFocus = (e) => {
    callbackFocus && callbackFocus(e);
  };
  const handleOnBlur = (e) => {
    callbackBlur && callbackBlur(e);
  };
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          id="date"
          name="date"
          label={props.label}
          className={props.className}
          value={selectedDate}
          onChange={handleDateChange}
          onFocus={handleOnFocus ?? undefined}
          onBlur={handleOnBlur ?? undefined}
          onMouseOver={handleOnFocus ?? undefined}
          onMouseOut={handleOnBlur ?? undefined}
          format={"dddd DD MMMM"}
          disabled={props.disabled}
          shouldDisableDate={shouldDisableDate}
          animateYearScrolling
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

const getLanguageCode = (metaData, language) => {
  if (language == null) {
    return;
  }
  let languages = metaData?.languages;
  if (languages) {
    let results = languages.filter((obj) => {
      return obj.displayName.toLowerCase() === language.toLowerCase();
    });
    if (results && results.length > 0) {
      return results[0].ietfTag;
    }
  }
};
export const SelectChannelsFromChannelAPI = (props) => {
  const {value, isDesabled} = props;

  const {channels} = useSelector(selectChannels);

  const Optionlable = "name";
  const id = "tag";
  let filterChannelsByTenants = [] as any;
  if (Array.isArray(channels)) {
    channels?.map((item, index) => {
      if (item?.tenants?.indexOf(localStorage.getItem("csTenantId")) !== -1) {
        filterChannelsByTenants.push(item.name);
      }
    });
  }

  return (
    <React.Fragment>
      {channels && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          id={id}
          disabled={isDesabled}
          lable={props.lable ?? Optionlable}
          {...props}
          options={filterChannelsByTenants}
        />
      )}
    </React.Fragment>
  );
};
export const SelectChannelMetaData = (props) => {
  const {value} = props;

  const {metaData} = useSelector(selectMetaData);

  const Optionlable = "name";
  const id = "tag";
  const channels = metaData?.channels?.map((channel) => channel.name);

  return (
    <React.Fragment>
      {metaData?.channels && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          id={id}
          disabled={false}
          lable={props.lable ?? Optionlable}
          {...props}
          options={channels}
        />
      )}
    </React.Fragment>
  );
};
export const SelectProvider = (props) => {
  const {value} = props;
  const {providers: metaData} = useSelector(selectProviders);
  const Optionlable = "name";
  const id = "tag";
  const providers = metaData?.map((provider) => provider.name);
  return (
    <React.Fragment>
      {providers && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          id={id}
          disabled={false}
          lable={props.lable ?? Optionlable}
          {...props}
          options={providers}
        />
      )}
    </React.Fragment>
  );
};
export const SelectChannel = (props) => {
  const {value} = props;
  const channelData = useSelector(layoutContentChannelData);
  const Optionlable = "name";
  const id = "tag";
  const channels = channelData?.map((channel) => channel.name);
  return (
    <React.Fragment>
      {channels && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          id={id}
          disabled={false}
          lable={props.lable ?? Optionlable}
          {...props}
          options={channels}
        />
      )}
    </React.Fragment>
  );
};

export const SubscrptionStatus = (props) => {
  const {value} = props;
  const metaData = useSelector(selectUserReportMetaData);
  const subscriptionStatus = metaData?.subscriptionStatus;
  return (
    <React.Fragment>
      {subscriptionStatus && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          lable={props.lable}
          {...props}
          options={subscriptionStatus}
        />
      )}
    </React.Fragment>
  );
};

export const ExtSubscriptionStatus = (props) => {
  const {value} = props;
  const metaData = useSelector(selectUserReportMetaData);
  const externalSubscriptionStatus = metaData?.externalSubscriptionStatus;
  return (
    <React.Fragment>
      {externalSubscriptionStatus && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          lable={props.lable}
          {...props}
          options={externalSubscriptionStatus}
        />
      )}
    </React.Fragment>
  );
};

export const ExtSubscriptionSources = (props) => {
  const {value} = props;
  const metaData = useSelector(selectUserReportMetaData);
  const externalSubscriptionSource = metaData?.externalSubscriptionSource;
  return (
    <React.Fragment>
      {externalSubscriptionSource && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          {...props}
          options={externalSubscriptionSource}
        />
      )}
    </React.Fragment>
  );
};

export const SelectCategory = (props) => {
  const {value, filterKeys} = props;
  const {metaData} = useSelector(selectMetaData);
  const Optionlable = "name";
  const id = "tag";
  const categories = metaData?.categories.map((category) => category.category);
  return (
    <React.Fragment>
      {categories && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          id={id}
          disabled={false}
          lable={props.lable ?? Optionlable}
          {...props}
          options={categories}
        />
      )}
    </React.Fragment>
  );
};
export const SelectSubCategory = (props) => {
  const {value, category, filterKeys} = props;
  const {metaData} = useSelector(selectMetaData);
  const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    if (category && category !== "" && metaData) {
      for (let i = metaData?.categories?.length; i--; ) {
        if (metaData?.categories[i].category === category) {
            
    let subCategoryData: [] =
      metaData?.categories[i].subCategories &&
      metaData?.categories[i].subCategories.length > 0
        ? metaData?.categories[i].subCategories.map((subCat: any) => {
            let listSubcategory: any = {
              name: subCat?.name,
              id: subCat?.id,
            };
            return listSubcategory;
          })
        : [];
       subCategoryData && subCategoryData.length > 0 ?
        setSubCategories(handelOptions(true, subCategoryData, "name")) : setSubCategories([]);
          break;
        }
      }
    } else setSubCategories([]);
  }, [category]);

  return (
    <React.Fragment>
      {subCategories && subCategories.length > 0 && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          lable={props.lable}
          {...props}
          options={subCategories}
          ignorecase={true}
        />
      )}
    </React.Fragment>
  );
};
export const SelectKeyword = (props) => {
  const {value} = props;

  const {metaData} = useSelector(selectMetaData);
  return (
    <React.Fragment>
      {metaData?.labels && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          lable={props.lable}
          {...props}
          options={metaData?.labels}
        />
      )}
    </React.Fragment>
  );
};
export const SelectLanguage = (props) => {
  const {value, filterKeys} = props;

  const {metaData} = useSelector(selectMetaData);

  const Optionlable = "name";
  const id = "tag";
  const languages = metaData?.languages.map((language) => language.displayName);
  return (
    <React.Fragment>
      {languages && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          disabled={false}
          lable={props.lable ?? Optionlable}
          id={id}
          {...props}
          options={languages}
        />
      )}
    </React.Fragment>
  );
};

export const SelectMediaType = (props) => {
  const {pathname} = props;
  let value =
    pathname === scheduleTitles ? MEDIA_TYPE_FILTER_VALUES.Video : props.value;
  const Optionlable = "name";
  const id = "value";
  const options =
    pathname === scheduleTitles
      ? [MEDIA_TYPE_FILTER_OPTIONS[0]]
      : MEDIA_TYPE_FILTER_OPTIONS;
  return (
    <React.Fragment>
      <MultiSelect
        multiple={false}
        values={MEDIA_TYPE_FILTER_OPTIONS[value - 1] || ""}
        isArray={true}
        disabled={false}
        lable={props.lable ?? Optionlable}
        id={id}
        {...props}
        options={options}
        disableClearable={pathname === scheduleTitles}
      />
    </React.Fragment>
  );
};

export const getDurationInMinsWithLabel = (filterKeys = false) => {
  const durationMins = [
    Translate?.Collections?.tenmins, //Translate({ id: "Collections.10mins" })
    ,
    Translate?.Collections?.twentymins,
    ,
    Translate?.Collections?.thirtymins,
    ,
    Translate?.Collections?.fourtymins,
    ,
  ];
  return durationMins;
};

export const SelectDuration = (props) => {
  const {value, filterKeys} = props;
  const {metaData} = useSelector(selectMetaData);
  const Optionlable = "Duration";
  const id = "tag";
  return (
    <React.Fragment>
      {metaData?.durationSecond && (
        <MultiSelect
          multiple={false}
          values={value}
          isArray={true}
          id={id}
          disabled={false}
          lable={props.lable ?? Optionlable}
          {...props}
          options={getDurationInMinsWithLabel(filterKeys)}
        />
      )}
    </React.Fragment>
  );
};
