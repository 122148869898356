import { titleStatusEnums } from "constants/enums";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isVideoValid } from "utils/utilityFunctions";
import { errorInTrailerUpload } from "_pages/contentTitles/contentTitleSlice";
import {
  eventStatus,
  isAnotheBorkenUploadGoingOn,
  preventEventVideoUpload,
  showErrorPop,
  updateEventStatus,
  uploadBrokenEventVideo,
} from "_pages/scheduleEvents/ScheduleEventSlice";

export const useScheduleVideoUploaderHook = (props) => {
  const {
    value,
    isFormPrefilled,
    uploadingStatus,
    uploaderCallback,
    tag,
    optionalCallback,
  } = props;
  const { ASPECT_RATIO, ERROR_MESSAGE } = props.matadata;
  const [file, setFile] = useState(null as any);
  const [fileName, setFileName] = useState("");
  const [progress, setProgess] = useState(0);
  const [loading, setLoading] = useState(0);
  const [isFileBroken, setFileBroken] = useState(false);
  const [isTrailerRemoved, setTrailerRemoveStatus] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [newError, setErrorStatus] = useState("");
  const [isReplacing, setReplaceing] = useState(false);
  const preventVideoUpload = useSelector(preventEventVideoUpload);
  const anotherBrokenUpload = useSelector(isAnotheBorkenUploadGoingOn);
  const {status: eventCreatedStatus} = useSelector(eventStatus);
  const dispatch = useDispatch();
  const errorInUpload: any = useSelector(errorInTrailerUpload);

  useEffect(() => {
    if (tag && uploadingStatus && uploadingStatus?.progress !== null) {
      if (uploadingStatus?.tag === tag) {
        if (uploadingStatus.isBroken) {
          setFileBroken(true);
        }
        setProgess(uploadingStatus.progress);

        if (uploadingStatus.progress === 1) {
          setLoading(99);
        }
        if (value?.status === titleStatusEnums.Completed) {
        } else {
          setLoading(80);
        }
      }
    } else {
      setLoading(0);
    }
  }, [uploadingStatus, tag, value]);

  useEffect(() => {
    if (isTrailerRemoved) {
      setLoading(0);
    }
  }, [isTrailerRemoved]);

  useEffect(() => {
    if (isFormPrefilled && value && value !== "" && value?.status) {
      if (value?.status !== titleStatusEnums.Completed) {
        setProgess(80); // Rajneesh || need to check/optimise/remove this setProgess() logic from this component
      }
      setLoading(100);
    }
    if (value && value !== "") {
      if (
        value?.status === titleStatusEnums.Completed ||
        value?.status === titleStatusEnums.Incomplete ||
        value?.status === titleStatusEnums.Published
      ) {
        setFileName(value.className); //RR \\ need to remove setFileName logic later
      }
    }
  }, [isFormPrefilled, value]);

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    var eventTargetFile = event.target.files;
    if (file) {
      setErrorStatus("");
      setReplaceing(true);
      dispatch(updateEventStatus({status: {...eventCreatedStatus, video: ""}}));
      var video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        var videoHeight = video.videoWidth;
        var videoWidth = video.videoHeight;
        if (isVideoValid(videoWidth, videoHeight, ASPECT_RATIO)) {
          setFileName(file.name.substr(0, file.name.length - 4));
          setFile(file);
          setProgess(0);

          setLoading(80);
          if (value?.status === titleStatusEnums.Completed) {
            dispatch(uploaderCallback(eventTargetFile, event, tag, true));
            setReplaceing(false);
          } else {
            dispatch(uploaderCallback(eventTargetFile, event, tag, false));
          }

          if (optionalCallback) {
            optionalCallback(true);
          }
        } else {
          setErrorStatus(ERROR_MESSAGE);
          setFile(null);
          setLoading(0);
          setReplaceing(false);
        }
      };
    }
  };

  const uploadFilesFromDB = (e) => {
    dispatch(uploadBrokenEventVideo(tag, e, anotherBrokenUpload));
    setFileBroken(false);
  };

  const popUpCallback = () => {
    dispatch(showErrorPop());
    setLoading(0);
  };

  return {
    setTrailerRemoveStatus,
    preventVideoUpload,
    popUpCallback,
    isReplacing,
    handleUploadClick,
    isFileBroken,
    uploadFilesFromDB,
    progress,
    loading,
    fileName,
    isProcessing,
    newError
  };
};
