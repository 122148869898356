import React, { useEffect, useState } from "react";
import {
  API_ERROR_TYPE,
  TIME_CONSTANTS,
} from "constants/enums";
import { Alert } from "@material-ui/lab";
import { useAppSelector } from "store/hooks";
import { apiResponse, resetApiResponse } from "Layout/layoutSlice";
import { CommonAPIResponse } from "models/common.model";
import { useDispatch } from "react-redux";
import { getResponseType } from "utils/utilityFunctions";

const APIResponseAlert = () => {
  const dispatch = useDispatch();
  const { status, data, autoHide = true } = useAppSelector<CommonAPIResponse>(apiResponse);
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>("");

  const timeout = (msg: string) => {
    const msgLength = msg.split('').length;
    let time = TIME_CONSTANTS.T3s;
    if (msgLength > 75) {
      time += Math.ceil(msgLength / 75) * 1000;
    }
    return time;
  }

  useEffect(() => {
    if (status && data) {
      setType(getResponseType(status));
      setOpen(true);
      if (autoHide) {
        setTimeout(function() {
          setOpen(false);
          dispatch(resetApiResponse());
        }, timeout(data));
      }
    }
    return () => setOpen(false);
  }, [status, data, autoHide, dispatch]);

  const getAlertType = () => {
    if (type === API_ERROR_TYPE.ERROR) return "error";
    // if (type === API_ERROR_TYPE.INFO) return "info";
    return "success";
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetApiResponse());
  }

  return (
    <React.Fragment>
      {open && (
        <Alert
          className="apiResponsePopup"
          severity={getAlertType()}
          onClose={handleClose}
        >
          {data}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default APIResponseAlert;
