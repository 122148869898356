import {
  makeStyles,
  createStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import { FormCommonStyle, PickerCommonStyle, TextCommonStyle } from "./Common.style";

export const AdminReportStyles = makeStyles((theme) => ({
  ...{ ...FormCommonStyle(theme) },
  ...PickerCommonStyle,
  ...TextCommonStyle,
  form: {
    marginTop: "10px",
  },
}));

export const AdminScreenStyles = (theme: Theme) =>
  createStyles({
    main: {
      flex: 1,
      // padding: "172px 32px 32px 32px",
      background: "rgb(239,239,244)",
      "&::before": {
        position: "absolute",
        top: 50,
        right: 0,
        background: theme.palette.secondary.main,
      },
    },
  });

export interface AdminScreenProps
  extends WithStyles<typeof AdminScreenStyles> {}
