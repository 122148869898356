import {
  layoutContentChannelValue,
  layoutContentSelectedValue,
  layoutTabId,
  layoutSelectedTenantValue,
  setTabId,
  setContentTenantValue,
  setShowSelector,
  layoutShowSelector,
} from "Layout/layoutSlice";
import { userRoleEnums } from "constants/enums";
import { selectUser } from "_pages/loginscreen/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useContentIndexHook = () => {
  const user = useSelector(selectUser);
  const tabId = useSelector(layoutTabId);
  const selectedValue = useSelector(layoutContentSelectedValue);
  const channelValue = useSelector(layoutContentChannelValue);
  const tenantValue = useSelector(layoutSelectedTenantValue);
  const showSelector = useSelector(layoutShowSelector);
  const dispatch = useDispatch();

  const resetFilterStatus = (index) => {
    dispatch(setShowSelector(index));
  };

  const handleChangeIndex = (index) => {
    dispatch(setTabId(index));
  };

  useEffect(() => {
    if (user?.role === userRoleEnums.CS_USER) {
      dispatch(setContentTenantValue(localStorage.getItem("csTenantId")));
    }
    if (user?.role === userRoleEnums.PRVIDER_USER) {
      dispatch(setShowSelector(true));
    }
  }, [tenantValue, showSelector]);

  return {
    user,
    tabId,
    selectedValue,
    channelValue,
    tenantValue,
    resetFilterStatus,
    handleChangeIndex,
  };
};
