import { paginationValues } from "constants/enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import { error } from "_pages/collections/CollectionsSlice";

export const useReorderClassTableHook = (props) => {
  const { pageConfig, optionalData, numSelected } = props;
  const [order, setOrder] = useState("desc");
  const [isLoading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(props.from ? "weekDay" : "className");
  const [selected, setSelected] = useState([] as any);
  const [page, setPage] = useState(0);
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [isCancelButtonDisabled, setCancelButtonDisabled] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationValues.DEFAULT_PAGE_SIZE
  );
  const [reorderData, setReorderData] = useState(null as any);

  const errorOccured = useSelector(error);
  useEffect(() => {
    if (numSelected === 0) {
      setSelected([]);
    }
  }, [numSelected]);

  useEffect(() => {
    if (pageConfig?.pageNumber) {
      if (pageConfig.pageNumber !== 0) {
        setPage(pageConfig.pageNumber - 1);
      }

      setRowsPerPage(pageConfig.NumberOfRowsSelected);
    }
  }, [pageConfig]);

  useEffect(() => {
    if (optionalData && optionalData.length > 0) {
      setSelected([...optionalData]);
    } else {
      setSelected([]);
    }
    setButtonStatus(true);
  }, [optionalData]);

  useEffect(() => {
    ScrollTop();
  }, []);

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    isButtonDesabled,
    setButtonStatus,
    rowsPerPage,
    setRowsPerPage,
    errorOccured,
    reorderData,
    setLoading,
    setReorderData,
    setCancelButtonDisabled,
    isCancelButtonDisabled,
    isLoading
  };
};
