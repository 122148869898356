import { Theme, makeStyles } from "@material-ui/core/styles";

export const DialogStyle = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: 0,
    overflow: "visible",
    width: 256,
    height: 88,
    backgroundColor: "#fff",
    borderRadius: 8,
    marginRight: 0,
    zIndex: 1,
    boxShadow:
      "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)",
  },
  AvatarContainer: {
    marginTop: 10,
    marginRight: 8,
    // marginTop: -17,
    // //marginRight: 8,
    // right: 30,
    // position: 'fixed'
  },
  devider: {
    color: "#e0e0e0",
    backgroundColor: "#e0e0e0 !important",
  },
  boxes: {
    height: 48,
  },
  popOverItems: {
    display: "flex",
    paddingTop: 15,
    paddingLeft: 16,
    paddingRight: 16,
  },
  leftArrow: {
    color: "#8e8e93",
    width: 24,
    height: 24,
    marginLeft: 4,
    marginRight: 4,
  },
  dialog: {
    backgroundColor: "#fff",
    borderRadius: 8,

    paddingTop: 0,
    paddingBottom: 0,
    // marginTop: 90,
  },
  button: {
    display: "flex",
    cursor: "pointer",
    width: 100,
    paddingTop: 8,
    paddingLeft: 8,
    flexDirection: "row",
    backgroundColor: "#fff", //theme.palette.primary.main
  },

  roleText: {
    display: "flex",
    cursor: "pointer",
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 16,
    flexDirection: "row",
    backgroundColor: "#fff", //theme.palette.primary.main
  },
}));
export const DialogComponentStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: "#ffffff !important",
  },

  buttonsSMEvent: {
    display: "flex !important",
    flexDirection: "row-reverse",
    justifyContent: "flex-end !important",
    backgroundColor: "#ffffff !important",
    "& span": {
      textTransform: "uppercase !important",
    },
  },
  buttonsLiveEvent: {
    display: "flex !important",
    flexDirection: "row-reverse",
    justifyContent: "space-around !important",
    backgroundColor: "#ffffff !important",
    "& span": {
      textTransform: "uppercase !important",
    },
  },
  buttonsEvent: {
    display: "flex !important",
    flexDirection: "row-reverse",
    justifyContent: "flex-end !important",
    backgroundColor: "#ffffff !important",
    "& span": {
      textTransform: "uppercase !important",
    },
  },
  paper: {
    margin: "0 auto",
    width: 512,
    borderRadius: 4,
  },
  dialog: {
    margin: "0 auto",
    width: 512,
  },
  content: {
    padding: "16px 24px 16px 19px",
    margin: "0px 24px 0px 24px",
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "row",
  },
  contentText: {
    margin: "12px 24px 12px 24px",
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "row",
  },
  unpublishContent: {
    padding: "16px 24px 16px 19px",
    margin: "0px 24px 0px 24px",
    borderRadius: 4,
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "row",
    minHeight: 102,
  },
  extraContent: {
    padding: "16px 16px 16px 16px",
    borderRadius: 4,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
  },
  liTextStyle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.6,
    letterSpacing: 0.07,
    color: "rgba(0, 0, 0, 0.87)",
  },
  warning: {
    color: "#d32f2f",
    marginRight: 13,
    width: 20,
  },
  collectionWarning: {
    color: "#f57c00",
    marginRight: 13,
    width: 20,
  },
  actionSpacing: {
    padding: "18px 16px 18px 0px",
  },
  eventActionSpacing: {
    padding: "18px 0px !important",
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-around !important",
  },
}));
