import * as ApiConstants from 'constants/apiConstants';
import { createResumableInstance } from 'utils/uploadUtilFuctions';
// import { addTitlesToQueue, deletTitlesQueue } from '../../utils/db';
import { createNewTitleFromRowFile, createNewTitle } from './Components/utils';
import { VIDEO_STATUS_ENUM, CONTENT_STATUS_ENUM, paginationValues, RESUMABLE_TITLE_STATUS } from '../../constants/enums';
import { clearUploadSelectedRow, filterContentListfromApi, getContentTitlesBeingUploaded, setBrokenFileBeingUploaded, setContentTitleUploadRowLoading, setCurrentTagId } from './contentTitleSlice';
import { refreshTokenWithoutCallback } from '_pages/loginscreen/authSlice';
import { contentTitleListCall } from './ContentAction';
import moment from 'moment';
// fileToUpload, event, false, null,false,tag
// File,Event,isFileBroken,brokenFilesList,isReplacing,tag
const getInstance = (tag:any=false)=>{
    let URI:string = ApiConstants.uploadTitleVideoAPI;
    URI = tag && URI + tag;
    return createResumableInstance(
        null,
        null,
        URI,
        false
      );
}
const getFileInfo = (fileInfo,contentChannelData,tag,titleStatus)=>{
    const fileObj = {
        ...fileInfo,
        providerID : contentChannelData[0]?.providerId,
        provider: "",//provider : contentChannelData[0]?.providerName,
        channelId: "",//channelId : contentChannelData[0]?.tag,
        tag:tag,
        videoStatus: VIDEO_STATUS_ENUM.UPLOADING,
        status:CONTENT_STATUS_ENUM.INCOMPLETE,
        creationDate:moment().format("YYYY-MM-DDThh:mm:ss")
    }
    if(RESUMABLE_TITLE_STATUS.ERROR === titleStatus){
      fileObj.videoStatus = VIDEO_STATUS_ENUM.FAILED;
    }
    if(RESUMABLE_TITLE_STATUS.COMPLETE === titleStatus){
      fileObj.videoStatus = VIDEO_STATUS_ENUM.COMPLETED;
      fileObj.status = CONTENT_STATUS_ENUM.COMPLETE;
    }
    return fileObj;
}
export const contentUpload = (fileToUpload, event,tag,getState,dispatch)=>{
    const {layout:{contentChannelData}} = getState();
    let file = fileToUpload[0];
    const timeStamp = Date.now();
    file["identifier"] = `${timeStamp}-${file.name}`;
    let interval;
    let singleResumableInstance:any = getInstance(tag);
    let isFileAreadyInQueue = false;
    for (let i = 0; i < singleResumableInstance.files.length; i++) {
        if (singleResumableInstance.files[i] === fileToUpload?.identifier) {
        isFileAreadyInQueue = true;
        }
    }

    if (isFileAreadyInQueue === false) {
        singleResumableInstance.appendFilesFromFileList(fileToUpload, event);
    } else {
        const status = new Promise((resolve) => {
        singleResumableInstance.removeFile(fileToUpload);
        resolve("done");
        });
        status.then(() => {
        singleResumableInstance.appendFilesFromFileList(fileToUpload, event);
        });
    }
    let items = [] as any;
    let titlesListInStore = [] as any;
    // addTitlesToQueue(file, createNewTitleFromRowFile(getFileInfo(file,contentChannelData,tag)), file.name);

    for (let i = singleResumableInstance.files.length; i--; ) {
        items[i] = createNewTitle(getFileInfo(singleResumableInstance.files[i],contentChannelData,tag,RESUMABLE_TITLE_STATUS.INCOMPLETE));
    }

    let lisToDisplay = [...titlesListInStore, ...items];
    lisToDisplay = lisToDisplay.filter(
        (v, i, a) => a.findIndex((t) => t.className === v.className) === i
    );
    dispatch(getContentTitlesBeingUploaded(lisToDisplay));
    dispatch(setContentTitleUploadRowLoading({updateLoading:true,titleTag:tag}));
      singleResumableInstance.on("fileAdded", (file, event) => {
        // dispatch(setUploadStatusToTrue(true));
        if (interval) {
          clearInterval(interval);
        }
        let optionalPaylad = [] as any;
    
    
        singleResumableInstance.upload();
    
        function myCallback(ResumableInstance, isReplacing) {
          if (isReplacing === false) {
            if (window.navigator.onLine) {
              if (ResumableInstance.isUploading() === false) {
                //
                ResumableInstance.upload();
              }
              let files = ResumableInstance.files;
              let newTitleList = [] as any;
              let isAnyFileCompleted;
    
              for (let l = 0; l < files.length; l++) {
                let file = files[l];
    
                if (file.isComplete() && files.length > 1) {
                  isAnyFileCompleted = true;
                  try {
                    let storedUploadedChunkStatus = null;
                    if (storedUploadedChunkStatus) {
                      let parsedData = JSON.parse(storedUploadedChunkStatus);
                      parsedData.filter((titles) => titles.name !== file.fileName);
                    }
                    ResumableInstance.removeFile(file);
                    // removing file from ResumableJs instance
                    // deletTitlesQueue(file.fileName);
                  } catch (err) {
                    //console.log(err);
                  }
                } else {
                  const {layout:{contentChannelData}} = getState();
                  const fileObj =  {
                              ...file,
                              providerID : contentChannelData[0]?.providerId,
                              provider : contentChannelData[0]?.providerName,
                              //channelId : contentChannelData[0]?.tag,
                              tag : tag,
                              videoStatus: VIDEO_STATUS_ENUM.UPLOADING,
                              status:CONTENT_STATUS_ENUM.INCOMPLETE,
                              creationDate:moment().format("YYYY-MM-DDThh:mm:ss")
                            }
                  newTitleList.push(createNewTitle(fileObj));
                }
              }
    
              let finailListToDisplay = [...optionalPaylad, ...newTitleList];

              finailListToDisplay = finailListToDisplay.filter(
                (v, i, a) => a.findIndex((t) => t.className === v.className) === i
              );
    
              if (isAnyFileCompleted === true) {
                if (localStorage.getItem("csTenantId") !== "") {
                  dispatch(
                    filterContentListfromApi(
                      paginationValues.DAFAULT_PAGE_NUMBER,
                      paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
                      "",
                      "",
                      finailListToDisplay,
                      true,
                      localStorage.getItem("csTenantId")
                    )
                  );
                } else {
                  dispatch(
                    filterContentListfromApi(
                      paginationValues.DAFAULT_PAGE_NUMBER,
                      paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
                      "",
                      "",
                      finailListToDisplay,
                      true
                    )
                  );
                }
    
                dispatch(getContentTitlesBeingUploaded(finailListToDisplay));
                isAnyFileCompleted = false;
              } else {
                dispatch(getContentTitlesBeingUploaded(finailListToDisplay));
    
                localStorage.setItem(
                  "uploads",
                  JSON.stringify(finailListToDisplay)
                );
                localStorage.setItem(
                  "progress",
                  JSON.stringify(file.progress(true))
                );
                // localStorage.setItem("uploading", "true");
              }
            } else {
              if (ResumableInstance.isUploading() === true) {
                ResumableInstance.pause(); //
              }
            }
          }
        }
        interval = setInterval(
          myCallback,
          3000,
          singleResumableInstance,
          false
        );
      });
    
      singleResumableInstance.on("error", (message: string, file: any) => {
        //console.error("Error: There is Error in while uploading Video");
        clearInterval(interval);
        let files = singleResumableInstance.files;
        let newTitleList = [] as any;
    
        for (let l = 0; l < files.length; l++) {
          let file = files[l];
          file = getFileInfo(file,contentChannelData,tag,RESUMABLE_TITLE_STATUS.ERROR);
         // if (tag) file.tag = file;
          newTitleList.push(createNewTitle(file, true, true));
        }
        //dispatch(getContentTitlesBeingUploaded(newTitleList));
        dispatch(setBrokenFileBeingUploaded({ status: false, message: "" }));
      });
    
      singleResumableInstance.on("complete", (file) => {
          dispatch(setBrokenFileBeingUploaded({ status: false, message: "" }));
    
          let files = singleResumableInstance.files;
          try {
            let index;
            for (let i = 0; i < files?.length; i++) {
              if (files[i].file["identifier"] === file?.file["identifier"]) {
                index = i;
              }
            }
            singleResumableInstance.removeFile(
              singleResumableInstance.files[index]
            );
          } catch (err) {
            //console.log(new Date() + "File Uploaded completed :" + err);
          }
    
          let newTitleList = [] as any;
    
          for (let l = 0; l < files.length; l++) {
            let file = files[l];
            newTitleList[l] = createNewTitle(getFileInfo(file,contentChannelData,tag,RESUMABLE_TITLE_STATUS.COMPLETE));
            // newTitleList[l].contentStatus=8
          }
          // dispatch(getContentTitle(tag))
          if (singleResumableInstance?.files?.length === 0) {
            clearInterval(interval);
            localStorage.setItem("uploads", "");
            // dispatch(resetUploadStatusforNewFiles());
            dispatch(setContentTitleUploadRowLoading({updateLoading:true,titleTag:tag}));
            // dispatch(getContentTitlesBeingUploaded([]));
            dispatch(
              filterContentListfromApi(
                paginationValues.DAFAULT_PAGE_NUMBER,
                paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
                "",
                "",
                null,
                true,
                localStorage.getItem("csTenantId") ?? ""
                // currentTagId.titleTag
              ))
          }
      });
      // singleResumableInstance.on("catchAll",(eventName:string,res:any) => {
      //   if(eventName === "chunkFinished" && res === ""){
      //     let fileObj = singleResumableInstance.files[0];
      //     const shouldContentTitleCall = contentTitleListCall(fileObj);
      //     shouldContentTitleCall && dispatch(getContentTitle(tag))
      //   }
      // })
      let updateChunk = 0;
      singleResumableInstance.on("chunkFinished",(res:any) => {
        try{
          const result =  JSON.parse(res);
          if(result?.titleTag && updateChunk===0){
            updateChunk++;
            dispatch(setCurrentTagId({titleTag : result.titleTag,creationDate : result.creationDate}))
              const {
                contentTitles: { TitlesBeingUploaded, isLoading, currentTagId },
              } = getState();

            if (result.titleTag !== null) {
               //** Solved duplicate entry in listing */
               // 1. Find the TitlesBeingUploaded with the className
               const currentTtitleIndex = TitlesBeingUploaded.findIndex(
                 (titles) => titles.className === fileToUpload[0]?.name
               );

               // 2. Mark the tag n creation date as titleTag from first chunk response
               const updatedTitleBeingUpload = {
                 ...TitlesBeingUploaded[currentTtitleIndex],
                 tag: result.titleTag,
                 creationDate: result.creationDate,
               };
               // 3. Update the TitlesBeingUploaded list with the updated TitlesBeingUploaded
               const newTitlesBeingUploaded = [
                 ...TitlesBeingUploaded.slice(0, currentTtitleIndex),
                 updatedTitleBeingUpload,
                 ...TitlesBeingUploaded.slice(currentTtitleIndex + 1),
               ];
               dispatch(getContentTitlesBeingUploaded(newTitlesBeingUploaded));
             }
          }
        }catch(e){
          // console.info(e.message)
        }
      })
      singleResumableInstance.on("ChunkComplete",(file: any) => {
          let files = singleResumableInstance.files;
    
          let newTitleList = [] as any;
          for (let l = 0; l < files.length; l++) {
            let file = files[l];
            // if(file.isComplete())
            {
              const {layout:{contentChannelData},contentTitles : {currentTagId}} = getState();
              const providerInfo = {
                      providerID : contentChannelData[0]?.providerId,
                      provider : contentChannelData[0]?.providerName,
                      //channelId : contentChannelData[0]?.tag,
                      tag : currentTagId?.titleTag ?? tag,
                      creationDate : currentTagId?.creationDate ?? moment().format("YYYY-MM-DDThh:mm:ss"),
                      videoStatus: VIDEO_STATUS_ENUM.UPLOADING,
                      status:CONTENT_STATUS_ENUM.INCOMPLETE,
                    }
              const fileData = {...file,...providerInfo,};
              newTitleList[l] = createNewTitle(fileData);
            }
          }
          let displayTitlesOnChunkUpload = [...titlesListInStore, ...newTitleList];
    
          displayTitlesOnChunkUpload = displayTitlesOnChunkUpload.filter(
            (v, i, a) => a.findIndex((t) => t.className === v.className) === i
          );
       
          dispatch(getContentTitlesBeingUploaded(displayTitlesOnChunkUpload));
          localStorage.setItem("uploads", JSON.stringify(newTitleList));
          localStorage.setItem("progress", JSON.stringify(file.progress(true)));
      });
    
      singleResumableInstance.on("AuthError", (file: any, message: string) => {
        dispatch(refreshTokenWithoutCallback());
      });
    
}