import React from "react";
import { selectUser } from "_pages/loginscreen/authSlice";
import { useSelector } from "react-redux";
import { userRoleEnums } from "constants/enums";

//const AdminOnlyView = React.memo(
export function ProviderOnlyView(props) {
  const user = useSelector(selectUser);

  return (
    <React.Fragment>
      {user.role === userRoleEnums.PRVIDER_USER && props.children}
    </React.Fragment>
  );
}
export function AdminOnlyView(props) {
  const user = useSelector(selectUser);

  return (
    <React.Fragment>
      {user.role === userRoleEnums.ADMIN_USER && props.children}
    </React.Fragment>
  );
}
//);
export function CSOnlyView(props) {
  const user = useSelector(selectUser);

  return (
    <React.Fragment>
      {user.role === userRoleEnums.CS_USER && props.children}
    </React.Fragment>
  );
}
export function CSOnlyViewCheck() {
  const user = useSelector(selectUser);
  if (user.role === userRoleEnums.CS_USER) {
    return true;
  } else {
    return false;
  }
}
export function AdminOnlyViewCheck() {
  const user = useSelector(selectUser);
  if (user.role === userRoleEnums.ADMIN_USER) {
    return true;
  } else {
    return false;
  }
}
export function ProviderOnlyViewCheck() {
  const user = useSelector(selectUser);
  if (user.role === userRoleEnums.PRVIDER_USER) {
    return true;
  } else {
    return false;
  }
}
interface RoleProps {
  role: number;
  notEqual?: boolean;
  children: any;
}
export function RoleSpecificView(props: RoleProps) {
  const { role,notEqual=false } = props;
  const user = useSelector(selectUser);
  const checkRole = notEqual ? user.role !== role : user.role === role;
  return (
    <React.Fragment>{checkRole && props.children}</React.Fragment>
  );
}
export default AdminOnlyView;
