import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { apiResponseEnums, USER_MESSAGES } from "constants/enums";
import {
  BulkUploadModel,
  GetApiBulkUploadPayload,
  FetchBulkUploadData
} from "models/BulkUpload/BulkUploadModel";
import { ApiResponse } from "apisauce";
import { setApiResponse } from "Layout/layoutSlice";
import {
  getCustomAPIErrorMessage,
  handleUnauthorized,
} from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";
import { History } from "history";
import { bulkUploadPath } from "constants/routeConstants";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
interface BulkUploadLinksState {
  submitLoading: boolean;
  dataLoading: boolean;
  bulkUploadData: BulkUploadModel | null;
  refreshBulkUploadData: FetchBulkUploadData | null;
  processError: boolean;
  processStopLoading: boolean;
}

const initialState: BulkUploadLinksState = {
  submitLoading: false,
  dataLoading: true,
  bulkUploadData: null,
  refreshBulkUploadData: null,
  processError: false,
  processStopLoading: false,
};

export const BulkUploadSlice = createSlice({
         name: "bulkUpload",
         initialState,
         reducers: {
           setSubmitLoading: (state, action: PayloadAction<boolean>) => {
             state.submitLoading = action.payload;
           },
           setDataLoading: (state, action: PayloadAction<boolean>) => {
             state.dataLoading = action.payload;
           },
           setProcessStopLoading: (state, action: PayloadAction<boolean>) => {
             state.processStopLoading = action.payload;
           },
           setBulkUploadData: (
             state,
             action: PayloadAction<BulkUploadModel | null>
           ) => {
             state.bulkUploadData = action.payload;
           },
           setRefreshBulkUploadData: (
             state,
             action: PayloadAction<FetchBulkUploadData | null>
           ) => {
             state.refreshBulkUploadData = action.payload;
             //  state.refreshBulkUploadData = {
             //    id: 2,
             //    title: null,
             //    notificationEmail: null,
             //    status: null,
             //    filesCompleted: 0,
             //    filesToProcess: 0,
             //    filesFailed: 0
             //  };
           },
           resetRefreshBulkUploadData: (
             state,
             action: PayloadAction<FetchBulkUploadData | null>
           ) => {
             state.refreshBulkUploadData = initialState.refreshBulkUploadData;
           },
           setProcessError: (state, action: PayloadAction<boolean>) => {
             state.processError = action.payload;
           },
         },
       });

export const {
  setSubmitLoading,
  setDataLoading,
  setBulkUploadData,
  setRefreshBulkUploadData,
  resetRefreshBulkUploadData,
  setProcessError,
  setProcessStopLoading
} = BulkUploadSlice.actions;

export const stopBulkUpload = (data,  callback: () => void): AppThunk => (
  dispatch,
  getState
) => {
  dispatch(setProcessStopLoading(true));
  userApi
    .post(`${ApiConstants.bulkUploadStopAPI}`, data)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.BULK_UPLOAD_STOP,
          })
        );
        dispatch(setProcessStopLoading(false));
        callback()
      } else {
        const callback401 = () =>
        dispatch(regfeshTokenId(stopBulkUpload(data, callback)));
        handleUnauthorized(res.status as number, callback401);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const addBulkUpload = (data, history: History): AppThunk => (
  dispatch,
  getState
) => {
  dispatch(setProcessStopLoading(true));
  userApi
    .post(`${ApiConstants.bulkUploadAddAPI}`, data)
    .then((res: ApiResponse<any>) => {
      if (
        res.status === apiResponseEnums.OKAY &&
        res.status !== apiResponseEnums.NOT_IMPLEMENTED
      ) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.BULK_UPLOAD_PROCESS,
          })
        );
        dispatch(setProcessStopLoading(false));
        let payload = { tenantId: data.tenantId, id: res.data["batchId"] };
        dispatch(
          fetchBulkUpload(payload, () => {
            history.push(`${bulkUploadPath}/${res.data["batchId"]}/edit`);
          })
        );
      } else if (res.status === apiResponseEnums.OKAY && res.status === apiResponseEnums.NOT_IMPLEMENTED) {
        dispatch(setProcessError(true));
        dispatch(setProcessStopLoading(false));
        history.push(`${bulkUploadPath}/${res.data["batchId"]}/edit`);
      } else {
        const callback = () =>
          dispatch(
            regfeshTokenId(
              addBulkUpload(data,history)
            )
          );
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};
export const updateBulkUpload = (data, history: History): AppThunk => (
  dispatch,
  getState
) => {
  dispatch(setProcessStopLoading(true));
  userApi
    .post(`${ApiConstants.bulkUploadFetchAPI}`, data)
    .then((res: ApiResponse<any>) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        history.push(`${bulkUploadPath}/${res.data["BulkUploadId"]}/edit`);
        dispatch(setProcessStopLoading(false));
      } else {
        const callback = () =>
        dispatch(regfeshTokenId(updateBulkUpload(data, history)));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};
export const fetchBulkUpload = (data, callback: () => void): AppThunk => (dispatch, getState) => {
  dispatch(setSubmitLoading(true));
  userApi
    .post(`${ApiConstants.bulkUploadFetchAPI}`, data)
    .then((res: ApiResponse<any>) => {
      dispatch(setSubmitLoading(false));
      if (res.status === apiResponseEnums.OKAY) {
//         dispatch(setRefreshBulkUploadData( {
//                id: 0,
//                title: null,
//                notificationEmail: null,
//                status: null,
//                filesCompleted: 0,
//                filesToProcess: 2,
//                filesFailed: 0}
// ));
       dispatch(setRefreshBulkUploadData(res.data));
        callback();
      } else {
        const callback401 = () =>
        dispatch(regfeshTokenId(fetchBulkUpload(data, callback)));
        handleUnauthorized(res.status as number, callback401);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
      
    });
};
export const getBulkUploadData = (body: GetApiBulkUploadPayload): AppThunk => (
  dispatch
) => {
  dispatch(setDataLoading(true));
  dispatch(setBulkUploadData(null));
  userApi
    .post(ApiConstants.bulkUploadListAPI, body)
    .then((res: ApiResponse<any>) => {  
      dispatch(setDataLoading(false));
      if (
        res.status === apiResponseEnums.OKAY &&
        res?.data?.status !== apiResponseEnums.NOT_IMPLEMENTED
      ) {
        dispatch(setBulkUploadData(res.data as BulkUploadModel));
      } else if (
        res.status === apiResponseEnums.OKAY &&
        res?.data?.status === apiResponseEnums.NOT_IMPLEMENTED
      ) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: apiResponseEnums.INTERNAL_ERROR as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      } else {
        const callback = () =>
          dispatch(regfeshTokenId(getBulkUploadData(body)));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }

       
    });
};

export const submitLoading = (state: RootState) =>
  state.bulkUpload.submitLoading;

export const bulkUploadDataLoading = (state: RootState) =>
  state?.bulkUpload?.dataLoading;

export const bulkUploadData = (state: RootState) =>
  state?.bulkUpload?.bulkUploadData;

export const refreshBulkUploadData = (state: RootState) =>
         state?.bulkUpload?.refreshBulkUploadData;

export const bulkUploadProcessError = (state: RootState) =>
         state?.bulkUpload?.processError;

export const isProcessStopLoading = (state: RootState) =>
         state?.bulkUpload?.processStopLoading;
           
export default BulkUploadSlice.reducer;
