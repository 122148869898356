import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { dataURLtoFile } from "utils/utilityFunctions";
import {
  termsCreatedStatus,
  uploadTermsApi,
  getTenantsTermsListfromApi,
} from "_pages/tenants/tenantSlice";

export const useTenantUploadHook = (props) => {
  // const [isButtonDisabled, setButtonStatus] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([] as any);
  const [error, setError] = useState(null as any);
  const dispatch = useDispatch();
  const termsCreationStatus = useSelector(termsCreatedStatus);
  const [isReplaceButtonDisabled, setReplaceButtonStatus] = useState(false);
  const { supportedLanguages, selectedTenant } = props;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (files.length !== 0) handelForm();
  }, [files])
  
  useEffect(() => {
    if (termsCreationStatus) {
      setLoading(false);
      setReplaceButtonStatus(true);
      history.push(`/tenants/siteterms/${selectedTenant.tenantId}`);
      dispatch(getTenantsTermsListfromApi());
    }
  }, [termsCreationStatus]);
  const handelForm=()=>{
    let inValid;
    if (files.length === 0){
      inValid = true;
    }else{
      if(supportedLanguages !== null && files.length === supportedLanguages?.length){
        inValid = false;
      }else{
        inValid = true;
      }
    }
    setError(inValid);
    return inValid;
  }

  const submitForm = (e) => {
    const isValid=handelForm();
    if(isValid){return false;}
    if (supportedLanguages != null) {
      if (files.length == supportedLanguages?.length) {
        // setButtonStatus(false);
        setError(false);
        const data = Object.assign({}, ...files);
        delete data.file_id;
        delete data.file_name;
        delete data.uploaded_file;

        const formData = new FormData();
        formData.append("Title", "Terms & Conditions");
        formData.append("PolicyType", "tnc");

        supportedLanguages?.map(function(item, index) {
          let filekey = "file_" + item;
          let filenamekey = "filename_" + item;
          formData.append(
            filekey,
            dataURLtoFile(files[index][filekey], files[index][filenamekey])
          );
        });
        dispatch(uploadTermsApi(formData, selectedTenant.tenantId));
      }
    }
  };
  return {
    files,
    setFiles,
    error,
    isReplaceButtonDisabled,
    isLoading,
    submitForm,
    // isButtonDisabled,
  };
};
