import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import {
  userApi,
  streamingLinkApiHeaderTransform,
  streamingLinkApi,
  tenantHeaderTransform,
  tenantHeaderApi
} from "api/api";
import * as ApiConstants from "constants/apiConstants";
import {
  API_ERROR_TOAST_DURATION,
  API_ERRORS_MESSAGES,
  EVENT_TYPE_VALUES,
  MEDIA_TYPE_FILTER_VALUES,
  SORT_ITEMS,
  STREAMING_PLATFORM_LIVE,
  TIME_CONSTANTS,
  USER_MESSAGES,
} from "constants/enums";

import {
  getIntensityEnums,
  getSkillLableEnums,
  fetchDataAfterFiveMinutes,
  setSessionCalendarData,
  getWeekDaysUTC,
  getCustomAPIErrorMessage,
  handleUnauthorized,
  getLanguageFromConfig,
} from "utils/utilityFunctions";
import {
  refreshTokenWithoutCallback,
  regfeshTokenId,
} from "_pages/loginscreen/authSlice";
import { setVideoMetaDataFormStatus } from "_pages/contentTitles/contentTitleSlice";
import { isEmpty, keys } from "lodash";
import {
  apiResponseEnums,
  localStorageEventVideos,
  localStorageEventChunkStatus,
  paginationValues,
} from "constants/enums";

import {
  createResumableInstance,
  exractEventBrokenFileStatusFromLocalStorage,
  exractEventBrokenFileChunksFromLocalStorage,
  deleteAssociatedDataFromBrowser,
  checkIfEventsExists,
} from "utils/uploadUtilFuctions";
import {
  addEventsToQueue,
  getQueuedEvents,
  removeEventFromQueue,
} from "utils/db";
import moment from "moment";
import { setApiResponse } from "Layout/layoutSlice";
import { ErrorObjectType } from "models/common.model";
interface ScheduleEventSlice {
  eventsData: any;
  isEventCreated: boolean;
  isEventDeleted: boolean;
  isLoading: any;
  isEventKeyCreated: boolean;
  error: string;
  eventStatus: any;
  eventKey: any;
  videoUploadProgress: Array<any>;
  errorInVideoUpload: any;
  TrailerUploadProgress: any;
  existTabData: any;
  preventEventVideoUpload: boolean;
  isAnotheBorkenUploadGoingOn: boolean;
  isBackGroundFetchEnableForEvents: boolean;
  eventsCalendarData: any;
  chooseClassData: any;
  favouriteClassData: any;
  isLoadingFav: any;
  isFavUnFavMark: any;
  streamingLink: any;
  errorInStreamingLink: any;
  streamingLinkLoading: boolean;
  calFilteredData: any;
  isLoadingCal: any;
}

const initialState: ScheduleEventSlice = {
  eventsData: [],
  isEventCreated: false,
  isEventDeleted: false,
  isEventKeyCreated: false,
  error: "",
  isLoading: true,
  eventStatus: "",
  eventKey: "",
  videoUploadProgress: [],
  errorInVideoUpload: {},
  TrailerUploadProgress: {},
  existTabData: {},
  preventEventVideoUpload: false,
  isAnotheBorkenUploadGoingOn: false,
  isBackGroundFetchEnableForEvents: false,
  eventsCalendarData: [],
  chooseClassData: [],
  favouriteClassData: [],
  isLoadingFav: true,
  isFavUnFavMark: false,
  streamingLink: "",
  errorInStreamingLink: "",
  streamingLinkLoading: true,
  calFilteredData: {
    showCalendar: false,
    activeStatus: { label: "All", value: "" },
    eventType: { label: "All", value: "" },
  },
  isLoadingCal: true,
};

let SCHEDULE_EVENT_RESUMABLE_INSTANCE = null as any;
let SCHEDULE_EVENT_CANCEL_ALL_UPLOADS = false;

export const ScheduleEventSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<any>) => {
      state.eventsData = action.payload;
    },
    setScheduleTabData: (state, action: PayloadAction<any>) => {
      state.existTabData = action.payload;
    },
    setCalendarEvents: (state, action: PayloadAction<any>) => {
      state.eventsCalendarData = action.payload;
    },
    setChooseClassData: (state, action: PayloadAction<any>) => {
      state.chooseClassData = action.payload;
    },
    setFavouriteClassData: (state, action: PayloadAction<any>) => {
      state.favouriteClassData = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    setFavLoading: (state, action: PayloadAction<any>) => {
      state.isLoadingFav = action.payload;
    },
    setFavUnFavMark: (state, action: PayloadAction<any>) => {
      state.isFavUnFavMark = action.payload;
    },
    setTrailerProgess: (state, action: PayloadAction<any>) => {
      state.TrailerUploadProgress = action.payload;
    },
    eventCreated: (state, action: PayloadAction<any>) => {
      state.isEventCreated = action.payload;
    },
    eventKeyCreated: (state, action: PayloadAction<any>) => {
      state.isEventKeyCreated = action.payload;
    },
    eventDeleted: (state, action: PayloadAction<any>) => {
      state.isEventDeleted = action.payload;
    },
    errorInEventCreation: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    updateEventStatus: (state, action: PayloadAction<any>) => {
      state.eventStatus = action.payload;
    },
    updateEventKey: (state, action: PayloadAction<any>) => {
      state.eventKey = action.payload;
    },
    setVideoUploadProgress: (state, action: PayloadAction<any>) => {
      state.videoUploadProgress = action.payload;
    },
    setErrorInTrailorUploads: (state, action: PayloadAction<any>) => {
      state.errorInVideoUpload = action.payload;
    },
    setPreventVideoUpload: (state, action: PayloadAction<any>) => {
      state.preventEventVideoUpload = action.payload;
    },
    setAnotherBrokenUploadStatus: (state, action: PayloadAction<any>) => {
      state.isAnotheBorkenUploadGoingOn = action.payload;
    },
    setStreamingLinkLoading: (state, action: PayloadAction<any>) => {
      state.streamingLinkLoading = action.payload;
    },
    getStreamingLink: (state, action: PayloadAction<any>) => {
      let response = action.payload;
      state.streamingLink = response?.streamingLink?.streamingLink || "";
    },
    errorInGetStreamingLink: (state, action: PayloadAction<any>) => {
      state.errorInStreamingLink = action.payload;
    },
    setFilterDataIntoStorage: (state, action: PayloadAction<any>) => {
      state.calFilteredData = action.payload;
    },
    setResetFilterData: (state, action: PayloadAction<any>) => {
      state.calFilteredData = action.payload;
    },
    setCalLoading: (state, action: PayloadAction<any>) => {
      state.isLoadingCal = action.payload;
    },
  },
});

export const {
  setAnotherBrokenUploadStatus,
  setPreventVideoUpload,
  setTrailerProgess,
  setLoading,
  setEvents,
  setScheduleTabData,
  updateEventKey,
  eventKeyCreated,
  eventCreated,
  eventDeleted,
  errorInEventCreation,
  updateEventStatus,
  setVideoUploadProgress,
  setErrorInTrailorUploads,
  setCalendarEvents,
  setChooseClassData,
  setFavouriteClassData,
  setFavLoading,
  setFavUnFavMark,
  getStreamingLink,
  errorInGetStreamingLink,
  setStreamingLinkLoading,
  setFilterDataIntoStorage,
  setResetFilterData,
  setCalLoading,
} = ScheduleEventSlice.actions;

export const showErrorPop = (): AppThunk => (dispatch) => {
  dispatch(setPreventVideoUpload(false));
};
export const generateEventKey = (data: any, astTableConfig: any): AppThunk => (
  dispatch
) => {
  dispatch(eventKeyCreated(false));
  dispatch(updateEventKey({ key: "" }));
  let weekday = getDayOfWeek(data.dateTimeInUTC);
  let body = {
    event_Id: data.event_Id,
    title: data.title,
    description: data.description,
    imageUrl: data.cover,
    active: data.active,
    schedule_Time: data.dateTimeInUTC.toISOString(),
    type: data.type,
    schedule: {
      type: 1,
      weekdays: [weekday],
      daily_time: data.dateTimeInUTC.toISOString(),
    },
    tenantId: data.tenantId,
    video: data.video ? vidoePayloadCreator(data.video, data.type) : null,
    allowAutoPublish: data.allowAutoPublish,
  };

  userApi
    .post(`${ApiConstants.generateEventKey}`, {
      ...body,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(eventKeyCreated(true));
        dispatch(updateEventKey({ key: res.data }));
        setTimeout(function() {
          dispatch(eventKeyCreated(false));
        }, 1500);
      } else {
        dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_400));
        setTimeout(function() {
          dispatch(errorInEventCreation(""));
        }, TIME_CONSTANTS.T3s);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const addEvent = (
  data: any,
  tenantId: any,
  lastTableConfig: any,
  tenantData
): AppThunk => (dispatch) => {
  dispatch(eventCreated(false));

  if (data.type == 2 && data.eventKey == "") {
    dispatch(eventKeyCreated(false));
    dispatch(updateEventKey({ key: "" }));
  }
  const weekDays = getWeekDaysUTC(
    data.dateTimeInUTC,
    data.weekDays,
    tenantData
  );
  const daysInUtc = new Set([...weekDays, moment(data.dateTimeInUTC).day()]);
  let body: any = {
    title: data.title,
    description: data.description,
    category: data.category,
    durationSecond: parseInt(data.durationSecond),
    active: !!data.active,
    schedule_time: data.dateTimeInUTC.toISOString(),
    type: data.type,
    schedule: {
      type: 1,
      weekdays: Array.from(daysInUtc),
      daily_time: data.dateTimeInUTC.toISOString(),
    },
    tenantId: tenantId,
    imageUrl: data.cover,
    allowAutoPublish:
      data.eventSource === STREAMING_PLATFORM_LIVE.EXTERNAL
        ? false
        : data.allowAutoPublish,
    liveStreamingLink:
      data.eventSource === STREAMING_PLATFORM_LIVE.EXTERNAL
        ? data.liveStreamingLink
        : "",
  };

  if (data.type === EVENT_TYPE_VALUES.LIVE) {
    body.eventSource = data.eventSource;
    if (data.eventSource === STREAMING_PLATFORM_LIVE.EXTERNAL) {
      body.allowAutoPublish = false;
      body.liveStreamingLink = data.liveStreamingLink;
      body.video = null;
    }
  }

  userApi
    .post(`${ApiConstants.addEventAPI}`, {
      ...body,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.CREATED) {
        dispatch(eventKeyCreated(true));
        dispatch(eventCreated(true));
        dispatch(updateEventStatus({ status: res.data }));
        if (data.type == 2 && data.eventKey == "") {
          dispatch(
            updateEventKey({
              key: { eventKey: res.data.eventKey, eventId: res.data.event_Id },
            })
          );
          dispatch(
            setApiResponse({
              status: res.status,
              data: USER_MESSAGES.SAVED,
            })
          );
          setTimeout(function() {
            dispatch(eventKeyCreated(false));
          }, 1500);
        }
      } else {
        dispatch(setEvents({ eventsData: [] }));
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        setTimeout(function() {
          dispatch(errorInEventCreation(""));
        }, TIME_CONSTANTS.T3s);
      }
    });
};
export const resetEvents = (): AppThunk => (dispatch) => {
  dispatch(setEvents({ eventsData: [] }));
  dispatch(setLoading(false));
};

export const getScheduleEvents = (
  tenantid: any,
  lastTableConfig: any,
  showShimmer: boolean = true,
  page: number = paginationValues.DAFAULT_PAGE_NUMBER,
  items: number = paginationValues.DEFAULT_PAGE_SIZE
): AppThunk => (dispatch: any, getState: any) => {
  const { eventTitles, sortTable } = getState();
  if (showShimmer) {
    dispatch(setEvents({ eventsData: [] }));
  }
  dispatch(setLoading(true));
  let url;
  let params = "";
  if (sortTable.pageName === "/schedule") {
    if (sortTable.isDefault === false) {
      const sortItem =
        sortTable.index < 3
          ? SORT_ITEMS.created_date
          : SORT_ITEMS.next_occurrence_time;
      params = `sort=${sortItem}&dir=${sortTable.sort}`;
    } else {
      if (
        keys(sortTable?.existedSort).length > 0 &&
        sortTable?.existedSort["/schedule"]
      ) {
        const sortInfo = sortTable.existedSort["/schedule"];
        const sortItem =
          sortInfo.index < 3
            ? SORT_ITEMS.created_date
            : SORT_ITEMS.next_occurrence_time;
        params = `sort=${sortItem}&dir=${sortInfo.sort}`;
      } else
        params = `sort=${SORT_ITEMS.next_occurrence_time}&dir=${sortTable.sort}`;
    }
  }
  if (items == -1) {
    // code for All events data
    url = `${ApiConstants.getEventsAPI}${tenantid}?${params}`;
  } else {
    url = `${ApiConstants.getEventsAPI}${tenantid}?page=${page}&take=${items}&${params}`;
  }

  userApi.get(url).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      const currEventId = eventTitles?.eventStatus?.status?.event_Id;
      const currEventData = res?.data?.data.find(
        (i) => i.event_Id === currEventId
      );
      if (currEventData) {
        dispatch(updateEventStatus({ status: currEventData }));
      }
      dispatch(setEvents({ eventsData: res.data }));
      let newVideoProgress = checkIfEventsExists(res.data);
      dispatch(setVideoUploadProgress(newVideoProgress));
      if (newVideoProgress.length === 0) {
        dispatch(setPreventVideoUpload(false));
      }

      //Rajneesh check if Encoding is goingon for any vide
      //
      const dispatchCllabck = () => {
        dispatch(
          getScheduleEvents(tenantid, lastTableConfig, false, page, items)
        );
        if (
          currEventData &&
          currEventData.type === EVENT_TYPE_VALUES.SIMULATED &&
          currEventData.video
        ) {
          dispatch(
            getStreamingLinkfromApi(
              currEventData?.video?.providerID,
              currEventData?.event_Id,
              tenantid,
              "event"
            )
          );
        }
      };
      if (isEmpty(eventTitles?.existTabData))
        if (!eventTitles?.calFilteredData?.showCalendar) {
          fetchDataAfterFiveMinutes(dispatchCllabck, res.data);
        }
    } else if (res.status === apiResponseEnums.UNAUTHRISED) {
      dispatch(
        regfeshTokenId(
          getScheduleEvents(tenantid, lastTableConfig, true, page, items)
        )
      ); //Todo: Need to check
    } else {
      const callback = () =>
        dispatch(
          regfeshTokenId(
            getScheduleEvents(tenantid, lastTableConfig, true, page, items)
          )
        );
      handleUnauthorized(res.status, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setEvents({ eventsData: [] }));
      dispatch(errorInEventCreation(res.status));
      setTimeout(function() {
        dispatch(errorInEventCreation(""));
      }, TIME_CONSTANTS.T3s);
    }
    dispatch(setLoading(false));
  });
};
export const getCalendarEvents = (
  tenantid: any,
  eventStatus: any,
  eventType: any
): AppThunk => (dispatch) => {
  dispatch(setCalLoading(true));
  let url = `${ApiConstants.getEventsAPI}${tenantid}?eventStatus=${eventStatus}&eventType=${eventType}`;
  userApi.get(url).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setCalendarEvents({ eventsCalendarData: res.data }));
    } else {
      const callback = () =>
        dispatch(
          regfeshTokenId(getCalendarEvents(tenantid, eventStatus, eventType))
        );
      handleUnauthorized(res.status, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setCalendarEvents({ eventsCalendarData: [] }));
      dispatch(errorInEventCreation(res.status));
      setTimeout(function() {
        dispatch(errorInEventCreation(""));
      }, TIME_CONSTANTS.T3s);
    }
    dispatch(setCalLoading(false));
  });
};
/**
 * Post api for set favorite and remove favorite api
 * @param tenantId 
 * @param contentId -it's a class tag id 
 * @param Action  -   // 1 - Favourite
        // 2 - Unfavourite
 * @returns 
 */
export const setFavouriteClass = (
  tenantId: any,
  contentId: any,
  Action: any
): AppThunk => (dispatch) => {
  let body = {
    TenantId: tenantId,
    ContentId: contentId,
    Action: Action,
  };
  try {
    userApi
      .post(`${ApiConstants.getFavouritesClass}`, {
        ...body,
      })
      .then((res: any) => {
        if (
          res.status === apiResponseEnums.OKAY ||
          res.status === apiResponseEnums.DELETED
        ) {
          dispatch(setFavUnFavMark(true));
          setTimeout(function() {
            dispatch(setFavUnFavMark(false));
          }, 1500);
        } else {
          const callback = () =>
            dispatch(
              regfeshTokenId(setFavouriteClass(tenantId, contentId, Action))
            );
          handleUnauthorized(res.status, callback);
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage({
                status: res.status as number,
                data: res.data as ErrorObjectType,
              }),
            })
          );
          dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_400));
          setTimeout(function() {
            dispatch(errorInEventCreation(""));
          }, TIME_CONSTANTS.T3s);
        }
      });
  } catch (err) {
    dispatch(errorInEventCreation(err));
    dispatch(setFavUnFavMark(false));
  }
};
export const getFavouriteClass = (
  tenantId: any,
  lastTableConfig: any,
  showShimmer: boolean = true
): AppThunk => (dispatch) => {
  if (showShimmer) {
    dispatch(setFavouriteClassData({ favouriteClassData: [] }));
  }
  dispatch(setFavLoading(true));
  let url: any;
  if (tenantId != "") {
    // code for events with tenantId
    url = `${ApiConstants.getFavouritesClass}/${tenantId}`;
  } else {
    url = `${ApiConstants.getFavouritesClass}`;
  }
  try {
    userApi.get(url).then((res: any) => {
      if (
        res.status === apiResponseEnums.OKAY ||
        res.status === apiResponseEnums.DELETED
      ) {
        dispatch(setFavouriteClassData({ favouriteClassData: res.data }));
      } else {
        const callback = () =>
          dispatch(
            regfeshTokenId(
              getFavouriteClass(tenantId, lastTableConfig, showShimmer)
            )
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        dispatch(setFavouriteClassData({ favouriteClassData: [] }));
        dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_400));
        setTimeout(function() {
          dispatch(errorInEventCreation(""));
        }, TIME_CONSTANTS.T3s);
      }
      dispatch(setFavLoading(false));
    });
  } catch (err) {
    dispatch(errorInEventCreation(err));
    dispatch(setFavLoading(false));
  }
};
export const getScheduleChooseClass = (
  tenantid: any,
  lastTableConfig: any,
  showShimmer: boolean = true,
  page: number = paginationValues.DAFAULT_PAGE_NUMBER,
  items: number = paginationValues.DEFAULT_PAGE_SIZE,
  data?: any,
  filterData?: any
): AppThunk => (dispatch) => {
  dispatch(setFavLoading(true));
  if (showShimmer) {
    dispatch(setChooseClassData({ chooseClassData: [] }));
  }
  let url;
  if (items == -1) {
    // code for All events data
    url = `${ApiConstants.getSchedulechooseClass}`;
  } else {
    url = `${ApiConstants.getSchedulechooseClass}?page=${page}&take=${items}`;
  }
  let body = {
    query: data,
    ...filterData,
    contentType: MEDIA_TYPE_FILTER_VALUES.Video,
    Tenant: tenantid,
    take: 9999,
  };
  try {
    userApi
      .post(url, {
        ...body,
      })
      .then((res: any) => {
        if (res.status === apiResponseEnums.OKAY) {
          dispatch(setChooseClassData({ chooseClassData: res.data }));
        } else {
          const callback = () =>
            dispatch(
              regfeshTokenId(
                getScheduleChooseClass(
                  tenantid,
                  lastTableConfig,
                  true,
                  page,
                  items
                )
              )
            );
          handleUnauthorized(res.status, callback);
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage({
                status: res.status as number,
                data: res.data as ErrorObjectType,
              }),
            })
          );
          dispatch(setChooseClassData({ chooseClassData: [] }));
          dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_400));
          setTimeout(function() {
            dispatch(errorInEventCreation(""));
          }, TIME_CONSTANTS.T3s);
        }
        dispatch(setFavLoading(false));
      });
  } catch (err) {
    dispatch(errorInEventCreation(err));
    dispatch(setFavLoading(false));
  }
};

export const addEventVideoToUploadQueue = (
  File: any,
  Event: any,
  tag: string,
  isReplacing: boolean
): AppThunk => (dispatch, getState) => {
  //Rajneesh Raghav
  //flow of uploading video in event sectio is different from content titles,
  // as user will select the file it will go to db first and will create an indexed Queue
  // when (n)th file is completed then only new Resumable instance will be created for (n+1)th file

  const { videoUploadProgress } = getState().eventTitles;
  //merging state with old uploads
  const timeStamp = Date.now();
  const identifier = `${timeStamp}-${File[0].name}`;
  let preventFileUpload = false;

  if (videoUploadProgress.length > 0) {
    let checkIfAnyFileWasBroken = videoUploadProgress.find(
      (item) => item.isBroken === true
    );
    if (checkIfAnyFileWasBroken) {
      preventFileUpload = true;
    }
    if (preventFileUpload === false) {
      dispatch(
        setVideoUploadProgress([
          ...videoUploadProgress,
          { progress: 0, tag: tag },
        ])
      );
    }
  } else {
    dispatch(setVideoUploadProgress([{ progress: 0, tag: tag }]));
    dispatch(
      resumableVideoUploader(File, Event, tag, isReplacing, false, identifier)
    );
  }

  if (preventFileUpload === false) {
    localStorage.setItem(
      localStorageEventVideos,
      JSON.stringify([...videoUploadProgress, { progress: 0, tag: tag }])
    );
    addEventsToQueue(File, tag, isReplacing, identifier);
  } else {
    dispatch(setPreventVideoUpload(true));
  }
};

export const uploadBrokenEventVideo = (
  tag: string,
  e,
  isAnotheBorkenUploadGoingOn
): AppThunk => (dispatch, getState) => {
  async function takeEvents() {
    let remainingEvents = await getQueuedEvents();
    return remainingEvents;
  }

  //merging state with old uploads

  takeEvents().then((items: any) => {
    let eventToUpload = items.find((item) => item.event_id === tag);
    if (eventToUpload) {
      if (isAnotheBorkenUploadGoingOn === true) {
        let brokenUploadStatus = exractEventBrokenFileStatusFromLocalStorage(
          null,
          tag,
          true,
          0
        );
        dispatch(setVideoUploadProgress(brokenUploadStatus));
      } else {
        dispatch(setAnotherBrokenUploadStatus(true));
        dispatch(
          resumableVideoUploader(
            eventToUpload.file,
            e,
            tag,
            eventToUpload.isReplacing,
            true,
            eventToUpload.identifier
          )
        );
      }
    }
  });
};

export const resumableVideoUploader = (
  File: any,
  Event: any,
  tag: string,
  isReplacing: boolean,
  isFileBroken: boolean = false,
  identifier: string
): AppThunk => (dispatch, getState) => {
  let singleResumableInstance;

  File[0]["identifier"] = identifier;
  File[0]["tag"] = tag;

  const URI = ApiConstants.uploadTitleVideoAPI + `event/${tag}`;
  let resumableInstances = [] as any;
  let lastBrokenChunk = 0;

  let chunksFromLocalStorage = localStorage.getItem(
    localStorageEventChunkStatus
  );
  if (chunksFromLocalStorage) {
    let chunks = JSON.parse(chunksFromLocalStorage);
    let brokenFileStutus = chunks.find((chunk) => chunk.tag === tag);
    if (brokenFileStutus) {
      lastBrokenChunk = brokenFileStutus.uploadingChunkNumber;
    }
  }
  singleResumableInstance = createResumableInstance(
    File,
    Event,
    URI,
    isReplacing
  );
  SCHEDULE_EVENT_RESUMABLE_INSTANCE = singleResumableInstance;

  let interval;

  singleResumableInstance.appendFilesFromFileList(File, Event);

  singleResumableInstance.on("fileAdded", (file, event) => {
    if (isFileBroken === true) {
      let chunks = file.chunks as any;
      if (chunks) {
        for (let i = 0; i < lastBrokenChunk; i++) {
          if (chunks[i]) {
            chunks[i]["preprocessState"] = 2;
          }
        }
      }
    } else {
      exractEventBrokenFileChunksFromLocalStorage(file, tag);
    }

    singleResumableInstance.upload();

    function myCallback(ResumableInstance) {
      let file = ResumableInstance[0].files[0];
      let localTag = ResumableInstance[1];

      const newVideoUploadProgress = exractEventBrokenFileStatusFromLocalStorage(
        file,
        localTag
      );
      dispatch(setVideoUploadProgress(newVideoUploadProgress));
    }
    interval = setInterval(myCallback, 3000, [singleResumableInstance, tag]);
  });
  singleResumableInstance.on("AuthError", (file: any, message: string) => {
    dispatch(refreshTokenWithoutCallback());

    let userStatus = localStorage.getItem("userChanged");

    if (userStatus === "true") {
      singleResumableInstance.cancel();
    }
  });

  singleResumableInstance.on("error", (file: any, message: string) => {
    /**
     * file cancelled toast coming so not needed and commented
     */
    //setErrorInTrailorUploads({ error: "Error : file Not Uploaded" });

    dispatch(
      getScheduleEvents(
        localStorage.getItem("tenantId"),
        "",
        true,
        paginationValues.DEFAULT_PAGE_SIZE,
        -1
      )
    );
  });

  singleResumableInstance.on("complete", (file) => {
    async function onCompleteCallback() {
      let remainingEvents = await getQueuedEvents();
      return remainingEvents;
    }
    if (SCHEDULE_EVENT_CANCEL_ALL_UPLOADS === false) {
      onCompleteCallback().then((events: any) => {
        if (events && events.length > 0) {
          const newVideoUploadProgress = exractEventBrokenFileStatusFromLocalStorage(
            file,
            tag,
            true
          );

          singleResumableInstance.removeFile(file);
          exractEventBrokenFileChunksFromLocalStorage(file, tag, 0, true);

          let indexOfCurrentEvent = events.findIndex(
            (item) => item.event_id === tag
          );
          let nextEvent = indexOfCurrentEvent + 1;

          if (indexOfCurrentEvent !== -1 && events[nextEvent] !== undefined) {
            dispatch(
              resumableVideoUploader(
                events[nextEvent].file,
                Event,
                events[nextEvent].event_id,
                events[nextEvent].isReplacing,
                false,
                events[nextEvent].identifier
              )
            );
          }

          dispatch(
            getScheduleEvents(
              localStorage.getItem("tenantId"),
              "",
              true,
              paginationValues.DEFAULT_PAGE_SIZE,
              -1
            )
          );

          removeEventFromQueue(events[indexOfCurrentEvent].event_id);
          dispatch(setVideoUploadProgress(newVideoUploadProgress));
          localStorage.setItem(
            localStorageEventVideos,
            JSON.stringify(newVideoUploadProgress)
          );
        }
        clearInterval(interval);
      });
    } else {
      SCHEDULE_EVENT_CANCEL_ALL_UPLOADS = false;
    }
  });

  singleResumableInstance.on("ChunkComplete", (file: any) => {
    let userStatus = localStorage.getItem("userChanged");

    if (userStatus === "true") {
      singleResumableInstance.cancel();
      localStorage.setItem("userChanged", "false");
      return;
    }

    let fileObj = file.resumableObj.files[0];
    let chunks = fileObj.chunks;

    if (chunks) {
      for (let i = 0; i < chunks.length; i++) {
        let status = chunks[i].status();
        if (status === "uploading") {
          exractEventBrokenFileChunksFromLocalStorage(file, tag, i, false);
        }
      }
    }
  });
};

export const deleteEvent = (data: any, tenant, e, item, page): AppThunk => (
  dispatch
) => {
  dispatch(eventDeleted(false));
  let url = `${ApiConstants.deleteEventAPI}`;
  tenantHeaderTransform(tenant);
  tenantHeaderApi
    .delete(
      url,
      {},
      {
        data: data,
      }
    )
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        let successCount = 0;
        let failedCount = 0; 
        res.data.forEach(i => {
          if (i.item2 === 200) {
            successCount++;
          } else {
            failedCount++;
          }
        });
       
        if (failedCount && successCount) {
          dispatch(
            setApiResponse({
              status: res.status,
              data: `${successCount}${USER_MESSAGES.DELETE_PREFIX}${failedCount}${USER_MESSAGES.DELETE_SUFFIX}`,
            })
          );
        } else if (!successCount) {
          dispatch(
            setApiResponse({
              status: 400,
              data: USER_MESSAGES.FAILED_DELETE,
            })
          );
        } else {
          dispatch(
            setApiResponse({
              status: res.status,
              data: USER_MESSAGES.DELETE,
            })
          );
        }
        dispatch(eventDeleted(true));
        setTimeout(function() {
          dispatch(eventDeleted(false));
        }, 1500);
        dispatch(getScheduleEvents(tenant, "", true, item, page));

        // Rajneesh
        // Delete associated data from Localstorage and IndexedDB as well

        let newUploadStatus = deleteAssociatedDataFromBrowser(data);

        dispatch(setVideoUploadProgress(newUploadStatus));

        if (newUploadStatus.length === 0) {
          dispatch(setPreventVideoUpload(false));
        } else {
          if (SCHEDULE_EVENT_RESUMABLE_INSTANCE) {
            SCHEDULE_EVENT_CANCEL_ALL_UPLOADS = true;
            SCHEDULE_EVENT_RESUMABLE_INSTANCE.cancel();

            setTimeout(function() {
              dispatch(uploadNextItemFromQueue("", newUploadStatus[0], e));
            }, 3000);
          }
        }
      } else {
        const callback = () =>
          dispatch(regfeshTokenId(deleteEvent(data, tenant, e, item, page)));
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_500));
        setTimeout(function() {
          dispatch(errorInEventCreation(""));
        }, TIME_CONSTANTS.T3s);
      }
    });
};

export const uploadNextItemFromQueue = (item, scheduledEvent, e): AppThunk => (
  dispatch
) => {
  getQueuedEvents().then((events: any) => {
    let chooseNextEvent = events.filter(
      (event) => event.event_id === scheduledEvent.tag
    );

    if (chooseNextEvent) {
      dispatch(
        resumableVideoUploader(
          chooseNextEvent[0].file,
          e,
          scheduledEvent.tag,
          chooseNextEvent[0].isReplacing,
          false,
          chooseNextEvent[0].identifier
        )
      );
    }
  });
};

//Modify Event
export const updateEvent = (
  data: any,
  lastTableConfig: any,
  tenantData,
  refreshRequired = true
): AppThunk => (dispatch) => {
  dispatch(eventCreated(false));
  dispatch(setLoading(true));
  dispatch(setVideoMetaDataFormStatus(true));
  const weekDays = getWeekDaysUTC(
    data.dateTimeInUTC,
    data.weekDays,
    tenantData
  );
  let daysInUtc = new Set([...weekDays]);
  if (refreshRequired) {
    daysInUtc = new Set([
      ...weekDays,
      moment(data.dateTimeInUTC).day(),
    ]);
  }
  let body: any = {
    event_Id: data.event_Id,
    title: data.title,
    description: data.description,
    imageUrl: data.cover,
    active: data.active,
    schedule_Time: data.dateTimeInUTC.toISOString(),
    type: data.type,
    category: data.category,
    durationSecond: parseInt(data.durationSecond),
    schedule: {
      type: 1,
      weekdays: Array.from(daysInUtc),
      daily_time: data.dateTimeInUTC.toISOString(),
    },
    tenantId: data.tenantId,
    video: data.video ? vidoePayloadCreator(data.video, data.type) : null,
    allowAutoPublish: data.allowAutoPublish,
    liveStreamingLink: "",
  };

  if (data.type === EVENT_TYPE_VALUES.LIVE) {
    body.eventSource = data.eventSource;
    if (data.eventSource === STREAMING_PLATFORM_LIVE.EXTERNAL) {
      body.allowAutoPublish = false;
      body.liveStreamingLink = data.liveStreamingLink;
      body.video = null;
    }
  }

  userApi
    .put(`${ApiConstants.updateEventAPI}/${data.event_Id}`, {
      ...body,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        dispatch(eventCreated(true));
        setTimeout(function() {
          dispatch(eventCreated(false));
        }, 1500);
        dispatch(setVideoMetaDataFormStatus(false));
        dispatch(getScheduleEvents(data.tenantId, "", false, 1, -1));
      } else {
        const callback = () =>
          dispatch(
            regfeshTokenId(updateEvent(data, lastTableConfig, tenantData))
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        dispatch(setEvents({ eventsData: [] }));
        dispatch(
          errorInEventCreation(res.data.message ?? API_ERRORS_MESSAGES.API_400)
        );
        setTimeout(function() {
          dispatch(errorInEventCreation(""));
        }, TIME_CONSTANTS.T3s);
      }
      dispatch(setLoading(false));
    });
};

const getDayOfWeek = (dateInUTC) => {
  try {
    return dateInUTC.day(); // for moment object
  } catch (error) {
    let dt = new Date(dateInUTC);
    return dt.getDay();
  }
};

export const vidoePayloadCreator = (data, eventType) => {
  let equipments = [] as any;
  let equipmentTypeTags = [] as any;
  if (Array.isArray(data?.equipments)) {
    data?.equipments.map((item, index) => {
      equipments.push(item.name);
      equipmentTypeTags.push(item.tag);
    });
  } else {
    equipments = data?.equipments ? data?.equipments.split("|") : [];
    equipmentTypeTags = data?.equipmentTypeTags ? data?.equipmentTypeTags : [];
  }

  let focusAreas = [] as any;
  let focusAreaTags = [] as any;
  if (Array.isArray(data?.focusAreas)) {
    data?.focusAreas.map((item, index) => {
      focusAreas.push(item.name);
      focusAreaTags.push(item.tag);
    });
  } else {
    focusAreas = data?.focusArea ? data?.focusArea.split("|") : [];
    focusAreaTags = data?.focusAreaTags ? data?.focusAreaTags : [];
  }

  let video = {
    alternateLink: data.alternateLink,
    classCategory: data.category ?? data.classCategory,
    classDescription: data.description ?? data.classDescription,
    classLanguage: data.videoLanguage ?? data.classLanguage,
    classLanguageCode: data?.classLanguageCode,
    className: data.name ?? data.className,
    classSubCategory: data.subCategory ?? data.classSubCategory,
    classSubCategoryId: data.classSubCategoryId ?? data.classSubCategoryId,
    creationDate: data?.creationDate,
    durationSecond: data?.durationSecond,
    endDate: data?.endDate,
    equipments: Array.isArray(data?.equipments)
      ? data?.equipments
      : data?.equipments
      ? data?.equipments.split("|")
      : [],
    equipmentTypeTags: data?.equipmentTypeTags,
    errorType: 0,
    focusArea: Array.isArray(data?.focusArea)
      ? data?.focusArea
      : data?.focusArea
      ? data?.focusArea.split("|")
      : [],
    focusAreaTags: data?.focusAreaTags,
    imageLink: data.cover ?? data.imageLink,
    instructor: data.instructorName ?? data.instructor,
    intensity:
      typeof data?.intensity === "string"
        ? getIntensityEnums(data?.intensity)
        : data?.intensity,
    isActive: data?.isActive,
    isEquipment: data?.isEquipment,
    jobID: null,
    keywords: null,
    labels: data?.labels,
    level: data?.level,
    provider: data?.provider,
    providerID: data?.providerId ? data?.providerId : data?.providerID,
    scheduleDate: data?.releaseDate,
    skill: getSkillLableEnums(data?.skillLevel) ?? data?.skill,
    startDate: data?.startDate,
    status: data?.status,
    streamingLink: data.video ?? data.streamingLink,
    tag: data?.tag,
    trailerLinkMobile: data?.trailerMobile,
    trailerLinkWeb: data.trailerWeb ?? data.trailerLinkWeb,
    trailerName: data?.trailerName,
    IsMigrating: data?.IsMigrating,
    SourceProviderId: data?.SourceProviderId,
    classCategoryId: data?.classCategoryId ?? data?.categoryId, // need to be changed,
    featured: true,
    publishedTenants: [],
  };
  if (eventType == 1) {
    return video;
  } else {
    const { creationDate, endDate, scheduleDate, ...other } = video;
    return other;
  }
};

export const editVODEvent = (
  data: any,
  tenantId: any,
  tenantData,
  refreshRequired = true
): AppThunk => (dispatch) => {
  dispatch(eventCreated(false));
  dispatch(setLoading(true));
  const weekDays = getWeekDaysUTC(
    data.dateTimeInUTC,
    data.weekDays,
    tenantData
  );
  let daysInUtc = new Set([...weekDays]);
  if (refreshRequired) {
    daysInUtc = new Set([...weekDays, moment(data.dateTimeInUTC).day()]);
  }
  let body = {
    event_Id: data.event_Id,
    title: data.title,
    description: data.description,
    category: data.category,
    durationSecond: parseInt(data.durationSecond),
    active: data.active,
    schedule_time: data?.dateTimeInUTC?.toISOString(),
    type: data.type,
    schedule: {
      type: 1,
      weekdays: Array.from(daysInUtc),
      daily_time: data?.dateTimeInUTC?.toISOString(),
    },
    tenantId: tenantId,
    imageUrl: data.cover,
    eventDetailsType: data.eventDetailsType,
    contentId: data.contentId,
    tag: data.event_Id,
  };
  try {
    userApi
      .put(`${ApiConstants.updateEventAPI}/${data.event_Id}`, {
        ...body,
      })
      .then((res: any) => {
        if (res.status === apiResponseEnums.OKAY) {
          dispatch(
            setApiResponse({
              status: res.status,
              data: USER_MESSAGES.SAVED,
            })
          );
          dispatch(eventCreated(true));
          setTimeout(function() {
            dispatch(eventCreated(false));
          }, 1500);
          dispatch(getScheduleEvents(data.tenantId, "", false, 1, -1));
        } else {
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage({
                status: res.status as number,
                data: res.data as ErrorObjectType,
              }),
            })
          );
          dispatch(setEvents({ eventsData: [] }));
          dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_400));
          setTimeout(function() {
            dispatch(errorInEventCreation(""));
          }, TIME_CONSTANTS.T3s);
        }
        dispatch(setLoading(false));
      });
  } catch (err) {
    dispatch(errorInEventCreation(err));
    dispatch(setLoading(false));
  }
};
export const addVODEvent = (data: any, tenantId: any, tenantData): AppThunk => (
  dispatch
) => {
  dispatch(eventCreated(false));
  dispatch(setLoading(true));
  const weekDays = getWeekDaysUTC(
    data.dateTimeInUTC,
    data.weekDays,
    tenantData
  );
  const daysInUtc = new Set([...weekDays, moment(data.dateTimeInUTC).day()]);
  let body = {
    title: data.title,
    description: data.description,
    category: data.category,
    durationSecond:
      data.durationSecond !== "" ? parseInt(data.durationSecond) : 0,
    active: data.active,
    schedule_time: data?.dateTimeInUTC?.toISOString(),
    type: data.type,
    schedule: {
      type: 1,
      weekdays: Array.from(daysInUtc),
      daily_time: data?.dateTimeInUTC?.toISOString(),
    },
    tenantId: tenantId,
    imageUrl: data.cover,
    eventDetailsType: data.eventDetailsType,
    contentId: data.contentId,
  };

  try {
    userApi
      .post(`${ApiConstants.addEventAPI}`, {
        ...body,
      })
      .then((res: any) => {
        if (res.status === apiResponseEnums.CREATED) {
          dispatch(
            setApiResponse({
              status: res.status,
              data: USER_MESSAGES.SAVED,
            })
          );
          dispatch(eventCreated(true));
          dispatch(updateEventStatus({ status: res.data }));
          setTimeout(function() {
            dispatch(eventCreated(false));
          }, 1500);
        } else {
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage({
                status: res.status as number,
                data: res.data as ErrorObjectType,
              }),
            })
          );
          dispatch(setEvents({ eventsData: [] }));
          dispatch(errorInEventCreation(API_ERRORS_MESSAGES.API_400));
          setTimeout(function() {
            dispatch(errorInEventCreation(""));
          }, TIME_CONSTANTS.T3s);
        }
        dispatch(setLoading(false));
      });
  } catch (err) {
    dispatch(errorInEventCreation(err));
    dispatch(setLoading(false));
  }
};

export const getStreamingLinkfromApi = (
  providerId: any,
  eventTag: any,
  tenantId: any,
  from: any
): AppThunk => (dispatch) => {
  dispatch(setStreamingLinkLoading(true));
  streamingLinkApiHeaderTransform(tenantId);
  const url =
    from === "event" ? "getEventStreamingLinkAPI" : "getTitleStreamingLinkAPI";
  const lang = getLanguageFromConfig();
  streamingLinkApi
    .get(`${ApiConstants[url]}/${providerId}/${eventTag}`)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(getStreamingLink({ streamingLink: res.data }));
      } else {
        const callback = () =>
          dispatch(
            regfeshTokenId(
              getStreamingLinkfromApi(providerId, tenantId, eventTag, from)
            )
          );
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: {
                code: apiResponseEnums.BAD_REQUEST,
                message: lang.Schedule.chooseClassError,
              },
            }),
          })
        );
        dispatch(getStreamingLink({ err: "" }));
        dispatch(errorInGetStreamingLink(API_ERRORS_MESSAGES.API_404));
        setTimeout(function() {
          dispatch(errorInGetStreamingLink(""));
        }, API_ERROR_TOAST_DURATION);
      }
    })
    .finally(() => dispatch(setStreamingLinkLoading(false)));
};

export const applyFilterDataIntoStorage = (filterData): AppThunk => (
  dispatch
) => {
  setSessionCalendarData(filterData);
  dispatch(setFilterDataIntoStorage(filterData));
};

export const resetFilterData = (): AppThunk => (dispatch) => {
  dispatch(setResetFilterData(initialState.calFilteredData));
};

export const isEventDeleted = (state: RootState) =>
  state.eventTitles.isEventDeleted;
export const isEventCreated = (state: RootState) =>
  state.eventTitles.isEventCreated;
export const eventsData = (state: RootState) => state.eventTitles.eventsData;
export const eventStatus = (state: RootState) => state.eventTitles.eventStatus;
export const videoUploadProgress = (state: RootState) =>
  state.eventTitles.videoUploadProgress;
export const errorInVideoUpload = (state: RootState) =>
  state.eventTitles.errorInVideoUpload;
export const preventEventVideoUpload = (state: RootState) =>
  state.eventTitles.preventEventVideoUpload;
export const isAnotheBorkenUploadGoingOn = (state: RootState) =>
  state.eventTitles.isAnotheBorkenUploadGoingOn;
export const error = (state: RootState) => state.eventTitles.error;

export const liveEventKey = (state: RootState) => state.eventTitles.eventKey;
export const isEventKeyCreated = (state: RootState) =>
  state.eventTitles.isEventKeyCreated;
export const Loading = (state: RootState) => state.eventTitles.isLoading;
export const LoadingCal = (state: RootState) => state.eventTitles.isLoadingCal;
export const getScheduleTabData = (state: RootState) =>
  state.eventTitles.existTabData;
export const LoadingFav = (state: RootState) => state.eventTitles.isLoadingFav;

export const eventsCalendarData = (state: RootState) =>
  state.eventTitles.eventsCalendarData;

export const chooseClassData = (state: RootState) =>
  state.eventTitles.chooseClassData;

export const favouriteClassData = (state: RootState) =>
  state.eventTitles.favouriteClassData;
export const isFavUnFavMarkClass = (state: RootState) =>
  state.eventTitles.isFavUnFavMark;
export const streamingLink = (state: RootState) =>
  state.eventTitles.streamingLink;
export const streamingLinkLoading = (state: RootState) =>
  state.eventTitles.streamingLinkLoading;
export const errorInStreamingLink = (state: RootState) =>
  state.eventTitles.errorInStreamingLink;
export const calFilteredData = (state: RootState) =>
         state.eventTitles.calFilteredData;

export default ScheduleEventSlice.reducer;
