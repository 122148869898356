import { makeStyles } from "@material-ui/core/styles";

export const NotItemFoundComponentStyles = makeStyles(() => ({
  notFoundMessage: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.15,
    color: "rgba(0, 0, 0, 0.87)",
    paddingTop: 34,
    paddingLeft: 31,
  },
  notFoundPadding:{
    paddingLeft:0,
     fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.15,
    color: "rgba(0, 0, 0, 0.87)",
    paddingTop: 34,
  }
}));
