import { useEffect, useState } from "react";
import { ScrollTop } from "utils/utilityFunctions";
import { useSelector } from 'react-redux';
import { isAsc, getSort } from '../../components/Sort/Sort.Slice';
// import { paginationValues } from '../../constants/enums';
import { usePaginationHook } from 'components/Pagination/Pagination.Hook';

export const useCustomTenantTableHook = (props) => {
  const { optionalData, numSelected, desableOption } = props;
  const {paginationData,handelPaginate} = usePaginationHook();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([] as any);
  const [page, setPage] = useState(0);
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(paginationData.DEFAULT_PAGE_SIZE);
  const [isAllSelected, setAllSelectingStatus] = useState(false);
  const sortAsc = useSelector(isAsc);
  const sortData = useSelector(getSort);

  useEffect(()=>{
    return setOrder(sortAsc);
  },[sortData])
  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (numSelected === 0) {
      setSelected([]);
    }
  }, [numSelected]);

  useEffect(() => {
    if (optionalData && optionalData.length > 0) {
      setSelected([...optionalData]);
    } else {
      setSelected([]);
    }
    // setButtonStatus(true);
  }, [optionalData]);

  useEffect(() => {
    if (desableOption === true) {
      setButtonStatus(true);
    }
  }, [desableOption]);

  useEffect(() => {
    ScrollTop();
  }, []);

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    isButtonDesabled,
    setButtonStatus,
    rowsPerPage,
    setRowsPerPage,
    isAllSelected,
    setAllSelectingStatus,
    paginationData,
    handelPaginate
  };
};
