import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { LinkCommonStyle, SearchAddBarCommonStyle } from './Common.style';
export const WelcomeScreenStyle = (theme: Theme) =>
  createStyles({
    ...SearchAddBarCommonStyle(theme),
    ...LinkCommonStyle(theme),
    paper: {
      maxWidth: 928,
      margin: 'auto',
      background: "#fff",
      zIndex: 100,
      marginTop: -161,
      paddingTop: 33.1,
      paddingLeft: 31,
      paddingRight: 31,
      borderRadius: 4,
      // minHeight: 680,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)"
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
      padding: theme.spacing(6, 4),
      background: '#fff',
      '&::before': {
        position: 'absolute',
        top: 50,
        right: 0,
        background: theme.palette.secondary.main
      },
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      marginTop: -20,
      zIndex: 2000
    },
    block: {
      display: 'block',
    },
    contentWrapper: {
      marginTop: 20
    },
    contentWrapperTop: {
      marginBottom: 33
    },
  });
export interface WelcomeProps extends WithStyles<typeof WelcomeScreenStyle> { }