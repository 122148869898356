import React from 'react'
import { FormattedMessage } from 'react-intl'
const config = require('../assets/translations/en.json')



 function Translate( obj:any) {
   if(obj.id)
   {
    return (
        <FormattedMessage id={obj.id} defaultMessage={obj.defaultMessage}  />
    );
   }
}

export function getLocalisedString(context: any, key: any) {
    try {
        let contextVal = config[context];    
        return contextVal[key];
    } catch (error) {
        console.error("getLocalisedStringFromFile ",error)
    }
}

export default Translate;