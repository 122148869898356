import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {userApi} from "api/api";
import {AppThunk, RootState} from "store/store";
import * as ApiConstants from "constants/apiConstants";
import {
  DATE_MONTH_NAME_YEAR,
  DATE_MONTH_NAME_YEAR_TIME,
  USER_MANAGEMENT_MESSAGES,
  USER_REPORT_MESSAGES,
  apiResponseEnums,
} from "constants/enums";
import moment from "moment";
import {CommonAPIResponse, ErrorObjectType} from "models/common.model";
import {setApiResponse} from "Layout/layoutSlice";
import {
  getCustomAPIErrorMessage,
  setUserReportFilterData,
} from "utils/utilityFunctions";
import {
  UserReportAPIRes,
  UserReportTableItem,
  UserReportTableItems,
} from "models/userReport.model";

export interface URState {
  urData: UserReportAPIRes;
  data: UserReportTableItems | null;
  pageLoading: boolean;
  loadingTemplate: boolean;
  loadingFile: boolean;
  userReportFilteredData: any;
  metaData: any;
  udData: any;
  udSubscriptionData: any;
  udFilterListOption: any;
  udAllSubscriptionData: any;
}

const initialState: URState = {
  urData: {
    pageNo: 0,
    totalRecords: 0,
    totalPages: 0,
    data: [],
  },
  data: [],
  pageLoading: true,
  loadingTemplate: false,
  loadingFile: false,
  userReportFilteredData: {
    subscriptionStatus: "" as any,
    externalSubscriptionStatus: "" as any,
    externalSubscriptionSource: "" as any,
    searchTerm: "" as any,
    reset: false,
  },
  metaData: {},
  udData: [],
  udSubscriptionData: [],
  udFilterListOption: [],
  udAllSubscriptionData: {},
};

export const userReportSlice = createSlice({
  name: "userReport",
  initialState,
  reducers: {
    setURLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
      if (action.payload) {
        state.data = null as null;
      }
    },
    setURData: (state, action: PayloadAction<UserReportAPIRes>) => {
      let response: UserReportAPIRes = action.payload;
      state.urData = response;
      state.data = response.data?.map((listItem: UserReportTableItem) => {
        let createdDate = listItem?.createdDate;
        let lastAccessedAt = listItem?.lastAccessedAt;
        let email = listItem?.email ? listItem?.email : "-";
        let name = listItem?.name ? listItem?.name : "-";
        let externalUserId = listItem?.externalUserId
          ? listItem?.externalUserId
          : "-";
        let userId = listItem?.userId ? listItem?.userId : "-";
        try {
          createdDate = createdDate
            ? moment(createdDate).format(DATE_MONTH_NAME_YEAR)
            : "-";
          lastAccessedAt = lastAccessedAt
            ? moment(lastAccessedAt).format(DATE_MONTH_NAME_YEAR_TIME)
            : "-";
        } catch (error) {}
        return {
          ...listItem,
          createdDate,
          lastAccessedAt,
          email,
          name,
          externalUserId,
          userId,
        };
      }) as UserReportTableItems;
    },
    setUDData: (state, action: PayloadAction<any>) => {
      let response: any = action.payload;
      let createdDate = response?.createdDate;
      let lastAccessedAt = response.lastAccessedAt;
      try {
        createdDate = createdDate
          ? moment(createdDate).format(DATE_MONTH_NAME_YEAR)
          : "-";
        lastAccessedAt = lastAccessedAt
          ? moment(lastAccessedAt).format(DATE_MONTH_NAME_YEAR_TIME)
          : "-";
      } catch (error) {}
      let arr: any = [];

      let itemArr: any = [
        {
          attributeName: "",
          value: "",
          description: "",
          matchData: "id",
        },
        {
          attributeName: "Subscription Title",
          value: "",
          description: "Wexer platform subscription Title",
          matchData: "subscriptionTitle",
        },
        {
          attributeName: "Subscription ID",
          value: "",
          description: "Wexer platform subscription ID",
          matchData: "subscriptionId",
        },
        {
          attributeName: "Subscription Status",
          value: "",
          description:
            "Active means the subscription is functioning and active",
          matchData: "subscriptionStatus",
        },
        {
          attributeName: "Created Date",
          value: "",
          description:
            "The date the subscription was created in the Wexer platform",
          matchData: "createdDate",
        },
        {
          attributeName: "Last Modified Date",
          value: "",
          description:
            "Last date when the subscription was updated in the Wexer platform",
          matchData: "lastModifiedDate",
        },

        {
          attributeName: "External Subscription Status",
          value: "",
          description:
            "Subscription status from the external platform, eg active, cancelled, renewed, or trialing",
          matchData: "externalSubscriptionStatus",
        },
        {
          attributeName: "External Subscription ID",
          value: "",
          description:
            "Subscription ID from external subscription platform, eg Stripe or Apple",
          matchData: "externalSubscriptionId",
        },
        {
          attributeName: "External Subscription Source",
          value: "",
          description:
            "System from where the subscription is managed, eg Stripe or Apple",
          matchData: "externalSubscriptionSource",
        },
        {
          attributeName: "External Product ID",
          value: "",
          description:
            "Product ID from external subscription platform, eg Stripe or Apple",
          matchData: "externalProductId",
        },

        {
          attributeName: "External Plan ID",
          value: "",
          description:
            "Product ID from external subscription platform, eg Stripe or Apple",
          matchData: "externalPlanId",
        },
        {
          attributeName: "External Price",
          value: "",
          description:
            "Price from external subscription platform, eg Stripe or Apple",
          matchData: "externalPrice",
        },
        {
          attributeName: "External Subscription Start Date",
          value: "",
          description: "Date from external system or Wexer, as applicable",
          matchData: "externalSubscriptionStartDate",
        },
        {
          attributeName: "External Subscription End Date",
          value: "",
          description: "Date from external system or Wexer, as applicable",
          matchData: "externalSubscriptionEndDate",
        },
        {
          attributeName: "External Subscription Trial Start Date",
          value: "",
          description: "Date from external system or Wexer, as applicable",
          matchData: "externalSubscriptionTrailStartDate",
        },
        {
          attributeName: "External Subscription Trial End Date",
          value: "",
          description: "Date from external system or Wexer, as applicable",
          matchData: "externalSubscriptionTrailEndDate",
        },
        {
          attributeName: "External Subscription Cancelled Date",
          value: "",
          description: "Date from external system or Wexer, as applicable",
          matchData: "externalSubscriptionCancelledDate",
        },
        {
          attributeName: "External CRM Source",
          value: "",
          description: "CRM/Email marketing system connected for this client",
          matchData: "externalCRMSource",
        },
        {
          attributeName: "External CRM User ID",
          value: "",
          description: "User ID from external CRM system",
          matchData: "externalCRMUserId",
        },
        {
          attributeName: "Stripe Discount Code",
          value: "",
          description: "Discount code from Stripe",
          matchData: "stripeDiscountCode",
        },
        // {
        //   attributeName: "Stripe Discount Code Type",
        //   value: "",
        //   description:
        //     "Discount code type from Stripe, eg one-off, recurring",
        //   matchData: "stripeDiscountCodeType",
        // },
        // {
        //   attributeName: "Stripe Discount Code End Date",
        //   value: "",
        //   description: "Discount code end date from Stripe",
        //   matchData: "stripeDiscountCodeEndDate",
        // },
      ];

      arr.push({
        userId: response.userId ? response.userId : "-",
        email: response.email ? response.email : "-",
        name: response.name ? response.name : "-",
        externalUserId: response.externalUserId ? response.externalUserId : "-",
        lastLoginAt: response.lastLoginAt ? response.lastLoginAt : "-",
        lastAccessedAt: lastAccessedAt,
        createdDate: createdDate,
      });

      let filterarray: any = [];
      response?.subscriptionItems?.forEach((element, index, value) => {
        filterarray.push({
          name: element?.subscriptionTitle
            ? element.subscriptionTitle
            : "no bundle assigned",
          value: element.id,
        });

        let valuesName = Object.values(value[0]);
        let valuesKey = Object.keys(value[0]);
        itemArr?.forEach((item, index) => {
          let matchData = item.matchData;
          let valueIndex = valuesKey.indexOf(matchData);
          if (valueIndex !== -1 && valueIndex < valuesName.length) {
            let value = valuesName[valueIndex];
            itemArr[index]["value"] =
              typeof value === "string" &&
              value.includes(":") &&
              value.includes("-") &&
              value.includes("T") &&
              !isNaN(Date.parse(value))
                ? moment(value).format(DATE_MONTH_NAME_YEAR)
                : value !== null && value !== "" && value !== undefined
                ? value + ""
                : "-";
          }
        });
      });
      state.udData = arr;
      state.udSubscriptionData = itemArr;
      state.udFilterListOption = filterarray;
      state.udAllSubscriptionData = response?.subscriptionItems;
    },
    setLoadingTemplate: (state, action: PayloadAction<boolean>) => {
      state.loadingTemplate = action.payload as boolean;
    },
    setMetaData: (state, action: PayloadAction<any>) => {
      state.metaData = action.payload;
    },
    setLoadingFile: (state, action: PayloadAction<boolean>) => {
      state.loadingFile = action.payload as boolean;
    },
    setFilterDataIntoStorageUserReport: (state, action: PayloadAction<any>) => {
      state.userReportFilteredData = action.payload;
    },
    setResetFilterData: (state, action: PayloadAction<any>) => {
      state.userReportFilteredData = initialState.userReportFilteredData;
    },
    resetURData: (state) => {
      state.data = [];
      state.urData = {
        pageNo: 0,
        totalRecords: 0,
        totalPages: 0,
        data: [],
      };
    },
    resetUDData: (state) => {
      state.udData = [];
      state.udSubscriptionData = {};
    },
  },
});

export const {
  setURLoading,
  setURData,
  setUDData,
  setLoadingTemplate,
  setLoadingFile,
  resetURData,
  setMetaData,
  setFilterDataIntoStorageUserReport,
  setResetFilterData,
} = userReportSlice.actions;

let timer: any = 0;

export const getURListFromApi = (
  data: any,
  refreshRequired: boolean = true
): AppThunk => (dispatch) => {
  if (refreshRequired) {
    dispatch(setURLoading(true));
  }
  userApi.post(ApiConstants.userReportList, data).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setURData(res.data));
      dispatch(setURLoading(false));
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(resetURData());
    }
  });
};

export const downloadURTemplate = (
  data: any,
  refreshRequired: boolean = true
): AppThunk => (dispatch) => {
  userApi.post(ApiConstants.userReportExport, data).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      const url = window.URL.createObjectURL(new Blob([res.data] as any));
      const link = document.createElement("a");
      link.href = url;
      let date = moment(new Date()).format("DDMMMYYYY");
      link.setAttribute("download", `User_Report_Export_${date}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_REPORT_MESSAGES.DOWNLOADING_REPORT,
        } as CommonAPIResponse)
      );
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};

export const getUserReportMetaDataFromApi = (data): AppThunk => (dispatch) => {
  userApi.post(ApiConstants.userMetaData, data).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setMetaData(res.data));
      dispatch(setURLoading(false));
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};

export const getUDListFromApi = (
  data: any,
  refreshRequired: boolean = true
): AppThunk => (dispatch) => {
  if (refreshRequired) {
    dispatch(setURLoading(true));
  }
  userApi.post(ApiConstants.userSubscrptionList, data).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setUDData(res.data));
      dispatch(setURLoading(false));
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};
export const applyFilterDataIntoUserReportStorage = (filterData): AppThunk => (
  dispatch
) => {
  setUserReportFilterData(filterData);
  dispatch(setFilterDataIntoStorageUserReport(filterData));
};

export const userReportFilteredData = (state: RootState) =>
  state.userReport.userReportFilteredData;
export const classInitialData = () => initialState.userReportFilteredData;
export const selectUserReportMetaData = (state: RootState) =>
  state.userReport.metaData;
export const urListItems = (state: RootState) => state.userReport?.data;
export const urDataListItems = (state: RootState) => state.userReport?.urData;
export const udListItems = (state: RootState) => state.userReport?.udData;
export const udSubscriptionData = (state: RootState) =>
  state.userReport?.udSubscriptionData;
export const pageLoadingData = (state: RootState) =>
  state.userReport?.pageLoading;
export const udFilterListOptionData = (state: RootState) =>
  state.userReport?.udFilterListOption;
export const udAllListSubscriptionData = (state: RootState) =>
  state.userReport?.udAllSubscriptionData;
export default userReportSlice.reducer;
