import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Navigator from "components/paperbase/Navigator";
import Routes from "routes";
import Dialog from "components/Dialogs/ConfirmationDialog";
import { changeNetworkStatus } from "_pages/contentTitles/contentTitleSlice";
import { NETWORK_STATUS_ENUMS } from "constants/enums";
import { useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { HomePageProps, HomePageStyles } from "style";
import Layout from "Layout";
import { navigatorPanelStye } from "style/components";

const HomePage = (props: HomePageProps) => {
  const dispatch = useDispatch();
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showWarning, setWarningStatus] = React.useState(false);
  const [isUserOnline, setNetworkStatus] = React.useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const closePupUp = () => {
    setWarningStatus(false);
  };
  React.useEffect(() => {
    window.addEventListener("load", function() {
      function updateOnlineStatus(event) {
        let status = window.navigator.onLine;
        setNetworkStatus(status);
        dispatch(
          changeNetworkStatus(
            status ? NETWORK_STATUS_ENUMS.ONLINE : NETWORK_STATUS_ENUMS.OFFLINE
          )
        );
        if (!status) {
          setWarningStatus(true);
        }
      }
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });
  }, []);
  return (
    <>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={{ style: { ...navigatorPanelStye } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator
            PaperProps={{ style: { ...navigatorPanelStye } }}
          />
        </Hidden>
      </nav>

      <Layout
        classes={classes}
        isUserOnline={isUserOnline}
        showWarning={showWarning}
        closePupUp={closePupUp}
      />
    </>
  );
};

HomePage.propTypes = {};

export default withStyles(HomePageStyles)(HomePage);
