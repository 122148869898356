import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Spinner from './Loader/Spinner';
import { CustomButtonComponentStyles, OtherButtonComponentStyles } from 'style/components';

const CustomButton = (props) => {
    const { classes, disabled } = props;
    return (

        <Button disabled={disabled}
            startIcon={props.loading ? <Spinner small={true} /> : null}

            variant="contained" onClick={() => props.callback()} color='primary'
            className={props.type === "small" ? classes.small : props.fullWidth ?  classes.fullWidth : props?.autoPublish ? classes.autoPublish : classes.root}>
            {props.text}
        </Button>
    )
}
export default withStyles(CustomButtonComponentStyles)(CustomButton);


export const CustomButtonSubmit = (props) => {
    const {  disabled } = props;
    
    return (
        <Button disabled={disabled}
            startIcon={props.loading ? <Spinner small={true} /> : null}
            type="submit"
            variant="contained" color='primary' className="custom-button-submit">
            {props.text}
        </Button>
    )
}

export const CustomButtonRounded = (props) => {
    const classes = OtherButtonComponentStyles();
    const { callback, text } = props

    const callbackMethod = () => {
      
        if (callback) {
            callback();
        }
    }

    return (

        <Button
            onClick={() => callbackMethod()}
            className={classes.root}
            disableElevation
            //disabled
            variant="contained" color='primary'  >
            <Typography className={classes.text}>
                {text}
            </Typography>
        </Button>
    )
}

export const CustomButtonDisabled = (props) => {
    const classes = OtherButtonComponentStyles();

    return (

        <Button
            className={classes.disabled}
            disableElevation
            disabled
            variant="contained">
            <Typography className={classes.disabledText}>
                {props.text}
            </Typography>
        </Button>
    )
}
export const CustomButtonRoundedRed = (props) => {
    const classes = OtherButtonComponentStyles();

    return (

        <Button
            className='btn-incomplete'
            disableElevation
            disabled
            variant="contained" >

            <Typography className={classes.text}>
                {props.text}
            </Typography>
        </Button>
    )
}
export const CustomButtonRoundedGrey = (props) => {
  const classes = OtherButtonComponentStyles();

  return (
    <Button
      className="btn-cancelled"
      disableElevation
      variant="contained"
    >
      <Typography className={classes.text}>{props.text}</Typography>
    </Button>
  );
};

export const CustomButtonRoundedOrange = (props) => {
    const classes = OtherButtonComponentStyles();

    return (

        <Button
            className='btn-unpublished'
            disableElevation
            disabled
            variant="contained" >

            <Typography className={classes.text}>
                {props.text}
            </Typography>
        </Button>
    )
}

export const MediaTypeButtonRounded = (props) => {
  const classes = OtherButtonComponentStyles();

  return (
    <Button
      className="mediaTypeTag"
      disableElevation
      variant="contained"
      disabled
    >
      <Typography className={classes.text}>{props.text}</Typography>
    </Button>
  );
};

export const UploadCaptionButton = ({ callback, name }) => {
  const classes = OtherButtonComponentStyles();

  return (
    <>
      <label htmlFor="file">
        <Typography className={classes.captionUploadButton}>{name}</Typography>
      </label>
      <input
        id="file"
        name="file"
        type="file"
        accept=".vtt"
        hidden
        onChange={(event) => {
          if (!event.target.files) return;
          callback(event.target.files[0]);
          event.target.value = "";
        }}
      />
    </>
  );
};