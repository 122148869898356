import { createProvider, providerCreatedStatus, providerUpdatedStatus, updateProvider } from "_pages/provider/providerSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import { emailValidation, hasBlankSpaces, nameValidation, phoneValidation } from "utils/validation";
import { useHistory } from "react-router-dom";
import { isEmpty, trim } from 'lodash';
const _ = require("lodash");

export const useProviderFormHook = (props) => {
  const providerCreationStatus = useSelector(providerCreatedStatus);
  const providerUpdationStatus = useSelector(providerUpdatedStatus);
  const { selectedProvider, formSubmitStatus, edit } = props; 
  const selectedProvider2 = selectedProvider as any;
  const dispatch = useDispatch();
  const history = useHistory();

  const [isFormDataLoading, setFormDataLoading] = useState(!!edit);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    phone: "",
    email: "",
    password: "",
    mediaPlatform: 0,
    jwPlayerSiteId: "",
    jwPlayerSecret: "",
  });
  const [errors, setError] = useState({
    name: "",
    contact: "",
    phone: "",
    email: "",
    password: "",
    jwPlayerSiteId: "",
    jwPlayerSecret: "",
  });
  // const [isButtonEnabled, setButtonStatus] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  useEffect(() => {
    ScrollTop();
  }, []);
  const getErrorText= (stateData:any=false)=>{
    let frmData:any = stateData ? stateData : formData,errObj:any = {};
    if(nameValidation(trim(frmData.name)) === false){
      errObj = {name:"Required Name"};
    }
    if(trim(formData?.contact).length < 5){
      errObj = {...errObj,contact:"Contact need minimum 5 characters"};
    }
    if (trim(formData?.phone) !== "") {
        if(phoneValidation(formData.phone) === false){
          errObj = {...errObj,phone:"Phone number is not valid"};
        }
    }
    if(formData.mediaPlatform === 1){
      const isJWPlayerSiteId = trim(formData?.jwPlayerSiteId).length < 5,
          isJWPlayerSecret = trim(formData?.jwPlayerSecret).length < 5;
      if(isJWPlayerSiteId){
        errObj = {...errObj,jwPlayerSiteId:"Required JwPlayerSiteId"};
      }
      if(isJWPlayerSecret){
        errObj = {...errObj,jwPlayerSecret:"Required JwPlayerSecret"};
      }
    }
    if (inEditMode === false){
      if(trim(formData?.password).length < 5){
        errObj = {...errObj,password:"Password need minimum 5 characters"};
      }
      if(emailValidation(trim(frmData.email)) === false){
        errObj = {...errObj,email:"Email is not valid"};
      }
    }
    setError(errObj)
    return errObj;
  }
  /* 
  const handleSaveButton = () => {
    let isEmailValid = emailValidation(formData?.email);
    let isEmailBlankSpace = hasBlankSpaces(formData?.email);
    let isProviderNameValid = nameValidation(formData?.name);
    let isProviderContactValid = formData?.contact?.length > 5;
    let isProviderContactBlankSpace = hasBlankSpaces(formData?.contact);
    let isPhoneValid = true;

    if (formData.phone != "") {
      if (formData.phone != null)
        isPhoneValid = phoneValidation(formData.phone);
    }

    let isJWPlayerSiteId =
      formData?.mediaPlatform == 1 &&
      formData?.jwPlayerSiteId &&
      formData?.jwPlayerSiteId.length > 0 &&
      formData?.jwPlayerSiteId.length > 5 &&
      hasBlankSpaces(formData?.jwPlayerSiteId);
    let isJWPlayerSecret =
      formData?.mediaPlatform == 1 &&
      formData?.jwPlayerSecret &&
      formData?.jwPlayerSecret.length > 0 &&
      formData?.jwPlayerSecret.length > 5 &&
      hasBlankSpaces(formData?.jwPlayerSecret);

    if (inEditMode == true) {
      if (
        isProviderNameValid &&
        isProviderContactValid &&
        isProviderContactBlankSpace &&
        formData?.mediaPlatform === 0 &&
        isPhoneValid
      ) {
        setButtonStatus(false);
      } else if (
        isProviderNameValid &&
        isProviderContactValid &&
        isProviderContactBlankSpace &&
        isJWPlayerSiteId &&
        isJWPlayerSecret &&
        isPhoneValid
      ) {
        setButtonStatus(false);
      } else {
        setButtonStatus(true);
      }
    } else {
      if (
        isProviderNameValid &&
        isProviderContactValid &&
        isProviderContactBlankSpace &&
        isEmailValid &&
        isEmailBlankSpace &&
        formData?.password?.length > 5 &&
        formData?.mediaPlatform === 0 &&
        isPhoneValid
      ) {
        setButtonStatus(false);
      } else if (
        isProviderNameValid &&
        isProviderContactValid &&
        isEmailValid &&
        isEmailBlankSpace &&
        formData?.password?.length > 5 &&
        isJWPlayerSiteId &&
        isJWPlayerSecret &&
        isPhoneValid
      ) {
        setButtonStatus(false);
      } else {
        setButtonStatus(true);
      }
    }
  };
  useEffect(() => {
    if (inEditMode == true) {
      if (selectedProvider2 != null) {
        if (
          _.isEqual(selectedProvider2?.name, formData.name) &&
          _.isEqual(selectedProvider2?.contact, formData.contact) &&
          _.isEqual(selectedProvider2?.phoneNumber, formData.phone) &&
          _.isEqual(
            selectedProvider2?.mediaPlatform,
            formData.mediaPlatform
          ) &&
          (_.isEqual(
            selectedProvider2?.jwPlayerSiteId,
            formData.jwPlayerSiteId
          ) === true)
          &&
          _.isEqual(
            selectedProvider2?.jwPlayerSecret,
            formData.jwPlayerSecret
          ) === true
        ) {
          setButtonStatus(true);
        } else {
          handleSaveButton();
        }
      }
    } else {
      handleSaveButton();
    }
  }, [formData]);
 */
  useEffect(() => {
    if (selectedProvider2 != null) {
      // setButtonStatus(true);
      setFormData({
        ...formData,
        jwPlayerSiteId: selectedProvider2?.jwPlayerSiteId,
        jwPlayerSecret: selectedProvider2?.jwPlayerSecret,
        mediaPlatform: selectedProvider2?.mediaPlatform,
        name: selectedProvider2?.name,
        contact: selectedProvider2?.contact,
        phone: selectedProvider2?.phoneNumber,
        email: selectedProvider2?.email,
      });
      setFormDataLoading(false);
      setInEditMode(true);
    }
  }, [selectedProvider2]);
  useEffect(() => {
    if (formSubmitStatus !== "") {
      setLoading(false);
    }
  }, [formSubmitStatus]);

  useEffect(() => {
    if (providerCreationStatus) {
      setLoading(false);
      // setButtonStatus(true);
    }
  }, [providerCreationStatus]);

  useEffect(() => {
    if (providerUpdationStatus) {
      setLoading(false);
      // setButtonStatus(true);
    }
  }, [providerUpdationStatus]);

  const submitForm = () => {
    const isError = getErrorText();
    if(isEmpty(isError)){
      setLoading(true);
      dispatch(inEditMode ? updateProvider(formData, selectedProvider2, history) : createProvider(formData, history));
    }
  };
  return {
    history,
    formData,
    inEditMode,
    errors,
    getErrorText,
    setFormData,
    isLoading,
    submitForm,
    // isButtonEnabled,
    isFormDataLoading,
  }
};
