const config = require('assets/config.json')
//
// { id: 'The content channel can be used for display in health clubs', active: false }
const useStrings = (type) => {
    const lang = require(`assets/translations/${config.language}.json`);
    let c = lang.Channels;
    let d = lang.Tenants;
    let data = {};
    if (type === 1) { 
        data = {
            heading: c.systemAvailability,
            selectedStatus: { inClub: false, appAndweb: false, hotel: false },
            options: [{ lable: c.checkBoxLable1, name: "inClub" }, { lable: c.checkBoxLable2, name: "appAndweb" }, { lable: c.checkBoxLable3, name: "hotel" }],
            subHeading: [c.checkBoxsubHeading1, c.checkBoxsubHeading2, c.checkBoxsubHeading3]
        }
    } 
    else if(type === 2) {
        data = {
            heading: c.channelPromotionStatus,
            selectedStatus: { status: false},
            options: [{ lable: c.checkBoxLable4, name: "status" }],
            subHeading: [c.checkBoxsubHeading4]
        }
    }
        else {
            data = {
                heading: d.tenantContentUpload,
                selectedStatus: { status: false},
                options: [{ lable: c.checkBoxLable4, name: "status" }],
                subHeading: [d.tenantLoginHeading]
            } 
        }
    


    return data;
}



export default useStrings;