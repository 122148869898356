import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InputComponentStyles } from 'style/components';

const Input = (props) => {
    const { classes } = props;
    return (
        <TextField
            InputProps={{
                className: classes.root
            }}
           type={props.type}
            onChange={props.callback}
            autoComplete="on"
            label={props.label}
            variant='filled'
            color="primary"
        />
    )
}
export default withStyles(InputComponentStyles)(Input);
