import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ScrollTop } from "utils/utilityFunctions";
import {
  getChannelListfromApi,
  selectChannels,
} from "_pages/channels/channelSlice";
import {
  deletProvider,
  getTenantsListfromApi,
  publishChannelsOnTenant,
  selectTenants,
  tenantCreatedStatus,
} from "_pages/tenants/tenantSlice";

export const useTenantPublishingHook = () => {
  const [showPublishingTable, setPublishingTableStatus] = useState(false);
  const [numSelected, setSelectedNumbers] = useState(0);
  const [data, setData] : any = useState(null);
  const [selectedChannels, setSelectedChannel] = useState([] as any);
  const [selectedTenantId, setSelectedTenantId] = useState("");
  const [selectedTenantName, setSelectedTenantName] = useState("");
  const dispatch = useDispatch();
  const { tenants } = useSelector(selectTenants);
  const tenatnUpdatedStatus = useSelector(tenantCreatedStatus);
  const { channels } = useSelector(selectChannels);
  const [channelData, setChannels] = useState(0 as any);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [payload, setPayload] = useState([] as any);
  const [preSelectedChannels, setPreSelectedChannels] = useState([] as any);
  const [channelTagsToBePublished, setPublishableTags] = useState([] as any);
  const [channelTagsToBeUnPublished, setUnPublishableTags] = useState(
    [] as any
  );
  const [channelNamesToBeUnPublished, setUnPublishableNames] = useState(
    [] as any
  );
  const preSelectchannelTagsToBeUnPublished = [];
  const [buttonStatus, setButtonStatus] = useState(false);
  const [unPublishPopUp, setUnPublishPopUp] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (tenants) {
      setData(tenants);
    }
  }, [tenants]);

  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (channels) {
      setChannels(channels);
    }
  }, [channels]);

  useEffect(() => {
    if (payload.length > 0) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [payload]);

  useEffect(() => {
    dispatch(getTenantsListfromApi());
    dispatch(getChannelListfromApi());
  }, [dispatch]);

  useEffect(() => {}, [selectChannels?.name]);

  useEffect(() => {
    if (tenatnUpdatedStatus) {
      setLoading(false);
      setPayload([]); //resseting payload on api call
      setPublishableTags([]);
      setUnPublishableTags([]);
      setUnPublishableNames([]);
    }
  }, [tenatnUpdatedStatus]);

  const deletRows = () => {
    dispatch(deletProvider([selectedChannels]));
    setSelectedNumbers(0);
  };

  const reset = () => {
    dispatch(getChannelListfromApi());

    setPublishableTags([]);
    setUnPublishableTags([]);
    setUnPublishableNames([]);
    setPayload([]);
    setPreSelectedChannels([]);
    setPublishingTableStatus(false);
    history.goBack();
  };

  const handleSubmit = () => {
    if (channelNamesToBeUnPublished.length) {
      setUnPublishPopUp(true);
    } else {
      submitCallback();
    }
  }

  const submitCallback = () => {
    // setChannels(null);
    setLoading(true);
    setUnPublishPopUp(false);
    if (payload && payload.length > 0) {
      dispatch(
        publishChannelsOnTenant(
          payload,
          selectedTenantId,
          channelTagsToBePublished,
          [
            ...channelTagsToBeUnPublished,
            ...preSelectchannelTagsToBeUnPublished,
          ]
        )
      );
    }
    setPreSelectedChannels([...selectedChannels]);
  };
  
  return {
    showPublishingTable,
    setPublishingTableStatus,
    numSelected,
    setSelectedNumbers,
    data,
    setData,
    selectedChannels,
    setSelectedChannel,
    selectedTenantId,
    setSelectedTenantId,
    selectedTenantName,
    setSelectedTenantName,
    channelData,
    setChannels,
    pageNumber,
    setPageNumber,
    isLoading,
    setLoading,
    payload,
    setPayload,
    preSelectedChannels,
    setPreSelectedChannels,
    channelTagsToBePublished,
    channelNamesToBeUnPublished,
    setPublishableTags,
    channelTagsToBeUnPublished,
    setUnPublishableTags,
    setUnPublishableNames,
    preSelectchannelTagsToBeUnPublished,
    buttonStatus,
    setButtonStatus,
    channels,
    reset,
    submitCallback,
    deletRows,
    handleSubmit,
    unPublishPopUp,
    setUnPublishPopUp,
  };
};
