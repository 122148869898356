export const homePath: string = "/";
export const adminPath: string = "/admin";
export const adminReportPath: string = "/admin/report";
export const tenantPath: string = "/tenants";
export const tenantPublishingPath: string = "/tenants/publishing";
export const tenantSitetermsPath: string = "/tenants/siteterms";
export const providerPath: string = "/providers";
export const channelsPath: string = "/channels";
export const channelsPublishPath: string = "/channels/publishing";
export const contentTitles: string = "/media/contentTitles";         
export const scheduleTitles : string = "/schedule";    
export const collectionsPath : string = "/collections";
export const reviews: string = "/media/reviews";
export const subscriptionsPath: string = "/subscriptions";
export const userManagement: string = "/users/userManagement";
export const siteSettings: string = "/siteSettings";
export const socialLinks: string = "/siteSettings/socialLinks";
export const customLinks: string = "/siteSettings/customLinks";
export const appLinks: string = "/siteSettings/appLinks";
export const documentsPath: string = "/documents";
export const documentsAddPath: string = "/documents/add";
export const user:string ="/users";
export const userReportPath: string = "/users/userReport";
export const mediaPath: string = "/media";
export const categoriesPath: string = "/media/categories";
export const subCateorgyPath: string = "/subcategory/add";
export const contentTitlesEdit: string = "/media/contentTitles/edit/";  
export const userDetails: string = "userDetails";
export const bulkUploadPath: string = "/media/bulkUpload";
