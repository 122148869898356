import {
  channelCreatedStatus,
  createChannel,
  updateChannel,
} from "_pages/channels/channelSlice";
import { selectProviders } from "_pages/provider/providerSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChannelStatus,
  getChennelAvailiblityBooleans,
  ScrollTop,
} from "utils/utilityFunctions";
import { useHistory } from "react-router";
import { nameValidation } from "utils/validation";
import { trim, isEmpty } from 'lodash';

export const useChannelsFormHook = (props) => {
  const [isTitleError, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isFormPrefilled, setPreFilledStatus] = useState(false);
  const [subTitle, setSubTitle] = useState("");
  const channelCreationStatus = useSelector(channelCreatedStatus);
  const [isFormDataLoading, setFormDataLoading] = useState(!!props.edit);
  const [formData, setFormData] = useState({
    name: "",
    providerId: "",
    inClub: false,
    appAndweb: false,
    hotel: false,
    status: false,
    displayName: "",
    description: "",
    profilePicRef: "",
    mediaSpaceRef: "",
    tag: "",
    tenants: [],
  });
  const [errors, setError] = useState({
    name: "",
    providerId: "",
    inClub: "",
    appAndweb: "",
    hotel: "",
    status: "",
    displayName: "",
    description: "",
    profilePicRef: "",
    mediaSpaceRef: "",
  });
  const { providers } = useSelector(selectProviders);

  const { channelDetails } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (channelCreationStatus) {
      setLoading(false);
      setErrorMessage("");
      history.push('/channels');
    }
  }, [channelCreationStatus]);

  useEffect(() => {
    if (channelDetails && channelDetails.length > 0) {
      setPreFilledStatus(true);
      let channelDetail = channelDetails[0];
      setFormData({
        name: channelDetail?.name,
        providerId: channelDetail?.providerId,
        inClub: getChennelAvailiblityBooleans(
          "inClub",
          channelDetail?.availability
        ),
        appAndweb: getChennelAvailiblityBooleans(
          "appAndweb",
          channelDetail?.availability
        ),
        hotel: getChennelAvailiblityBooleans(
          "hotel",
          channelDetail?.availability
        ),
        status: getChannelStatus(channelDetail),
        displayName: channelDetail?.displayName,
        description: channelDetail?.description,
        profilePicRef: channelDetail?.profileImageUrl ?? "",
        mediaSpaceRef: channelDetail?.mediaSpaceImageUrl ?? "",
        tag: channelDetail?.tag,
        tenants: channelDetail?.tenants,
      });
      setFormDataLoading(false);
      setSubTitle(channelDetail?.name);
    } else {
      setSubTitle("Create Channel");
    }
  }, [channelDetails]);
  const getErrorText = (stateData:any=false)=>{
    let frmData:any = stateData ? stateData : formData,errObj:any = {};
    if(nameValidation(trim(frmData.name)) === false){
      errObj = {name:"Please add atleast 1 alphanumeric character"};
    }
    if(isEmpty(trim(frmData?.providerId))){
      errObj = {...errObj,providerId:"Required provider"};
    }
    if(frmData?.inClub === false && frmData?.appAndweb  === false &&  frmData?.hotel === false && frmData?.status === false){
      errObj = {...errObj,Checkboxes:"Select Any"};
    }
    if(frmData?.status){
      if(trim(frmData.displayName)?.length <= 3){
        errObj = {...errObj,displayName:"Please add atleast 4 alphanumeric character"};
      }
      if(trim(frmData.description)?.length <= 3){
        errObj = {...errObj,description:"Please add atleast 4 alphanumeric character"};
      }
      if(isEmpty(trim(frmData.profilePicRef))){
        errObj = {...errObj,profilePicRef:"Required Profile Pic"};
      }
      if(isEmpty(trim(frmData.mediaSpaceRef))){
        errObj = {...errObj,mediaSpaceRef:"Required MediaSpace"};
      }
    }
    setError(errObj)
    return errObj;
  }
  const submitForm = () => {
    const isError = getErrorText();
    if(isEmpty(isError)){
      setLoading(true);
      if (isFormPrefilled) {
        dispatch(
          updateChannel(
            {
              name: formData.name,
              description: formData.description,
              displayName: formData.displayName,
              appAndweb: formData.appAndweb,
              hotel: formData.hotel,
              inClub: formData.inClub,
              active: formData.status,
              providerId: formData.providerId,
              tenants: formData.tenants,
              profileImage: formData.profilePicRef,
              mediaImage: formData.mediaSpaceRef,
              tag: formData.tag,
            },
            false,
            []
          )
        );
      } else {
        dispatch(
          createChannel({
            name: formData.name,
            description: formData.description,
            displayName: formData.displayName,
            appAndweb: formData.appAndweb,
            hotel: formData.hotel,
            inClub: formData.inClub,
            active: formData.status,
            providerId: formData.providerId,
            tenants: ["wexer"],
            profileImage: formData.profilePicRef,
            mediaImage: formData.mediaSpaceRef,
          })
        );
      }
    }
  };

  return {
    providers,
    submitForm,
    getErrorText,
    errors,
    isTitleError,
    setErrorMessage,
    isLoading,
    setLoading,
    isFormPrefilled,
    setPreFilledStatus,
    subTitle,
    setSubTitle,
    channelCreationStatus,
    formData,
    setFormData,
    isFormDataLoading,
  };
};
