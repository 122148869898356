import { channelCreatedError, channelCreatedStatus, getChannelListfromApi, selectChannels } from "_pages/channels/channelSlice";
import { selectUser } from "_pages/loginscreen/authSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import { useChannelsIndexHook } from "./useChannelsIndex.hooks";

export const useChannelsTableHook = () => {
  const [channelDetails, setChannelDetails] = useState([] as any);
  const user = useSelector(selectUser);
  const [numSelected] = useState(0);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { channels } = useSelector(selectChannels);
  const channelCreationStatus = useSelector(channelCreatedStatus);
  const chennelError = useSelector(channelCreatedError);
  const { selectedValue: filterParam } = useChannelsIndexHook();

  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (data !== null) {
      const ch = channels;
      let newData = [] as any;
      if (filterParam && filterParam !== "") {
        for (let i = ch.length; i--; ) {
          const { providerId } = ch[i];
          if (providerId === filterParam) {
            newData.unshift(ch[i]);
          }
        }
        setData(newData);
      } else {
        setData(channels);
      }
    }
  }, [filterParam]);

  useEffect(() => {
    if (channels) {
      if (filterParam && filterParam !== "") {
        const ch = channels;
        let newData = [] as any;
        for (let i = ch.length; i--; ) {
          const { providerId } = ch[i];
          if (providerId === filterParam) {
            newData.unshift(ch[i]);
          }
        }
        setData(newData);
      } else {
        setData(channels);
      }
    }
  }, [channels]);

  useEffect(() => {
    dispatch(getChannelListfromApi());
  }, [dispatch]);

  return {
    channelDetails,
    setChannelDetails,
    user,
    numSelected,
    data,
    setData,
    channels,
    channelCreationStatus,
    chennelError,
  };
};
