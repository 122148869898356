// import { paginationValues } from "constants/enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { enteredQuery } from "_pages/contentTitles/contentTitleSlice";
import { getSort } from '../../components/Sort/Sort.Slice';
import { keys } from "lodash";
import { usePaginationHook } from 'components/Pagination/Pagination.Hook';
export const useTitleTableHook = (props) => {
  const { pageConfig, optionalData, numSelected } = props;
  const {paginationData,handelPaginate} = usePaginationHook();
  const defaultOrderBy = () => {
    if (props.from === "Event") {
      return "scheduled_Time"
    }
    if (props.from === "contentTitles") {
      return "creationDate";
    }
    return "weekdays";
  }
  const enteredSearchQuery = useSelector(enteredQuery);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(props.from ? defaultOrderBy() : "className");
  const [selected, setSelected] = useState([] as any);
  const [page, setPage] = useState(0);
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationData.DEFAULT_PAGE_SIZE
  );
  const sortData:any = useSelector(getSort);
  const handelContentSorting = ()=>{
    switch (sortData.index) {
      case 1:
      case 2:
        setOrderBy(props.headCells[0].id);
        break;
      case 3:
      case 4:
        setOrderBy(props.headCells[1].id);
        break;
    }
    if (sortData.isDefault) {
      if(keys(sortData?.existedSort).length>1 && sortData?.existedSort['/contentTitles']){
        const sortInfo = sortData.existedSort['/contentTitles'];
        setOrder(sortInfo.index > 1 ? sortInfo.sort : "desc");
      }
      else
        setOrder("desc");
    } else {
      setOrder(sortData.sort)
    }
  }
  const handelEventSorting = ()=>{
    setOrderBy(props.headCells[0].id);
    if (sortData.isDefault) {
      setOrder("desc");
    } else {
    setOrder(sortData.sort)}
  }
  useEffect(()=>{
    switch (sortData.pageName) {
      case '/contentTitles':
        handelContentSorting();
        break;
      case '/schedule':
        handelEventSorting();
        break;
    }
  },[sortData])
  useEffect(() => {
    if (numSelected === 0) {
      setSelected([]);
    }
  }, [numSelected]);

  // Not in use
  // useEffect(() => {
  //   if (enteredSearchQuery !== "" && enteredSearchQuery?.length > 0) {
  //     setOrderBy("");
  //   } else {
  //     setOrderBy(props.from ? defaultOrderBy() : "className");
  //   }
  // }, [enteredSearchQuery]);

  useEffect(() => {
    if (pageConfig?.pageNumber) {
      if (pageConfig.pageNumber !== 0) {
        setPage(pageConfig.pageNumber - 1);
      }
      // setOrderBy(props.from ? defaultOrderBy() : "className");
      setRowsPerPage(pageConfig.NumberOfRowsSelected);
    }
  }, [pageConfig]);

  useEffect(() => {
    if (optionalData && optionalData.length > 0) {
      setSelected([...optionalData]);
    } else {
      setSelected([]);
    }
    setButtonStatus(true);
  }, [optionalData]);
  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    isButtonDesabled,
    setButtonStatus,
    rowsPerPage,
    setRowsPerPage,
    sortData,
    paginationData,
    handelPaginate
  };
};
