import { Theme, createStyles } from "@material-ui/core/styles";
const lightColor = "rgba(255, 255, 255, 0.7)";
export const FormCommonStyle = (theme: Theme) =>
  createStyles({
    formControl: {
      margin: "16px 0px 8px 0px !important",
      width: "100%",
    },
    item: {
      maxWidth: 512,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    categoryPromoted:{
      margin: "16px 0px 8px 9px !important",
      width: "100%",
      color: "#263238"
    }
  });

export const PickerCommonStyle = {
  datePicker: {
    marginTop: "15px",
    maxWidth: "200px",
  },
};
export const TextCommonStyle = {
  btnText: {
    color: "#36dcb6",
  },
};
export const PaperCommonStyle = {
  paper: {
    maxWidth: 928,
    margin: "auto",
    background: "#fff",
    zIndex: 100,
    marginTop: -161,
    paddingTop: 33.1,
    paddingLeft: 31,
    paddingRight: 31,
    borderRadius: 4,
    minHeight: 680,
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
  },
};
export const AppCommonStyle = {
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
};
export const SearchBarCommonStyle = (theme: Theme) => ({
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    marginTop: -20,
    zIndex: 2000,
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
});

export const DisplayCommonStyle = {
  block: {
    display: "block",
  },
  input: {
    display: "none",
  },
};

export const AddUserCommonStyle = (theme: Theme) => ({
  addUser: {
    marginRight: theme.spacing(1),
  },
});

export const ContentWrapperCommonStyle = {
  contentWrapper: {
    marginTop: 20,
  },
  contentWrapperTop: {
    marginBottom: 33,
  },
};

export const SecondaryBarCommonStyle = {
  secondaryBar: {
    zIndex: -1,
  },
};

export const LinkCommonStyle = (theme: Theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
});

export const InputCommonStyle = {
  inputName: {
    marginTop: 16,
  },
  inputDisplayName: {
    marginTop: 1,
  },
};

export const Form2CommonStyle = {
  formControl2: {
    margin: "21px 0px 8px 0px",
    width: "100%",
  },
};
export const PaddingCommonStyle = {
  lable: {
    paddingLeft: 8,
  },
};

export const TitleArrowSubtitleCommonStyle = () =>
  createStyles({
    title: {
      fontfamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontstyle: "normal",
      lineHeight: 1.5,
      letterspacing: 0.09,
      color: "rgba(0, 0, 0, 0.54)",
      cursor: "pointer",
    },
    arrow: {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 20,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      marginTop: 2,
    },
    subTitle: {
      fontfamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontstyle: "normal",
      lineHeight: 1.5,
      letterspacing: 0.09,
      color: "rgba(0, 0, 0, 0.87)",
    },
  });

export const SearchAddBarCommonStyle = (theme) => ({
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  secondaryBar: {
    zIndex: -1,
  },
});

export const FormPaddingCommonStyle = {
  form: {
    paddingTop: 5,
  },
};

export const MainCommonStyle = (theme) => ({
  main: {
    flex: 1,
    // padding: "192px 32px 32px 32px",
    background: "rgb(239,239,244)",
    "&::before": {
      position: "absolute",
      top: 50,
      right: 0,
      background: theme.palette.secondary.main,
    },
  },
});

export const RootSpinnerCommonStyle = {
  root: {
    color: "#fff",
    borderRadius: 4,
    height: 30,
    width: 67,
    fontFamily: "roboto",
    fontSize: 13,
    fontWeight: 500,
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: "#259a7f",
    },
  },
  spinner: {
    margin: 8,
    padding: 4,
  },
};

export const HeaderRootCommonStyle = {
  root: {
    backgroundColor: "#fff",
    width: 250,
    marginTop: 16,
  },
};

export const HeaderMenuAndButtonCommonStyle = (theme) => ({
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  button: {
    borderColor: lightColor,
  },
});

export const HeaderLinkCommonStyle = (theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
});

export const HeaderTabBarCommonStyle = {
  tabBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
  },
};

export const HeaderSecondaryBarCommonStyle = (height) => {
  return {
    secondaryBar: {
      zIndex: 1,
      height: height,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    },
  };
};

export const HeaderFixedHeaderCommonStyle = () =>
  createStyles({
    fixedHeader: {
      position: "fixed",
      width: "100%",
      zIndex: 10,
    },
  });

export const HeaderH56CommonStyle = {
  header: {
    height: 56,
  },
};

export const HeaderSelectCommonStyle = {
  tenantSelect: {
    margin: "-4px 20px 0px 0px",
    width: "120px",
  },
  channelSelectWrapper: {
    marginRight: 12,
  },
  channelSelectWrapperTenant: {
    marginRight: 36,
    width: "196px",
  },
};

export const HeaderToolBarEndCommonStyle = () =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    toolbarSelect: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  });

export const HeaderToolBarBetweenCommonStyle = () =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexBasis:'50%'
    },
    toolbarSelect: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

export const HeaderRoot2CommonStyle = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: "solid 1px #e0e0e0",
    height: 53,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

export const HeaderWhiteBGRootCommonStyle = {
  root: {
    backgroundColor: "#fff",
    width: 400,
    marginTop: 8,
  },
};

export const HeaderFormControlCommonStyle = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 196,
    borderBottom: "none",
  },
});

export const HeaderSelectLableCommonStyle = {
  lable: {
    color: "#000",
  },
  select: {
    borderBottom: "#fff",
  },
  providerSelect: {
    margin: "-4px 45px 0px 0px !important",
    width: "196px !important",
  },
};
