import axios from 'axios';
import {versionConstant} from 'constants/version'
const config = require('assets/config.json');

function compareVersions(
  currentVersion: string,
  latestVersion: string
): number {
  const currentComponents: number[] = currentVersion.split(".").map(Number);
  const latestComponents: number[] = latestVersion.split(".").map(Number);

  for (
    let i = 0;
    i < Math.max(currentComponents.length, latestComponents.length);
    i++
  ) {
    const current: number = currentComponents[i] || 0;
    const latest: number = latestComponents[i] || 0;

    if (current < latest) {
      return -1; // Current version is less than latest version
    } else if (current > latest) {
      return 1; // Current version is greater than latest version
    }
    // If current and latest components are equal, continue to the next component
  }

  // If all components are equal
  return 0; // Versions are equal
}

function VersionManager() {
  const version = localStorage.getItem("version");
  if (version !== null) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.info("dev mode");
    } else {
      try {
        axios.get(process.env.PUBLIC_URL + "/manifest.json").then((file) => {
          const currentVersion = version.toString();
          const latestVersion = file.data.version.toString();
          console.info(`Old Version :${version.toString()}`);
          console.info(`New Version ${file.data.version.toString()}`);

          const comparisonResult: number = compareVersions(
            currentVersion,
            latestVersion
          );
          if (comparisonResult < 0) {
            const strings = require(`assets/translations/${config.language}`);
            localStorage.setItem("version", file.data.version.toString());
            alert(strings?.app?.update);
            window.location.reload();
          }
        });
      } catch (e) {
        console.info("something went wrong");
        window.location.reload();
      }
    }
  } else {
    //setting version number to localstorage if version not found
    localStorage.setItem("version", versionConstant);
  }
}

export default VersionManager;
