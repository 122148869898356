import { createStyles } from "@material-ui/core/styles";

export const CompInputRootCommonStyle = {
  root: {
    color: "#000",
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: "53px !important",
    fontFamily: "roboto",
    fontSize: 16,
  },
};

export const CompOptionalStyleInputCommonStyle = {
  optionalStyle: {
    margin: "12px 0px 0px 0px",
  },
};

export const CompOptionalInputCommonStyle = () =>
  createStyles({
    optional: {
      textAlign: "right",
      marginBottom: -40,
    },
  });

export const CompLableCommonStyle = () =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: "8px 8px 8px 0px",
    },
    legend: {
      marginLeft: 2,
    },
    lable: {
      paddingLeft: 8,
      cursor: "pointer",
    },
    footerText: {
      paddingLeft: 32,
      fontFamily: "Roboto",
      fontSize: 12,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.58,
      letterSpacing: 0.3,
      color: "rgba(0, 0, 0, 0.54)",
    },
    legendText: {
      color: "#263238",
    },
  });

export const CompTableRootCommonStyle = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: "solid 1px #e0e0e0",
    height: 53,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

export const CompDeleteTitleCommonStyle = {
  deletTitle: {
    flex: "1 1 100%",
    marginRight: 20,
    marginLeft: 20,
  },
  addSubscription:{
     flex: "1 1 50%",
    marginRight: 20,
    marginLeft: 20,
  }
};

export const CompDeletingTitlesCommonStyle = {
  deletingTitles: {
    flex: "1 1 100%",
    marginRight: 20,
    marginLeft: 20,
    color: "rgba(0, 0, 0, 0.26) !important",
  },
};

export const CompDeleteButtonCommonStyle = {
  deletButton: {
    backgroundColor: "transparent",
    color: "#fff",
    border: "#fff 1px solid",
    borderRadius: 4,
    width: 64,
    height: 30,
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.69,
    letterSpacing: 0.3,
    "&:hover": {
      backgroundColor: "transparent",
      color: "#fff",
      border: "#f5f5f5 1px solid",
    },
  },
};

export const CompDividerCommonStyle = {
  divider: {
    height: 24,
    backgroundColor: "#fff",
    marginLeft: "8px !important",
    marginRight: "24px !important",
  },
};

export const CompDividerDisabledCommonStyle = {
  dividerDisabled: {
    height: 24,
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    marginLeft: "8px !important",
    marginRight: "24px !important",
  },
};

export const CompCrossIconCommonStyle = {
  crossIcon: {
    cursor: "pointer",
  },
  crossIconDisabled: {
    cursor: "default",
    color: "rgba(0, 0, 0, 0.26)",
  },
};

export const CompTitleCommonStyle = () =>
  createStyles({
    title: {
      flex: "1 1 100%",
      fontfamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontstyle: "normal",
      lineHeight: 1.5,
      letterspacing: 0.09,
      color: "rgba(0, 0, 0, 0.54)",
    },
  });

export const CompTableRoot2CommonStyle = {
  root: {
    width: "100%",
  },
};

export const CompTitleArrowCommonStyle = () =>
  createStyles({
    title: {
      fontfamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontstyle: "normal",
      lineHeight: 1.5,
      letterspacing: 0.09,
      color: "rgba(0, 0, 0, 0.54)",
      cursor: "pointer",
    },
    arrow: {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 20,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      marginTop: 2,
    },
     noSubTitle: {
      fontfamily: "Roboto",
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontstyle: "normal",
      lineHeight: 1.5,
      letterspacing: 0.09,
      color: "rgba(0, 0, 0, 0.54)"
    },
  });
