import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const useImageUploaderHook = (props) => {
  const { ID } = props.matadata;
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [progress, setProgess] = useState(0);
  const [loading, setLoading] = useState(0);
  const [error, setErrorStatus] = useState("");
  const { value, isFormPrefilled, postImages, tenantId, assetType } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFormPrefilled && value && value !== "") {
      setProgess(0.8);
      setSelectedFile(value);
      setLoading(100);
    }
    if (props.from === "Scheduleevent" && value == "") {
      setProgess(0);
      setSelectedFile(null);
      setLoading(0);
    }
  }, [isFormPrefilled]);

  useEffect(() => {
    if (selectedFile && loading === 15) {
      postImages(selectedFile, ID, dispatch,tenantId,assetType).then((res) => {
        setProgess(1);
        setLoading(100);
        props.callback(res, ID);
      });
      setProgess(0.25);
      let newProgres = Math.random();
      setTimeout(function() {
        setProgess(newProgres < 0.5 ? 0.5 : newProgres);
      }, 900);
    }
  }, [selectedFile]);
  return {
    setProgess,
    setLoading,
    setSelectedFile,
    setErrorStatus,
    loading,
    selectedFile,
    progress,
    error
  };
};
