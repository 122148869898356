import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import Translate from "utils/translate";
import {
  AdminOnlyViewCheck,
  CSOnlyViewCheck,
} from "components/RoleHook/RoleHook";
// import { handelOptions } from "utils/utilityFunctions";
// import { SelectUI } from "containers/contentTitles/Components/Selecters";
import { a11yProps } from "components/Headers";
import { HeaderHooks } from "../Hooks/Header.Hooks";
import PropTypes from "prop-types";
function ChannelsHeader() {
  const {
    // providers,
    channel: {
      tabId,
      // showSelector,
      classes,
      handleChange,
      // handleSelect,
      // getProviderValues,
    }
  } = HeaderHooks();
  return (
        <Tabs
          className={`header-tab-wrapper`}
          value={tabId}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            className={`${classes.tabBar} header-tab`}
            label={Translate({ id: "Channels.channelsTab" })}
            {...a11yProps(0)}
          />

          {(AdminOnlyViewCheck() === true || CSOnlyViewCheck() === true) && (
            <Tab
              className={`${classes.tabBar} header-tab`}
              label={Translate({ id: "Channels.publishingTab" })}
              {...a11yProps(1)}
            />
          )}
        </Tabs>
  );
}
ChannelsHeader.defaultProps = {
  value: "",
  handleProviderSelect: () => {
    throw new Error("Function not implemented.");
  },
  
};
ChannelsHeader.prototype = {
  value: PropTypes.any,
  handleProviderSelect: PropTypes.func,
};
export { ChannelsHeader };