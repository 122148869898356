import { useEffect, useState,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage, ScrollTop } from "utils/utilityFunctions";
import { emailValidation, nameValidation, passwordValidation, securePasswordValidation } from "utils/validation";
import { selectLanguage } from "_pages/contentTitles/contentTitleSlice";
import { appKeyAndSecretLoading, createTenant, generateAppKeySecretFromAPI, getAppKeyAndSecret, getAppKeySecretFromAPI, getCreatedTenant, selectTenants, tenantCreatedStatus, updateTenant } from "_pages/tenants/tenantSlice";
import { isEmpty } from 'lodash';
import {TENANT_ID_LENGTH} from "constants/enums";

export const useTenantFormHook = (props) => {
  const dispatch = useDispatch();
  const createdTenants = useSelector(getCreatedTenant);
  const providerCreationStatus = useSelector(tenantCreatedStatus);
  const { tenants } = useSelector(selectTenants);
  const { language } = useSelector(selectLanguage);
  const [checkWexerCategories, setcheckWexerCategories] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    apikey: "",
    tenantId: "",
    timezone: "",
    offset: "",
    status: false, // set true for tenant as provider
    email: "",
    password: "",
    providerId: "",
    mediaPlatform: 0,
    jwPlayerSiteId: "",
    jwPlayerSecret: "",
    LanguageCodes: [] as any,
    LanguageTag: "",
    isMarketingConsent: false,
    isWexerOwnedCategories:false
  });
  const [errors, setError] = useState({
    name: "",
    tenantId: "",
    timezone: "",
    // offset: "",
    // apikey: "",
    email: "",
    password: "",
    // providerId: "",
    // mediaPlatform: 0,
    jwPlayerSiteId: "",
    jwPlayerSecret: "",
    LanguageCodes: "",
    LanguageTag: "",
  });
  const [isButtonEnabled, setButtonStatus] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [inEditMode, setInEditModel] = useState(false);
  const [isEmailPasswordEditable, setEmailPasswordEditable] = useState(true);
  const [isFormDataLoading, setFormDataLoading] = useState(!!props.edit);
  const [modal, setModal] = useState(false);
  const appKeyAndSecret = useSelector(getAppKeyAndSecret);
  const appKeySecretLoading = useSelector(appKeyAndSecretLoading);
  const [selectedTenantId, setSelectedTenantId] = useState("");

  useEffect(() => {
    return () =>
      setFormData({
        name: "",
        apikey: "",
        tenantId: "",
        timezone: "",
        offset: "",
        status: false,
        email: "",
        password: "",
        providerId: "",
        mediaPlatform: 0,
        jwPlayerSiteId: "",
        jwPlayerSecret: "",
        LanguageCodes: [] as any,
        LanguageTag: "",
        isMarketingConsent: false,
        isWexerOwnedCategories: false
      });
  }, []);

  useEffect(() => {
    setSelectedTenantId(props?.tenant?.tenantID);
  }, [props.tenant]);


  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (formData?.name?.length > 0 && inEditMode == false) {
      let id = formData?.name.split(" ").join("");
      let lowercaseId = id.toLowerCase();
      setFormData({
        ...formData,
        tenantId: lowercaseId.slice(0, TENANT_ID_LENGTH.LIMIT),
      });
    }
  }, [formData.name]);

  const getAppKeySecret = (tenantId: any) => {
    dispatch(getAppKeySecretFromAPI(selectedTenantId));
  }

  const generateAppKeySecret = (tenantId: any) => {
    dispatch(generateAppKeySecretFromAPI(selectedTenantId));
    setModal(false);
  }

  useEffect(() => {
    if (!isLoading && selectedTenantId) {
      getAppKeySecret(selectedTenantId);
    }
  }, [selectedTenantId]);

  useEffect(() => {
    if (formData?.tenantId?.length > 0) {
      let id = formData?.tenantId.split(" ").join("");
      let lowercaseId = id.toLowerCase();
      setFormData({ ...formData, tenantId: lowercaseId.slice(0,TENANT_ID_LENGTH.LIMIT) });
    }
  }, [formData.tenantId]);

  useEffect(() => {
    if (providerCreationStatus) {
      setLoading(false);
      setButtonStatus(true);
    }
  }, [providerCreationStatus]);

  useEffect(() => {
    if (tenants) {
            if (createdTenants && providerCreationStatus) {
              let filteredTenants = tenants.filter(
                (data) => data?.tenant?.tenantID === createdTenants?.tenantID
              );
              setSelectedTenantId(createdTenants?.tenantID);
              if (
                Array.isArray(filteredTenants) &&
                filteredTenants?.length > 0
              ) {
                let offsetName = "";
                let utcOffset = "";
                if (filteredTenants[0]?.tenant.timeZoneInfo) {
                  offsetName =
                    filteredTenants[0]?.tenant?.timeZoneInfo?.offsetName;
                  utcOffset =
                    filteredTenants[0]?.tenant?.timeZoneInfo?.utcOffset;
                }
                let lcode = getLanguage(
                  filteredTenants[0]?.tenant?.languageCodes,
                  language
                );
                setFormData({
                  ...formData,
                  name: filteredTenants[0]?.tenant?.name,
                  tenantId: filteredTenants[0]?.tenant?.tenantID,
                  apikey: filteredTenants[0]?.tenant?.apiKey,
                  email: filteredTenants[0]?.tenant?.providerEmail,
                  status: filteredTenants[0]?.tenant?.active,
                  providerId: filteredTenants[0]?.tenant?.providerId,

                  mediaPlatform: filteredTenants[0]?.tenant?.mediaPlatform,
                  jwPlayerSiteId: filteredTenants[0]?.tenant?.jwPlayerSiteId,
                  jwPlayerSecret: filteredTenants[0]?.tenant?.jwPlayerSecret,
                  LanguageCodes: lcode,
                  LanguageTag: filteredTenants[0]?.tenant?.languageTag,
                  password: "P@ssw0rd", // this is just to show some character in field
                  timezone: offsetName,
                  offset: utcOffset,
                  isMarketingConsent:
                    filteredTenants[0]?.tenant?.isMarketingConsent,
                  isWexerOwnedCategories:
                    filteredTenants[0]?.tenant?.isWexerOwnedCategories,
                });
                setInEditModel(true);
                setButtonStatus(true);
                setcheckWexerCategories(
                    filteredTenants[0]?.tenant?.isWexerOwnedCategories
                  );
              }
            }
            else
            {
                    let filteredTenants = tenants.filter(
                      (data) =>
                        data?.tenant?.tenantID ===
                        (localStorage.getItem("TenantId") ?? "")
                    );
                    if (
                      Array.isArray(filteredTenants) &&
                      filteredTenants?.length > 0
                    ) {
                      setFormData({
                        ...formData,
                        isWexerOwnedCategories:
                          filteredTenants[0]?.tenant?.isWexerOwnedCategories,
                      });
                      setcheckWexerCategories(
                        filteredTenants[0]?.tenant?.isWexerOwnedCategories
                      );
                    }
            }
    }
  }, [tenants, providerCreationStatus]);

  useEffect(() => {
    if (props.formSubmitStatus !== "") {
      setLoading(false);
    }
  }, [props.formSubmitStatus]);

  useEffect(() => {
    if (props.tenant) {
      const selectedTenant = props.tenant;
      let offsetName = "";
      let utcOffset = "";
      if (selectedTenant.timeZoneInfo) {
        offsetName = formData?.timezone !== "" ?formData?.timezone : selectedTenant.timeZoneInfo.offsetName;
        utcOffset =
          formData?.offset !== ""
            ? formData?.offset
            : selectedTenant.timeZoneInfo.utcOffset;
      }
      let lcode = getLanguage(selectedTenant?.languageCodes, language);
      setFormData({
        ...formData,
        name: selectedTenant.name,
        tenantId: selectedTenant.tenantID,
        apikey: selectedTenant.apiKey,
        email: selectedTenant.providerEmail,
        status: selectedTenant.active,
        providerId: selectedTenant.providerId,
        mediaPlatform: selectedTenant.mediaPlatform,
        jwPlayerSiteId: selectedTenant.jwPlayerSiteId,
        jwPlayerSecret: selectedTenant.jwPlayerSecret,
        LanguageCodes: lcode,
        LanguageTag: selectedTenant?.languageTag,
        password: "P@ssw0rd", // this is just to show some character in field
        timezone: offsetName,
        offset: utcOffset,
        isMarketingConsent: selectedTenant?.isMarketingConsent,
        isWexerOwnedCategories: selectedTenant?.isWexerOwnedCategories,
      });
       setcheckWexerCategories(selectedTenant?.isWexerOwnedCategories);
      setInEditModel(true);
      setFormDataLoading(false);
      if (selectedTenant.providerEmail) {
        setEmailPasswordEditable(false);
      }
    } else {
      setInEditModel(false);
      setEmailPasswordEditable(true);
    }
  }, [props.tenant]);

  const getErrorText= (stateData:any=false)=>{
      let frmData:any = stateData ? stateData : formData;
      let isValidTimeZone = frmData.offset !== "" && frmData.timezone !== "",errObj:any = {};
      if(nameValidation(frmData.name) === false){
        errObj = {name:"Required Name"};
      }
      if(nameValidation(frmData.tenantId) === false){
        errObj = {...errObj,tenantId:"Required Tenant id"};
      }
      if(isValidTimeZone === false){
        errObj = {...errObj,timezone:"Required timezone"};
      }
      if(frmData.LanguageCodes.length === 0){
        errObj = {...errObj,LanguageCodes:"Required Template Language"};
      }
      if(isEmpty(frmData.LanguageTag)){
        errObj = {...errObj,LanguageTag:"Required Default Languages Code"};
      }
      if(formData?.status){
        const isEmailValid = emailValidation(formData.email),
        isJWPlayerSiteId = formData.mediaPlatform === 1 && formData?.jwPlayerSiteId && formData?.jwPlayerSiteId.length > 1,
        isJWPlayerSecret = formData.mediaPlatform === 1 && formData?.jwPlayerSecret && formData?.jwPlayerSecret.length > 1,
        isPasswordValid = inEditMode === true ? passwordValidation(formData.password) : securePasswordValidation(formData.password);
        if(isEmailValid === false){
          errObj = {...errObj,email:"Required email"};
        }
        if(isPasswordValid === false){
          errObj = {...errObj,password:"Required password"};
        }
        if(isJWPlayerSiteId === false){
          errObj = {...errObj,jwPlayerSiteId:"Required jwPlayerSiteId"};
        }
        if(isJWPlayerSecret === false){
          errObj = {...errObj,jwPlayerSecret:"Required jwPlayerSecret"};
        }
      }
      setError(errObj)
      return errObj;
  }
  

  const submitForm = () => {
    const isError = getErrorText();
    if(isEmpty(isError)){
      setLoading(true);
      dispatch(inEditMode ? updateTenant(formData) : createTenant(formData));
    }
  };

  return {
    formData,
    setFormData,
    setButtonStatus,
    inEditMode,
    errors,
    getErrorText,
    providerCreationStatus,
    setLoading,
    tenants,
    createdTenants,
    language,
    setInEditModel,
    setEmailPasswordEditable,
    isEmailPasswordEditable,
    isLoading,
    submitForm,
    isButtonEnabled,
    isFormDataLoading,
    appKeyAndSecret,
    getAppKeySecret,
    modal,
    setModal,
    generateAppKeySecret,
    appKeySecretLoading,
    selectedTenantId,
    checkWexerCategories,
  };
};
