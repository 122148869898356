import React from "react";
import {
  COLLECTION_REORDER,
  COLLECTION_STATUS,
  MEDIA_TYPE_VALUES,
  titleStatusEnums,
  VIDEO_STATUS_ENUM,
  CHECK_INPROGRESS_STATUS_BULK_UPLOAD,
  CHECK_CANCEL_STATUS_BULK_UPLOAD,
  CHECK_COMPLETE_STATUS_BULK_UPLOAD,
  BULK_UPLOAD_STATUS,
} from "constants/enums";
import {
  CustomButtonRounded,
  CustomButtonRoundedRed,
  CustomButtonRoundedOrange,
  CustomButtonDisabled,
  MediaTypeButtonRounded,
  CustomButtonRoundedGrey,
} from "../Button";
import UploadProgressBar, { LinearProgressContinueProcessing } from "../Loader/ProgressBar";
import { Typography } from "@material-ui/core";
import Translate from "utils/translate";
import Radio from "@material-ui/core/Radio";
import { TitleStatusComponentStyles } from "style/components";
import { useHistory } from "react-router-dom";

const EnterDetails = () => {
  return (
    <div className="row">
      <Typography color="primary" className="underLineText">
        {Translate({ id: "userMessages.enterDetails" })}
      </Typography>
    </div>
  );
};

export const FeaturedCollection = (props) => {
  const { featured, row, handleFeaturedClick } = props;
  const history = useHistory();
  
  const style = TitleStatusComponentStyles(row.isFeaturedCollection);
  return (
    <React.Fragment>
      <Radio
        checked={row.isFeaturedCollection == true ? true : false}
        onChange={(event) => handleFeaturedClick(event, row.collectionTag)}
        value={row.collectionTag}
        name="radio-collection"
        inputProps={{ "aria-label": "A" }}
        style={style}
        disabled={
          row.isActive == COLLECTION_STATUS.DISABLED ||
          history.location.pathname.includes(COLLECTION_REORDER)
            ? true
            : false
        }
      />
    </React.Fragment>
  );
};

export const COTDCollection = (props) => {
  const { cotd, row, handleCOTDClick } = props;
  const history = useHistory();

  const style = TitleStatusComponentStyles(row.isClassOfDayCollection);
  return (
    <React.Fragment>
      <Radio
        checked={row.isClassOfDayCollection == true ? true : false}
        onChange={(event) => handleCOTDClick(event, row.collectionTag)}
        value={row.collectionTag}
        name="radio-cotd"
        inputProps={{ "aria-label": "A" }}
        style={style}
        disabled={
          row.isActive == COLLECTION_STATUS.DISABLED ||
          history.location.pathname.includes(COLLECTION_REORDER)
            ? true
            : false
        }
      />
    </React.Fragment>
  );
};

const TitleStatusButton = (props) => {
  const {
    status,
    uploadProgess,
    isTitleInfoMissing,
    row,
    isTableOptional,
    isBreadcrumb=false,
    mediaType,
  } = props;
  return (
    <React.Fragment>
      {status === titleStatusEnums.Published && (
        <CustomButtonRounded text="Published" />
      )}
      {status === titleStatusEnums.Incomplete && !isTitleInfoMissing && (
        <CustomButtonRoundedRed text="incomplete" />
      )}
      {(status === titleStatusEnums.EncodingQueued ||
        status === titleStatusEnums.NormalisationQueued) &&
        !isBreadcrumb && (
          <LinearProgressContinueProcessing statusCode={status} />
        )}
      {status === titleStatusEnums.Error &&
        row?.streamingLink !== null &&
        row?.streamingLink !== "" && (
          <CustomButtonRoundedRed text="incomplete" />
        )}
      {status === titleStatusEnums.Error && row?.streamingLink === null && (
        <UploadProgressBar
          isTableOptional={isTableOptional}
          row={row}
          isError={true}
          progress={uploadProgess}
        />
      )}
      {status === titleStatusEnums.Unpublished && (
        <CustomButtonRoundedOrange text="Unpublished" />
      )}
      {status === titleStatusEnums.Incomplete && isTitleInfoMissing && (
        <EnterDetails />
      )}
      {status === titleStatusEnums.Uploading && (
        <UploadProgressBar progress={uploadProgess} />
      )}
      {status === titleStatusEnums.Completed && (
        <CustomButtonRounded text="Complete" />
      )}
      {mediaType === MEDIA_TYPE_VALUES.AUDIO && (
        <MediaTypeButtonRounded text="Audio" />
      )}
      {mediaType === MEDIA_TYPE_VALUES.VIDEO && (
        <MediaTypeButtonRounded text="Video" />
      )}
    </React.Fragment>
  );
};

export const VideoStatusButton = (props) => {
  const {
    status,
    uploadProgess,
    isTitleInfoMissing,
    row,
    isTableOptional,
    isVideoPrimaryComplete
  } = props;

  
  return (
    <React.Fragment>
      {/* Todo WDP-3359  props.rows.streamingLink */}
      {[VIDEO_STATUS_ENUM.PROCESSING, VIDEO_STATUS_ENUM.NORMALIZING].includes(
        status
      ) &&
        !isVideoPrimaryComplete && (
          <LinearProgressContinueProcessing statusCode={status} />
        )}
      {[VIDEO_STATUS_ENUM.UPLOADING].includes(status) &&
        !isVideoPrimaryComplete && (
          <UploadProgressBar progress={uploadProgess} />
        )}
      {[VIDEO_STATUS_ENUM.UPLOADED].includes(status) &&
        !isVideoPrimaryComplete && <CustomButtonDisabled text="Uploaded" />}
      {(isVideoPrimaryComplete || status === VIDEO_STATUS_ENUM.COMPLETED) && (
        <CustomButtonRounded text="Complete" />
      )}
      {/* {[VIDEO_STATUS_ENUM.FAILED,VIDEO_STATUS_ENUM.CANCELED].includes(status) && (
        <CustomButtonRoundedRed text="Failed" />
      )} */}

      {/*
        {status === titleStatusEnums.Incomplete && !isTitleInfoMissing && (
          <CustomButtonRoundedRed text="incomplete" />
        )} 
      */}
    </React.Fragment>
  );
};
export const TitleStatusButtonWithDeepObject = (props) => {
  const { uploadProgess, isTitleInfoMissing, row, isTableOptional } = props;

  let item = row.video;
  return (
    <React.Fragment>
      {item &&
        uploadProgess === null &&
        (item.status === titleStatusEnums.Completed
          ? true
          : item.status === titleStatusEnums.Published) && (
          <CustomButtonRounded text="Complete" />
        )}
      {item &&
        uploadProgess !== null &&
        item.status === titleStatusEnums.Completed && (
          <UploadProgressBar progress={uploadProgess} />
        )}
      {item &&
        item.status === titleStatusEnums.Incomplete &&
        !isTitleInfoMissing && <CustomButtonRoundedRed text="incomplete" />}
      {((item && item.status === titleStatusEnums.EncodingQueued) ||
        (item && item.status === titleStatusEnums.NormalisationQueued)) && (
        <LinearProgressContinueProcessing statusCode={item.status} />
      )}

      {!item && uploadProgess !== null && isTableOptional === true && (
        <UploadProgressBar
          isTableOptional={true}
          row={row}
          isError={true}
          progress={uploadProgess}
        />
      )}
      {item && item.status === titleStatusEnums.Error && (
        <UploadProgressBar
          isTableOptional={isTableOptional}
          row={row}
          isError={true}
          progress={uploadProgess}
        />
      )}
      {item && item.status === titleStatusEnums.Unpublished && (
        <CustomButtonRoundedOrange text="Unpublished" />
      )}
      {item &&
        item.status === titleStatusEnums.Incomplete &&
        isTitleInfoMissing && <EnterDetails />}
      {!item && uploadProgess !== null && isTableOptional === false && (
        <UploadProgressBar progress={uploadProgess} />
      )}
      {!item && uploadProgess === null && (
        <CustomButtonDisabled text="Upload" />
      )}
    </React.Fragment>
  );
};
export const LiveEventStatusButton = (props) => {
  const { uploadProgess, isTitleInfoMissing, row, isTableOptional } = props;

  let item = row.video;
  return (
    <React.Fragment>
      {item &&
        (item.status === titleStatusEnums.Completed
          ? true
          : item === titleStatusEnums.Published) && (
          <CustomButtonRounded text="Complete" />
        )}
      {item &&
        uploadProgess !== null &&
        item.status === titleStatusEnums.Completed && (
          <UploadProgressBar progress={uploadProgess} />
        )}
      {item &&
        item.status === titleStatusEnums.Incomplete &&
        !isTitleInfoMissing && <CustomButtonRoundedRed text="incomplete" />}
      {item && item.status === titleStatusEnums.Error && (
        <UploadProgressBar
          isTableOptional={isTableOptional}
          row={row}
          isError={true}
          progress={uploadProgess}
        />
      )}
      {item && item.status === titleStatusEnums.Unpublished && (
        <CustomButtonRoundedOrange text="Unpublished" />
      )}
      {item &&
        item.status === titleStatusEnums.Incomplete &&
        isTitleInfoMissing && <EnterDetails />}
      {item && item.status === titleStatusEnums.Published && (
        <CustomButtonRounded text="Published" />
      )}
      {!item && uploadProgess === null && <CustomButtonDisabled text="NA" />}
    </React.Fragment>
  );
};
export const BulkUploadStatusButton = (props) => {
  const {
    status
  } = props;
  
  return (
    <React.Fragment>
      {CHECK_CANCEL_STATUS_BULK_UPLOAD.includes(status) && (
        <CustomButtonRoundedGrey text={BULK_UPLOAD_STATUS.Cancelled} />
      )}
      {CHECK_COMPLETE_STATUS_BULK_UPLOAD.includes(status) && (
        <CustomButtonRounded text={BULK_UPLOAD_STATUS.Complete} />
      )}
      {/* {CHECK_INPROGRESS_STATUS_BULK_UPLOAD.includes(status) && (
        <CustomButtonRoundedRed text={BULK_UPLOAD_STATUS.InProcess} />
      )} */}
    </React.Fragment>
  );
};

export default TitleStatusButton;
