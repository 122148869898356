import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { downloadreportApi, uploadApi, userApi } from "api/api";
import {
  UserManagementAPIRes,
  UserManagementTableItem,
  UserManagementTableItems,
} from "models/userManagement.model";
import { AppThunk, RootState } from "store/store";
import * as ApiConstants from "constants/apiConstants";
import { 
  DATE_MONTH_NAME_YEAR,
  USER_MANAGEMENT_MESSAGES,
  apiResponseEnums
} from "constants/enums";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import moment from "moment";
import { CommonAPIResponse, ErrorObjectType } from "models/common.model";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";

export interface UMState {
  umData: UserManagementAPIRes;
  data: UserManagementTableItems | null;
  pageLoading: boolean;
  loadingTemplate: boolean;
  loadingFile: boolean;
}

const initialState: UMState = {
  umData: {
    pageNo: 0,
    totalRecords: 0,
    totalPages: 0,
    data: [],
  },
  data: [],
  pageLoading: true,
  loadingTemplate: false,
  loadingFile: false,
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setUMLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
      if (action.payload) {
        state.data = null as null;
      }
    },
    setUMData: (state, action: PayloadAction<UserManagementAPIRes>) => {
      let response: UserManagementAPIRes = action.payload;
      state.umData = response;
      state.data = response.data.map((listItem: UserManagementTableItem) => {
        let createdDate = listItem.createdDate;
        try {
          createdDate = moment(createdDate).format(DATE_MONTH_NAME_YEAR);
        } catch (error) {}
        return {
          ...listItem,
          createdDate,
        };
      }) as UserManagementTableItems;
    },
    setLoadingTemplate: (state, action: PayloadAction<boolean>) => {
      state.loadingTemplate = action.payload as boolean;
    },
    setLoadingFile: (state, action: PayloadAction<boolean>) => {
      state.loadingFile = action.payload as boolean;
    },
    resetUmData: (state) => {
      state.data = [];
      state.umData = {
        pageNo: 0,
        totalRecords: 0,
        totalPages: 0,
        data: [],
      };
    },
  },
});

export const {
  setUMLoading,
  setUMData,
  setLoadingTemplate,
  setLoadingFile,
  resetUmData,
} = userManagementSlice.actions;

let timer: any = 0;

export const getUMListFromApi = (
  tenantId: string | null = "",
  refreshRequired: boolean = true
): AppThunk => (dispatch) => {
  if (refreshRequired) {
    dispatch(setUMLoading(true));
  }
  userApi.post(ApiConstants.getUMListAPI, { tenantId }).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setUMData(res.data as UserManagementAPIRes));
      dispatch(setUMLoading(false));
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(resetUmData());
      dispatch(setUMLoading(false));
    }
  });
};

export const downloadUMTemplate = (): AppThunk => (dispatch) => {
  dispatch(setLoadingTemplate(true));
  downloadreportApi.get(ApiConstants.downloadUMTemplateAPI).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      const url = window.URL.createObjectURL(new Blob([res.data] as any));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "UserDataTemplate.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(setLoadingTemplate(false));
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_MANAGEMENT_MESSAGES.DOWNLOADING_TEMPLATE,
        } as CommonAPIResponse)
      );
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setLoadingTemplate(false));
    }
  });
};

export const handleUMFileUpload = (
  formData: FormData,
  tenantId: string | null
): AppThunk => (dispatch) => {
  dispatch(
    setApiResponse({
      status: apiResponseEnums.CREATED,
      data: USER_MANAGEMENT_MESSAGES.UPLOADING_FILE,
    } as CommonAPIResponse)
  );
  uploadApi.post(ApiConstants.uploadUMCsvDataAPI, formData).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getUMListFromApi(tenantId, false));
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};

export const downloadUMUploadedFile = (
  tenantId: string | null = "",
  fileId: string = "",
  fileName: string = ""
): AppThunk => (dispatch) => {
  dispatch(setLoadingFile(true));
  downloadreportApi
    .post(ApiConstants.downloadUMUploadedFileAPI, { tenantId, fileId })
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        const url = window.URL.createObjectURL(new Blob([res.data] as any));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}_report.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(setLoadingFile(false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MANAGEMENT_MESSAGES.DOWNLOADING_REPORT,
          } as CommonAPIResponse)
        );
      } else {
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
        dispatch(setLoadingFile(false));
      }
    });
};

export const resetUMPageData = (): AppThunk => (dispatch) => {
  dispatch(resetUmData());
  clearTimeout(timer);
};

export const umListItems = (state: RootState) => state.userManagement.data;

export default userManagementSlice.reducer;
