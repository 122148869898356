import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import * as ApiConstants from "constants/apiConstants";
import {
  userApi,
  uploadApi,
  downloadreportApi,
  tenantHeaderApi,
  tenantHeaderTransform,
} from "api/api";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { getChannelAvailabilityEnums, getCustomAPIErrorMessage, handleUnauthorized } from "utils/utilityFunctions";
import { apiResponseEnums, USER_MESSAGES } from "constants/enums";
import { setApiResponse } from "Layout/layoutSlice";
import { ErrorObjectType } from "models/common.model";

interface TenantState {
  tenantsData: any;
  error: string;
  isTenantCreated: boolean;
  createdTenant: any;
  tenantsTermsData: any;
  isLoading: boolean;
  isUploadTerms: boolean;
  isDownloadTerms: boolean;
  appKeyAndSecret: any;
  loadingAppKeyAndSecret: boolean;
}

const initialState: TenantState = {
  tenantsData: {},
  isTenantCreated: false,
  error: "",
  createdTenant: {},
  tenantsTermsData: {},
  isLoading: false,
  isUploadTerms: false,
  isDownloadTerms: false,
  appKeyAndSecret: {},
  loadingAppKeyAndSecret: false,
};

export const tenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    getTenantList: (state, action: PayloadAction<any>) => {
      state.tenantsData = action.payload;
    },

    tenantCreated: (state, action: PayloadAction<any>) => {
      state.isTenantCreated = action.payload;
    },
    uploadTermsCreated: (state, action: PayloadAction<any>) => {
      state.isUploadTerms = action.payload;
    },
    downloadTermsCreated: (state, action: PayloadAction<any>) => {
      state.isDownloadTerms = action.payload;
    },
    errorInDownload: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    errorInTenantCreation: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    errorInTermsCreation: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setCreatedTenant: (state, action: PayloadAction<any>) => {
      state.createdTenant = action.payload;
    },
    getTenantTermsList: (state, action: PayloadAction<any>) => {
      state.tenantsTermsData = action.payload;
    },
    loadingTenantTermsList: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    setAppKeyAndSecret: (state, action: PayloadAction<any>) => {
      state.appKeyAndSecret = action.payload;
    },
    loadingAppKeyAndSecret: (state, action: PayloadAction<any>) => {
      state.loadingAppKeyAndSecret = action.payload;
    },
  },
});

export const {
  getTenantList,
  tenantCreated,
  errorInTenantCreation,
  setCreatedTenant,
  getTenantTermsList,
  uploadTermsCreated,
  errorInTermsCreation,
  errorInDownload,
  downloadTermsCreated,
  loadingTenantTermsList,
  setAppKeyAndSecret,
  loadingAppKeyAndSecret,
} = tenantSlice.actions;

// RR || The function below is called to check user auth status from firebase

export const publishChannelsOnTenant = (
  data: any,
  tenantId,
  tagsTobePublish,
  tagsToUnpublish
): AppThunk => (dispatch) => {
  let channels = [...data];
  tenantHeaderTransform();
  tenantHeaderApi
    .put(ApiConstants.updateChannelsInBulkAPI, channels)
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        let payload = {
          ChannelIdsToPublish: tagsTobePublish,
          ChannelIdsToUnPublish: tagsToUnpublish,
        };
       tenantHeaderTransform(tenantId);
        tenantHeaderApi
          .put(ApiConstants.publishTenantsAPI, payload)
          .then((newRes) => {
            dispatch(getTenantsListfromApi());
            if (newRes.status === apiResponseEnums.DELETED) {
              dispatch(tenantCreated(true));
              setTimeout(function() {
                dispatch(tenantCreated(false));
              }, 2500);
              dispatch(
                setApiResponse({
                  status: res.status,
                  data: USER_MESSAGES.SAVED,
                })
              );
            } else {
              dispatch(errorInTenantCreation("Something went wrong"));
              setTimeout(function() {
                dispatch(errorInTenantCreation(""));
              }, 1500);
              dispatch(
                setApiResponse({
                  status: res.status,
                  data: getCustomAPIErrorMessage({
                    status: res.status as number,
                    data: res.data as ErrorObjectType,
                  }),
                })
              );
            }
          });
      } else {
        dispatch(errorInTenantCreation("Something went wrong"));
        setTimeout(function() {
          dispatch(errorInTenantCreation(""));
        }, 1500);
        const callback = () =>
          dispatch(
            regfeshTokenId(
              publishChannelsOnTenant(
                data,
                tenantId,
                tagsTobePublish,
                tagsToUnpublish
              )
            )
          );
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
};

export const createTenant = (data: any): AppThunk => (dispatch) => {
  let filteredLanguageTag = data?.LanguageCodes.filter(
    (obj) => obj.tag == data.LanguageTag
  );
  let LanguageTag =
    filteredLanguageTag[0]?.tag != "" ? filteredLanguageTag[0]?.tag : "";

  let LanguageCodes = data.LanguageCodes.map((x) => x.tag);
  let body = {
    tenant: {
      tenantID: data.tenantId,
      isWexerOwnedCategories:data.isWexerOwnedCategories,
      name: data.name,
      apikey: data.apikey,
      timeZoneInfo: {
        offsetName: data.timezone,
        utcOffset: data.offset,
      },
      IsProviderActive: data.status,
      languageCodes: LanguageCodes != "" ? LanguageCodes : null,
      languageTag: LanguageTag,
    },
    email: data.status ? data.email : "",
    password: data.status ? data.password : "",
    mediaPlatform: data?.mediaPlatform == "0" ? 0 : 1,
    jwPlayerSiteId: data?.mediaPlatform == "1" ? data?.jwPlayerSiteId : null,
    jwPlayerSecret: data?.mediaPlatform == "1" ? data?.jwPlayerSecret : null,
    isMarketingConsent: data?.isMarketingConsent,
  };

  userApi.post("/api/v1/tenant", body).then((res) => {
    if (res.status === apiResponseEnums.CREATED) {
      dispatch(tenantCreated(true));
      let createdTenant = res.data;
      dispatch(setCreatedTenant(createdTenant));
      dispatch(getTenantsListfromApi());
      setTimeout(function() {
        dispatch(tenantCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      dispatch(errorInTenantCreation("Email Already Exists"));
      setTimeout(function() {
        dispatch(errorInTenantCreation(""));
      }, 3000);
      const callback = () => dispatch(regfeshTokenId(createTenant(data)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};

export const getTenantsListfromApi = (): AppThunk => (dispatch) => {
  userApi.get(ApiConstants.getTenantsListAPI).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getTenantList({ tenants: res.data }));
    } else {
      const callback = () => dispatch(regfeshTokenId(getTenantsListfromApi()));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(getTenantList({ tenants: [] }));
    }
  });
};

export const getTenantsTermsListfromApi = (): AppThunk => (dispatch) => {
  dispatch(loadingTenantTermsList(true));
  userApi.get(ApiConstants.getTenantsTermsListAPI).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getTenantTermsList({ tenantsTerms: res.data }));
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(getTenantsTermsListfromApi()));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(getTenantTermsList({ tenantsTerms: [] }));
    }
  }).finally(()=>{
    dispatch(loadingTenantTermsList(false))
  });
};

export const uploadTermsApi = (data, tenantId): AppThunk => (dispatch) => {
  delete uploadApi.headers["TenantID"];
  uploadApi.setHeader("TenantID", tenantId);
  uploadApi.post(ApiConstants.uploadTermsAPI, data).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(uploadTermsCreated(true));
      setTimeout(function() {
        dispatch(uploadTermsCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      const callback = () => dispatch(regfeshTokenId(uploadTermsApi(data, tenantId)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};
export const downloadTermsApi = (
  tenantId,
  languageCode,
  versionnumber,
  fileName
): AppThunk => (dispatch) => {
  let parameters;
  let url;

  parameters = `tenantId=${tenantId}&languageCode=${languageCode}&versionnumber=${versionnumber}&consentType=tnc`;
  url = ApiConstants.downloadTermsAPI;

  downloadreportApi.get(url + parameters).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(downloadTermsCreated(true));
      //Create blob link to download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      //Append to html page
      document.body.appendChild(link);
      //Force download
      link.click();
      //Clean up and remove the link
      link.remove();
      setTimeout(function() {
        dispatch(downloadTermsCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.DOWNLOAD })
      );
    } else {
      const callback = () =>
        dispatch(
          regfeshTokenId(
            downloadTermsApi(tenantId, languageCode, versionnumber, fileName)
          )
        );
      handleUnauthorized(res.status, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};
export const deletProvider = (uids: any): AppThunk => (dispatch) => {
  dispatch(getTenantList({ tenants: null }));
  userApi.delete(`/api/v1/tenant`, uids).then((res) => {
    dispatch(getTenantsListfromApi());
  });
};

export const updateTenant = (data: any): AppThunk => (dispatch) => {
  let filteredLanguageTag = data?.LanguageCodes.filter(
    (obj) => obj.tag == data.LanguageTag
  );
  let LanguageTag =
    filteredLanguageTag[0]?.tag != "" ? filteredLanguageTag[0]?.tag : "";

  let LanguageCodes = data.LanguageCodes.map((x) => x.tag);

  let body = {
    tenant: {
      tenantID: data.tenantId,
      name: data.name,
      apikey: data.apikey,
      isWexerOwnedCategories: data.isWexerOwnedCategories,
      timeZoneInfo: {
        offsetName: data.timezone,
        utcOffset: data.offset,
      },
      IsProviderActive: data.status,
      providerId: data.providerId,
      languageCodes: LanguageCodes != "" ? LanguageCodes : null,
      languageTag: LanguageTag,
    },
    email: data.email,
    password: data.password,
    mediaPlatform: data?.mediaPlatform == "0" ? 0 : 1,
    jwPlayerSiteId: data?.mediaPlatform == "1" ? data?.jwPlayerSiteId : null,
    jwPlayerSecret: data?.mediaPlatform == "1" ? data?.jwPlayerSecret : null,
    isMarketingConsent: data?.isMarketingConsent,
  };
  let url = `/api/v1/tenant/${data.tenantId}`;
  userApi.put(url, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(tenantCreated(true));
      let createdTenant = res.data;
      dispatch(setCreatedTenant(createdTenant));
      dispatch(getTenantsListfromApi());
      setTimeout(function() {
        dispatch(tenantCreated(false));
      }, 3000);
      dispatch(
        setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
      );
    } else {
      const callback = () => dispatch(regfeshTokenId(updateTenant(data)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
  });
};

export const getAppKeySecretFromAPI = (tenantId: string): AppThunk => (dispatch) => {
  dispatch(loadingAppKeyAndSecret(true));
  userApi.get(`${ApiConstants.getAppKeyAndSecretAPI}/${tenantId}`).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setAppKeyAndSecret(res.data));
    } else {
      const callback = () => dispatch(regfeshTokenId(getAppKeySecretFromAPI(tenantId)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setAppKeyAndSecret({}));
    }
  }).finally(()=>{
    dispatch(loadingAppKeyAndSecret(false))
  });
};

export const generateAppKeySecretFromAPI = (tenantId: string): AppThunk => (dispatch) => {
  dispatch(loadingAppKeyAndSecret(true));
  userApi.put(`${ApiConstants.generateAppKeyAndSecretAPI}/${tenantId}`).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(setAppKeyAndSecret(res.data));
    } else {
      const callback = () =>
        dispatch(regfeshTokenId(getAppKeySecretFromAPI(tenantId)));
      handleUnauthorized(res.status as number, callback);
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setAppKeyAndSecret({}));
    }
  }).finally(()=>{
    dispatch(loadingAppKeyAndSecret(false))
  });
};

export const selectTenants = (state: RootState) => state.tenants.tenantsData;
export const tenantCreatedStatus = (state: RootState) =>
  state.tenants.isTenantCreated;
export const tenantCreatedError = (state: RootState) => state.tenants.error;
export const getCreatedTenant = (state: RootState) =>
  state.tenants.createdTenant;
export const selectTenantsTerms = (state: RootState) =>
  state.tenants.tenantsTermsData;
export const termsCreatedStatus = (state: RootState) =>
  state.tenants.isUploadTerms;
export const downloadStatus = (state: RootState) =>
  state.tenants.isDownloadTerms;
export const termsCreatedError = (state: RootState) => state.tenants.error;
export const getLoadingTenantTermsList = (state: RootState) => state.tenants.isLoading;
export const getAppKeyAndSecret = (state: RootState) => state.tenants.appKeyAndSecret;
export const appKeyAndSecretLoading = (state: RootState) => state.tenants.loadingAppKeyAndSecret;
export default tenantSlice.reducer;
