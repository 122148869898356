import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { removeTrailer } from "_pages/contentTitles/contentTitleSlice";
import { isEmpty } from 'lodash';

export const useVideoUploaderHook = (props) => {
  const {
    value,
    isFormPrefilled,
    tag,
    trailer,
    uploadProgress,
    LocalContentStatus,
    errorInUpload
  } = props;
  const dispatch  = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [file, setFile] = useState(null as any);
  const [progress, setProgess] = useState(0);
  const [loading, setLoading] = useState(0);
  const [newError, setErrorStatus] = useState("");
  const [isTrailerRemoved, setTrailerRemoveStatus] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isAnotherUploadGoingOn, setAnotherUploadStatus] = useState(false);
  useEffect(()=>{
    if(!isEmpty(errorInUpload) && loading > 0){
      setLoading(0);
    }
  },[errorInUpload,loading])
  useEffect(() => {
    if (uploadProgress?.tag) {
      if (tag === uploadProgress.tag) {
        setAnotherUploadStatus(false);
        if (uploadProgress?.progress) {
          setProgess(uploadProgress.progress);
          setLoading(80);
          if (uploadProgress.progress === 1) {
            setAnotherUploadStatus(false);
            setLoading(99);
            if (trailer) {
              setProcessing(true);
            }
          }
        } else {
          setLoading(0);
        }
      } else {
        if (uploadProgress.progress === 1) {
          setAnotherUploadStatus(false);
        } else {
          setAnotherUploadStatus(true);
        }
      }
    } else if (uploadProgress?.tag === null) {
      setAnotherUploadStatus(false);
      if (uploadProgress.progress === 1) {
        setLoading(99);
        if (trailer) {
          setProcessing(true);
        }
      }
    }
  }, [uploadProgress, tag, trailer]);

  useEffect(() => {
    if (isFormPrefilled && value && value !== "") {
      setProgess(80);
      setSelectedFile(value);
      if (tag === uploadProgress.tag) {
        setProgess(uploadProgress.progress);
        //  setLoading(80);
      } else {
        setLoading(100);
      }
    }
    if (isFormPrefilled === true) {
    }
  }, [isFormPrefilled, value, tag, uploadProgress]);

  useEffect(() => {
    if (LocalContentStatus) {
      if (LocalContentStatus === 1 || LocalContentStatus === 11) {
        setProcessing(true);
      } else {
        setProcessing(false);
        setLoading(100);
      }
    }
  }, [LocalContentStatus]);

  useEffect(() => {
    if (isTrailerRemoved) {
      setSelectedFile(null);
      setLoading(0);
      setProcessing(false);
    }
  }, [isTrailerRemoved]);

  useEffect(() => {
    if (props.formChange === false) {
      setErrorStatus("");
    }
  }, [props.formChange]);
  const updateRemoveTrailer = (titleTag)=>{
    // dispatch(removeTrailer(`${titleTag}`))
    setTrailerRemoveStatus(true);
  }
  return {
    setErrorStatus,
    setFile,
    setProgess,
    setLoading,
    selectedFile,
    setTrailerRemoveStatus,
    updateRemoveTrailer,
    loading,
    isAnotherUploadGoingOn,
    isProcessing,
    progress,
    newError,
  };
};
