import { userRoleEnums } from "constants/enums";
import { layoutSelectedTenantValue } from "Layout/layoutSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import { selectUser } from "_pages/loginscreen/authSlice";
import { selectTenantAsProviders } from "_pages/provider/providerSlice";
import { useAppSelector } from "store/hooks";
import { UserDataModel } from "models/common.model";

export const useUserManagementIndexHook = () => {
  const [tenantId, setTenantId] = useState<string | null>("");
  const selectedTenantValue = useAppSelector<string | null>(layoutSelectedTenantValue);
  const user = useAppSelector<UserDataModel>(selectUser);
  const { tenantAsProviders } = useSelector(selectTenantAsProviders);

  useEffect(() => {
    ScrollTop();
  });

  useEffect(() => {
    if (user?.role === userRoleEnums.CS_USER) {
      if (localStorage.getItem("csTenantId") !== null) {
        setTenantId(localStorage.getItem("csTenantId"));
      } else {
        setTenantId(null);
      }
    }
    if (user?.role === userRoleEnums.TENANT_AS_PROVIDER_USER) {
      setTenantId(tenantAsProviders[0].tenantId);
    }
  }, [tenantAsProviders, user, localStorage.getItem("csTenantId")]);

  return {
    selectedTenantValue,
    tenantId,
  };
};
