import React from "react";
import PropTypes from "prop-types";
import SelectTenants from "_pages/tenants/Components/SelectTenants";
import { HeaderHooks } from "../../Hooks/Header.Hooks";

const TenentDropDown = () => {
  const {
    classes,
    selectedTenantValue,
    setTenantValue,
  } = HeaderHooks();
  return (
    <div className="header-dropdown">
      <SelectTenants
        value={selectedTenantValue?.name ?? null}
        lable="Tenant"
        from="header-layout"
        selectTenantCallback={setTenantValue}
      />
    </div>
  );
};

export { TenentDropDown };
