import React from "react";
import { HeaderHooks } from "../Hooks/Header.Hooks";
import { TenentDropDown } from "./DropDown/TenentDropDown";
import * as RouteContants from "constants/routeConstants";
import { ProviderDropDown } from "./DropDown/ProviderDropDown";
import {
  CSOnlyView,
  AdminOnlyView,
  ProviderOnlyView
} from "components/RoleHook/RoleHook";
import { ChannelDropDown } from "./DropDown/ChannelDropdown";
const DropDownComponent = () => {
  const { pathname, getProviderValues,
    reviews: {
      reviewsFilterParamChannel,
    } } = HeaderHooks();
  let renderContent = (<></>) as any;
  switch (pathname) {
    case RouteContants.channelsPath:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <ProviderDropDown />
          </CSOnlyView>
          <AdminOnlyView>
            {" "}
            <ProviderDropDown />{" "}
          </AdminOnlyView>
        </React.Fragment>
      );
      break;
    case RouteContants.scheduleTitles:
    case RouteContants.collectionsPath:
      renderContent = (
        <CSOnlyView>
          {" "}
          <TenentDropDown />{" "}
        </CSOnlyView>
      );
      break;
    case RouteContants.contentTitles:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
          <ProviderOnlyView>
            {" "}
            <ChannelDropDown />{" "}
          </ProviderOnlyView>
        </React.Fragment>
      );
      break;
    case RouteContants.reviews:
      renderContent = (
        <React.Fragment>
          <AdminOnlyView>
            <ChannelDropDown
              from="reviews"
              isDisabled={getProviderValues() !== ""}
            />
            <ProviderDropDown
              from="reviews"
              isDisabled={
                reviewsFilterParamChannel && reviewsFilterParamChannel !== ""
              }
            />
          </AdminOnlyView>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
      break;
    case RouteContants.subscriptionsPath:
      renderContent = (
        <CSOnlyView>
          <TenentDropDown />
        </CSOnlyView>
      );
      break;
    case RouteContants.userManagement:
      renderContent = (
        <CSOnlyView>
          <TenentDropDown />
        </CSOnlyView>
      );
      break;
    case RouteContants.userReportPath:
      renderContent = (
        <CSOnlyView>
          <TenentDropDown />
        </CSOnlyView>
      );
      break;
    case RouteContants.socialLinks:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
      break;
    case RouteContants.appLinks:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
      break;
    case RouteContants.customLinks:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
      break;
    case RouteContants.documentsPath:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
    case RouteContants.categoriesPath:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
    case RouteContants.bulkUploadPath:
      renderContent = (
        <React.Fragment>
          <CSOnlyView>
            <TenentDropDown />
          </CSOnlyView>
        </React.Fragment>
      );
      break;
    default:
      break;
  }
  return (
    <div className='drop-down-container'>
      {renderContent}
    </div>
  );
};

DropDownComponent.propTypes = {};

export { DropDownComponent };
