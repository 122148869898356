import {
  channelAvailability,
  intensityConstants,
  titleStatusEnums,
  skillLevelConstants,
  BACKGROUND_FETCH_TIMEOUT,
  TENANT_CONNECT_VALUES,
  mediaPlatforms,
  FORMAT_DATE_TIME_STR,
  FORMAT_DATE_STR,
  FORMAT_TIME_STR,
  WEEK_DAY_ARR,
  FORMAT_DATE_CUSTOM_DRAG_ERROR,
  ORDER_BY,
  LEVEL_ASPECT_RATIO,
  apiResponseEnums,
  API_ERRORS_MESSAGES,
  API_ERROR_TYPE,
  userRoleEnums,
} from "constants/enums";
import { AppThunk } from "store/store";
import { ImageUploadMatadata, titleUploadMatadata } from "utils/staticMataData";
import { find } from "lodash";
import { EVENT_TYPE_VALUES } from "constants/enums";
import { useEffect, useRef } from "react";
import { categoriesPath, contentTitles } from "constants/routeConstants";
import { setApiResponse } from "Layout/layoutSlice";
import { ErrorObjectType } from "models/common.model";
const _ = require("lodash");
const moment = require("moment-timezone"); //moment-timezone
const TranslateByLoad = require("assets/translations/en.json");

export const isNotEmptyArray = (params :Array<[]>|undefined|null) => {
  if(!Array.isArray(params)) return false;
  if(!params)return false;
  if (!params.length) return false;
  return true;
}

export const getChannelAvailabilityEnums = (
  inClub: boolean,
  appAndWeb: boolean,
  hotel: boolean
) => {
  let channelAvailabilitySet = [] as any;
  if (inClub) {
    channelAvailabilitySet.push(channelAvailability.IN_CLUB_AVAILIBLITY);
  }
  if (appAndWeb) {
    channelAvailabilitySet.push(channelAvailability.APP_AND_WEB_AVAILIBLITY);
  }
  if (hotel) {
    channelAvailabilitySet.push(channelAvailability.IN_HOTEL_AVAILIBLITY);
  }

  return channelAvailabilitySet;
};

export const getChennelAvailiblityBooleans = (
  availiblity: string,
  data: any
) => {
  if (
    availiblity === "inClub" &&
    data.includes(channelAvailability.IN_CLUB_AVAILIBLITY)
  ) {
    return true;
  } else if (
    availiblity === "appAndweb" &&
    data.includes(channelAvailability.APP_AND_WEB_AVAILIBLITY)
  ) {
    return true;
  } else if (
    availiblity === "hotel" &&
    data.includes(channelAvailability.IN_HOTEL_AVAILIBLITY)
  ) {
    return true;
  } else {
    return false;
  }
};

export const resetStateToDefault = (callback: any): AppThunk => (dispatch) => {
  setTimeout(function() {
    dispatch(callback());
  }, 2500);
};

export const getChannelStatus = (data: any) => {
  if (data.status === "No") {
    return false;
  } else {
    return true;
  }
};

export const getEquipment = (data: String, metaDataEquipments: any) => {
  if (Array.isArray(data)) {
    var result: Array<any> = new Array<any>();
    data.forEach((element) => {
      let equipment = {};
      equipment =isNotEmptyArray(metaDataEquipments) &&
        metaDataEquipments.filter(function(e) {
          return e.typeTag == element;
        });
      if (Array.isArray(equipment)) {
        //result.push({ tag: equipment[0]?.typeTag, name: equipment[0]?.name });
        result.push(equipment[0]?.name);
      }
    });
    return result;
  } else {
    return [];
  }
};

export const getfocusArea = (data: String, metaDatafocusArea: any) => {
  if (Array.isArray(data)) {
    var result: Array<any> = new Array<any>();
    data.forEach((element) => {
      let focusArea = {};
      focusArea = isNotEmptyArray(metaDatafocusArea) &&
        metaDatafocusArea.filter(function(e) {
          return e.tag == element;
        });
      if (Array.isArray(focusArea)) {
        //result.push({ tag: focusArea[0]?.tag, name: focusArea[0]?.name });
        result.push(focusArea[0]?.name);
      }
    });
    return result;
  } else {
    return [];
  }
};
export const ScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const isScheduleAddByCSRoleConnect = (
  isCSUserRole: any,
  tentantId: any,
  configTenantConnectValue
) => {
  if (isCSUserRole === true && (tentantId == null || tentantId === "")) {
    return "";
  } else if (
    isCSUserRole === true &&
    (tentantId != null || tentantId !== "") &&
    (configTenantConnectValue == null ||
      configTenantConnectValue === TENANT_CONNECT_VALUES.NONE ||
      configTenantConnectValue === TENANT_CONNECT_VALUES.ONDEMAND)
  ) {
    return "";
  } else {
    return "add";
  }
};
export const isTitlesAddByCSRoleConnect = (
  isCSUserRole: any,
  tentantId: any,
  configTenantConnectValue
) => {
  if (isCSUserRole === true && (tentantId == null || tentantId === "")) {
    return "";
  } else if (
    isCSUserRole === true &&
    (tentantId != null || tentantId !== "") &&
    (configTenantConnectValue == null ||
      configTenantConnectValue === TENANT_CONNECT_VALUES.NONE ||
      configTenantConnectValue === TENANT_CONNECT_VALUES.ONDEMAND)
  ) {
    return "";
  } else {
    return "add";
  }
};
export const getLanguage = (data: String, languages: any) => {
  if (data && data.length > 0) {
    var result: Array<any> = new Array<any>();
    if (Array.isArray(data)) {
      data.map((element) => {
        let language = {};
        language = languages.filter(function(e) {
          return e.ietfTag == element;
        });
        if (Array.isArray(language)) {
          result.push({
            tag: language[0]?.ietfTag,
            name: language[0]?.displayName,
          });
        }
      });
      return result;
    }
  } else {
    return [];
  }
};

export const getLabels = (data: any) => {
  if (data && data.length > 0) {
    var result: Array<any> = new Array<any>();
    data.forEach((element) => {
      result.push({ tag: element, name: element });
    });
    return result;
  }
};

export const getChannelName = (data: String) => {
  if (data && data.length > 0) {
    return data.split("|");
  } else {
    return [];
  }
};

export const getIntensityEnums = (data: string) => {
  if (data) {
    let i = intensityConstants.indexOf(data);
    if (i !== -1) {
      return i + 1;
    }
    return 1;
  }
};
export const getIntensityValue = (data: number) => {
  if (data > 0) {
    return intensityConstants[data - 1];
  }
  return "";
};

export const getSkillLableValue = (data: number) => {
  if (data > 0) {
    return skillLevelConstants[data - 1];
  }
  return "";
};

export const getSkillLableEnums = (data: string) => {
  if (data) {
    let i = skillLevelConstants.indexOf(data);
    if (i !== -1) {
      return i + 1;
    }
    return 1;
  }
};

export const isTitleDeletable = (data: number) => {
  if (data) {
    if (titleStatusEnums.Incomplete === data) {
      return false;
    } else {
      // RR ||if true it means checkbox will be disabled, hence title can't be deleted
      return true;
    }
  }
};

export const isCollectionDeletable = (data: any) => {
  if (data) {
    if (data == true) {
      return false;
    } else {
      return true;
    }
  }
};
const formValidationWithType = (formData: any) => {
  if (
    formData.name !== "" &&
    formData.description &&
    formData.description !== "" &&
    formData.skillLevel &&
    formData.skillLevel !== "" &&
    formData.videoLanguage &&
    formData.videoLanguage !== "" &&
    formData.category &&
    formData.category !== "" &&
    formData.equipments.length > 0 &&
    formData.intensity &&
    formData.intensity !== "" &&
    formData.cover &&
    formData.cover !== ""
  ) {
    return true;
  }
  return false;
};
const formValidationWithLiveEventType = (formData: any) => {
  if (
    formData.name !== "" &&
    formData.description &&
    formData.description !== "" &&
    formData.skillLevel !== "" &&
    formData.videoLanguage &&
    formData.videoLanguage !== "" &&
    formData.category &&
    formData.category !== "" &&
    formData.equipments.length > 0 &&
    formData.intensity !== ""
  ) {
    return true;
  }
  return false;
};
export const contentFormValidation = (
  formData: any,
  extraValidation: boolean,
  Event: any
) => {
  let validatationStatus =
    Event?.type == 2
      ? formValidationWithLiveEventType(formData)
      : formValidationWithType(formData);

  if (extraValidation && validatationStatus) {
    if (
      formData.channel &&
      formData.channel !== "" &&
      formData.releaseDate &&
      formData.releaseDate !== ""
    ) {
      return true;
    }
    return false;
  }
  return validatationStatus;
};
export const contentFormValidationForNextFourHrs = (formData: any) => {
  let cutoffString = formData.dateTime;
  let dateTimeInUTC = moment.utc(cutoffString);
  var duration = moment.duration(
    moment(dateTimeInUTC.toISOString()).diff(
      moment()
        .utc()
        .toISOString()
    )
  );
  var hours = duration.asHours();
  if (hours < 4) {
    return true;
  }
  return false;
};
export const contentFormVideoValidation = (val) => {
  if (val?.streamingLink === undefined) {
    return true;
  }
  return val?.streamingLink && val?.streamingLink?.length < 3 ? true : false;
};
export const contentFormValidationForPastDateTime = (formData: any) => {
  let cutoffString = formData.dateTime;
  let dateTimeInUTC = moment.utc(cutoffString);
  let difference = moment(dateTimeInUTC.toISOString()).diff(
    moment()
      .utc()
      .toISOString(),
    "minutes"
  );
  if (difference < 0) {
    return true;
  }
  return false;
};

export const checkIfEncodingIsInProgess = (data: any) => {
  data = data.data ?? data;
  if (data.length > 0) {
    for (const event of data) {
      if (
        event.video?.status === titleStatusEnums.EncodingQueued ||
        event.video?.status === titleStatusEnums.NormalisationQueued ||
        (event.video?.trailerLinkWeb && event.video?.trailerLinkMobile === null)
      ) {
        return true;
      }
    }
  }

  return false;
};

export const fetchDataAfterFiveMinutes = (callback, data) => {
  let lastTimeout = sessionStorage.getItem("timeoutsId");
  if (lastTimeout) {
    clearTimeout(JSON.parse(lastTimeout));
  }
  if (checkIfEncodingIsInProgess(data)) {
    const timeOutId = setTimeout(() => {
      callback();
      clearTimeout(timeOutId);
    }, BACKGROUND_FETCH_TIMEOUT);

    sessionStorage.setItem("timeoutsId", JSON.stringify(timeOutId));
  }
};

export const isValidAspectRatio = (aspectRatio, width, height) => {
  let aspectRatioRoundOff = Math.round(aspectRatio * 100) / 100;
  // Calculate the actual aspect ratio and round to the nearest hundredth
  let actualAspectRatio = Math.round((width / height) * 100) / 100;

  // Calculate lower and upper bound
  let lowerBound = aspectRatioRoundOff - LEVEL_ASPECT_RATIO.VALUES;
  let upperBound = aspectRatioRoundOff + LEVEL_ASPECT_RATIO.VALUES;

  // Validate if the actual aspect ratio is within the range
  if (actualAspectRatio >= lowerBound && actualAspectRatio <= upperBound) {
    return true;
  } else {
    return false;
  }
}
export const isImageValid = (
  imageWidth,
  imageHeight,
  requiredWidth,
  requiredHeight,
  aspectRatio,
  fileTypes,
  file,
  from
) => {
  if (file.name && fileTypes) {
    const escapedExtensions = fileTypes
      .split(",")
      .map((ext) => ext.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
      .join("|");

    const regex = new RegExp(`(${escapedExtensions})$`, "i");
    const isMatchingExtension = regex.test(file.name);

    if (!isMatchingExtension) {
      return { validated: false, message: IMAGE_ERROR_TYPES.FILE_TYPE };
    }
  }
  if (from == categoriesPath)
  {
      if (
        (aspectRatio !== null &&
          isValidAspectRatio(aspectRatio, imageWidth, imageHeight)) &&
        (requiredWidth === imageWidth && requiredHeight === imageHeight)
      ) {
        return {validated: true, message: IMAGE_ERROR_TYPES.FILE_TYPE};
      }
  }
  else
  {
     if (
       (aspectRatio !== null &&
         isValidAspectRatio(aspectRatio, imageWidth, imageHeight)) ||
       (requiredWidth === imageWidth && requiredHeight === imageHeight)
     ) {
       return {validated: true, message: IMAGE_ERROR_TYPES.FILE_TYPE};
     }
  }
   
  return { validated: false, message: IMAGE_ERROR_TYPES.ASPECT_RATIO };
};

export const IMAGE_ERROR_TYPES = {
  ASPECT_RATIO: "ASPECT_RATIO",
  FILE_TYPE: "FILE_TYPE"
}

export const isVideoValid = (videoWidth, videoHeight, aspectRatio) => {
  if (aspectRatio !== null && videoHeight / videoWidth == aspectRatio) {
    return true;
  }
  return false;
};

export const checkImageSize = (w, h, id) => {
  if (id == ImageUploadMatadata.PROFILE_IMAGE.ID) {
    if (w == "160" && h == "160") {
      return true;
    }
    return false;
  } else if (id == ImageUploadMatadata.MEDIA_SPACE.ID) {
    if (w == "1920" && h == "564") {
      return true;
    }
    return false;
  } else if (id === titleUploadMatadata.COVER_PHOTO.ID) {
    if (w == "1920" && h == "1080") {
      return true;
    }
    return false;
  }
};
/**
 * Check value is in range Ex : 3 in 2,5 in range
 * @param value  its checking value with checked in range
 * @param rangeValue it's ranged value (0,500)
 * inRange taking intial start and end so splited string
 * return value if matched else false
 */
export const getDurationSecondRange = (value, rangeValue) => {
  let durationArr = rangeValue.split(",");
  if (Array.isArray(durationArr) && Array(durationArr).length > 0) {
    let durationSeconds = _.inRange(value, durationArr[0], durationArr[1]);
    if (durationSeconds) {
      return value;
    } else {
      return false;
    }
  }
};
/**
 * Check value is in array of string Ex : 80 in ["thick scales", 80, "4 foot tail"]
 * @param value  its checking value within array  EX.80
 * @param array it's array value ["thick scales", 80, "4 foot tail"]
 * return array if matched else false getTagInArray
 */
export const getTagInArray = (str, value) => {
  if (str !== null) {
    let array = str.split("|");
    if (Array.isArray(array) && array.length > 0) {
      let checkExist = array.indexOf(value);
      if (checkExist === -1) {
        return false;
      } else {
        return str;
      }
    }
  } else {
    return false;
  }
};

/**
 *
 * @param publishedData it's data of past date  which checking with today's date
 * @returns publishedData if true then return false isSameOrBefore
 */

export const getPastData = (publishedData) => {
  if (publishedData !== null) {
    let date = moment(publishedData, "YYYY-MM-DD").isSameOrBefore(
      moment(new Date(), "YYYY-MM-DD"),
      "day"
    );

    if (date === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getFileName = (type) => {
  if (type !== null) {
    if (type == "BU") {
      return `BillableUserReport.xlsx`;
    } else if (type == "TU") {
      return `TenantUploadReport.xlsx`;
    } else if (type == "CU") {
      return `ContentUsageReport.xlsx`;
    } else if (type == "OD") {
      return `OnDemandTrackingReport.xlsx`;
    } else {
      return `ContentUsageReport.xlsx`;
    }
  } else {
    return `TenantUploadReport.xlsx`;
  }
};

export const getSpecificOfLength = (text, count, insertDots,returnText = true) => {
  if (text && returnText) {
    return text;
    // text?.slice(0, count) + (text?.length > count && insertDots ? "..." : "")
  }
   if (text && returnText === false) {
     return  text?.slice(0, count) + (text?.length > count && insertDots ? "..." : "")
   }
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getLanguageCode = (metaData, language) => {
  if (language == null) {
    return;
  }
  let languages = metaData?.languages;
  if (languages) {
    let results = languages.filter((obj) => {
      return obj.displayName.toLowerCase() == language.toLowerCase();
    });
    if (results && results.length > 0) {
      return results[0].ietfTag;
    }
  }
};
export const getValue = (
  options: Array<any>,
  findValue: object,
  key: string
) => {
  try {
    const selected: any = find(options, findValue);
    return selected[key] ?? "";
  } catch (error) {
    return "";
  }
};
export const handelOptions = (
  isArray = false,
  options: any[] = [],
  nameKey = "displayName"
) => {
  return _.map(options, (option: any) =>
    isArray
      ? option[nameKey]
      : { name: option[nameKey], value: option[nameKey], ...option }
  );
};
export const eventList = () => {
  let ed = [] as any;
  for (let l = 0; l <= 120; l += 5) {
    let eed = 0 as any;
    if (l === 0) eed = 1;
    else eed = l;
    ed.push(eed.toString());
  }
  return ed;
};

export const getCalendarEndDateTime = (durSecond, scheduled_Time, type) => {
  let end: any;
  let durationSecond = durSecond;
  if (type !== EVENT_TYPE_VALUES.SIMULATED) {
    durationSecond = durationSecond * 60;
  }
  if (durationSecond == 0) {
    end = scheduled_Time;
  } else {
    end = moment(scheduled_Time)
      .add(durationSecond, "seconds")
      .format("YYYY-MM-DDTHH:mm:ss"); //scheduled_Time+durationSecond;
  }
  return end;
};

export const getRecurringObject = (scheduled_Time, weekdays) => {
  var check = moment(scheduled_Time, "YYYY/MM/DD");
  var month = check.format("M");
  let weekdaysData = weekdays.map(i => check.weekday(i).format('dd').toUpperCase()).join(',')
  return { repeat: "weekly", month: month, weekDays: weekdaysData };
};

export const getDurationInMiniutes = (durationSecond) => {
  let minutes = Math.ceil(durationSecond / 60);
  return minutes > 0 ? minutes : 1;
};

export const getFilterDataFromSessionStorage = () => {
  if (
    sessionStorage.getItem("filterSessionData") &&
    sessionStorage.getItem("filterSessionData") !== null
  ) {
    return sessionStorage.getItem("filterSessionData");
  }
};

export const setSessionStorageFilterData = (filterData) => {
  sessionStorage.setItem("filterSessionData", JSON.stringify(filterData));
};


export const removeSessionFilterData = () => {
  sessionStorage.removeItem("filterSessionData");
};

export const getSessionCalendarData = () => {
  if (
    sessionStorage.getItem("filterCalendarData") &&
    sessionStorage.getItem("filterCalendarData") !== null
  ) {
    return sessionStorage.getItem("filterCalendarData");
  }
};

export const setSessionCalendarData = (filterData) => {
  sessionStorage.setItem("filterCalendarData", JSON.stringify(filterData));
};

export const removeSessionCalendarData = () => {
  sessionStorage.removeItem("filterCalendarData");
};


export const getUserReportFilterData = () => {
  if (
    sessionStorage.getItem("filterUserReportData") &&
    sessionStorage.getItem("filterUserReportData") !== null
  ) {
    return sessionStorage.getItem("filterUserReportData");
  }
}

export const setUserReportFilterData = (filterData) => {
         sessionStorage.setItem(
           "filterUserReportData",
           JSON.stringify(filterData)
         );
       };

export const removeUserReportFilterData = () => {
         sessionStorage.removeItem("filterUserReportData");
       };


export const getVideoStatus = (videoStatus) => {
  switch (videoStatus) {
    case titleStatusEnums.Completed:
    case titleStatusEnums.Published:
      return titleStatusEnums.Completed;
    case titleStatusEnums.Incomplete:
    case titleStatusEnums.Unpublished:
      return titleStatusEnums.Incomplete;
  }
};

export const amsPlatformTooltipEvent = (tenantAsProviders, tenantsList, isCSUserRole, tenantId) => {
  // To show tooltip and make save disabled if tenant is of AMS platform
  if (isCSUserRole && isNotEmptyArray(tenantsList)) {
    return tenantsList.find(
      (i) =>
        i.tenant.tenantID === tenantId &&
        i.tenant.mediaPlatform === mediaPlatforms[0].tag
    );
  }

  return isNotEmptyArray(tenantAsProviders) && tenantAsProviders.find(
    (i) => i.tenantId === tenantId && i.mediaPlatform === mediaPlatforms[0].tag
  );
}

export const getZoomValue = (curr, type) => {
  if (type === 'zoomin') {
    if (curr === 60) return 30;
    if (curr === 30) return 15;
    if (curr === 15) return 5;
    if (curr === 5) return 1;
    return 1;
  } else {
    if (curr === 1) return 5;
    if (curr === 5) return 15;
    if (curr === 15) return 30;
    if (curr === 30) return 60;
    return 60;
  }
}
export const getResultParams = (
  filterRecords,
  filterData,
  getSortOptions,
  sortData = {}
) => {
  const filterSessionData = getFilterDataFromSessionStorage();
  let _ = !filterRecords ? filterData : filterRecords;
  if (filterSessionData && filterSessionData !== null) {
    _ = JSON.parse(filterSessionData);
  }

  const resultParams = {
    categoryId: _?.category,
    subcategory: _?.subcategory,
    classSubCategoryId: _?.classSubCategoryId,
    labels: _?.keyword,
    query: _?.title,
    duration: _?.durationSecond,
    skill: _?.skillLevel,
    focusAreaTags: _?.focusArea,
    equipments: _?.equipment,
    equipmentTags: _?.equipment,
    intensity: _?.intensity,
    classLanguageName: _?.language,
    channelId: _?.channel,
    providerId: _?.provider,
    contentType: _?.contentType,
    ...getSortOptions(false, sortData),
  };
  return resultParams;
};

export const getSessionFilters=(filterData, getSortOptions, sortData)=>{
  const filterSessionData = getFilterDataFromSessionStorage();
  let _ = filterData;
  if (filterSessionData && filterSessionData !== null) {
    _ = JSON.parse(filterSessionData);
  }
  const resultParams = {
    category: _?.category,
    categoryName: _?.categoryName,
    subcategory: _?.subcategory,
    keyword: _?.keyword,
    title: _?.title,
    durationSecond: _?.durationSecond,
    durationName: _?.durationName,
    skillLevel: _?.skillLevel,
    skillLevelName: _?.skillLevelName,
    focusArea: _?.focusArea,
    equipment: _?.equipment,
    intensity: _?.intensity,
    intensityName: _?.intensityName,
    language: _?.language,
    languageName: _?.languageName,
    channel: _?.channel,
    channelName: _?.channelName,
    provider: _?.provider,
    providerName: _?.providerName,
    contentType: _?.contentType,
    classSubCategoryId: _?.classSubCategoryId,
    ...getSortOptions(false, sortData)
  };
  return resultParams;
}

export const getSessionCalFilters=(filterData)=>{
  const filterSessionData = getSessionCalendarData();
  let _ = filterData;
  if (filterSessionData && filterSessionData !== null) {
    _ = JSON.parse(filterSessionData);
  }
  const resultParams = {
    showCalendar: _?.showCalendar,
    activeStatus: _?.activeStatus,
    eventType: _?.eventType,
  };
  return resultParams;
}

export const getInitParams = ()=>{
  const resultParams = {
    category: "",
    categoryName: "",
    channelName: "",
    durationName: "",
    classSubCategoryId: "",
    subcategory: "",
    keyword: "",
    title: "",
    durationSecond: "",
    intensityName: "",
    languageName: "",
    reset: true,
    skillLevel: "",
    skillLevelName: "",
    focusArea: "",
    equipment: "",
    intensity: "",
    language: "",
    channel: "",
    provider: "",
    providerName: "",
  };
  return resultParams;
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length < 1;
}

export const hasExtensions = (value, exts = ['.csv']) =>
    new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(value);

export const getDateInTenantsTimeZone = (tenantData, dateTime, formatRequired) => {
  if (tenantData && tenantData.timeZoneInfo && dateTime) {
    let cutoffString = dateTime; // in utc
    let utcCutoff = moment.utc(cutoffString);
    let clonedUTC = utcCutoff.clone();
    let displayCutoff = clonedUTC.tz(tenantData.timeZoneInfo.offsetName);
    if (formatRequired) {
      return displayCutoff.format();
    }
    return displayCutoff;
  }
  return dateTime;
}

export const getWeekDaysUTC = (dateTimeInUTC, initialWeekDays, tenantData) => {
  const diff = moment(dateTimeInUTC).day() - getDateInTenantsTimeZone(tenantData, dateTimeInUTC, false).day();
  const weekDays = [] as any;
  initialWeekDays.forEach(item => {
    let day = item + diff;
    if (day < 0) {
      day += 7;
    } else if (day > 6) {
      day -= 7;
    }
    weekDays.push(day);
  });
  return weekDays;
};

export const getTenantTZWeekDays = (dateTimeInUTC, initialWeekDays, tenantData) => {
  const dateInTenantTZ = getDateInTenantsTimeZone(tenantData, dateTimeInUTC, false);
  const dateInUTC = dateInTenantTZ.clone().utc();
  const diff = dateInTenantTZ.day() - dateInUTC.day();
  const weekDays = initialWeekDays.map(weekday => (weekday + diff + 7) % 7);
  return weekDays;
};
export const labelDisplayedRows = ({ from, to, count }) => {
  if (to == -1) to = count;
  else to = to;
  return `${from}-${to} of ${count}`;
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const isDragError = (event, dispatch, tenantData) => {
  const lang = getLanguageFromConfig();
  const droppedDateTime = event.newEvent.start;
  let existingWeekDays = [...event.event.eventData.schedule.weekdays];
  const existingWeekDaysTZ = getTenantTZWeekDays(event.event.eventData.scheduled_Time, existingWeekDays, tenantData);
  const pickedDateTime: any = event.oldEventOccurrence?.start;
  const isLocked = event.event.eventData.isLocked;
  const dragEndUtc = moment.utc(droppedDateTime).format(FORMAT_DATE_TIME_STR);
  const dragEndTZ = getDateInTenantsTimeZone(tenantData, dragEndUtc, false);
  const pickedDateUtc = moment.utc(pickedDateTime.m).format(FORMAT_DATE_TIME_STR);
  const pickedDateTZ = getDateInTenantsTimeZone(tenantData, pickedDateUtc, false);
  const pickedDay = pickedDateTZ.day();
  const droppedDay = dragEndTZ.day();
  if (isLocked) {
    dispatch(
      setApiResponse({
        status: apiResponseEnums.BAD_REQUEST,
        data: lang.Schedule.dragLockedEventMessage,
      })
    );
    return true;
  }
  if (dragEndTZ.isBefore(moment())) {
    const now = getDateInTenantsTimeZone(tenantData, moment.utc().format(FORMAT_DATE_TIME_STR), false);
    const formattedErrorMessage = `${TranslateByLoad?.Schedule?.dragEventInPastMessage} ${now.format(FORMAT_DATE_CUSTOM_DRAG_ERROR)}`;
    dispatch(
      setApiResponse({
        status: apiResponseEnums.BAD_REQUEST,
        data: formattedErrorMessage,
      })
    );
    return true;
  };
  if (droppedDay === pickedDay && dragEndTZ.isSame(pickedDateTZ)) {
    return true;
  }
  if (droppedDay !== pickedDay && existingWeekDaysTZ.includes(droppedDay)) {
    dispatch(
      setApiResponse({
        status: apiResponseEnums.BAD_REQUEST,
        data: lang.Schedule.dragEventOnExistingWeekDay,
      })
    );
    return true;
  };
  return false;
};

export const calculateWeekDays = (pickedDateTime, droppedDateTime, existingWeekDays) => {
  let finalWeekDays = [ ...existingWeekDays ] as any;
  const droppedDay = moment(droppedDateTime.split('.')[0]).day();
  const pickedDay = pickedDateTime.day();
  const indexOfPickedDay = finalWeekDays.findIndex(i => i === pickedDay);
  finalWeekDays[indexOfPickedDay] = droppedDay;
  finalWeekDays = finalWeekDays.sort((a, b) => a - b);
  return finalWeekDays;
}

const getNextToNextOccurrence = (existingWeekDays, nextOccDay) => {
  const nextOccDayIndex = existingWeekDays.indexOf(nextOccDay);
  const nextToNextOccDayIndex = (nextOccDayIndex + 1) % existingWeekDays.length;
  const nextToNextOccDay = existingWeekDays[nextToNextOccDayIndex];
  return nextToNextOccDay;
}

const getNextToNextOccDiff = (weekDays, nextOccDay) => {
  const temp = WEEK_DAY_ARR;
  const nextToNextOccDay = getNextToNextOccurrence(weekDays, nextOccDay);
  let i = temp.findIndex(i => i === nextOccDay);
  let diff = 0;
  while (temp[i] !== nextToNextOccDay) {
    diff++;
    if (i === temp.length - 1) {
      i = 0;
    } else {
      i++;
    }
  }
  return diff;
}

export const getScheduledDateTime = (pickedDateTime, dragEndDateTime, nextOccuenceDateTime, existingWeekDays, tenantData) => {
  moment.tz.setDefault(tenantData?.timeZoneInfo?.offsetName);
  const dragEndTZ = moment(dragEndDateTime.split('.')[0]);
  const nextOccTZ = moment(nextOccuenceDateTime);
  const pickedDateTZ = moment(pickedDateTime.m);
  const nextOccDay = nextOccTZ.day();
  const isAfterCurrent = dragEndTZ.isAfter(moment());
  moment.tz.setDefault();
  // if dragEndDateTime is after currDateTime and before nextOccuenceDateTime then return dragEndDateTime
  if (isAfterCurrent && dragEndTZ.isBefore(nextOccTZ)) return dragEndTZ.format(FORMAT_DATE_TIME_STR);
  const combinedDateTime = `${nextOccTZ.format(FORMAT_DATE_STR)}T${dragEndTZ.format(FORMAT_TIME_STR)}`;
  // if picked occurence is not same as next occurence
  if (pickedDateTZ.format(FORMAT_DATE_STR) !== nextOccTZ.format(FORMAT_DATE_STR)) {
    moment.tz.setDefault(tenantData?.timeZoneInfo?.offsetName);
    const isCurrAfterComb = moment(combinedDateTime).isAfter(moment());
    moment.tz.setDefault();
    if (isCurrAfterComb) {
      return moment(combinedDateTime).format(FORMAT_DATE_TIME_STR)
    } else {
      const diff = getNextToNextOccDiff(existingWeekDays.sort((a, b) => a - b), nextOccDay);
      return moment(combinedDateTime).add(diff, 'days').format(FORMAT_DATE_TIME_STR);
    }
  } else {
    // if picked occurence is same as next occurence
    const diff = getNextToNextOccDiff(existingWeekDays.sort((a, b) => a - b), nextOccDay);
    const nextToNextOccDateTZ = nextOccTZ.add(diff, 'days');
    if (pickedDateTZ.format(FORMAT_DATE_STR) === dragEndTZ.format(FORMAT_DATE_STR)) {
      return moment(combinedDateTime).format(FORMAT_DATE_TIME_STR);
    } else if (dragEndTZ.isAfter(nextToNextOccDateTZ)) {
      // if droppedDay is after nextToNextOccurence day
      return moment(combinedDateTime).add(diff, 'days').format(FORMAT_DATE_TIME_STR);
    } else {
      // if droppedday is before nextToNextOccurence day
      return dragEndTZ.format(FORMAT_DATE_TIME_STR);
    }
  }
};

export const applyUtcOffset = (dateString, timeZoneInfo) => {
  const { offsetName } = timeZoneInfo;
  return moment.tz(dateString, offsetName);
}

export const getSortInfo=(sortData)=>{
  let sortOptions={}
  if(sortData?.index < 3){
    sortOptions = {
      sort:"createddate",
      dir:sortData?.sort
    }
  }
  else{
    sortOptions = {
      sort:"name",
      dir:sortData?.sort
    }
  }
  return sortOptions;
}

export const getSortOptions = (isDefault=false, sortData: any = {})=>{
  if(!sortData.isDefault){
    if(sortData?.existedSort[contentTitles]){
      return getSortInfo(sortData?.existedSort[contentTitles])
    }
    else if(sortData?.pageName === contentTitles){
      return getSortInfo(sortData)
      }
    return {sort:"createddate",dir:ORDER_BY.DESC}
    }
  else{
    if(isDefault) return {}
    else{
      if(sortData?.existedSort[contentTitles]){
        return getSortInfo(sortData?.existedSort[contentTitles])
  }
     return {sort:"createddate",dir:ORDER_BY.DESC}
    }
  }
}

export const getLanguageFromConfig = () => {
  const config = require("assets/config.json");
  const lang = require(`assets/translations/${config.language}.json`);
  return lang;
};

export const getApiErrorMessage = (
  data: string | ErrorObjectType | undefined
) => {
    if (data && typeof data === "object" && data.message) {
      return data.message;
    }
    if (data && typeof data === "object" && data.error) {
      return data.error.message;
    }
    if (data && typeof data === "object" && data.title) {
      return data.title;
    }
    if (data && typeof data === "string") {
      return data;
    }
    return API_ERRORS_MESSAGES.OTHER_ERRORS;
};

export const handleUnauthorized = (status: number, callback: Function) => {
  if (status === apiResponseEnums.UNAUTHRISED) {
    callback();
  }
};

export const getCustomAPIErrorMessage = (res: {
  status: number;
  data: string | ErrorObjectType | undefined;
}) => {
  let message = "";
  if (typeof res.data === "string") {
    message = res.data;
  } else if (typeof res.data === "object" && res.data !== null) {
    message = getApiErrorMessage(res.data);
  } else {
    message = "";
  }
  switch (res.status) {
    case apiResponseEnums.NO_CONTENT:
      message = API_ERRORS_MESSAGES.NO_CONTENT;
      break;
    case apiResponseEnums.NOT_FOUND:
      message = message || API_ERRORS_MESSAGES.API_400;
      break;
    case apiResponseEnums.INTERNAL_ERROR:
      message = message || API_ERRORS_MESSAGES.API_500;
      break;
    case apiResponseEnums.BAD_REQUEST:
      message = message || API_ERRORS_MESSAGES.OTHER_ERRORS;
      break;
    case apiResponseEnums.CONFLICTED:
      message = message || API_ERRORS_MESSAGES.EMAIL_EXISTS;
      break;
    case apiResponseEnums.METHOD_NOT_ALLOWED:
      message = message || API_ERRORS_MESSAGES.NOT_ALLOWED;
      break;
    case apiResponseEnums.UNAUTHRISED:
      message = message || API_ERRORS_MESSAGES.UNAUTHORIZED;
      break;
    default:
      message = API_ERRORS_MESSAGES.OTHER_ERRORS;
      break;
  }
  return message;
};

export const getResponseType = (status: number) => {
  if (status >= 200 && status <= 299) return API_ERROR_TYPE.SUCCESS;
  return API_ERROR_TYPE.ERROR;
};

export const getDateFormat = (date : Date ) => {
  return moment(date).format("DD MMMM YYYY");
};

export const getCreatedDateFormat = (date: string | undefined) => {
  return moment(date).format("DD MMM YYYY");
};

export const getDataUpperCase = (data: string) => {
  return data && data.charAt(0).toUpperCase() + data.slice(1);
}
export const isCSUser = (user:any) => {
      return user?.role === userRoleEnums.CS_USER &&
        (localStorage.getItem("csTenantId") == null ||
          localStorage.getItem("csTenantId") == "")
        ? false
        : true;
    };
    
export const isCSUserOrNot = (user: any) => {
      return user?.role !== userRoleEnums.CS_USER ? false : true;
};

export const  areObjectValuesNotEmpty = (obj:any) => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            // Check if the value is not empty
            if (value === "" || value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
                return false; // Return false if any value is empty
            }
        }
    }
    return true; // Return true if all values are not empty
}

export const allUniqueSupportedLanguagesData = (defaultLanguage,supportedLanguages) => {
      let allUniqueLanguages = [defaultLanguage];
        if (Array.isArray(supportedLanguages) && supportedLanguages) {
          allUniqueLanguages = Array.from(
            new Set([defaultLanguage, ...supportedLanguages]?.map(lang => {
              const [language, region] = lang?.split('-');
              if (region) {
                return `${language}-${region?.toUpperCase()}`;
              } else {
                return language?.toLowerCase(); // If no region, keep the language code as it is
              }
            }))
          );
        };
      return allUniqueLanguages;
}
export const isProviderOrNot = (user: any) => {
  return user?.role !== userRoleEnums.PRVIDER_USER ? false : true;
};

export const upperCaseFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1).toLocaleLowerCase();
};