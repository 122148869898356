import { selectUser } from "_pages/loginscreen/authSlice";
import { deletProvider } from "_pages/provider/providerSlice";
import {  getTenantsListfromApi, selectTenants, setAppKeyAndSecret, tenantCreatedError } from "_pages/tenants/tenantSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

export const useTenantContentHook = () => {
  const history = useHistory();
  const [numSelected, setSelectedNumbers] = useState(0);
  const [data, setData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([] as any);
  const dispatch = useDispatch();
  const { tenants } = useSelector(selectTenants);
  const user = useSelector(selectUser);
  const providerCreationError = useSelector(tenantCreatedError);
  const deletRows = () => {
    dispatch(deletProvider([selectedTags]));
    setSelectedNumbers(0);
  };
  useEffect(() => {
    dispatch(getTenantsListfromApi());
  }, [dispatch]);
  useEffect(() => {
    if (tenants) {
      setData(tenants);
    }
  }, [tenants]);

  useEffect(() => {
    return () => {
      dispatch(setAppKeyAndSecret({}));
    }
  }, [])
  

  const handleTenantBreadcrumb = () => {
    history.push("/tenants");
  };
  return {
    setSelectedNumbers,
    setSelectedTags,
    selectedTags,
    providerCreationError,
    deletRows,
    numSelected,
    data,
    user,
    handleTenantBreadcrumb
  };
};
