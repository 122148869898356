import React from "react";
import Translate from "utils/translate";
import { Tab, Tabs } from "@material-ui/core";
import { a11yProps } from "components/Headers/TabPanel";
import { HeaderHooks } from "../Hooks/Header.Hooks";

function TenantsHeader() {
  const {
    tenant: { classes, tabId, handleChange },
  } = HeaderHooks();
  return (
      <Tabs
        className={`header-tab-wrapper`}
        value={tabId}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          className={`${classes.tabBar} header-tab`}
          label={Translate({ id: "Tenants.tenantTab" })}
          {...a11yProps(0)}
        />

        <Tab
          className={`${classes.tabBar} header-tab`}
          label={Translate({ id: "Tenants.publishingTab" })}
          {...a11yProps(1)}
        />
        <Tab
          className={`${classes.tabBar} header-tab`}
          label={Translate({ id: "Tenants.siteTerms" })}
          {...a11yProps(2)}
        />
      </Tabs>
  );
}

export { TenantsHeader };
