import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { a11yProps } from "components/Headers/TabPanel";
import { userRoleEnums } from "constants/enums";
import { HeaderHooks } from "../Hooks/Header.Hooks";

const ContentHeader = () => {
  const {
    user,
    content: {
      tabId,
      classes,
      handleChange,
    },
  } = HeaderHooks();
  return (
        <Tabs
          className={`header-tab-wrapper`}
          value={tabId}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {(user.role == userRoleEnums.ADMIN_USER ||
            user.role == userRoleEnums.PRVIDER_USER) && (
            <Tab className={`${classes.tabBar} header-tab`} label={"WEXER"} {...a11yProps(0)} />
          )}
        </Tabs>
  );
};

export { ContentHeader };
