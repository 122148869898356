import React from 'react'
import Title from '../Titles/Title'
import TitleStatusButton, {
  BulkUploadStatusButton,
} from "../Tables/TitleStatus";
import { PaperHeaderComponentStyles } from 'style/components';

const Breadcrumb = (props) => {
    const classes = PaperHeaderComponentStyles(); 
    const {top = 'top-10'} = props;
    return (

        <React.Fragment>
            <div className={classes.header+' sticky-position-top '+top}>
                <Title {...props} />
            </div>
        </React.Fragment>
    )
}

export const BreadcrumbWithLable = (props) => {
    const classes = PaperHeaderComponentStyles(); 
    const { status, mediaType } = props;

    return (

        <React.Fragment>
            <div className={classes.headerLable+' sticky-position-top top-10'}>
                <Title {...props} />
                <TitleStatusButton isBreadcrumb status={status} mediaType={mediaType} />
            </div>
        </React.Fragment>
    )
}

export const BreadcrumbStatusBulkUpload = (props) => {
  const classes = PaperHeaderComponentStyles();
  const { status } = props;

  return (
    <React.Fragment>
      <div className={classes.headerLable + " sticky-position-top top-10"}>
        <Title {...props} />
        <BulkUploadStatusButton isBreadcrumb status={status} />
      </div>
    </React.Fragment>
  );
};

export default Breadcrumb;