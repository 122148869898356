import {
  categoryData,
  getCategoryData,
  categoryDataLoading,
  reorderCategory,
} from "_pages/categoryManagement/CategorySlice";
import { selectUser } from "_pages/loginscreen/authSlice";
import {
  userRoleEnums,
  EVENT_STATUS,
  PROMOTED_NONPROMOTED_STATUS,
} from "constants/enums";
import {
  CategoryModel,
  CategoryData,
  SubCategoryData,
  User,
} from "models/Category/CategoryModel";
import { CommonHeadCellItem } from "models/common.model";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getLanguageFromConfig,
  isCSUser,
  isCSUserOrNot as checkRoleCsUser
} from "utils/utilityFunctions";
import {isEmpty} from "lodash";
import { categoriesPath } from "constants/routeConstants";
type TableData = {
  categoryId: string | undefined;
  title: string;
  description: string;
  imageLink: string;
  subCategories?: SubCategoryData[];
};

export const useCategoryContentHook = ({ tenantId }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const lang:any = getLanguageFromConfig();
  const apiData = useAppSelector<CategoryModel | null>(categoryData);
  const dataLoading = useAppSelector<boolean>(categoryDataLoading);
  const user = useAppSelector<User>(selectUser);
  const [tableData, setTableData] = useState<TableData[] | undefined | null>(null);
  const [numSelected, setSelectedNumbers] = useState<number>(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (tenantId) {
      dispatch(
        getCategoryData({
          tenantId,
        })
      );
    }
  }, [tenantId, dispatch]);

   const handleBackCategoryListState = () => {
     history.push(categoriesPath);
   };

  const headCells = useMemo((): CommonHeadCellItem[] => {
    return [
      {
        id: "title",
        numeric: false,
        label: lang.Category.title,
        itemToRender: "title",
        width:300,
      },
      {
        id: "description",
        numeric: true,
        label: lang.Category.description,
        itemToRender: "description",
      },
      {
        id: "status",
        numeric: true,
        label: lang.Category.status,
        itemToRender: "isPromoted",
        width: 150,
      },
    ];
  }, []);

  useEffect(() => {
    if (!tenantId) {
      setTableData([]);
    } else if (tenantId && !dataLoading && isEmpty(apiData?.data)) {
             setTableData([]);
           } else if (tenantId && !dataLoading && !isEmpty(apiData?.data)) {
                    const tableDataCustom = apiData?.data?.map((i: CategoryData) => {
                        return {
                          isPromoted:
                            i.isPromoted === true
                              ? PROMOTED_NONPROMOTED_STATUS.ACTIVE
                              : PROMOTED_NONPROMOTED_STATUS.INACTIVE,
                          imageLink: i.imageLink,
                          title: i.name.invariantText,
                          description: i.description.invariantText,
                          categoryId: i.categoryId,
                          subCategories: i.subCategories,
                        };
                      })
                      
                    setTableData(tableDataCustom);
                  } else {
                    setTableData(null);
                  }
  }, [tenantId, dataLoading, apiData]);

  const handleAdd = () => {
    history.push(`${pathname}/add`);
  };

  const cancelBtnPress =()=>{
     history.push(categoriesPath);
  }
  const handleRowClick = (event: SyntheticEvent, tag: string) => {
    history.push(`${pathname}/${tag}/edit`);
  };
  const handleReorderClick = (event: SyntheticEvent, tag: string) => {
    history.push(`${pathname}/reorder`);
  };
  const handleRowSelect = (tag = []) => {
    if (tag.length === 0) {
      setSelectedNumbers(0);
      setSelectedTags([]);
    } else if (tag.length === 1) {
      const selectedIndex = selectedTags.indexOf(tag[0]);
      if (selectedIndex === -1) {
        setSelectedNumbers(selectedTags.length + 1);
        setSelectedTags([...selectedTags, ...tag]);
      } else {
        let newSelected = [...selectedTags];
        newSelected.splice(selectedIndex, 1);
        setSelectedTags(newSelected);
        setSelectedNumbers(newSelected.length);
      }
    } else {
      setSelectedNumbers(tag.length);
      setSelectedTags([...tag]);
    }
  };

  const handleRowDeselect = () => {
    setSelectedNumbers(0);
    setSelectedTags([]);
  };

const submitCallback = (data) => {
    const payload = {
      tenantId: tenantId,
      categoryTags: data,
    };
    dispatch(reorderCategory(payload, history));
};
  const isCSUserOrNot = checkRoleCsUser(user);
  const isCsUser = isCSUser(user);


  return {
    headCells,
    handleAdd,
    pathname,
    tableData,
    numSelected,
    isCsUser,
    handleRowClick,
    handleRowSelect,
    handleRowDeselect,
    handleReorderClick,
    handleBackCategoryListState,
    cancelBtnPress,
    submitCallback,
    isCSUserOrNot,
  };
};
