import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi, uploadApi } from "api/api";
//import { uploadApi } from 'api/uploadApi';
import * as ApiConstants from "constants/apiConstants";
import { getProviderList } from "_pages/provider/providerSlice";
//import { getTenantList } from 'containers/tenants/tenantSlice';
import { getChannelAvailabilityEnums, getCustomAPIErrorMessage, handleUnauthorized } from "utils/utilityFunctions";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { API_ERRORS_MESSAGES, apiResponseEnums, USER_MESSAGES } from "constants/enums";
import { regfeshTokenIdWithoutDispatch } from "_pages/loginscreen/authSlice";
import { orderBy, trim } from 'lodash';
import { batch } from "react-redux";
import { setApiResponse } from "Layout/layoutSlice";
import { ErrorObjectType } from "models/common.model";
const moment = require("moment-timezone");

interface ChannelState {
  channelData: any;
  loading:any;
  tenantsData: any;
  error: string;
  isChannelCreated: boolean;
  isChannelPublished: boolean;
  isFileUploaded: boolean;
  fileUrl: string;
}

const initialState: ChannelState = {
  channelData: {},
  tenantsData: {},
  isChannelCreated: false,
  isChannelPublished: false,
  isFileUploaded: false,
  loading: false,
  error: "",
  fileUrl: "",
};

export const channelSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    getChannelList: (state, action: PayloadAction<any>) => {
      state.channelData = action.payload;
    },
    getChannelLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    getTenantsData: (state, action: PayloadAction<any>) => {
      state.tenantsData = action.payload;
    },
    channelCreated: (state, action: PayloadAction<any>) => {
      state.isChannelCreated = action.payload;
    },
    channelPubling: (state, action: PayloadAction<any>) => {
      state.isChannelPublished = action.payload;
    },
    errorInChennelCreation: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fileUploaded: (state, action: PayloadAction<any>) => {
      state.isFileUploaded = action.payload.status;
      state.fileUrl = action.payload.url;
    },
  },
});

export const {
  getChannelList,
  getChannelLoading,
  getTenantsData,
  channelCreated,
  errorInChennelCreation,
  fileUploaded,
  channelPubling,
} = channelSlice.actions;

export const updateChannel = (
  data: any,
  isPublishing: boolean,
  tenantsTobePublished: any = [],
  tenantsTobeUnPublished: any = []
): AppThunk => (dispatch) => {
  dispatch(getChannelLoading(true))
  if (isPublishing) {
    let body = {
      name: data.name ?? null,
      description: data.description ?? null,
      displayName: data.displayName ?? null,
      Availability: data.availability,
      active: data.active,
      Provider: data.providerId, //ProviderId
      tenants: data.tenants,
      mediaSpaceImageUrl: data.mediaSpaceImageUrl,
      profileImageUrl: data.profileImageUrl,
      tag: data.tag,
    };
    userApi.put(ApiConstants.publishChannelsAPI, body).then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        let payload = {
          tenantIdsToPublish: tenantsTobePublished,
          tenantIdsToUnPublish: tenantsTobeUnPublished,
        };
        userApi
          .put(
            `${ApiConstants.publishChannelsAPI}/${data.tag}/publish`,
            payload
          )
          .then((newRes) => {
            if (newRes.status === apiResponseEnums.DELETED) {
              dispatch(channelCreated(true));
              setTimeout(function() {
                dispatch(channelCreated(false));
              }, 2500);
              dispatch(getChannelListfromApi());
              dispatch(
                setApiResponse({
                  status: newRes.status,
                  data: USER_MESSAGES.SAVED,
                })
              );
            } else {
              dispatch(errorInChennelCreation("Something went wrong"));
              setTimeout(function() {
                dispatch(errorInChennelCreation(""));
              }, 1500);
              dispatch(
                setApiResponse({
                  status: apiResponseEnums.BAD_REQUEST,
                  data: API_ERRORS_MESSAGES.OTHER_ERRORS,
                })
              );
            }
          });
      } else {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
        const callback = () =>
          dispatch(
            regfeshTokenId(
              updateChannel(
                data,
                isPublishing,
                tenantsTobePublished,
                tenantsTobeUnPublished
              )
            )
          );
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: apiResponseEnums.BAD_REQUEST,
            data:
              res.status === apiResponseEnums.INTERNAL_ERROR
                ? API_ERRORS_MESSAGES.API_500
                : API_ERRORS_MESSAGES.OTHER_ERRORS,
          })
        );
      }
    }).finally(()=>{
      dispatch(getChannelLoading(false))
    });
  } else {
    let body = {
      name: data.name ?? null,
      description: data.description ?? null,
      displayName: data.displayName ?? null,
      Availability: isPublishing
        ? data.availability
        : getChannelAvailabilityEnums(data.inClub, data.appAndweb, data.hotel),
      active: data.active ?? false,
      Provider: data.providerId ?? null, //ProviderId
      tenants: data.tenants,
      mediaSpaceImageUrl: data.mediaImage,
      profileImageUrl: data.profileImage,
      tag: data.tag,
    };

    userApi.put(ApiConstants.createChannelAPI, body).then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(channelCreated(true));
        setTimeout(function() {
          dispatch(channelCreated(false));
        }, 1500);
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        dispatch(getChannelListfromApi());
      } else {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
        const callback = () =>
          dispatch(
            regfeshTokenId(
              updateChannel(data, isPublishing, tenantsTobeUnPublished)
            )
          );
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    }).finally(()=>{
      dispatch(getChannelLoading(false))
    });
  }
};

export function postImages(img: any, type: string, dispatch: any = null) {
  let formData = new FormData();
  var promise = new Promise(function(resolve, reject) {
    let exe = "jpg";
    exe = img[0].toLowerCase().match(/[^:/]\w+(?=;|,)/)[0];
    if (exe === "jpeg") {
      exe = "jpg";
    }
    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        formData.append("img", blob, `${type}_${Math.random()}.${exe}`);
        uploadApi.post("/images", formData).then((res) => {
          if (res.status === apiResponseEnums.CREATED) {
            resolve(res.data);
          } else if (res.status === apiResponseEnums.UNAUTHRISED) {
            if (dispatch) {
              dispatch(
                regfeshTokenIdWithoutDispatch(postImages(img, type, dispatch))
              );
            }
          } else {
            postImages(img, type);
          }
        });
      });
  });
  return promise;
}

export const uploadFile = (data: any, type: string): AppThunk => (dispatch) => {
  const res = postImages(data.img, type);

  dispatch(fileUploaded({ fileUrl: res, status: true }));
};

export const createChannel = (data: any): AppThunk => (dispatch) => {
  let body = {
    name: data.name ?? null,
    description: data.description ?? null,
    displayName: data.displayName ?? null,
    Availability: getChannelAvailabilityEnums(
      data.inClub,
      data.appAndweb,
      data.hotel
    ), // Changing UI lables to integer value
    active: data.active ?? false,
    Provider: data.providerId ?? "", //ProviderId
    tenants: data.tenant,
  };

  if (data.active) {
    let updatedBody = {
      ...body,
      profileImageUrl: data.profileImage,
      mediaSpaceImageUrl: data.mediaImage,
    };

    userApi.post(ApiConstants.createChannelAPI, updatedBody).then((res) => {
      if (res.status === apiResponseEnums.CREATED) {
        dispatch(channelCreated(true));
        setTimeout(function() {
          dispatch(channelCreated(false));
        }, 1500);
        dispatch(getChannelListfromApi());
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
      } else {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
        const callback = () => dispatch(regfeshTokenId(createChannel(data)));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
  } else {
    userApi.post(ApiConstants.createChannelAPI, body).then((res) => {
      if (res.status === apiResponseEnums.CREATED) {
        dispatch(channelCreated(true));
        setTimeout(function() {
          dispatch(channelCreated(false));
        }, 1500);
        dispatch(getChannelListfromApi());
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
      } else {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
        const callback = () => dispatch(regfeshTokenId(createChannel(data)));
        handleUnauthorized(res.status as number, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    });
  }
};
const sortData = (collection: any) => {
  return orderBy(collection, [(item: any) => trim(item.name).toLowerCase()], "asc");
};
export const getChannelListfromApi = (): AppThunk => (dispatch) => {
  userApi.get(ApiConstants.getChannelListAPI).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      let channels = res.data.channels;
      for (var i = channels.length; i--; ) {
        Object.assign(channels[i], {
          providerName: channels[i].provider?.name,
          providerId: channels[i].provider?.tag,
          status: channels[i].active ? "Yes" : "No",
        });
      }

      let data: any = res.data;

      ///Update Timezone
      moment.tz.setDefault();
      if (data.providers && data.tenants) {
        for (let index = 0; index < data.providers.length; index++) {
          let provider = data.providers[index];
          if (provider.isTenantProvider) {
            let tenant = data.tenants.find(
              (obj) => obj.id?.toLowerCase() == provider.tenantId.toLowerCase()
            );
            if (tenant) {
              moment.tz.setDefault(tenant?.timeZoneInfo?.offsetName);
              break;
            }
          }
        }
      }
      batch(()=>{
        dispatch(getProviderList({ providers: sortData(data.providers) }));
        dispatch(getTenantsData({ tenants: sortData(data.tenants) }));
        dispatch(getChannelList({ channels: sortData(data.channels) }));
      });
    } else {
      const callback = () => dispatch(regfeshTokenId(getChannelListfromApi()));
      handleUnauthorized(res.status, callback);
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(getChannelList({ providers: [] }));
    }
  });
};

export const deletProvider = (uids: any): AppThunk => (dispatch) => {
  dispatch(getChannelList({ providers: null }));
  userApi.delete(`/api/v1/providers`, uids).then((res) => {
    dispatch(getChannelListfromApi());
  });
};

export function postAssetsImages(img: any, type: string, dispatch: any = null,tenantId:string,assetType:string) {
  let formData = new FormData();
  var promise = new Promise(function(resolve, reject) {
    let exe = "jpg";
    exe = img[0].toLowerCase().match(/[^:/]\w+(?=;|,)/)[0];
    if (exe === "jpeg") {
      exe = "jpg";
    }
    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        formData.append("image", blob, `${type}_${Math.random()}.${exe}`);
        formData.append("AssetType", assetType);
        formData.append("tenantId", tenantId);
        uploadApi.post(ApiConstants.uploadCoverImageAPI, formData).then((res) => {
          if (res.status === apiResponseEnums.OKAY) {
            resolve(res.data);
          } else if (res.status === apiResponseEnums.UNAUTHRISED) {
            if (dispatch) {
              dispatch(
                regfeshTokenIdWithoutDispatch(
                  postAssetsImages(img, type, dispatch,tenantId,assetType)
                )
              );
            }
          } else {
             dispatch(
               setApiResponse({
                 status: res.status ?? apiResponseEnums.BAD_REQUEST,
                 data: getCustomAPIErrorMessage({
                   status: res.status as number,
                   data: res.data as ErrorObjectType,
                 }),
               })
             );
          }
        });
      });
  });
  return promise;
}
export const selectTenants = (state: RootState) => state.channel.tenantsData;
export const selectChannels = (state: RootState) => state.channel.channelData;
export const channelCreatedStatus = (state: RootState) =>
  state.channel.isChannelCreated;
export const channelPublishingStatus = (state: RootState) =>
  state.channel.isChannelPublished;
export const fileUploadStatus = (state: RootState) =>
  state.channel.isFileUploaded;
export const fileUrl = (state: RootState) => state.channel.fileUrl;
export const channelCreatedError = (state: RootState) => state.channel.error;

export default channelSlice.reducer;
