import { useEffect, useState } from "react";
import { ScrollTop } from "utils/utilityFunctions";
import { useSelector } from 'react-redux';
import { getSort } from "components/Sort/Sort.Slice";
import { usePaginationHook } from "components/Pagination/Pagination.Hook";
export const useTenantTableHook = (props) => {
  const {
    optionalData,
    numSelected,
  } = props;
  const {paginationData,handelPaginate} = usePaginationHook();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([] as any);
  const [page, setPage] = useState(0);
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationData?.DEFAULT_PAGE_SIZE
  );
  const sortData = useSelector(getSort);

  useEffect(()=>{
    return setOrder(sortData.sort === "asc" ? "desc" : "asc");
  },[sortData])

  useEffect(() => {
    if (numSelected === 0) {
      setSelected([]);
    }
  }, [numSelected]);

  useEffect(() => {
    if (optionalData && optionalData.length > 0) {
      setSelected([...optionalData]);
    } else {
      setSelected([]);
    }
    setButtonStatus(true);
  }, [optionalData]);

  useEffect(() => {
    ScrollTop();
  }, []);

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    isButtonDesabled,
    setButtonStatus,
    rowsPerPage,
    setRowsPerPage,
    sortData,
    paginationData,
    handelPaginate
  };
};
