import { makeStyles } from "@material-ui/core/styles";
import theme from "utils/theme";
import { PaperTheme } from './Paperbase.style';
import {
  CompCrossIconCommonStyle,
  CompDeleteButtonCommonStyle,
  CompDeleteTitleCommonStyle,
  CompDeletingTitlesCommonStyle,
  CompDividerCommonStyle,
  CompDividerDisabledCommonStyle,
  CompTableRootCommonStyle,
  CompTitleCommonStyle,
  CompTableRoot2CommonStyle,
} from "./CommonComponent.style";

export const TableWrapperComponentStyles = makeStyles(() => ({
  ...CompTableRoot2CommonStyle,
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
    // minHeight: 680,
    // maxWidth:1120,
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
  },
}));

export const TableTitleHeaderComponentStyles = makeStyles(() => ({
  ...CompTableRootCommonStyle(theme),
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main + "!important",
        }
      : {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main + "!important",
        },
  title: {
    flex: "1 1 60%",
    fontfamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.5,
    letterspacing: 0.09,
    color: "rgba(0, 0, 0, 0.54)",
    [PaperTheme.breakpoints.down("md")]: {
      flex: "1 1 20%",
    },
  },
  eventTitle: {
    flex: "1 1 20%",
    fontfamily: "Roboto",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.5,
    letterspacing: 0.09,
    color: "rgba(0, 0, 0, 0.54)",
  },
  ...CompDeleteTitleCommonStyle,
  ...CompDeletingTitlesCommonStyle,
  ...CompDeleteButtonCommonStyle,
  ...CompDividerCommonStyle,
  ...CompDividerDisabledCommonStyle,
  ...CompCrossIconCommonStyle,
  view: {
    width: 33,
    height: 22,
    margin: "5px 1px 3px 16px",
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.69",
    letterSpacing: "0.3px",
    color: "#8e8e93",
  },
  all: {
    height: 22,
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.69",
    letterSpacing: "0.3px",
    color: "#8e8e93",
    cursor: "pointer",
  },
  allSelected: {
    height: 22,
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.69",
    letterSpacing: "0.3px",
    color: "#36dcb6",
    cursor: "pointer",
  },
  filterRightPadding: {
    marginRight: 30,
  },
  filterNotRightPadding: {
    marginRight: 98,
  },
  line: {
    width: 1,
    height: 30,
    margin: "0 7",
    border: "solid 1px #e0e0e0",
  },
  listView: {
    margin: "4px 6px 4px 3px",
  },
  zoom: {
    // width: 33,
    height: 22,
    margin: "5px 0px 3px 0px",
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.69",
    letterSpacing: "0.3px",
    color: "#8e8e93",
  },
  activeStatus: {
    width: "99px",
    height: "22px",
    margin: "5px 0px 3px 0px",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.69",
    letterSpacing: "0.3px",
    color: "#8e8e93",
  },
  eventType: {
    width: "78px",
    height: "22px",
    margin: "5px 0px 3px 0px",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.69",
    letterSpacing: "0.3px",
    color: "#8e8e93",
  },
  notAllowedCursor: {
    cursor: "not-allowed",
  },
  stylingBackground: {
    background: "#36dcb6",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    textAlign: "center",
    padding: "2px 0px",
  },
  stylingBackgroundDisabled: {
    background: "rgba(0, 0, 0, 0.26)",
  },
  downloadTemplate: {
    width: 144,
    height: 24,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.09,
    color: "#36dcb6",
    cursor: "pointer",
    marginRight: 5,
  },
}));

export const TableTermsHeaderComponentStyles = makeStyles(() => ({
  ...CompTableRootCommonStyle(theme),
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main,
        }
      : {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main,
        },
  ...{ ...CompTitleCommonStyle() },
  ...CompDeleteTitleCommonStyle,
  ...CompDeletingTitlesCommonStyle,
  ...CompDeleteButtonCommonStyle,
  ...CompDividerCommonStyle,
  ...CompDividerDisabledCommonStyle,
  ...CompCrossIconCommonStyle,
}));

export const TableMngClsCollComponentStyles = makeStyles(() => ({
  ...CompTableRootCommonStyle(theme),
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main + "!important",
        }
      : {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main + "!important",
        },
  ...{ ...CompTitleCommonStyle() },
  addCollectionText: {
    flex: "1 1 100%",
    height: "20px",
    margin: "4px 0px 7px 0px",
    fontfamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontstyle: "normal",
    lineHeight: 1.43,
    letterspacing: 0.1,
    color: "#fff",
  },
  ...CompDeletingTitlesCommonStyle,
  addCollectionButton: {
    backgroundColor: "transparent",
    color: "#fff",
    border: "#fff 1px solid",
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.69,
    letterSpacing: 0.3,
    "&:hover": {
      backgroundColor: "transparent",
      color: "#fff",
      border: "#f5f5f5 1px solid",
    },
  },
  divider: {
    height: 24,
    width: "1px",
    backgroundColor: "#fff",
    margin: "3px 23px 4px 25px",
    border: "solid 1px var(--white)",
  },
  ...CompDividerDisabledCommonStyle,
  crossIcon: {
    cursor: "pointer",
    margin: "2px 22px 5px 0",
  },
  crossIconDisabled: {
    cursor: "default",
    color: "rgba(0, 0, 0, 0.26)",
    margin: "2px 22px 5px 0",
  },
}));

export const TableCollHeaderComponentStyles = makeStyles(() => ({
  ...CompTableRootCommonStyle(theme),
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main + "!important",
        }
      : {
          color: "#ffffff",
          backgroundColor: theme.palette.primary.main + "!important",
        },
  ...{ ...CompTitleCommonStyle() },
  title: {
    flex: '1 1 60%',
  },
  deletTitle: {
    marginRight: 20,
    marginLeft: 20,
  },
  ...CompDeletingTitlesCommonStyle,
  deletButton: {
    backgroundColor: "transparent",
    color: "#fff",
    border: "#fff 1px solid",
    borderRadius: 4,
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.69,
    letterSpacing: 0.3,
    "&:hover": {
      backgroundColor: "transparent",
      color: "#fff",
      border: "#f5f5f5 1px solid",
    },
  },
  ...CompDividerCommonStyle,
  ...CompDividerDisabledCommonStyle,
  ...CompCrossIconCommonStyle,
}));

export const TableComponentStyles = makeStyles((theme) => ({
  ...CompTableRoot2CommonStyle,
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    boxShadow:
      "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)",
  },
  table: {
    minWidth: "auto",
  },
  reorderTable: {
    minWidth: "auto",
    tableLayout: "fixed",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    color: "#000",
    height: 1,
    margin: -1,
    overflow: "visible",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  header: {
    height: 53,
  },
  checkbox: {
    fontSize: 15,
    width: 16,
    height: 16,
    color: "#000",
    marginLeft: -25,
    marginRight: 15,
  },
  noMargin: {
    marginLeft: "0px !important",
  },
  sort: {
    fontSize: 15,
    width: 16,
    height: 16,
    color: "#000",
    marginLeft: -25,
    marginRight: 15,
  },
  checkboxCell: {
    padding: "0 0 0 16px",
    width: 64,
  },
  tableTitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.57,
    letterSpacing: 0.07,
    color: "rgba(0, 0, 0, 0.87)",
  },
  tableHeadCell: {
    padding: 15,
  },
  tableHeadCellReorderWithSort: {
    padding: "16px 15px 15px 15px",
    width: 260,
  },
  tableHeadCellWithSort: {
    padding: "16px 15px 15px 15px",
  },
  reorderTableHeadCellWithSort: {
    padding: 15,
    width: 150,
  },
  tableCellText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    width:158,
    //color: "rgba(0, 0, 0, 0.87)"
  },
  tableCancelIcon:{
    display:'flex',
    alignItems:'center !important',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
  },
  tableCellTextTerms: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    marginLeft: "30px",
    //color: "rgba(0, 0, 0, 0.87)"
  },
  divWrap: {
    maxWidth: "200px",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  tableCellTextForEventTitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    width: 180,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    wordBreak: "break-word",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    ["@media (max-width:1024px)"]: {
      width: 80,
    },
  },
  tableCellTextDisabled: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    color: "rgba(0, 0, 0, 0.6) !important",
  },
  tableCellTextWithoutCheckbox: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    paddingLeft: 20,
    color: "rgba(0, 0, 0, 0.87)",
  },
  tableCellTextWithoutCheckboxInTitleTable: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    paddingLeft: 20,
    color: "rgba(0, 0, 0, 0.87)",
  },

  tablePagination: {
    color: "#000",
  },
  tableCell: {
    padding: "18px 16px",
  },
  selectableTable: {
    cursor: "pointer",
    color: "#000",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
  },
  helpTable: {
    cursor: "not-allowed",
    color: "rgba(0, 0, 0, 0.6) !important",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
  },
  nonSelectableTable: {
    cursor: "help",
    color: "rgba(0, 0, 0, 0.6) !important",
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    //color: "rgba(0, 0, 0, 0.87)"
  },
  nonSelectableTablePadding: {
    fontFamily: "Roboto",
    cursor: "help",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
    paddingLeft: 20,
    color: "rgba(0, 0, 0, 0.6) !important",
  },
  cancelDiv: {
    display: "inline-flex",
    alignItems: "center",
  },
  Cancel: {
    fontFamily: "Roboto",
    fontSize: 16,
    width: 144,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: 0.09,
    color: "#36dcb6",
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
  },
  ButtonText: {
    fontFamily: "Roboto",
    fontSize: 12,
    width: 136,
    height: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: 0.3,
    color: "white",
  },
  btnSecondCOTD: {
    borderRadius: "10px",
    backgroundColor: "#f57c00",
    color: "white",
    width: 133,
    height: 20,
    fontFamily: "Roboto",
    fontSize: 12,
    letterSpacing: 0.3,
    textAlign: "center",
    padding: "2px 6px 3px 6px",
  },
  btnFirstCOTD: {
    borderRadius: "10px",
    backgroundColor: "#36dcb6",
    color: "white",
    width: 148,
    height: 20,
    fontFamily: "Roboto",
    fontSize: 12,
    letterSpacing: 0.3,
    textAlign: "center",
    padding: "2px 6px 3px 6px",
  },
  weekDayCellContainer: {
    display: 'flex',
    gap: '3px',
    fontSize: 11
  },
  weekDayCellEach: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: "18px", 
  },
  weekDaySelected: {
    backgroundColor: '#36dcb6',
    color: 'rgba(255, 255, 255, 0.87)',
  },
  weekDayUnSelected: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  ratingIcon: {
    width: 23,
    height: 23
  },
  tableCellWithExtraML: {
    marginLeft: 7,
  },
  tableCellTextSub: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: 0.01,
  },
  downloadUMContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.09,
    color: "#36dcb6",
  },
  downloadUMFileButton: {
    width: 64,
    height: 24,
    cursor: "pointer",
    marginRight: 4,
  },
  downloadUMIcon: {
    width: 18,
    height: 19,
    objectFit: "contain",
  },
  noSortFirstColumn: {
    padding: 15,
    paddingLeft: 22,
  }
}));
