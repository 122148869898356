import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Translate from 'utils/translate'

import { withStyles } from '@material-ui/core/styles';
import Form from './loginForm';
import Logo from 'assets/images/wexer-white.svg';
import { LoginScreenProps, LoginScreenStyles } from 'style';

function Login(props: LoginScreenProps) {
    const { classes } = props;
    return (
        <React.Fragment>

            <main className={classes.main}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src={Logo} alt='logo' />
                    <Typography className={classes.logoSubTitle} variant='body2' color='textPrimary'>
                        {Translate({ id: "loginPage.pageTitle" })}
                    </Typography>
                </div>
 
                <Paper classes={{ root: classes.paper }}>
                    {

                        <div className={classes.card}>


                            <Form title={Translate({ id: "loginPage.loginFormTitle" })} />
                        </div>



                    }

                </Paper>

            </main>

        </React.Fragment>
    );
}

export default withStyles(LoginScreenStyles)(Login);
