import { createStyles, WithStyles } from "@material-ui/core/styles";
import { navigatorPanelStye } from "./components";

export const HomePageStyles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      alignItems: "center",
      background: "#19202c",
    },
    rootWithoutBG: {
      height:'100%'
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: navigatorPanelStye.width,
        flexShrink: 0,
      },
    },
    app: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      background: "rgb(239,239,244)",
      zIndex: -0,
      overflowY: "hidden",
      overflowX:"hidden",
      height:"100vh",
    },
    main: {
      flex: 1,
      padding: theme.spacing(6, 4),
    },
    footer: {
      padding: theme.spacing(2),
    },
  });

export interface HomePageProps extends WithStyles<typeof HomePageStyles> {}
