import React from "react";
import { useSelector } from "react-redux";
import Select from "components/Inputs/Select";

import { selectTenants } from "_pages/channels/channelSlice";
import { SelectUI } from "_pages/contentTitles/Components/Selecters";
import { CollectionFormStyles } from "style";

const SelectTenants = (props) => {
  const { value } = props;
  let tenant = useSelector(selectTenants);
  const classes = CollectionFormStyles();
  // console.log(tenant, "tenant select");
  // console.log(tenant.tenants, "tenant select select");

  const Optionlable = "name";
  const id = "tag";

  const callback = (e) => {
    const currentTenant = tenant?.tenants?.find((data) => data.name === e);
    props.selectTenantCallback(currentTenant ?? e);
  };
  var result: Array<any> = new Array<any>();
  if (Array.isArray(tenant.tenants)) {
    tenant.tenants.map((element) => {
      if (element.name !== null) result.push(element.name);
    });
  }

  return (
    <React.Fragment>
      {result && (
        <SelectUI
          values={value}
          style={props.from === 'header-layout' ? `header-input-box no-under-line` : ''}
          id={id}
          multiple={false}
          isArray={true}
          disabled={false}
          selectCallback={callback}
          options={result}
          lable={props.lable ?? Optionlable}
        />
      )}
    </React.Fragment>
  );
};

export default SelectTenants;
