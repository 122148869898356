import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  HeaderRootCommonStyle,
  LinkCommonStyle,
  HeaderMenuAndButtonCommonStyle,
  TitleArrowSubtitleCommonStyle,
  HeaderLinkCommonStyle,
  HeaderTabBarCommonStyle,
  HeaderSecondaryBarCommonStyle,
  HeaderFixedHeaderCommonStyle,
  HeaderH56CommonStyle,
  HeaderSelectCommonStyle,
  HeaderToolBarEndCommonStyle,
  HeaderToolBarBetweenCommonStyle,
  HeaderRoot2CommonStyle,
  HeaderWhiteBGRootCommonStyle,
  HeaderFormControlCommonStyle,
  HeaderSelectLableCommonStyle,
} from "./Common.style";

export const HeaderStyle = (theme: Theme) =>
  createStyles({
    ...LinkCommonStyle(theme),
    secondaryBar: {
      zIndex: -1,
      height: 247,
    },
    ...HeaderMenuAndButtonCommonStyle(theme),
  });

export const TenantHeaderStyle = makeStyles((theme) => ({
  ...HeaderMenuAndButtonCommonStyle(theme),
  root: {
    backgroundColor: "#fff",
    maxWidth: 550,
    width: "100%",
    marginTop: 8,
  },
  ...HeaderSecondaryBarCommonStyle(88),
  ...HeaderLinkCommonStyle(theme),
  ...HeaderTabBarCommonStyle,
}));

export const ScheduleHeaderStyle = makeStyles((theme) => ({
  ...HeaderRootCommonStyle,
  ...HeaderMenuAndButtonCommonStyle(theme),
  ...HeaderSecondaryBarCommonStyle(88),
  ...{ ...HeaderFixedHeaderCommonStyle() },
  ...HeaderH56CommonStyle,
  ...HeaderLinkCommonStyle(theme),
  ...HeaderTabBarCommonStyle,
  ...HeaderSelectCommonStyle,
  ...{ ...HeaderToolBarEndCommonStyle() },
  providerSelect: {
    margin: "-4px 45px 0px 0px",
    width: "196px",
  },
}));

export const ScheduleEventHeaderStyles = makeStyles((theme) => ({
  ...{ ...TitleArrowSubtitleCommonStyle() },
  ...HeaderRoot2CommonStyle(theme),
  tabBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    "& span": {
      textTransform: "uppercase !important",
    },
  },
}));

export const ProviderHeaderStyles = makeStyles((theme) => ({
  ...HeaderMenuAndButtonCommonStyle(theme),
  ...HeaderWhiteBGRootCommonStyle,
  ...HeaderSecondaryBarCommonStyle(88),
  ...{ ...TitleArrowSubtitleCommonStyle() },
  ...HeaderH56CommonStyle,
  ...HeaderLinkCommonStyle(theme),
  ...HeaderTabBarCommonStyle,
}));

export const ContentTitleHeaderStyles = makeStyles((theme) => ({
  ...HeaderMenuAndButtonCommonStyle(theme),
  root: {
    backgroundColor: "transparent",
    width: 250,
    marginTop: 8,
  },
  toolbarHeight: {
    minHeight: 59,
  },
  ...HeaderFormControlCommonStyle(theme),
  ...HeaderSelectLableCommonStyle,
  ...HeaderSecondaryBarCommonStyle(86),
  ...{ ...HeaderToolBarBetweenCommonStyle() },
  channelSelect: {
    margin: "-4px 20px 0px 0px",
    width: "196px !important",
  },
  ...HeaderSelectCommonStyle,
  ...HeaderLinkCommonStyle(theme),
  ...HeaderTabBarCommonStyle,
}));

export const CollectionHeaderStyles = makeStyles((theme) => ({
  ...{ ...TitleArrowSubtitleCommonStyle() },
  ...HeaderRoot2CommonStyle(theme),
}));

// TODO - need to discuss/change the name later as collection has two header files
export const CollectionMainHeaderStyles = makeStyles((theme) => ({
  ...HeaderRootCommonStyle,
  ...HeaderMenuAndButtonCommonStyle(theme),
  MuiNativeSelectIcon: {
    color: "#fff !important",
  },
  ...HeaderSecondaryBarCommonStyle(88),
  ...{ ...TitleArrowSubtitleCommonStyle() },
  ...HeaderH56CommonStyle,
  ...HeaderLinkCommonStyle(theme),
  ...HeaderTabBarCommonStyle,
  ...HeaderSelectCommonStyle,
  ...{ ...HeaderToolBarEndCommonStyle() },
}));

export const ChannelHeaderStyles = makeStyles((theme) => ({
  ...HeaderMenuAndButtonCommonStyle(theme),
  root: {
    backgroundColor: "transparent",
    width: 400,
    marginTop: 8,
  },
  ...HeaderFormControlCommonStyle(theme),
  ...HeaderSelectLableCommonStyle,
  ...HeaderSecondaryBarCommonStyle(86),
  ...{ ...HeaderToolBarBetweenCommonStyle() },
  ...HeaderLinkCommonStyle(theme),
  header: {
    height: 60,
  },
  ...HeaderTabBarCommonStyle,
}));

export const ChannelNewHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: 250,
    marginTop: 8,
  },
  ...HeaderSecondaryBarCommonStyle(86),
  ...{ ...HeaderToolBarBetweenCommonStyle() },
  ...HeaderLinkCommonStyle(theme),
  ...HeaderH56CommonStyle,
  ...HeaderTabBarCommonStyle,
  providerSelect: {
    margin: "-4px 45px 0px 0px",
    width: "196px",
  },
}));

export const AdminHeaderStyles = makeStyles((theme) => ({
  ...HeaderMenuAndButtonCommonStyle(theme),
  ...HeaderWhiteBGRootCommonStyle,
  ...HeaderSecondaryBarCommonStyle(88),
  ...HeaderLinkCommonStyle(theme),
  ...HeaderTabBarCommonStyle,
}));

export interface ScheduleHeaderProps {
  value: Number;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
  actionCallbackTenants: (v) => void;
  filterParamTenant: string;
  showSelector: boolean;
}
export interface HeaderProps extends WithStyles<typeof HeaderStyle> {
  onDrawerToggle?: () => void;
}
export interface TenantHeaderProps {
  value: Number;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
}
export interface ProviderHeaderProps {
  value: Number;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
}
export interface ContentTitleHeaderProps {
  value: Number;
  actionCallback: (v) => void;
  actionCallbackChannels: (v) => void;
  showSelector: boolean;
  filterParamProvider: string;
  filterParamChannel: string;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
  actionCallbackTenants: (v) => void;
  filterParamTenant: string;
}
export interface CollectionMainHeaderProps {
  value: Number;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
  actionCallbackTenants: (v) => void;
  filterParamTenant: string;
  showSelector: boolean;
}
export interface ChannelHeaderProps {
  classes: any;
  selectedValue: string;
  value: Number;
  actionCallback: (v) => void;
  showSelector: boolean;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
}
export interface ChannelNewHeaderProps {
  selectedValue: string;
  value: Number;
  actionCallback: (v) => void;
  showSelector: boolean;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
}
export interface AdminHeaderProps {
  value: Number;
  onDrawerToggle: () => void;
  handleChange: (e, v) => void;
}
