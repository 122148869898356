import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";

const lightColor = "rgba(255, 255, 255, 0.7)";

export const HeaderComponentStyles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  });

export const PaperHeaderComponentStyles = makeStyles((theme) => ({
  header: {
    //height:50,
    paddingTop: 14,
    paddingBottom: 14,
    // paddingLeft: 14,
    borderBottom: "solid 1px #e0e0e0",
  },
  headerLable: {
    //height:50,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 24,
    borderBottom: "solid 1px #e0e0e0",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 14,
  },
}));

export const PageHeaderComponentStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: 250,
    marginTop: 8,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 196,
    borderBottom: "none",
  },

  lable: {
    color: "#000",
  },
  select: {
    borderBottom: "#fff",
  },
  secondaryBar: {
    zIndex: 1,
    height: 86,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbarSelect: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  menuButton: {
    marginLeft: -theme.spacing(1),
  },

  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  header: {
    height: 56,
  },
  button: {
    borderColor: lightColor,
  },
  tabBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  providerSelect: {
    margin: "-4px 45px 0px 0px !important",
    width: "196px",
  },
}));

export interface HeaderComponentProps
  extends WithStyles<typeof HeaderComponentStyles> {
  onDrawerToggle: () => void;
}
