import theme from "utils/theme";
import { makeStyles } from "@material-ui/core/styles";

export const ProgressBarComponentStyles = makeStyles({
    root: {
      width: '100%',
    },
    failedFull: {
      width: "100%",
      color: "#d54c37",
    },
    bar: {
      height: 4,
      borderRadius: 2,
    },
    text: {
      fontFamily: "Roboto",
      fontSize: 10,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.87)",
    },
    Actiontext: {
      fontFamily: "Roboto",
      fontSize: 10,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    ActionError: {
      fontFamily: "Roboto",
      fontSize: 10,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#d54c37",
      cursor: "pointer",
    },
    error: {
      fontFamily: "Roboto",
      fontSize: 10,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      color: "#d54c37",
    },
    waitingFiles: {
      backgroundColor: "#36dcb6 !important",
    },
  });