import {
  addCategory,
  categoryData,
  tenantConfigLoading,
  getCategoryData,
  getTenantConfigData,
  languageConfig,
  submitLoading,
  categoryDataLoading,
  resetTenantConfig,
  updateCategory,
} from "_pages/categoryManagement/CategorySlice";
import {categoriesPath} from "constants/routeConstants";
import {
  CategoryData,
  CategoryFormData,
  CategoryFormError,
  FormError,
  TenantConfigLanguageModel,
  UpdateApiCategoryPayload,
} from "models/Category/CategoryModel";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {allUniqueSupportedLanguagesData, getLanguageFromConfig} from "utils/utilityFunctions";
import {isEqual} from "lodash";
export const useCategoryAddHook = ({tenantId}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();
  const {supportedLanguages, defaultLanguage} = useAppSelector<
    TenantConfigLanguageModel
  >(languageConfig);
  const lang: any = getLanguageFromConfig();
  const configLoading = useAppSelector<boolean>(tenantConfigLoading);
  const submitLoader = useAppSelector<boolean>(submitLoading);
  const dataLoading = useAppSelector<boolean>(categoryDataLoading);
  const apiData = useAppSelector<CategoryData | null>(categoryData);
  const [subtitle, setSubtitle] = useState<string | undefined>(
    lang.buttons.addButton
  );
  const [unsavedModal, setUnsavedModal] = useState(false);

  const [formData, setFormData] = useState<CategoryFormData>({
    categoryId: "",
    isPromoted: false,
    imageLink: "",
    subCategories: [],
    dynamicData: {},
    isActive: false,
  });
  const [errors, setErrors] = useState<CategoryFormError>({
    imageLink: {error: false, errorText: ""},
    dynamicErrors: {},
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [noLanguage, setNoLanguage] = useState<boolean>(false);
 const allUniqueLanguagesData = allUniqueSupportedLanguagesData(defaultLanguage,supportedLanguages);

  const getLanugeDataFromAPI = () => {
    let textDescription = {};
   allUniqueLanguagesData?.forEach((language) => {
      const nameText = apiData?.name?.localTexts.find(
        (element) => (element?.ietfTag).toLowerCase() === language?.toLowerCase()
      );
      const descriptionText = apiData?.description?.localTexts.find(
        (element) => (element?.ietfTag).toLowerCase() === language?.toLowerCase()
      );
      if (nameText && descriptionText) {
        textDescription[language] = {
          text: nameText.text,
          description: descriptionText.text,
        };
      } else {
        textDescription[language] = {text: "", description: ""};
      }
    });
    return textDescription;
  };
  


  const handleBack = () => {
    if (noLanguage)
    {
       history.push(categoriesPath);
       return;
    }
      if (id) {
        const currData = {
          categoryId: formData.categoryId,
          isPromoted: formData.isPromoted,
          imageLink: formData.imageLink,
          subCategories: formData.subCategories,
          dynamicData: formData.dynamicData,
          isActive: false,
        };
        const existingData = {
          categoryId: apiData?.categoryId,
          isPromoted: apiData?.isPromoted,
          imageLink: apiData?.imageLink,
          subCategories: apiData?.subCategories,
          dynamicData: getLanugeDataFromAPI(),
          isActive: false,
        };
        if (
          currData.imageLink == existingData.imageLink &&
          currData.isPromoted == existingData.isPromoted &&
          isEqual(currData.dynamicData, existingData.dynamicData)
        ) {
          history.push(categoriesPath);
        } else {
          setUnsavedModal(true);
        }
      } else {
        let dynamicData = {};
       allUniqueLanguagesData?.forEach((lang) => {
          dynamicData[lang] = {text: "", description: ""};
        });
        const currData = {
          categoryId: formData.categoryId,
          isPromoted: formData.isPromoted,
          imageLink: formData.imageLink,
          subCategories: formData.subCategories,
          dynamicData: formData.dynamicData,
          isActive: formData.isActive,
        };
        const existingData = {
          categoryId: "",
          isPromoted: false,
          imageLink: "",
          subCategories: [],
          dynamicData: dynamicData,
          isActive: false,
        };
        if (
          currData.imageLink == existingData.imageLink &&
          currData.isPromoted == existingData.isPromoted &&
          isEqual(currData.dynamicData, existingData.dynamicData)
        ) {
          history.push(categoriesPath);
        } else {
          setUnsavedModal(true);
        }
      }
  };

  const handleUnsavedModalOkClick = () => {
    history.push(categoriesPath);
  };

  const addSubCateorgy = () => {
    history.push(`${categoriesPath}/${id}/subcategory/add`);
  };

  const editSubCateorgy = (subCategoryId) => {
    history.push(`${categoriesPath}/${id}/subcategory/${subCategoryId}/edit`);
  };

  const handleUnsavedModalClose = () => {
    setUnsavedModal(false);
  };

  useEffect(() => {
    if (tenantId) {
      dispatch(getTenantConfigData(tenantId));
    }
    if (tenantId && id) {
      dispatch(
        getCategoryData({
          tenantId,
          categoryId: id,
        })
      );
    }
  }, [tenantId, id, dispatch]);

  useEffect(() => {
    return () => {
      setNoLanguage(false);
      setLoading(true);
      dispatch(resetTenantConfig());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!configLoading && !defaultLanguage) {
      setNoLanguage(true);
      setLoading(false);
    }
    if (!configLoading && !dataLoading && defaultLanguage) {
          const initialData: CategoryFormData = {...formData};
          const errObj: FormError = {error: false, errorText: ""};
          const initialError: CategoryFormError = {...errors};
        allUniqueLanguagesData?.forEach((lang) => {
        initialData.dynamicData[lang] = {text: "", description: ""};
        initialError.dynamicErrors[lang] = {
          text: errObj,
          description: errObj,
        };
      });
      setFormData(initialData);
      if (id && !dataLoading) {
        let textDescription = {};
        apiData?.name?.localTexts?.forEach((element1, index1) => {
          apiData?.description?.localTexts?.forEach((element2, index2) => {
            if (index1 == index2) {
              textDescription[element1.ietfTag] = {
                text: element1.text,
                description: element2.text,
              };
            }
          });
        });
        setFormData({
          categoryId: id,
          isPromoted: Boolean(apiData?.isPromoted),
          imageLink: apiData?.imageLink ? apiData?.imageLink : "",
          subCategories: apiData?.subCategories,
          dynamicData: getLanugeDataFromAPI(),
          isActive: false,
        });
        setSubtitle(apiData?.name?.invariantText);
      }

      setErrors(initialError);
      setLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    supportedLanguages,
    defaultLanguage,
    configLoading,
    id,
    dataLoading,
    apiData,
    dispatch,
  ]);

  const resetErrors = () => {
    let allUniqueLanguages = [defaultLanguage];
    if (Array.isArray(supportedLanguages) && supportedLanguages) {
      allUniqueLanguages = Array.from(
        new Set([defaultLanguage, ...supportedLanguages])
      );
    }
    const errObj: FormError = {error: false, errorText: ""};
    const initialError: CategoryFormError = {
      imageLink: errObj,
      dynamicErrors: {},
    };
     allUniqueLanguagesData?.forEach((lang) => {
      initialError.dynamicErrors[lang] = {
        text: errObj,
        description: errObj,
      };
    });
    setErrors(initialError);
  };

  const constructPayload = (): UpdateApiCategoryPayload => {
    return {
      tenantId,
    };
  };

  const handleAdd = (values) => {
    dispatch(addCategory(values, history));
  };

  const handleEdit = (values) => {
    dispatch(updateCategory(values, history));
  };

  return {
    tenantId,
    formData,
    errors,
    loading,
    submitLoader,
    noLanguage,
    id,
    handleBack,
    setFormData,
    setErrors,
    resetErrors,
    handleAdd,
    handleEdit,
    unsavedModal,
    handleUnsavedModalOkClick,
    handleUnsavedModalClose,
    addSubCateorgy,
    editSubCateorgy,
    subtitle,
    lang,
  };
};
