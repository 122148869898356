import { makeStyles, createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { FormPaddingCommonStyle, MainCommonStyle } from "./Common.style";

export const ProviderCreationStyles = makeStyles((theme) => ({
  formControl: {
    width: 500,
  },
  item: {
    maxWidth: 512,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  frmMedia: {
    marginTop: "12px !important",
  },
  ...FormPaddingCommonStyle,
}));

export const ProviderScreenStyles = (theme: Theme) =>
  createStyles({
    ...MainCommonStyle(theme),
  });

export interface ProviderScreenProps
  extends WithStyles<typeof ProviderScreenStyles> {}
