import {
  createMuiTheme,
} from '@material-ui/core/styles';
import * as  ThemeConstant from 'constants/themeConstants';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: ThemeConstant.primaryAccentColor,
      main: ThemeConstant.primaryColor,
      dark: '#006db3',
    },
    secondary: {
      light: ThemeConstant.secondaryColor,
      main: ThemeConstant.secondaryColor, 
      dark: ThemeConstant.secondaryAccentColor
    },
    text: {
      primary: ThemeConstant.textColorLight,
      secondary: ThemeConstant.textColorDark,
      disabled: ThemeConstant.textColorDarkDesabled,
      // hint: ThemeConstant.textColorDark

    },  
    info:{ 
      light: ThemeConstant.textColorDarkDesabled,
      main: ThemeConstant.primaryColor,
      dark: '#006db3',
    },
  },
  typography: {
    h1: {
      letterSpacing: 0.07,
      fontFamily: 'Roboto',
      fontSize: 34,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.15,
  
    },
    h2: {
      letterSpacing: 0.5,
      fontFamily: 'Roboto',
      fontSize: 34,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.15,
    },
    h3: {
      letterSpacing: 0.07,
      fontFamily: 'Roboto',
      fontSize: 20,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.6,

    },
    h4: {
      letterSpacing: 0.5,
      fontFamily: 'Roboto',
      fontSize: 34,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.15,

    },
    h5: {
      letterSpacing: 0.09,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.75,

    },
    h6: {
      letterSpacing: 0.1,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
    
    },
    body1: {
      letterSpacing: 0.1,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.71,

    },
    body2: {
      letterSpacing: 0.4,
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.58,

    }
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    MuiButton: {
      root: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
           backgroundColor:"white",
         
        }
      }, 
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
     
    },
    MuiInput: {
      // underline: {
      //   "&&&&:before": {
      //     paddingTop: "5px"
      //   }
      // },

      root: {
        //  width:'100%' 
         
        fontSize: "16px", 
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.36,
        letterSpacing: "-0.2px",
    
      }
    },
    MuiInputLabel: {
      root: {
        color: theme.palette.text.secondary,
        fontSize: "16px",
        fontStretch: "normal",
        fontStyle: "normal",
         lineHeight: 1.66,
         paddingLeft:12,
         paddingTop:3,
        letterSpacing: 0.09

      }
    },

    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

export default theme; 