import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { downloadTermsApi } from "_pages/tenants/tenantSlice";

export const useTenantDownloadHook = (props) => {
  const dispatch = useDispatch();
  const handleDownloadCallBack = (fileName, languageCode, versionNumber) => {
    dispatch(
      downloadTermsApi(props.selectedTenant?.tenantId, languageCode, versionNumber, fileName)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    handleDownloadCallBack
  };
};
