import Resumablejs from "resumable-file-uploads";

import {
  localStorageEventVideos,
  localStorageEventChunkStatus,
  MAX_VIDEO_FILE_NAME_CHAR,
  MAX_FILE_UPLOAD_LIMIT,
} from "constants/enums";
import { removeMultipleEventFromQueue, getQueuedEvents } from "utils/db";
import { map } from 'lodash';

const BASE_URL = process.env.REACT_APP_API_URI;


export const getFileType = (type) => {
  if (type === "video/mp4") return "mp4";
  return "mp3";
}

export function createResumableInstance(
  File: any,
  Event: any,
  target: string,
  isReplacing: boolean
) {
  let ResumableTraileField = new Resumablejs({
    target: BASE_URL + target,
    fileType: ["mp4", "mp3", "aac","m4a"],
    chunkSize: 4 * 1024 * 1024,
    simultaneousUploads: 1,
    testChunks: false,
    maxFiles: MAX_FILE_UPLOAD_LIMIT,
    uploadMethod: isReplacing ? "PUT" : "POST",
  });

  return ResumableTraileField;
}

export const exractEventBrokenFileStatusFromLocalStorage = (
  file,
  tag,
  isFileCompleted = false,
  optionalProgress: number = -1
) => {
  const videoUploadProgressFromLocalStorage = localStorage.getItem(
    localStorageEventVideos
  ) as any;
  let newVideoUploadProgress = [] as any;
  if (videoUploadProgressFromLocalStorage) {
    let videoUploadProgress = JSON.parse(videoUploadProgressFromLocalStorage);
    if (videoUploadProgress.length > 0) {
      newVideoUploadProgress = videoUploadProgress.filter(
        (item) => item.tag !== tag
      );

      if (optionalProgress !== -1) {
        newVideoUploadProgress = [
          ...newVideoUploadProgress,
          { progress: 0, tag: tag },
        ];
      } else if (isFileCompleted === false) {
        newVideoUploadProgress = [
          ...newVideoUploadProgress,
          { progress: file ? file.progress(false) : 0, tag: tag },
        ];
      }
      // dispatch(setVideoUploadProgress(newVideoUploadProgress))
      localStorage.setItem(
        localStorageEventVideos,
        JSON.stringify(newVideoUploadProgress)
      );
    }
  }
  return newVideoUploadProgress;
};

export const exractEventBrokenFileChunksFromLocalStorage = (
  file: any,
  tag: string,
  chunkNumber: number = 0,
  isFileCompleted: boolean = false
) => {
  let storedUploadedChunkStatus = localStorage.getItem(
    localStorageEventChunkStatus
  ) as any;

  let uploadedChunkStatus = {
    identifier: file.file["identifier"],
    tag: tag,
    uploadingChunkNumber: chunkNumber,
    totalNumberOfChunks: file.chunks.length,
  };

  if (storedUploadedChunkStatus) {
    let parsed = JSON.parse(storedUploadedChunkStatus);
    let checkIfFileExists = [] as any;

    if (Array.isArray(parsed)) {
      checkIfFileExists = parsed.filter((data) => data.tag !== tag);

      if (isFileCompleted === false) {
        checkIfFileExists = [...checkIfFileExists, uploadedChunkStatus];
      }
      // if file does'nt exist, user can upload another file from queue
    }

    localStorage.setItem(
      localStorageEventChunkStatus,
      JSON.stringify(checkIfFileExists)
    );
  } else {
    localStorage.setItem(
      localStorageEventChunkStatus,
      JSON.stringify([uploadedChunkStatus])
    );
  }
};

export const extractHelper = (item) => {
  const oldStatus = localStorage.getItem(item);
  const newStaus = oldStatus ? JSON.parse(oldStatus) : [];
  return newStaus;
};

export const eventVideosdeleteHelper = (tags, store: boolean = true) => {
  let progressStatus = extractHelper(localStorageEventVideos);
  let chunksStatus = extractHelper(localStorageEventChunkStatus);

  if (progressStatus.length > 0 || chunksStatus.length > 0) {
    for (const tag of tags) {
      progressStatus = progressStatus.filter(
        (storedItem) => storedItem.tag !== tag
      );

      chunksStatus = chunksStatus.filter(
        (storedItem) => storedItem.tag !== tag
      );
    }
    if (store === true) {
      localStorage.setItem(
        localStorageEventVideos,
        JSON.stringify(progressStatus)
      );
      localStorage.setItem(
        localStorageEventChunkStatus,
        JSON.stringify(chunksStatus)
      );
    }
  }
  return progressStatus;
};

export const deleteAssociatedDataFromBrowser = (tags: any) => {
  //
  removeMultipleEventFromQueue(tags);
  return eventVideosdeleteHelper(tags);
};

export function checkAndAddToUploadQueue(title) {
  let file = title[0];

  let qeueueItems = localStorage.getItem("uploads");

  if (qeueueItems) {
    let uploadesInProgress = JSON.parse(qeueueItems);

    if (uploadesInProgress) {
      for (let l = uploadesInProgress?.length; l--; ) {
        if (uploadesInProgress[l].className === file.fileName) {
        }

        // brokenDownloads[l]["status"] = type;
      }
      localStorage.setItem("uploads", JSON.stringify(uploadesInProgress));
      return false;
    } else {
      localStorage.setItem("uploads", JSON.stringify(title));
      return false;
    }
  } else {
    return false;
  }
}

export const removeAlldatafromBrowser = () => {
  // do not delet complete table
  getQueuedEvents().then((events: any) => {
    let eventsToDelet = Array.from(events, (e: any) => e.event_Id);
    removeMultipleEventFromQueue(eventsToDelet);
  });
  localStorage.setItem(localStorageEventVideos, JSON.stringify([]));
  localStorage.setItem(localStorageEventChunkStatus, JSON.stringify([]));
};

export const checkIfEventsExists = (data) => {
  let takeEventIds = Array.from(data, (i: any) => i.event_Id);
  let itemsToDelete = eventVideosdeleteHelper(takeEventIds, false);

  let takeEventIdsToDelet = Array.from(itemsToDelete, (i: any) => i.tag);

  if (takeEventIdsToDelet.length > 0) {
    removeMultipleEventFromQueue(takeEventIdsToDelet);
  }

  let itemsToReturn = eventVideosdeleteHelper(takeEventIdsToDelet, true);

  return itemsToReturn;
};
const cleanFileName = (_:string)=>{
  return _.replaceAll(/[/\\?%*:|"<>,!@#$%^()~&]/g, '').substring(0,MAX_VIDEO_FILE_NAME_CHAR);
}
export const validFileName = (files)=>{
  return map(files,(file)=>({...files,name:cleanFileName(file.name)}))
}
export const isValidFileLength = (_:string)=>{
  try {
    return _.length <= MAX_VIDEO_FILE_NAME_CHAR
  } catch (error) {
    return false;
  }
}
export const isValidFileName = (_:string)=>{
  try {
    const re = /[/\\?%*:|"<>,!@#$%^()~&]/;
    return re.exec(_) === null
  } catch (error) {
    return false;
  }
}
export const isInvalidFileType = (_:File)=>{
  try {
    const re = /(.mp4)$/i;
    let invalid = re.exec(_.name) === null;
    if(invalid) return true;
    return false;
  } catch (error) {
    return true;
  }
}

export const isInvalidCsvFile = (_: File) => {
  try {
    const re = /(.csv)$/i;
    let invalid = re.exec(_.name) === null;
    if (invalid) return true;
    invalid = re.exec(_.type) === null;
    if (invalid) return true;
    return false;
  } catch (error) {
    return true;
  }
};

export const isInvalidAudioVideoFileType = (_: File) => {
  try {
    const re = /^.*\.(mp4|aac|mp3|m4a)$/;
    let invalid = re.exec(_.name) === null;
    if (invalid) return true;
    return false;
  } catch (error) {
    return true;
  }
};

export const isInvalidAudioFileType = (_: File) => {
  try {
    const re = /^.*\.(aac|mp3|m4a)$/;
    let invalid = re.exec(_.name) === null;
    if (invalid) return true;
    return false;
  } catch (error) {
    return true;
  }
};

export const isInvalidPdfFile = (_: File) => {
  try {
    const re = /(.pdf)$/i;
    let invalid = re.exec(_.name) === null;
    if (invalid) return true;
    invalid = re.exec(_.type) === null;
    if (invalid) return true;
    return false;
  } catch (error) {
    return true;
  }
};

export const getFileSizeInMB = (sizeInBytes: number): number => {
  let sizeInMB = +(sizeInBytes / (1024 * 1024)).toFixed(2);
  return sizeInMB;
}

export const isInvalidVttFile = (_: File) => {
  try {
    const re = /^.*\.(vtt)$/;
    let invalid = re.exec(_.name) === null;
    if (invalid) return true;
    return false;
  } catch (error) {
    return true;
  }
};
