import { useDispatch, useSelector } from "react-redux";
import { auth, selectLoading } from "_pages/loginscreen/authSlice";
import { asyncActionEnums } from "../constants/enums";
import { useEffect, useState } from "react";
import { emailValidation, passwordValidation } from "utils/validation";
export const useLoginHook = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const loadingStatus = useSelector(selectLoading);
  const [isEmailValid, setEmailValid] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadingStatus === asyncActionEnums.ASYNC_ACTION_FAILED) {
      setLoading(false);
    }
  }, [loadingStatus]);
  const submitForm = () => {
    setLoading(true);
    dispatch(auth(email, password));
  };

  const fillEmail = (event: any) => {
    setEmail(event.target.value);
    if (emailValidation(event.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const fillPassword = (event: any) => {
    if (passwordValidation(event.target.value)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
    setPassword(event.target.value);
  };

  return {
    loadingStatus,
    fillEmail,
    fillPassword,
    submitForm,
    isEmailValid,
    isPasswordValid,
    isLoading,
  };
};
