import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Routes from "routes";
import Dialog from "components/Dialog";
import {WelcomeHeader,CommonHeader} from "./Header";
import { useLocation } from 'react-router-dom';
import * as RouteContants from "constants/routeConstants";
import APIResponseAlert from "components/Notification/ApiResponseAlert";
function Layout(props) {
  const { isUserOnline, showWarning, closePupUp, classes } = props;
  const {pathname} = useLocation();
  const GetHeaderComp =  useMemo(()=>{
    switch (pathname) {
      case RouteContants.homePath : return <WelcomeHeader  />;
      default: return <CommonHeader />;
    }
},[pathname])
  return (
    <div className={classes.app}>
      <APIResponseAlert />
      {GetHeaderComp}
      <Routes />
      {!isUserOnline && (
        <Dialog
          type={0}
          open={showWarning}
          callbackCancle={closePupUp}
          message={"Please Check Your Internet Connection."}
          title={"Warning "}
        />
      )}
    </div>
  );
}

Layout.propTypes = {
  isUserOnline: PropTypes.bool,
  showWarning: PropTypes.bool,
  closePupUp: PropTypes.func,
  classes: PropTypes.object,
};

export default Layout;
