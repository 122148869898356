import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  Reducer,
  AnyAction,
} from "@reduxjs/toolkit";
import authSlice from "_pages/loginscreen/authSlice";
import providerSlice from "_pages/provider/providerSlice";
import channelSlice from "_pages/channels/channelSlice";
import tenantSlice from "_pages/tenants/tenantSlice";
import contentTilteSlice from "_pages/contentTitles/contentTitleSlice";
import scheduleEventSlice from "_pages/scheduleEvents/ScheduleEventSlice";
import collectionSlice from "_pages/collections/CollectionsSlice";
import adminSlice from "_pages/admin/adminSlice";
import layoutSlice from "Layout/layoutSlice";
import sortSlice from "components/Sort/Sort.Slice";
import paginationSlice from "components/Pagination/Pagination.Slice";
import reviewsSlice from "_pages/reviews/reviewsSlice";
import socialLinksSlice from "_pages/socialLinks/socialLinksSlice";
import UserManagementSlice from '_pages/userManagement/UserManagement.slice';
import customLinksSlice from "_pages/customLinks/CustomLinksSlice";
import documentsSlice from "_pages/documents/documentsSlice";
import subscriptionsSlice from "_pages/subscriptions/SubscriptionsSlice";
import categorySlice from "_pages/categoryManagement/CategorySlice"
import UserReportSlice from "_pages/userReport/UserReport.slice";
import bulkUploadSlice from "_pages/bulkUpload/BulkUploadSlice";

const combinedReducer = combineReducers({
  auth: authSlice, //Authentication
  provider: providerSlice, //Provider Screen Redux
  channel: channelSlice, //Channel Screen Redux
  tenants: tenantSlice, //Tenant Screen Redux
  contentTitles: contentTilteSlice, //Content Titles Screen Redux
  eventTitles: scheduleEventSlice, //Schedule Event Screen Redux ,
  admin: adminSlice, //Admin Screen Redux ,
  collections: collectionSlice, // Collections Screen Redux ,
  layout: layoutSlice, // Layout Redux
  sortTable: sortSlice, // SortTable Redux
  pagination: paginationSlice, // Pagination Redux
  reviews: reviewsSlice, // Layout Redux
  userManagement: UserManagementSlice, // UserManagement Slice
  settings: socialLinksSlice, // Social Links
  customLinks: customLinksSlice, // Custom Links
  documents: documentsSlice,
  subscriptions: subscriptionsSlice, // subscriptions redux
  category: categorySlice, // categorySlice redux
  userReport:UserReportSlice,
  bulkUpload: bulkUploadSlice, //  bulkUploadSlice redux
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/logout") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;