import { makeStyles } from "@material-ui/core/styles";
import { CompLableCommonStyle } from "./CommonComponent.style";

export const MultiLabelCheckboxComponentStyles = makeStyles((theme) => ({
  ...{ ...CompLableCommonStyle() },
  formControlLable: {
    marginTop: 11,
    marginLeft: '0 !important',
  },
}));
