import { makeStyles } from "@material-ui/core/styles";

export const UploaderStyle = makeStyles((theme) => ({
  small: {
    height: 80,
    width: 80,
    backgroundColor: "#d8d8d8",
  },
  filled: {
    cursor: "normal",
  },
  blank: {
    cursor: "copy",
  },
  big: {
    width: 512,
    height: 150,
    backgroundColor: "#d8d8d8",
  },
  bigTransparent: {
    width: 248,
    height: 1,
    backgroundColor: "#fff",
  },
  medium: {
    width: 248,
    height: 140,
    backgroundColor: "#fff",
  },
  title: {
    marginTop: 8,
    color: "#263238",
  },
  smLiveVideo: {
    marginTop: 0,
  },
  meta: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: 0.83,
    // textAlign: right;
    color: "rgba(0, 0, 0, 0.54)",
    textTransform: "uppercase",
    paddingRight: 5,
  },
  replace: {
    color: theme.palette.primary.main,
    marginTop: 5,
    cursor: "pointer",
    paddingBottom: 10
    // marginLeft: 10,
  },
  replaceDisabled: {
    color: "rgba(0, 0, 0, 0.12) !important",
    marginTop: 17,
  },
  anotherUpload: {
    color: "rgba(0, 0, 0, 0.5) !important",
    marginTop: 17,
  },
  remove: {
    color: theme.palette.primary.main,
    marginTop: 5,
    cursor: "pointer",
  },
  input: {
    display: "none",
    marginLeft: 10,
  },
  cardContent: {
    padding: "0 !important",
  },
  error: {
    color: "#d32f2f",
    marginTop: 17,
    whiteSpace: "pre-line",
    marginLeft: 10,
    //width: 150,
  },
   errorTextCancel: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#000",
    marginTop:0
    },
  scheduleError: {
    color: "#d32f2f",
    marginTop: 17,
    whiteSpace: "pre-line",
  },
  generalImageError: {
    color: "#d32f2f",
    marginTop: 17,
    whiteSpace: "pre-line",
  },
  imageLables: {
    height: 30,
  },
  select: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    alignSelf: "flex-start",
    marginTop: -4,
    // marginLeft: 10,
  },
  videoPreview: {
    height: 80,
  },
}));
