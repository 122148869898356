import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { downloadreportApi, userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { apiResponseEnums } from "constants/enums";

interface ProviderState {
  reviewsData: any;
  data: any;
  loadingReport: any;
  error: any;
  pageLoading: any;
}

const initialState: ProviderState = {
  reviewsData: {},
  data: null,
  loadingReport: "",
  error: {},
  pageLoading: true,
};

export const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviewsLoading: (state, action: PayloadAction<any>) => {
      state.pageLoading = action.payload;
    },
    getReviewsList: (state, action: PayloadAction<any>) => {
      let response = action.payload;
      state.reviewsData = response;
      state.data = response.data;
    },
    setReviewsList: (state, action: PayloadAction<any>) => {
      state.reviewsData = action.payload;
    },
    setLoadingReport: (state, action: PayloadAction<any>) => {
      state.loadingReport = action.payload;
    },
    apiError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getReviewsList,
  setLoadingReport,
  apiError,
  setReviewsList,
  setReviewsLoading,
} = reviewsSlice.actions;

// RR || The function below is called to check user auth status from firebase

export const getReviewsListfromApi = (body = {
  channelId: "" as any,
  providerId: "" as any,
  tenantId: "" as any,
}): AppThunk => (dispatch) => {
  dispatch(setReviewsList(null));
  dispatch(setReviewsLoading(true));
  userApi.post(ApiConstants.getReviewsListAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getReviewsList(res.data));
      dispatch(setReviewsLoading(false));
    } else if (res.status === apiResponseEnums.DELETED) {
      dispatch(setReviewsList([]));
      dispatch(setReviewsLoading(false));
    } else if (res.status === apiResponseEnums.UNAUTHRISED) {
      dispatch(regfeshTokenId(getReviewsListfromApi));
    } else {
      dispatch(getReviewsList({}));
    }
  });
};

export const downloadReviewsDatafromApi = (body = {
  channelId: "" as any,
  providerId: "" as any,
  tenantId: "" as any,
}): AppThunk => (dispatch) => {
  dispatch(setLoadingReport(true));
  downloadreportApi.post(ApiConstants.downloadReviewsAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      const url = window.URL.createObjectURL(new Blob([res.data] as any));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "RatingReviewsReport.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(setLoadingReport(false));
    } else if (res.status === apiResponseEnums.UNAUTHRISED) {
      dispatch(
        regfeshTokenId(downloadReviewsDatafromApi())
      );
      dispatch(setLoadingReport(false));
    } else if (res.status === apiResponseEnums.BAD_REQUEST) {
      dispatch(apiError(res.status));
      dispatch(setLoadingReport(false));
    } else if (res.status === apiResponseEnums.TIME_OUT) {
      dispatch(apiError(500));
      dispatch(setLoadingReport(false));
    } else {
      dispatch(apiError(500));
      dispatch(setLoadingReport(false));
    }
  });
};

export const showError = (err): AppThunk => (dispatch) => {
  dispatch(apiError(err));
};

export const reviewsPageLoading = (state: RootState) =>
  state.reviews.pageLoading;
export const selectReviews = (state: RootState) =>
  state.reviews.reviewsData;
export const reviewsListItems = (state: RootState) =>
  state.reviews.data;
export const loadingReportResponse = (state: RootState) =>
  state.reviews.loadingReport;

export default reviewsSlice.reducer;
