import React, {useState, useEffect} from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "utils/flattenMessages"; // Utility function to parse json as string
import Theme from "utils/theme"; // Material UIs ovverridden theme
import VersionManager from "utils/VersionManager";
//import ErrorBoundary from 'containers/ErrorBoundary'
import "assets/styles/app.css"; // Single and globle css file
import LoginPage from "./_pages/loginscreen";
import { asyncActionEnums, authEnums } from "constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatus, selectAuth, selectLoading } from "_pages/loginscreen/authSlice";
import HomeShimmer from 'components/Loader/HomeScreenShimmer';
import HomeContainer from "_containers/Home.Container";
const config = require("assets/config.json"); // Config file to drive remote settings
const strings = require("assets/translations/en.json"); // String File, synched with PO

/*********************************************************************************************
 * Rajneesh Raghav, Sep, 18, 2020                                                            *
 * VersionManager Funtion will get executed as Window loads, This funtion will make an api   *
 * call to firebase  to check if new version is avialble, if new  version is deployed        *
 *  it will hard refresh the website with Pop Up alert and will remove all cahched files     *
 ********************************************************************************************/

VersionManager();
//Version checked, loading app s
function App() {
  const authStatus = useSelector(selectAuth);
  const authLoading = useSelector(selectLoading);
  const isLoggedIn = authStatus === authEnums.AUTH_STATUS_LOGGED_IN || authStatus === authEnums.AUTH_STATUS_LOGGED_OUT;
  const dispatch = useDispatch();
  useEffect(() => {
    if (authStatus !== authEnums.AUTH_STATUS_LOGGED_IN || authStatus !== authEnums.AUTH_STATUS_LOGGED_OUT) {
      dispatch(checkAuthStatus());
    }
  }, []);
  return (
    <StylesProvider injectFirst>
      <div className="App">
        <ThemeProvider theme={Theme}>
          <IntlProvider
            locale={config?.language}
            messages={flattenMessages(strings)}
            key={config?.language}
            onError={(...e)=>console.log("Error",e)}
          >
            {authLoading === asyncActionEnums.ASYNC_ACTION_SUCCESS ? <HomeContainer /> : isLoggedIn ? <LoginPage /> : <HomeShimmer/>}
          </IntlProvider>
        </ThemeProvider>
      </div>
    </StylesProvider>
  );
}
export default App;
