import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { MainCommonStyle } from "./Common.style";

export const UserManagementScreenStyles = (theme: Theme) =>
  createStyles({
    ...MainCommonStyle(theme),
  });

export interface UserManagementScreenProps
  extends WithStyles<typeof UserManagementScreenStyles> {}
