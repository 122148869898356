import React from 'react';
import Translate from 'utils/translate'
import { Typography } from '@material-ui/core';
import { OpenedInAnotherTabCompStyles } from 'style/components';

export default function UploadInProgess(props) {
    const classes = OpenedInAnotherTabCompStyles();




    return (

        <div className='autoCenter center'>
            <Typography color='primary' variant='h5'>
                Content Upload is going on in another Tab. Please Close this tab.
                </Typography>
        </div>
    );
}

