import { useEffect, useState } from "react";
import { isAsc } from 'components/Sort/Sort.Slice';
import { getSort } from '../../components/Sort/Sort.Slice';
import { useSelector } from 'react-redux';
// import { paginationValues } from '../../constants/enums';
import { usePaginationHook } from 'components/Pagination/Pagination.Hook';
export const useTermsTableHook = (props) => {
  const { optionalData, numSelected } = props;
  const {paginationData,handelPaginate} = usePaginationHook();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([] as any);
  const [page, setPage] = useState(0);
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(paginationData.DEFAULT_PAGE_SIZE);
  const sortAsc = useSelector(isAsc);
  const sortData = useSelector(getSort);
  
  useEffect(()=>{
    setOrder(sortAsc);
    if(orderBy !== "versionNumber") setOrderBy("versionNumber");
  },[sortData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (numSelected === 0) {
      setSelected([]);
    }
  }, [numSelected]);

  useEffect(() => {
    if (optionalData && optionalData.length > 0) {
      setSelected([...optionalData]);
    } else {
      setSelected([]);
    }
    setButtonStatus(true);
  }, [optionalData]);

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    isButtonDesabled,
    setButtonStatus,
    rowsPerPage,
    setRowsPerPage,
    paginationData,
    handelPaginate
  };
};
