import React from "react";
import { Grid, Divider, Typography, Avatar } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { userLogout } from "../_pages/loginscreen/authSlice";
import Translate from "../utils/translate";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Popover from "react-tiny-popover";
import { withRouter } from "react-router-dom";
import { userRoleEnums } from "constants/enums";
import { DialogStyle } from "style/components";
import { resetPaginate } from "./Pagination/Pagination.Slice";


function CustomDialog(props) {
  const dispatch = useDispatch();
  const classes = DialogStyle();
  const [isOpen, openPopUp] = React.useState(false);
  const { showOnlyAvtar } = props;

  const onClickLogout = () => {
    dispatch(resetPaginate());
    dispatch(userLogout());
    props.history.push("/");
  };

  return (
    <React.Fragment>
      <div className={classes.AvatarContainer}>
        {showOnlyAvtar ? (
          <Avatar
            onClick={() => openPopUp(!isOpen)}
            className={props.light ? 'avatar' : 'dark-avatar'}
            alt="Wexer"
          >
            {props.user?.displayName?.slice(0, 1)}
          </Avatar>
        ) : (
          <Popover
            position={["bottom", "left"]}
            align="end"
            padding={14}
            onClickOutside={() => openPopUp(false)}
            isOpen={isOpen}
            containerStyle={{
              boxShadow:
                "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14)",
              borderRadius: "8px",
            }}
            content={
              <div className={classes.paper}>
                <Grid
                  container
                  className={classes.boxes}
                  justify="space-between"
                  direction="row"
                >
                  <div className={classes.popOverItems}>
                    <Typography
                      className="showEllipsis"
                      variant="h5"
                      color="secondary"
                      component="h5"
                    >
                      {props.user?.displayName}
                    </Typography>
                  </div>
                </Grid>

                <Divider className={classes.devider} orientation="horizontal" />

                <Grid
                  container
                  className={classes.boxes}
                  justify="space-between"
                  direction="row"
                >
                  <div
                    className={classes.button}
                    onClick={() => onClickLogout()}
                  >
                    <ArrowBackIcon className={classes.leftArrow} />

                    <Typography variant="h6" color="secondary" component="h6">
                      {Translate({ id: "logout.logoutLabel" })}
                    </Typography>
                  </div>
                  <div className={classes.roleText}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      component="h6"
                    >
                      {props.user?.role === userRoleEnums.ADMIN_USER
                        ? Translate({ id: "logout.adminRole" })
                        : props.user?.role ===
                          userRoleEnums.TENANT_AS_PROVIDER_USER
                        ? Translate({ id: "logout.tenantRole" })
                        : props.user?.role === userRoleEnums.CS_USER
                        ? Translate({ id: "logout.csRole" })
                        : Translate({ id: "logout.providerRole" })}
                    </Typography>
                  </div>
                </Grid>
              </div>
            }
          >
            <Avatar
              onClick={() => openPopUp(!isOpen)}
              className={props.light ? 'avatar' : 'dark-avatar'}
              alt="Wexer"
            >
              {props.user?.displayName?.slice(0, 1)}
            </Avatar>
          </Popover>
        )}
      </div>
    </React.Fragment>
  );
}
export default withRouter(CustomDialog);
