export const emailValidation = (email) => {
  if (
    /^[a-zA-Z0-9._-]{1,62}@[a-zA-Z0-9](-?[a-zA-Z0-9]){1,62}(\.[a-zA-Z](-?[a-zA-Z0-9]){1,62})+$/.test(
      email
    )
  ) {
    return true;
  }else{ return false;}
};

export const passwordValidation = (password) => {
  if (password.length > 5) {
    return true;
  }else{ return false}
};

export const securePasswordValidation = (password) => {
  if (/^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const phoneValidation = (phone) => {
  if (/^[\d|\+|\(]+[\)|\d|\s|-]*[\d]$/.test(phone)) {
    return true;
  } else {
    return false;
  }
};

export const hasBlankSpaces = (str) => {
  if (str != null) {
    if (str.match(/^\s+$/) !== null) {
      return false;
    } else {
      return true;
    }
  }
};

export const nameValidation = (str) => {
  if (str.length < 1) return false;
  if (str.length === 1) {
    return /[A-Za-z0-9]/.test(str);
  }
  return true;
}

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(http|https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};
