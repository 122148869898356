import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Images from "components/SVGImages";
interface TooltipProps {
  title: any;
  isEvent?: any;
}
const TooltipInfo = ({ title, isEvent }: TooltipProps) => {
  return (
    <React.Fragment>
      <Tooltip title={title}>
        <IconButton>
          <img
            src={Images.Info}
            alt="tooltip"
            className={isEvent ? "imgIconDuration" : "imgIcon"}
          />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

export default TooltipInfo;
