import { useEffect, useState } from "react";
import { ScrollTop } from "utils/utilityFunctions";
import { useSelector } from 'react-redux';
import { getSort } from '../../components/Sort/Sort.Slice';
// import { paginationValues } from '../../constants/enums';
import { usePaginationHook } from 'components/Pagination/Pagination.Hook';

export const usePublishingTableHook = (props) => {
  const { optionalData, desableOption } = props;
  const {paginationData,handelPaginate} = usePaginationHook();
  const sortData:any = useSelector(getSort);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([] as any);
  const [page, setPage] = useState(0);
  const [isButtonDesabled, setButtonStatus] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(paginationData.DEFAULT_PAGE_SIZE);
  const [isAllSelected, setAllSelectingStatus] = useState(false);

  useEffect(() => {
    ScrollTop();
  }, []);

  useEffect(() => {
    if (optionalData && optionalData.length > 0) {
      setSelected([...optionalData]);
    } else {
      setSelected([]);
    }
  }, [optionalData]);

  useEffect(() => {
    if (desableOption === true) {
      setButtonStatus(true);
    } else if (desableOption === false) {
      setButtonStatus(false);
    }
  }, [desableOption]);
  
  useEffect(() => {
    if (sortData.isDefault) {
      setOrder("asc");
    } else {
      if (sortData.index === 1) {
        setOrder("asc");
      } else {
        setOrder("desc");
      }
    }
    setOrderBy(props.headCells[0].id);
  }, [sortData, props.headCells]);

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    isButtonDesabled,
    setButtonStatus,
    rowsPerPage,
    setRowsPerPage,
    isAllSelected,
    setAllSelectingStatus,
    paginationData,
    handelPaginate
  };
};
