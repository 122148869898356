import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "components/Dialog";
import { HeaderHooks } from "../Hooks/Header.Hooks";

const LayoutAppBar = (props) => {
  const {
    classes,
    handleDrawerToggle,
    user,
  } = HeaderHooks();
  return (
    <AppBar
      className={classes.header}
      color="inherit"
      position="static"
      elevation={0}
    >
      <Toolbar>
        <Grid container spacing={1} className="pt5" alignItems="center">
          <Hidden smUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>
            <Dialog user={user} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;
