import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { apiResponseEnums, actionStatus, USER_MESSAGES } from "constants/enums";

import {
  SocialLinkModel,
  ConfigurationTypeData,
  GetApiSocialLinksPayload,
  UpdateApiSocialLinksPayload,
} from "models/SiteSettings/SocialLinksModel";
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage } from "utils/utilityFunctions";
import { ErrorObjectType } from "models/common.model";

interface SiteSettingsState {
  socialLinksData: SocialLinkModel | null;
  data: ConfigurationTypeData[] | null;
  error: number;
  pageLoading: boolean;
}

const initialState: SiteSettingsState = {
  socialLinksData: null,
  data: null,
  error: 0,
  pageLoading: false,
};
export const socialLinksSlice = createSlice({
  name: "socialLinks",
  initialState,
  reducers: {
    setSocialLinksLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    getSiteConfig: (
      state,
      action: PayloadAction<SocialLinkModel | null >
    ) => {
      let response = action.payload;
      state.socialLinksData = response;
    },
    setSocialLinksList: (
      state,
      action: PayloadAction<SocialLinkModel | null>
    ) => {
      state.socialLinksData = action.payload;
    },
    setApiError: (state, action: PayloadAction<number>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getSiteConfig,
  setApiError,
  setSocialLinksList,
  setSocialLinksLoading,
} = socialLinksSlice.actions;

export const updateSocialLinksSettings = (
  body: UpdateApiSocialLinksPayload
): AppThunk => (dispatch) => {
  dispatch(setSocialLinksLoading(true));
  userApi.put(ApiConstants.updateSiteConfigAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_MESSAGES.SAVED,
        })
      );
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setSocialLinksLoading(false));
  });
};
// get site configuration for configurations type = sociallinks

export const getSiteConfigListFromApi = (
  body: GetApiSocialLinksPayload
): AppThunk => (dispatch) => {
  dispatch(setSocialLinksList(null));
  dispatch(setSocialLinksLoading(true));
  userApi.post(ApiConstants.getSiteConfigAPI, body).then((res) => {
    if (res.status === apiResponseEnums.OKAY) {
      dispatch(getSiteConfig(res.data as SocialLinkModel));
    
    } else {
      dispatch(getSiteConfig(null));
      dispatch(
        setApiResponse({
          status: res.status,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
    }
    dispatch(setSocialLinksLoading(false));
  });
};

export const resetSettingsData = (): AppThunk => (dispatch) => {
  dispatch(getSiteConfig(null));
};
export const socialLinksPageLoading = (state: RootState) =>
  state.settings.pageLoading;
export const selectSocialLinks = (state: RootState) =>
  state.settings.socialLinksData;
export const settingsCreatedOrNot = (state: RootState) => state.settings.error;

export default socialLinksSlice.reducer;
