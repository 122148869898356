import {
  createStyles,
  Theme,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  FormCommonStyle,
  InputCommonStyle,
  MainCommonStyle,
  PaddingCommonStyle,
} from "./Common.style";

export const ScheduleEventStyle = (theme: Theme) =>
  createStyles({
    ...MainCommonStyle(theme),
  });

export const ScheduleEventCreationStyles = makeStyles((theme) => ({
  ...{ ...FormCommonStyle(theme) },
  select: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    marginTop: -4,
    alignItems: "flex-end !important",
    justifyContent: "flex-end !important",
  },
  btnStatus: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    marginTop: "10px",
    alignItems: "flex-end !important",
    justifyContent: "flex-end !important",
  },
  metaData: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    marginTop: -4,
  },
  formControlCopyLable: {
    cursor: "copy !important",
    margin: "2px 0 5px 9px",
  },
  footerText: {
    paddingLeft: 32,
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.58,
    letterSpacing: 0.4,
    color: "rgba(0, 0, 0, 0.54)",
    margin: "5px 0px",
  },
  ...PaddingCommonStyle,
  formControl2: {
    margin: "21px 0px 8px 0px",
    width: "100%",
  },
  ...InputCommonStyle,
  input: {
    display: "none",
  },
  selectedLink: {
    color: "#36dcb6",
    fontSize: 16,
  },
  notSelectedLink: {
    color: "black",
    fontSize: 16,
  },
  labelEventType: {
    margin: "20px 0 10px 0",
  },
  disabledRadio: {
    color: "#f57c00",
  },
  checked: {},
  manageClassHr: {
    margin: "0px",
    color: "black",
    height: "1px",
    width:'100%'
  },
  choose: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    color: "#36dcb6",
    cursor: "pointer",
    height: "100%",
    alignItems: "center",
    display: "inline-flex"
  },
  chooseCLass:{
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.09px",
    color: "#36dcb6",
    cursor: "pointer",
    height: "100%",
    alignItems: "center",
    display: "inline-flex",
    fontSize: "12px",
    marginBlock: "0.3em",
    marginRight: "14px"
  },
  container: {
    width: "50%",
  },
  marginBottom: {
    marginBottom: "15% !important",
  },
  video: {
    marginTop: "2rem",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.4px",
    color: "#263238",
  },
  eventDetails: {
    marginTop: "21px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.4px",
    color: "#263238",
  },
  categoryTitle: {
    marginTop: "15px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.4px",
    color: "#263238",
    marginBottom: "15px",
  },
  rightBtn: {
    textAlign: "right",
  },
  scheduleMarginLeft: { marginLeft: 10 },
  scheduleCatMarginLeft: { marginLeft: 10, marginTop: 10 },
}));

export interface ScheduleEventProps
  extends WithStyles<typeof ScheduleEventStyle> {}
